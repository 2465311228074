import { Tag } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { PROFESSION_OPTIONS } from "src/config/const/profession";
import { set_expertise_propose } from "src/config/redux/slicer/expertise/expertiseProposeSlicer";
import { onProposeHealthCare } from "src/config/services/fellas/health-care.api";

function ExpertisePropose() {
  const expertise_propose = useSelector((state: any) => state.ExpertisePropose.value)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { toast } = useToast()
  const updateValue = (new_value) => {
    const new_expertise_propose = { ...expertise_propose, ...new_value }
    dispatch(set_expertise_propose(new_expertise_propose))
  }
  const [is_terms_checked, setTermsChecked] = useState(false)

  const submitForm = async (e) => {
    e.preventDefault()
    const resp = await onProposeHealthCare(expertise_propose)
    ToastAlert(resp, 'Berhasil mengajukan sebagai tenaga ahli', () => window.location.replace('/app?page=main'))
  }
  return (
    <div className="container gap-1 grid">
      <div className="text-3xl font-semibold pb-4">Daftar Tenaga Ahli</div>
      <form className="grid gap-4">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4 max-md:gap-2 md:h-[440px]">
          <div className="grid md:grid-rows-5 gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium">Nama Lengkap</label>
              <Input placeholder="Nama lengkap dengan Gelar" value={expertise_propose.fullname} onChange={e => updateValue({ fullname: e.target.value })} />
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">Preferensi Layanan</label>
              <div className="flex justify-evenly">
                <div className="flex items-center gap-2"><Checkbox value={expertise_propose.is_offline} onCheckedChange={e => updateValue({ is_offline: !expertise_propose.is_offline })} />Offline</div>
                <div className="flex items-center gap-2"><Checkbox value={expertise_propose.is_online} onCheckedChange={e => updateValue({ is_online: !expertise_propose.is_online })} />Online</div>
                <div className="flex items-center gap-2"><Checkbox value={expertise_propose.is_homecare} onCheckedChange={e => updateValue({ is_homecare: !expertise_propose.is_homecare })} />Homecare</div>
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">File STr (.pdf)</label>
              <Input type="file" accept="application/pdf" onChange={e => updateValue({ str_file: e.target.files![0] })} />
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">File SIP (.pdf)</label>
              <Input type="file" accept="application/pdf" onChange={e => updateValue({ sip_file: e.target.files![0] })} />
            </div>
          </div>
          <div className="md:grid md:grid-rows-5 max-md:flex max-md:flex-col-reverse gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium">Pilih Profesi</label>
              <Select onValueChange={e => updateValue({ profession: e })}>
                <SelectTrigger><SelectValue>{expertise_propose.profession}</SelectValue></SelectTrigger>
                <SelectContent>
                  {PROFESSION_OPTIONS.map(p => (
                    <SelectItem key={p.key} value={String(p.key)}>{p.text}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">Keahlian dan Tindakan</label>
              <Input placeholder="Vaksin, Nutrisi, ..." onChange={e => updateValue({ skills: e.target.value.replace(', ', ',') })} />
              <div className="flex flex-wrap gap-1">
                {expertise_propose.skills ? expertise_propose.skills.split(',').map((s, i) => <Tag color="pink" key={i}>{s}</Tag>) : <Tag color="pink">None</Tag>}
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">File Curriculum Vitae (.pdf)</label>
              <Input type="file" accept="application/pdf" onChange={e => updateValue({ cv_file: e.target.files![0] })} />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2"><Checkbox checked={is_terms_checked} onCheckedChange={e => setTermsChecked(Boolean(e))} />
          Saya setuju dengan <a className="text-primary cursor-pointer">syarat dan ketentuan</a>
        </div>
        <Button type="submit" disabled={!is_terms_checked} onClick={e => submitForm(e)}>Daftar</Button>
      </form>
    </div>
  );
}

export default ExpertisePropose;