import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Header from "src/components/header";
import Navbar from "src/components/navbar";
import NotFound from "src/components/not-found/NotFound";
import { INITIAL_EXPERTISE_NAVBAR } from "src/config/const/menubar";
import { set_expertise_data, set_expertise_navbar, set_user_is_expertise } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { getHealthCareDataByQuery } from "src/config/services/fellas/health-care.api";
import { useMenuBarStore } from "src/config/store/global/menubar";
import AppointmentExpertise from "./tabs/appointment";
import DashboadExpertise from "./tabs/dashboard";
import AppointmentHistory from "./tabs/history";
import AppointmentPatient from "./tabs/patient";
import ExpertiseWallet from "./tabs/wallet";

function ExpertisePage() {
  const user = getCurrentUser()
  const { health_care_id } = user
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'
  const { value: expertise, user_is_expertise, navbar } = useSelector((state: any) => state.Expertise)
  const { set_navbar_menu } = useMenuBarStore()

  const getExpertiseData = async () => {
    const resp = await getHealthCareDataByQuery({ params: { id: health_care_id } })
    dispatch(set_expertise_data(resp.results.length > 0 ? resp.results[0] : []))
    dispatch(set_user_is_expertise(Object.keys(resp.results).length > 0))
  }

  const main_menu = (
    navbar === 'main' ? <DashboadExpertise expertise={expertise} is_dashboard /> :
      navbar === 'appointment' ? <AppointmentExpertise /> :
        navbar === 'patient' ? <AppointmentPatient /> :
          navbar === 'history' ? <AppointmentHistory /> :
            navbar === 'wallet' ? <ExpertiseWallet /> :
              <NotFound />
  )
  useEffect(() => {
    getExpertiseData()
    set_navbar_menu(INITIAL_EXPERTISE_NAVBAR)
    ReactGA.send({ hitType: "pageview", page: "/app/expertise", title: "Expertise Page" });
  }, []);

  useEffect(() => {
    dispatch(set_expertise_navbar(page))
  }, [page]);

  return (
    <div className="bg-white min-h-screen overflow-hidden">
      <Header is_login />
      <div className="lg:container flex pt-[5vh] gap-2">
        <div className="max-lg:hidden w-1/5 h-fit fixed bottom-10 left-10">
          <img src={require(`src/assets/images/expertise_role/${(user.gender || 'laki-laki').toLowerCase()}.png`)} />
          <p className="text-[2vw] absolute top-[60%] left-3/4 transform -translate-x-1/2 -translate-y-1/2 text-left leading-5">
            {expertise.detail?.profession}
          </p>
        </div>
        <div className="w-1/5 max-lg:hidden">
          <Navbar navbar_menu={INITIAL_EXPERTISE_NAVBAR} setNavbar={set_expertise_navbar} selected_navbar={navbar} />
        </div>
        <div className="w-4/5 max-lg:w-screen text-start pt-12 pb-6">
          {main_menu}
        </div>
      </div>
    </div>
  );
}

export default ExpertisePage;