import { ClipboardList } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "src/components/ui/badge";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { set_appointment_form } from "src/config/redux/slicer/health-care/appointmentSlicer";

function AppointmentConditionCard() {
  const dispatch = useDispatch()
  const { appointment_form } = useSelector((state: any) => state.Appointment)

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-3">
          <ClipboardList className="w-5 h-5 text-primary" />
          <CardTitle> Keluhan</CardTitle>
        </div>
      </CardHeader>
      <div className="mx-14 pb-6">
        <div className="space-y-1">
          <div>Isi Keluhan Anda</div>
          <Input onChange={e => dispatch(set_appointment_form({ ...appointment_form, condition: e.target.value }))} value={appointment_form.condition} placeholder="i.e. Pusing, Sakit Kepala, Mual" />
          <div className="flex flex-wrap gap-2">
            {appointment_form.condition ? appointment_form.condition.split(',').map((item, i) => <Badge key={i}>{item}</Badge>) : <Badge>None</Badge>}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AppointmentConditionCard;