import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Header from "src/components/header";
import Navbar from "src/components/navbar";
import NotFound from "src/components/not-found/NotFound";
import { INITIAL_MAIN_NAVBAR } from "src/config/const/menubar";
import { set_menu } from "src/config/redux/slicer/main/menuBarSlicer";
import { getCurrentUser, onLogout } from "src/config/services/core/auth.api";
import { useMenuBarStore } from "src/config/store/global/menubar";
import ExpertisePropose from "../Expertise/propose";
import Appointment from "./appointment";
import ChildProfile from "./child_profile";
import Dashboard from "./dashboard";

function MainPage() {
  const dispatch = useDispatch()
  const location = useLocation()
  const user = getCurrentUser()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'
  const logout = queryParams.get('logout')

  const { value: navbar } = useSelector((state: any) => state.MenuBar)
  const { navbar_menu, set_navbar_menu } = useMenuBarStore()

  useEffect(() => {
    let sidebar_expertise = INITIAL_MAIN_NAVBAR
    if (user.health_care_id) {
      sidebar_expertise = sidebar_expertise.filter(nav => nav?.key !== 'expertise')
    }
    set_navbar_menu(sidebar_expertise)
    if (logout) onLogout()
  }, []);

  const main_menu = (
    navbar === 'main' ? <Dashboard /> :
      navbar === 'child_profile' ? <ChildProfile /> :
        navbar === 'appointment' ? <Appointment /> :
          navbar === 'expertise' ? <ExpertisePropose /> :
            <NotFound />
  )

  useEffect(() => {
    dispatch(set_menu(page))
    ReactGA.send({ hitType: "pageview", page: "/app", title: "Dashboard Page" });
  }, [page]);
  return (
    <div className="bg-white min-h-screen overflow-hidden">
      <Header is_login />
      <div className="lg:container flex pt-8 gap-2">
        <div className="w-1/5 max-lg:hidden">
          <Navbar navbar_menu={navbar_menu} setNavbar={set_menu} selected_navbar={navbar} />
        </div>
        <div className="w-4/5 max-lg:w-screen text-start pt-12 pb-4">
          {main_menu}
        </div>
      </div>
    </div>
  );
}

export default MainPage;