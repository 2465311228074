import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import './assets/styles/globals.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ConfigProvider } from 'antd';
import { theme } from './assets/styles/ant.theme';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// get client id from env variable 
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
const GOOGLE_MAP_LIBRARIES = ['places']
const QUERY_CLIENT = new QueryClient()

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <APIProvider apiKey={GOOGLE_MAP_API_KEY} libraries={GOOGLE_MAP_LIBRARIES}>
      <ConfigProvider theme={theme}>
        <QueryClientProvider client={QUERY_CLIENT}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </QueryClientProvider>
      </ConfigProvider>
    </APIProvider>
  </GoogleOAuthProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
