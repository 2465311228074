import { useSelector } from "react-redux";
import { getCurrentUser, getJWT } from "src/config/services/core/auth.api";
import { iconClassName, useMenuBarStore } from "src/config/store/global/menubar";
import Loading from "../loading";
import { Button, Dropdown, Menu } from "antd";
import FellasIcon from "src/assets/icons/Fellas.png";
import { Contact2, User, UserCog2 } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ItemType } from "antd/es/menu/hooks/useItems";
import MDSidebar from "../navbar/md-sidebar";

interface HeaderProps {
  is_login?: boolean
  header_key?: string
}

function Header({ is_login, header_key = 'service' }: HeaderProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const token = getJWT().key

  let user = getCurrentUser()
  const { header_menu, user_menu, set_user_menu } = useMenuBarStore()
  const { on_progress } = useSelector((state: any) => state.MenuBar)

  const getUserHeaderName = () => {
    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`
    } else {
      return user.username
    }
  }

  const updateUserMenu = () => {
    const updated_user_menu: ItemType[] = [...user_menu]
    if (user.is_staff) {
      updated_user_menu.splice(1, 0,
        {
          key: 'admin',
          label: 'Admin',
          icon: <UserCog2 className={iconClassName} />,
          onClick: () => window.location.replace('https://admin.fellas.id/?token=' + token)
        }
      )
    }
    if (user.health_care_id) {
      updated_user_menu.splice(1, 0,
        {
          key: 'expertise',
          label: 'Tenaga Ahli',
          icon: <Contact2 className={iconClassName} />,
          onClick: () => navigate('/app/expertise')
        },
      )
    }
    const filtered_menu = updated_user_menu.filter(item =>
      // location.pathname === '/app' ? item?.key !== 'dashboard' :
      location.pathname === '/app/admin' ? item?.key !== 'admin' :
        location.pathname === '/app/expertise' ? item?.key !== 'expertise' :
          true
    )
    const unique_menu = Array.from(new Map(filtered_menu.map(item => [item?.key, item])).values())
    set_user_menu(unique_menu);
  }

  useEffect(() => {
    updateUserMenu()
  }, [location.pathname]);

  return (
    <div className="z-10 w-full pl-10 pr-4 bg-white h-16 fixed shadow-lg flex items-center justify-between">
      <MDSidebar />
      {on_progress && <Loading />}
      <div className="flex items-center h-full">
        <img src={FellasIcon} width={64} height={64} />
        <Menu className="w-3/4 h-full gap-8 max-lg:hidden" selectedKeys={[header_key]} mode="horizontal" items={header_menu} />
      </div>
      {is_login
        ?
        <Dropdown menu={{ items: user_menu }} arrow>
          <div className="cursor-pointer items-center flex gap-2">
            <User className="text-primary w-8 h-8" />
            <div className="hover:text-primary grid align-middle text-start">
              <div className="text-sm font-semibold ">{getUserHeaderName() || '...'}</div>
              {user.is_staff && <div className="text-xs">Admin</div>}
            </div>
          </div>
        </Dropdown>
        : <Button className="px-6 bg-primary" size="large" type="primary" onClick={() => navigate('/')}>Masuk</Button>
      }
    </div>
  );
}

export default Header;