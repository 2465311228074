import { ColumnDef } from "@tanstack/table-core"
import { Badge } from "src/components/ui/badge"
import { STATUS_PAYMENT_TRANSACTION } from "src/config/const/status"
import { AppointmentType } from "src/lib/types"
import { cn } from "src/lib/utils"

export const columns: ColumnDef<AppointmentType>[] = [
  {
    id: 'expertise_fullname',
    header: "Tenaga Ahli",
    cell: ({ row }) => {
      const data = row.original
      const { health_care } = data
      return (
        <p>{health_care?.detail?.fullname}</p>
      )
    }
  },
  {
    id: 'status',
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const data = row.original
      let { status, location } = data
      if (status === 'Completed' && location === 'Online') {
        status = data.is_settled ? data.status : 'Accepted'
      }
      const { className, text } = STATUS_PAYMENT_TRANSACTION[status]

      return (
        <Badge className={className}>{text}</Badge>
      )
    }
  },
  {
    id: 'datetime',
    header: "Date (GMT+7)",
    cell: ({ row }) => {
      const data = row.original
      return (
        <p>{data.date}, {data.time_range}</p>
      )
    }
  },
  {
    id: 'location',
    accessorKey: 'location',
    header: "Layanan",
  },
  {
    id: 'registration_num',
    accessorKey: 'registration_num',
    header: "Appointment ID",
  },
  {
    id: 'income',
    header: "Nominal",
    cell: ({ row }) => {
      const data = row.original
      const { amount } = data
      const fees = Math.round(amount * 0.3)
      let { status, location } = data
      if (status === 'Completed' && location === 'Online') {
        status = data.is_settled ? data.status : 'Accepted'
      }
      const { className, text } = STATUS_PAYMENT_TRANSACTION[status]
      return (
        <b className={cn(text === 'Sukses' && (location === 'Online' ? "text-success" : "text-blue-500"))}>
          Rp {fees.toLocaleString('id')}
        </b>
      )
    }
  },
]