import { CalendarCheck, CheckCircle, MonitorSmartphone, Stethoscope } from "lucide-react";
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import Header from "src/components/header";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { set_field_query, set_profession_query, set_search_query } from "src/config/redux/slicer/health-care/searchQuerySlicer";
import { getJWT } from "src/config/services/core/auth.api";
import { cn } from "src/lib/utils";
import HealthCareQuery from "./query";

// Images
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import doctor_img from "src/assets/images/Doctor.png";
import hc_step1_img from "src/assets/images/HealthCare_Step1.png";
import hc_step2_img from "src/assets/images/HealthCare_Step2.png";
import hc_step3_img from "src/assets/images/HealthCare_Step3.png";
import main_img from "src/assets/images/LoginPage.png";
import midwife_img from "src/assets/images/Midwife.png";
import nutrition_consult_img from "src/assets/images/NutritionConsultant.png";
import psychology_img from "src/assets/images/Psychology.png";

function HealthCare() {
  const is_login = Boolean(getJWT().key)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_filter = queryParams.get('filter') || ''
  const query_profession = queryParams.get('profession') || ''
  const query_location = queryParams.get('location') || 'all'
  const query_gender = queryParams.get('gender') || 'all'
  const query_service = queryParams.get('service') || 'all'

  const { query, field, profession } = useSelector((state: any) => state.SearchQuery)
  const profession_options = [
    { key: 'Dokter', name: 'Dokter', image: doctor_img },
    { key: 'Psikologi', name: 'Psikologi', image: psychology_img },
    { key: 'Bidan', name: 'Bidan', image: midwife_img },
    { key: 'Konselor Gizi', name: 'Konselor Gizi', image: nutrition_consult_img },
  ]

  const onSelectProfession = (item) => () => {
    dispatch(set_profession_query(item.key))
    navigate(`?filter=${query_filter || field}&profession=${item.key}`)
  }
  useEffect(() => {
    if (query_filter || query_profession) {
      if (query_filter) dispatch(set_field_query(query_filter))
      dispatch(set_search_query(`?filter=${query_filter}&profession=${query_profession}&location=${query_location}&gender=${query_gender}&service=${query_service}`))
    }
  }, [query_filter, query_profession]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/health-care", title: "Health Care Page" });
  }, []);

  const InitialPage = () => (
    <div>
      <div className="md:container max-md:mx-2 mt-12 mb-24 max-md:mb-12">
        <div className="font-bold text-2xl">Bagaimana cara memesan layanan kesehatan di FELLAS?</div>
        <div className="space-y-8 mt-12 mb-24 max-md:mb-6">
          <div className="flex gap-8 mx-[4vw] items-center justify-center">
            <img src={hc_step1_img} className="h-48 max-md:h-16" />
            <hr className="border-secondary/80 border-[1.2px] rounded-full w-[8vw]" />
            <img src={hc_step2_img} className="h-48 max-md:h-16" />
            <hr className="border-secondary/80 border-[1.2px] rounded-full w-[8vw]" />
            <img src={hc_step3_img} className="h-48 max-md:h-16" />
          </div>
          <div className="grid grid-cols-3 md:gap-x-20 gap-y-2 md:mx-[4vw] items-start justify-center">
            <p className="md:text-xl font-semibold">Cari Layanan Kesehatan Anda</p>
            <p className="md:text-xl font-semibold">Isi Form Pendaftaran</p>
            <p className="md:text-xl font-semibold ">Temui Layanan Kesehatan Anda</p>
            <p className="max-md:text-xs">Pastikan anda memiliki akun Fellas, cari layanan sesuai kebutuhan anda dikolom pencarian atau pilih layanan dibawah halaman ini</p>
            <p className="max-md:text-xs">Pilih waktu dan jenis layanan yang tersedia, kemudian isi form pendaftaran sesuai dengan keluhan anda dan lanjut ke proses pembayaran</p>
            <p className="max-md:text-xs">Setelah pesanan anda dikonfirmasi, pastikan anda menghadiri pesanan layanan kesehatan anda</p>
          </div>
        </div>
        <div className="font-bold text-2xl md:mx-[25vw]">Apa yang anda bisa dapatkan pada layanan kesehatan dari FELLAS?</div>
        <div className="grid grid-cols-2 gap-8 items-center my-12 max-md:my-6">
          <div className="flex flex-col max-md:hidden gap-2 items-center ">
            <div className="justify-center gap-16 flex">
              <MonitorSmartphone className="h-24 w-24 stroke-[1.5] stroke-primary" />
              <Stethoscope className="h-24 w-24 stroke-[1.5] stroke-primary" />
            </div>
            <CalendarCheck className="h-24 w-24 stroke-[1.5] stroke-primary" />
          </div>
          <div className="space-y-8 max-md:space-y-2 max-md:col-span-2">
            <div className="flex gap-8 items-center max-md:text-sm text-start">
              <CheckCircle className="stroke-primary" />Secara cepat memeriksa kebutuhan layanan kesehatan anda
            </div>
            <div className="flex gap-8 items-center max-md:text-sm text-start">
              <CheckCircle className="stroke-primary" />Praktisi yang siap untuk membantu permasalahan ibu dan anak
            </div>
            <div className="flex gap-8 items-center max-md:text-sm text-start">
              <CheckCircle className="stroke-primary" />Secara cepat memeriksa kebutuhan layanan kesehatan anda
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary/20">
        <div className="grid grid-cols-4 max-md:grid-cols-2 justify-center gap-12 max-md:gap-4 p-12 max-md:p-4">
          {profession_options.map(item => (
            <div key={item.key} className="bg-white border w-fit rounded-md cursor-pointer group relative" onClick={onSelectProfession(item)}>
              <img src={item.image} className="rounded-md opacity-30 hover:opacity-90 duration-300 hover:shadow-md" />
              <div className="opacity-0 group-hover:opacity-100 rounded-b-md duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end bg-white text-primary font-medium">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
  const submitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    navigate(`?filter=${field}&profession=${query_profession || 'all'}`)
  }
  return (
    <div className="bg-white min-h-screen">
      <Header is_login={is_login} header_key="health-care"/>
      <div className={cn(query ? "pt-12 h-70 transition-all" : "pt-16 max-md:pt-12 md:h-80", "bg-primary/70 grid grid-cols-3")}>
        <div className={cn(query ? "col-span-3" : "col-span-2 max-md:col-span-3", "md:my-16 max-md:mt-8 max-md:mb-4 md:pl-16 max-md:mx-auto container max")}>
          <div className="grid items-center h-full">
            <div className={cn(query ? "text-center" : "text-start", "text-white font-black text-3xl max-md:hidden mb-2")}>
              Temukan Layanan Kesehatan Anda dan Anak Anda
            </div>
            <form className="flex" onSubmit={submitSearch}>
              {/* <Button variant="ghost" className="bg-white gap-2 text-muted-foreground pr-16 rounded-r-none border-e-2 border-muted-foreground/30" >
                <MapPinIcon /> Lokasi
              </Button> */}
              <Input
                placeholder="Doktor, Ahli, Gizi ..." value={field} onChange={e => dispatch(set_field_query(e.target.value))}
                className="bg-white rounded-r-none focus-visible:ring-0 focus-visible:ring-offset-0"
              />
              <Button type="submit" className="px-12 bg-pink-600 hover:bg-pink-700 rounded-l-none">Cari</Button>
            </form>
          </div>
        </div>
        {!query && <div className="justify-center flex max-md:hidden">
          <img src={main_img} className="h-64 pt-8" />
        </div>}
      </div>
      {query
        ? <HealthCareQuery />
        : <InitialPage />
      }
    </div>
  );
}

export default HealthCare;