import Axios, { AxiosRequestConfig } from 'axios';
import { authHeader } from '../core/auth.api';
import { set_vaccine_progress } from 'src/config/redux/slicer/lisban/lisbanAppSlicer';
import { store } from 'src/config/redux/store';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';

const API_URL = "/api/v1/vaccine/"

export const onGetVaccineScheduler = async (data: any) => {
  const requestConfig: AxiosRequestConfig = {
    method: 'get',
    url: API_URL + `scheduler/${data.query || ''}`,
    headers: authHeader(),
    data
  }
  try {
    const { data: response } = await Axios.request(requestConfig)
    return response
  }
  catch (err: any) {
    const errMessage = err.response.data
    return { error: errMessage }
  }
}

export const onCreateVaccineScheduler = async (data: any) => {
  store.dispatch(set_vaccine_progress(true))
  const requestConfig: AxiosRequestConfig = {
    method: 'post',
    url: API_URL + `scheduler/`,
    headers: authHeader(),
    data
  }
  try {
    const { data: response } = await Axios.request(requestConfig)
    return response
  }
  catch (err: any) {
    const errMessage = err.response.data
    return { error: errMessage }
  } finally {
    store.dispatch(set_vaccine_progress(false))
  }
}

