import { Button, Card, Carousel, Input, Popover, Rate } from "antd";
import { Building, CalendarDays, ChevronRight, Clock, Cross, Info, User } from "lucide-react";
import { useMutation } from "react-query";
import { ToastAlert } from "src/components/ui/toaster";
import { onUpdateAppointment } from "src/config/services/fellas/appointment.api";
import { useGlobalStore } from "src/config/store/global";
import { AppointmentType } from "src/lib/types";
import { cn } from "src/lib/utils";

export default function NotRateAppointment() {
  const { rate_appointment, set_rate_appointment } = useGlobalStore()
  const editAppointment = (selected: AppointmentType, key: 'rating' | 'description', value) => {
    const new_appointment = rate_appointment.map(item => item.id === selected.id
      ? ({ ...item, review: { ...item.review, [key]: value } })
      : item)
    set_rate_appointment(new_appointment)
  }

  const { mutate: submitReview, isLoading } = useMutation(async (selected: AppointmentType) => {
    const { id, review } = selected
    const resp = await onUpdateAppointment({ id, review })
    ToastAlert(resp, 'Terimakasih atas penilaiannya')
    set_rate_appointment(rate_appointment.filter(item => item.id !== id))
  })
  return (
    <Carousel arrows dotPosition="left" infinite={false} draggable className="px-8">
      {rate_appointment.map(item => (
        <Card key={item.id} className="shadow mb-12">
          <div className="flex gap-8 h-52 items-center">
            <img src={item.health_care.profile_img} className="h-24 md:h-28 rounded-full aspect-square" />
            <div className="grid md:grid-cols-2 gap-2">
              <div className="space-y-2">
                <div className="flex">
                  <p className="text-lg font-semibold">{item.health_care.detail.fullname}</p>
                  <Popover content={<AppointmentContent item={item} />} trigger={['click']}>
                    <Info className="text-primary md:hidden h-4 hover:text-secondary" />
                  </Popover>
                </div>
                <AppointmentContent item={item} className="max-md:hidden" />
              </div>
              <div className="space-y-2 flex flex-col items-center justify-center">
                <Rate value={item.review.rating || 0} allowClear allowHalf autoFocus onChange={val => editAppointment(item, 'rating', val)} />
                <Input.TextArea value={item.review.description} placeholder="Bagaimana pengalaman konsultasi anda?" style={{ height: '12vh' }} onChange={e => editAppointment(item, 'description', e.target.value)} />
                <Button disabled={!item.review.rating} loading={isLoading} type="primary" className="bg-primary w-full" onClick={() => submitReview(item)}>Selesai</Button>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </Carousel>
  )
}

function AppointmentContent({ item, className }: {
  item: AppointmentType
  className?: string
}) {

  return (
    <div className={cn("grid md:grid-cols-4 gap-2 text-zinc-400 w-full text-sm", className)}>
      <div className="md:col-span-4">{item.health_care?.detail?.profession}</div>
      <div className="flex gap-1"><Building className="h-5 text-primary" />{['Online', 'Homecare'].includes(item.location) ? item.location : 'Offline'}</div>
      <div className="flex gap-1"><CalendarDays className="h-5 text-primary" />{item.date}</div>
      <div className="md:col-span-2 flex gap-1"><Clock className="h-5 text-primary" />{item.time_range}</div>
      <div className="flex gap-1"><User className="h-5 text-primary" />{item.user_child ? item.user_child.first_name : item.user?.first_name} {item.user_child ? item.user_child.last_name : item.user?.last_name}</div>
      <div className="flex flex-wrap gap-1"><Cross className="h-5 text-primary" />{item.condition}</div>
    </div>
  )
}