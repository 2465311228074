import { createSlice } from '@reduxjs/toolkit'

export const expertiseWalletSlicer = createSlice({
  name: 'expertiseWalletSlicer',
  initialState: {
    withdraw_data: { limit: 4, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    bill_payment_data: { limit: 4, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    online_transaction_data: { limit: 4, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    offline_transaction_data: { limit: 4, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    current_balance: 0,
    current_bill: 0,
    current_income: 0,
    balance_cards: {},
    selected_tab: 'withdraw'
  },
  reducers: {
    set_withdraw_data: (state, action) => {
      state.withdraw_data = action.payload
    },
    set_bill_payment_data: (state, action) => {
      state.bill_payment_data = action.payload
    },
    set_online_transaction_data: (state, action) => {
      state.online_transaction_data = action.payload
    },
    set_offline_transaction_data: (state, action) => {
      state.offline_transaction_data = action.payload
    },
    set_current_balance: (state, action) => {
      state.current_balance = action.payload
    },
    set_current_bill: (state, action) => {
      state.current_bill = action.payload
    },
    set_current_income: (state, action) => {
      state.current_income = action.payload
    },
    set_balance_cards: (state, action) => {
      state.balance_cards = action.payload
    },
    set_selected_tab: (state, action) => {
      state.selected_tab = action.payload
    },
  },
})

export const {
  set_withdraw_data,
  set_bill_payment_data,
  set_online_transaction_data,
  set_offline_transaction_data,
  set_current_balance,
  set_current_bill,
  set_current_income,
  set_balance_cards,
  set_selected_tab
} = expertiseWalletSlicer.actions
export default expertiseWalletSlicer.reducer
