"use client"

import {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "src/components/ui/toast"
import { toast, useToast } from "src/components/ui/use-toast"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}

export function ToastAlert(resp, message, action = () => { },) {
  if (resp && resp.error) {
    Object.entries(resp.error).map(([key, val]) => {
      toast({
        variant: 'destructive',
        title: `[${key}] Something when wrong!`,
        description: `${val}`,
      })
    })
  } else {
    toast({
      variant: 'success',
      title: message
    })
    action()
  }
}