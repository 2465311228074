import Modal from "antd/es/modal/Modal";
import { BeanOffIcon, Calculator, CalendarIcon, CircleSlash2Icon, EditIcon, PlusIcon, RulerIcon, ScaleIcon, Syringe, Trash2Icon } from "lucide-react";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalProfile } from "src/components/modal/profile";
import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "src/components/ui/card";
import { ToastAction } from "src/components/ui/toast";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_close_user_dialog, set_open_user_dialog } from "src/config/redux/slicer/dialog/userDialogSlicer";
import { set_child_data } from "src/config/redux/slicer/main/userChildSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { getUserChildInformation, onAddUserChildInformation, onDeleteUserChildInformation, onUpdateUserChildInformation } from "src/config/services/core/user.api";

export const onRedirectLisban = (selected_child, navbar) => () => {
  localStorage.setItem('lisban', JSON.stringify({ selected_child, navbar }))
  window.location.assign(`/lisban?page=${navbar}`)
}

function ChildProfile() {
  const user = getCurrentUser()
  const dispatch = useDispatch()
  const { toast } = useToast()
  const { value: user_child } = useSelector((state: any) => state.UserChild)
  const { dialog } = useSelector((state: any) => state.UserDialog)
  const [selected_child_id, setSelectedChildID] = useState(0)

  const updateUserChildState = () => {
    getUserChildInformation({}).then(resp => {
      dispatch(set_child_data(resp))
      dispatch(set_close_user_dialog('child_profile'))
      dispatch(set_close_user_dialog('add_profile'))
    })
  }
  const addUserChild = async (value) => {
    if (!value.error) {
      const resp = await onAddUserChildInformation({ ...value, user_id: user.id })
      ToastAlert(resp, `Data anak berhasil ditambahkan`, updateUserChildState)
    }
  }

  const deleteUserChild = async (id) => {
    const resp = await onDeleteUserChildInformation({ id })
    ToastAlert(resp, `Data anak berhasil dihapus`, updateUserChildState)
  }

  const updateUserChild = async (value) => {
    const resp = await onUpdateUserChildInformation(value)
    ToastAlert(resp, 'Data anak telah diupdate', updateUserChildState)
    updateUserChildState()
  }
  useEffect(() => {
    updateUserChildState()
  }, []);

  return (
    <div className="container">
      <div className="flex justify-between max-md:flex-col items-end max-md:items-start">
        <div className="grid gap-1">
          <div className="text-3xl font-semibold">Profil Anak</div>
          <div className="text-zinc-400">Berikut informasi profil anak anda</div>
        </div>
        <Button
          onClick={() => dispatch(set_open_user_dialog('child_profile'))}
          className="gap-2"
        >
          Tambahkan Profil Anak<PlusIcon className="h-5" />
        </Button>
        <Modal
          open={dialog.child_profile}
          onCancel={() => dispatch(set_close_user_dialog('child_profile'))}
          footer={[]}
        >
          <ModalProfile action={e => addUserChild(e)} buttonText="Tambahkan Data Baru" formData={null} />
        </Modal>

      </div>
      <div className="grid gap-2 pt-6">
        {user_child && user_child.map(child => (
          <Card key={child.id} className="shadow-md">
            <CardHeader className="text-zinc-400">
              <div className="flex justify-between">
                <div className="text-lg font-medium text-zinc-950">{child.first_name} {child.last_name}</div>
                <div className="flex gap-4">
                  <EditIcon
                    className="text-primary hover:text-secondary cursor-pointer"
                    onClick={() => {
                      setSelectedChildID(child.id)
                      dispatch(set_open_user_dialog('child_profile'))
                    }}
                  />
                  {selected_child_id === child.id &&
                    <Modal
                      open={dialog.child_profile}
                      onCancel={() => dispatch(set_close_user_dialog('child_profile'))}
                      footer={[]}
                    >
                      <ModalProfile action={e => updateUserChild({ ...e, id: child.id })} buttonText="Edit Data" formData={child} />
                    </Modal>
                  }
                  {/* <Dialog>
                    <DialogTrigger asChild><EditIcon className="text-primary hover:text-secondary cursor-pointer" /></DialogTrigger>
                    <DialogProfile title="Ubah Data Anak Anda" action={(e) => updateUserChild({ ...e, id: c.id })} buttonText="Edit Data" formData={c} />
                  </Dialog> */}
                  <Trash2Icon className="text-primary hover:text-secondary cursor-pointer"
                    onClick={() => toast({
                      title: 'Apa kamu yakin untuk hapus data ini?',
                      variant: 'destructive',
                      action: <ToastAction altText="yes" onClick={() => deleteUserChild(child.id)}>Iya</ToastAction>
                    })} />
                </div>
              </div>
              <div className="grid text-sm grid-cols-4 gap-2 max-md:grid-cols-2">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">{child.gender}</div>
                  <div className="flex items-center gap-2"><CalendarIcon className="h-4" />{(moment(child.birth_date).format('YYYY-MM-DD'))}</div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center gap-2"><RulerIcon className="h-4" />{child.height} cm</div>
                  <div className="flex items-center gap-2"><ScaleIcon className="h-4" />{child.weight} kg</div>
                </div>
                <div className="space-y-1 max-md:col-span-2">
                  <div className="flex items-center gap-2"><BeanOffIcon className="h-4" />Alergi</div>
                  <div className="flex flex-wrap items-center gap-1 pl-8">
                    {child.allergy.split(',').map(a => (
                      <div key={a} className="bg-zinc-100 text-xs px-1 rounded-sm flex gap-1 items-center"><CircleSlash2Icon className="w-2" />{a}</div>
                    ))}
                  </div>
                </div>
                <div className="flex max-md:col-span-2 max-md:justify-center gap-4">
                  <Syringe onClick={onRedirectLisban(child, 'vaccine-scheduler')} className="cursor-pointer text-secondary p-2 h-12 w-12 rounded-full border-2 border-secondary" />
                  <Calculator onClick={onRedirectLisban(child, 'nutrition-calculator')} className="cursor-pointer text-secondary p-2 h-12 w-12 rounded-full border-2 border-secondary" />
                </div>
              </div>
            </CardHeader>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default ChildProfile;