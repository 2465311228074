import { ColumnDef } from "@tanstack/table-core"
import { TableProps, Tag } from "antd"
import { format } from "date-fns"
import { Badge } from "src/components/ui/badge"
import { STATUS_BILL } from "src/config/const/status"
import { PayoutType } from "src/lib/types"
import { cn } from "src/lib/utils"

export const columns: TableProps<PayoutType>['columns'] = [
  {
    key: 'status',
    title: "Status",
    align: 'center',
    fixed: 'left',
    width: 140,
    render: (data) => {
      const { className, text, color } = STATUS_BILL[data.status]

      return (
        <Tag color={color}>{text}</Tag>
      )
    }
  },
  {
    key: 'datetime',
    title: "Date (GMT+7)",
    align: 'center',
    render: (data) => {
      const date = new Date(data.created)
      return (
        <p>{format(date, 'yyyy-MM-dd, HH:mm')}</p>
      )
    }
  },
  {
    key: 'external_id',
    dataIndex: 'external_id',
    title: "Payment ID",
    align: 'center',
  },
  {
    key: 'amount',
    title: "Pembayaran Tagihan",
    align: 'center',
    fixed: 'right',
    width: 180,
    render: (data) => {
      const admin_fee = data.admin_fees.map(fee => fee.value).reduce((a, b) => a + b)
      const amount = data.amount - admin_fee
      return (
        <b className={cn(['PAID', 'SETTLED'].includes(data.status) && "text-destructive")}>Rp {amount.toLocaleString('id')}</b>
      )
    }
  },
]