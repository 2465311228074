import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "src/components/header";
import { set_selected_health_care } from "src/config/redux/slicer/health-care/searchQuerySlicer";
import { getJWT } from "src/config/services/core/auth.api";
import { USER_LOCAL_STORAGE_LOCATION } from "src/config/services/fellas/google.api";
import { getHealthCareDataByQuery } from "src/config/services/fellas/health-care.api";
import { decode_ } from "src/lib/hash";
import AppointmentForm from "./form";
import AppointmentPortfolio from "./portfolio";

function HealthCareAppointment() {
  const dispatch = useDispatch()
  const location = useLocation()
  const is_login = Boolean(getJWT().key)
  const { selected_appointment } = useSelector((state: any) => state.Appointment)
  const { city, province, coords } = USER_LOCAL_STORAGE_LOCATION

  const routeId = location.pathname.split('/').at(-1) || ''
  const id = decode_(routeId)
  const updateHealthCareData = async () => {
    if (parseInt(id)) {
      const coords_ = coords ? `${coords.lat},${coords.lng}` : undefined
      const resp = await getHealthCareDataByQuery({ params: { coords: coords_, id } })
      if (!resp.error) {
        resp.results.length > 0 && dispatch(set_selected_health_care(resp.results[0]))
      }
    }
  }
  useEffect(() => {
    updateHealthCareData()
    ReactGA.send({ hitType: "pageview", page: "/health-care", title: "Health Care Page" });
  }, []);
  return (
    <div className="bg-white min-h-screen overflow-hidden">
      <Header is_login={is_login} />
      <div className="container pt-24">
        {selected_appointment === id
          ? <AppointmentForm />
          : <AppointmentPortfolio id={id} />}
      </div>
    </div>
  );
}

export default HealthCareAppointment;