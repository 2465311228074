import { useDispatch, useSelector } from "react-redux";
import { DialogContent, DialogTitle } from "src/components/ui/dialog";
import { ToastAlert } from "src/components/ui/toaster";
import { toast, useToast } from "src/components/ui/use-toast";
import { set_close_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_appointment_form, set_invoice } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { set_appointment_data } from "src/config/redux/slicer/main/myAppointmentSlicer";
import { onCreateAppointment, onCreateCalendarEvent, onUpdateAppointment } from "src/config/services/fellas/appointment.api";
import { onPaymentGetInvoice, onPaymentUpdateInvoice } from "src/config/services/fellas/payment.api";
import { cn } from "src/lib/utils";

interface DialogPaymentType {
  invoice: any
  is_dashboard?: boolean
}
export async function createNewAppointment(invoice) {
  const { id, start_datetime, end_datetime, admin_fees } = invoice
  const admin_fee = admin_fees ? admin_fees.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0) : 0
  const amount = invoice.amount - admin_fee
  const appointment = await onCreateAppointment({ ...invoice, status: 'New Entry', amount })
  await onPaymentUpdateInvoice({ id, appointment_id: appointment.id })
  if (invoice.health_care.auto_accept) {
    await onUpdateAppointment({ id: appointment.id, status: 'Accepted' })
    const meet = await onCreateCalendarEvent({
      attendees: invoice.health_care.email,
      start: start_datetime.split('+')[0],
      end: end_datetime.split('+')[0],
      summary: `[Fellas] Janji Temu ${invoice.health_care.detail.profession}`,
      description: `Pertemuan dengan ${invoice.health_care.detail.fullname}`
    })
    ToastAlert(meet, 'Link Meeting berhasil dijadwalkan', async () => {
      const { hangoutLink } = meet.resp
      await onUpdateAppointment({ id: appointment.id, meet_link: hangoutLink })
      await onPaymentGetInvoice({ query: `?status=PENDING,EXPIRED&start_datetime=${start_datetime}&end_datetime=${end_datetime}&delete=true` })
    })
  }
  window.location.reload()
}

function DialogPayment({ invoice, is_dashboard }: DialogPaymentType) {
  const dispatch = useDispatch()
  const { appointment_form } = useSelector((state: any) => state.Appointment)
  const { data } = useSelector((state: any) => state.MyAppointment)
  const onVerifyPayment = async () => {
    if (invoice.invoice_id !== 0) {
      const inv = await onPaymentGetInvoice({ query: `?verify=${invoice.invoice_id}` })
      if (!inv.error) {
        const verif_inv = inv.results.length > 0 && inv.results[0]
        if (verif_inv) {
          if (is_dashboard) {
            if (verif_inv.status !== 'PAID') {
              const update_data = data.results.map(item => item.invoice_id === invoice.invoice_id ? ({ ...item, status: verif_inv.status }) : item)
              dispatch(set_appointment_data({ ...data, results: update_data }))
            } else {
              createNewAppointment(invoice)
            }
          } else {
            dispatch(set_invoice(verif_inv))
            dispatch(set_appointment_form({ ...appointment_form, payment_verification: ['PAID', 'SETTLED'].includes(verif_inv.status) }))
          }
        }
      }
    }
    dispatch(set_close_payment_dialog('online_invoice'))
  }
  return (
    <DialogContent onClose={onVerifyPayment} className="fixed h-[96vh] min-w-[96vw] mx-auto shadow-lg">
      <div className="space-y-2">
        <DialogTitle className="text-center">Pembayaran</DialogTitle>
        <hr />
        <iframe title="xendit-invoice" className={cn("bg-muted/60 w-full h-[calc(100%-16px)] bg-white", !invoice.status && "animate-pulse rounded-lg")} src={invoice.invoice_url} />
      </div>
    </DialogContent>
  );
}

export default DialogPayment;