import { TableProps, Tag, TagProps } from "antd";
import { Link2, MapPin, Minus, MoreHorizontal, Phone, Plus, ScrollText } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { ToastAlert } from "src/components/ui/toaster";
import { createToast, toast } from "src/components/ui/use-toast";
import { set_open_appointment_dialog, set_selected_appointment } from "src/config/redux/slicer/dialog/appointmentDialogSlicer";
import { store } from "src/config/redux/store";
import { onCreateCalendarEvent, onUpdateAppointment, readAppointmentHomecare, readAppointmentOffline, readAppointmentOnline } from "src/config/services/fellas/appointment.api";
import { AppointmentType } from "src/lib/types";
import { cn } from "src/lib/utils";


export const columns: TableProps<AppointmentType>['columns'] = [
  {
    title: 'Nama Pasien',
    key: 'patient_name',
    align: 'right',
    fixed: window.innerWidth > 600 && 'left',
    render: (data) => {
      const first_name = data.user_child ? data.user_child.first_name : data.user.first_name
      const last_name = data.user_child ? data.user_child.last_name : data.user.last_name
      return (<div>{first_name} {last_name}</div>)
    }
  },
  {
    title: 'Waktu Janji',
    key: 'time_range',
    align: 'center',
    width: 140,
    render: (data) => {
      return <Tag color="pink">{data.time_range}</Tag>
    }
  },
  {
    title: 'Tanggal',
    dataIndex: 'date',
    key: 'date',
    width: 140,
    align: 'center',
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center',
    width: 120,
    render: (data) => {
      const colors: Record<string, TagProps['color']> = {
        'Accepted': 'success',
        'Cancelled': 'error',
        'On Going': 'yellow',
        'New Entry': 'blue',
      }
      const text = {
        'Accepted': 'Diterima',
        'Cancelled': 'Ditolak',
        'On Going': 'Hari-H',
        'New Entry': 'Diajukan'
      }
      const selected_color = colors[data.status]
      return (
        <Tag color={selected_color} >{text[data.status] || data.status}</Tag>
      )
    }
  },
  {
    title: 'Kondisi',
    dataIndex: 'condition',
    key: 'condition',
    align: 'center',
  },
  {
    key: 'Action',
    fixed: 'right',
    align: 'center',
    width: 60,
    render: (data) => {
      const { toast } = createToast()
      const { online_appointment, offline_appointment, homecare_appointment } = store.getState().Expertise
      const params = '&status=New Entry,Accepted,Cancelled,On Going'
      const onUpdateData = () => {
        if (data.location === 'Online') {
          const { limit, offset } = online_appointment
          readAppointmentOnline(limit, offset, params)
        } else if (data.location === 'Homecare') {
          const { limit, offset } = homecare_appointment
          readAppointmentHomecare(limit, offset, params)
        } else {
          const { limit, offset } = offline_appointment
          readAppointmentOffline(limit, offset, params)
        }
      }
      const actionItems = {
        'New Entry': [
          { id: 1, text: 'Terima', onClick: () => acceptAppointment(data, toast, onUpdateData), icon: <Plus className="h-4 mr-1 text-success/50" />, className: 'text-success focus:text-success' },
          { id: 2, text: 'Tolak', onClick: () => cancelAppointment(data, toast, onUpdateData), icon: <Minus className="h-4 mr-1 text-destructive/50" />, className: 'text-destructive focus:text-destructive' },
        ],
        'Accepted': [
          { id: 1, text: 'Isi Rekam Medis', onClick: () => setReportAppointment(data, onUpdateData), icon: <ScrollText className="h-4 mr-1 text-black/20" /> },
          // { id: 2, text: 'Atur Ulang Jadwal', onClick: () => setReportAppointment(data, onUpdateData) },
        ],
        'On Going': [
          { id: 1, text: 'Isi Rekam Medis', onClick: () => setReportAppointment(data, onUpdateData), icon: <ScrollText className="h-4 mr-1 text-black/20" /> },
        ]
      }
      return Object.keys(actionItems).includes(data.status) ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <MoreHorizontal className="cursor-pointer hover:bg-muted p-1 rounded-md" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {['New Entry', 'Accepted', 'On Going'].map(status =>
              data.status === status && actionItems[status].map(item => (
                <DropdownMenuItem key={item.id} onClick={item.onClick} className={cn("cursor-pointer", item.className)}>
                  {item.icon}{item.text}
                </DropdownMenuItem>
              )))}
            {data.location === 'Online' && ['Accepted', 'On Going'].includes(data.status) &&
              <DropdownMenuItem onClick={() => window.open(data.meet_link, '_blank')} key="open-link-meet" className="cursor-pointer">
                <Link2 className="h-4 mr-1 text-black/20" />Link Meeting
              </DropdownMenuItem>
            }
            {data.location === 'Homecare' && (
              <DropdownMenuItem onClick={() => seeLocationHomecareAppointment(data)} key="see-location" className="cursor-pointer">
                <MapPin className="h-4 mr-1 text-black/20" />Liat Lokasi
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => redirectToWhatsApp(data)} key="contact-patient" className="cursor-pointer">
              <Phone className="h-4 mr-1 text-black/20" />Hubungi Pasien
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : <MoreHorizontal className="stroke-muted-foreground p-1" />
    }
  }
]

export const psychology_columns: TableProps<AppointmentType>['columns'] = [
  ...columns.slice(0, 1),
  {
    title: 'Layanan',
    key: 'service',
    render: (data) => <div>{
      data.mh_form_status > 0 ? 'Mental Health Checkup'
        : data.burnout_form_status > 0 ? 'Burnout Checkup'
          : 'Konsultasi Umum'}</div>
  },
  ...columns.slice(1)

]

export const homecare_columns: TableProps<AppointmentType>['columns'] = [
  ...columns.slice(0, 1),
  {
    title: 'Layanan',
    key: 'service',
    render: (data) => <div>{data.homecare.service}</div>
  },
  ...columns.slice(1)
]

export const redirectToWhatsApp = (data) => {
  if (!data.user?.number) { toast({ variant: 'destructive', title: 'Nomor tidak dicantumkan' }) }
  else {
    const phoneNumber = data.user?.number.replace(/^0/, '62')
    const { first_name, last_name } = data.user
    const { profession, fullname } = data.health_care.detail
    const message = `Halo ${first_name || ''} ${last_name || ''}, 
Saya ${fullname}, ${profession} dari Fellas. Saya ingin berbicara dengan Anda mengenai ...
    
Terima kasih,
${fullname}`
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  }
};

const rescheduleAppointment = async (data, onUpdateData) => {
  store.dispatch(set_open_appointment_dialog('reschedule'))
  store.dispatch(set_selected_appointment(data))
}

const seeLocationHomecareAppointment = async (data) => {
  store.dispatch(set_open_appointment_dialog('homecare_location'))
  store.dispatch(set_selected_appointment(data))
}

const setReportAppointment = async (data, onUpdateData) => {
  store.dispatch(set_open_appointment_dialog('report'))
  store.dispatch(set_selected_appointment(data))
  onUpdateData()
}

const acceptAppointment = async (data, toast, onUpdateData) => {
  if (data.location === 'Online') {
    const meet = await onCreateCalendarEvent({
      attendees: data.user.email,
      start: data.start_datetime.split('+')[0],
      end: data.end_datetime.split('+')[0],
      summary: `[Fellas] Janji Temu ${data.health_care.detail.profession}`,
      description: `Pertemuan dengan ${data.health_care.detail.fullname}`
    })
    ToastAlert(meet, 'Janji diterima dan link meeting berhasil dibuat', async () => {
      await onUpdateAppointment({ id: data.id, status: 'Accepted', meet_link: meet.resp.hangoutLink })
      onUpdateData()
    })
  } else {
    await onUpdateAppointment({ id: data.id, status: 'Accepted' })
    toast({
      variant: 'success',
      title: 'Data berhasil diupdate'
    })
    onUpdateData()
  }
}
const cancelAppointment = async (data, toast, onUpdateData) => {
  await onUpdateAppointment({ id: data.id, status: 'Cancelled' })
  toast({
    variant: 'success',
    title: 'Data berhasil diupdate'
  })
  onUpdateData()
}