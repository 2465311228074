import { Tag } from "antd";
import { format } from "date-fns";
import { Copy, X } from "lucide-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Timeline from "src/components/timeline";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { useToast } from "src/components/ui/use-toast";
import { PAYMENT_BILL_TIMELINE, STATUS_BILL } from "src/config/const/status";
import { set_close_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_bill_payment_data } from "src/config/redux/slicer/expertise/expertiseWalletSlicer";
import { onPaymentGetBillPayment } from "src/config/services/fellas/payment.api";

function DialogBillPayment() {
  const dispatch = useDispatch()
  const { toast } = useToast()
  const { dialog, payment_data } = useSelector((state: any) => state.PaymentDialog)
  const { bill_payment_data } = useSelector((state: any) => state.ExpertiseWallet)
  const { amount, admin_fees, invoice_url, invoice_id, external_id, created, status } = payment_data

  const { color, text } = STATUS_BILL[status]
  const VAT = admin_fees?.at(1).value
  const admin_fee = admin_fees?.at(0).value

  const verifyPayment = async (params = '') => {
    const verify_bill = await onPaymentGetBillPayment({ query: `?verify=${invoice_id}` + params })
    const selected_verify_data = verify_bill.results[0]
    const updated_data = bill_payment_data.results.map(data => data.invoice_id === invoice_id ? selected_verify_data : data)
    dispatch(set_payment_data(selected_verify_data))
    dispatch(set_bill_payment_data({ ...bill_payment_data, results: updated_data }))
  }

  const onClose = () => {
    dispatch(set_close_payment_dialog('bill'))
  }
  const onVoidBillPayment = async () => {
    verifyPayment('&void=true')
    toast({ variant: 'success', title: 'Link tagihan dibatalkan' })
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        verifyPayment()
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[600px] p-6 space-y-4">
          <div className="flex justify-between items-center">
            <div />
            <p className="text-lg font-semibold">Informasi Pembayaran</p>
            <X onClick={onClose} className="hover:stroke-red-500 cursor-pointer h-4 w-4" />
          </div>
          <div className="grid grid-cols-2 gap-2 gap-x-8 text-sm">
            <div>
              <label>Nominal Pembayaran</label>
              <p className="text-lg text-primary">Rp {(amount - VAT - admin_fee).toLocaleString('id')}</p>
            </div>
            <div>
              <label>Pajak</label>
              <p className="text-lg text-primary">Rp {VAT.toLocaleString('id')}</p>
            </div>
            <div>
              <label>Biaya Admin</label>
              <p className="text-lg text-primary">Rp {admin_fee.toLocaleString('id')}</p>
            </div>
            <div>
              <label>Nominal Akhir</label>
              <p className="text-lg text-primary">Rp {(amount).toLocaleString('id')}</p>
            </div>
            <div className="space-y-2">
              <div>
                <label>Invoice URL</label>
                <div className="flex gap-1">
                  <Input className="text-gray-400" value={invoice_url || '-'} disabled={!invoice_url} />
                  <Button
                    disabled={!invoice_url}
                    className="px-1" size="icon"
                    onClick={() => {
                      navigator.clipboard.writeText(invoice_url)
                      toast({ variant: 'success', title: 'Link telah disalin' })
                    }}
                  ><Copy /></Button>
                </div>
              </div>
              <div>
                <label>Payment ID</label>
                <p className="text-gray-400">{external_id}</p>
              </div>
              <div>
                <label>Tanggal Dibuat</label>
                <p className="text-gray-400">{format(new Date(created), 'PPPP')}</p>
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <label>Status</label>
                <Tag color={color}>{text}</Tag>
              </div>
              <Timeline data={PAYMENT_BILL_TIMELINE[text]} />
            </div>
          </div>
          <div className="flex justify-center gap-2">
            <Button variant="outline" onClick={onClose}>Kembali</Button>
            <Button onClick={onVoidBillPayment} disabled={status !== 'PENDING'}>Batalkan Pembayaran</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default DialogBillPayment;