import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "src/components/ui/button"
import DataTable from "../../data-table"
import { columns } from "./columns"
import { Search } from "lucide-react"
import { onQueryBillPayment, readBillPayment } from "src/config/services/fellas/payment.api"
import DialogBillPayment from "src/components/dialog/wallet/appointment/bill-payment"
import { DatePicker, Input, Table } from "antd"
import dayjs from "dayjs"
import { useMutation } from "react-query"
import { set_bill_payment_data } from "src/config/redux/slicer/expertise/expertiseWalletSlicer"
import { set_open_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer"

function BillWallet() {
  const dispatch = useDispatch()
  const health_care_id = localStorage.getItem('health_care_id') || 0
  const [query_filter, setQuerySearch] = useState({ start_date: '', end_date: '', filter: '', is_submit: true, is_verify: true })
  const { bill_payment_data, selected_tab } = useSelector((state: any) => state.ExpertiseWallet)
  const { dialog } = useSelector((state: any) => state.PaymentDialog)
  const { limit, offset, count } = bill_payment_data
  const { filter, start_date, end_date } = query_filter
  const current = Math.round(offset / limit) + 1
  const params = { filter, start_date, end_date, limit, offset, health_care_id }

  const { mutate: updateBillPayment, isLoading } = useMutation(async (is_verify: boolean) => {
    const bill = await onQueryBillPayment({ params })
    return { bill, is_verify }
  }, {
    onSuccess: async ({ bill, is_verify }) => {
      const results = bill.results.map((item, i) => ({ ...item, key: i }))
      const verif_bill = is_verify
        ? await Promise.all(results.map(async (data, i) => {
          const update_bill = await onQueryBillPayment({ params: { ...params, verify: data.invoice_id } })
          return update_bill.results.length ? update_bill.results[0] : data
        }))
        : results
      const data = { ...bill, results: verif_bill, limit, offset }
      dispatch(set_bill_payment_data(data))
    }
  })

  const handleDatePicker = (date) => {
    if (date) {
      setQuerySearch({
        ...query_filter,
        start_date: date[0].format('YYYY-MM-DD'),
        end_date: date[1].format('YYYY-MM-DD'),
      })
    } else {
      setQuerySearch({ ...query_filter, start_date: '', end_date: '' })
    }
  }

  const handleSubmitFilter = (e: React.FormEvent) => {
    e.preventDefault()
    updateBillPayment(false)
  }

  const handleTableParams = (pagination) => {
    if (pagination) {
      const { pageSize: limit, current } = pagination
      const offset = (current - 1) * limit
      dispatch(set_bill_payment_data({ ...bill_payment_data, offset, limit }))
    }
  }

  const rowHandle = (data) => ({
    onClick: () => {
      dispatch(set_payment_data(data))
      dispatch(set_open_payment_dialog('bill'))
    }
  })
  useEffect(() => {
    updateBillPayment(true)
  }, [offset]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-end">
        <form onSubmit={handleSubmitFilter} className="flex flex-wrap gap-2 items-end text-sm">
          <DatePicker.RangePicker className="max-sm:w-full" maxDate={dayjs()} onChange={handleDatePicker} />
          <div className="flex gap-2 max-sm:w-full">
            <Input className="h-8" placeholder="Cari" onChange={e => setQuerySearch({ ...query_filter, filter: e.target.value })} />
            <Button className="h-8 px-1" size="icon" type="submit"><Search className="h-5" /></Button>
          </div>
        </form>
        {/* <Button className="h-8 gap-1" variant="outline">Download CSV <FileDown className="h-4" /></Button> */}
      </div>
      <Table
        loading={isLoading}
        dataSource={bill_payment_data.results}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          current,
          total: count,
          pageSize: limit,
          hideOnSinglePage: true,
        }}
        onChange={handleTableParams}
        onRow={rowHandle}
      />

      {dialog.bill && <DialogBillPayment />}
    </div>
  );
}

export default BillWallet;