// DM SANS
const DM_Sans_Regular = require('./DM_Sans/static/DMSans-Regular.ttf')
const DM_Sans_Bold = require('./DM_Sans/static/DMSans-Bold.ttf')
const DM_Sans_SemiBold = require('./DM_Sans/static/DMSans-SemiBold.ttf')
const DM_Sans_Italic = require('./DM_Sans/static/DMSans-Italic.ttf')

// Inter
const Inter_Regular = require('./Inter/static/Inter-Regular.ttf')
const Inter_Bold = require('./Inter/static/Inter-Bold.ttf')
const Inter_Medium = require('./Inter/static/Inter-Medium.ttf')
const Inter_SemiBold = require('./Inter/static/Inter-SemiBold.ttf')

const fonts = {
  DM_SANS: {
    default: DM_Sans_Regular,
    semibold: DM_Sans_SemiBold,
    bold: DM_Sans_Bold,
    italic: DM_Sans_Italic,
  },
  Inter: {
    default: Inter_Regular,
    semibold: Inter_SemiBold,
    medium: Inter_Medium,
    bold: Inter_Bold,
  }
}

export default fonts