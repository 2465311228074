import { useDispatch, useSelector } from "react-redux"
import { set_form_page, set_list_of_times, set_service_form } from "src/config/redux/slicer/health-care/appointmentSlicer"
import { onGetScheduleAvailability } from "src/config/services/fellas/appointment.api"
import AppointmentSelectServiceCard from "./cards/select"
import AppointmentScheduleCard from "./cards/schedule"
import AppointmentConditionCard from "./cards/condition"
import { Button } from "src/components/ui/button"

function AppointmentServiceForm() {
  const dispatch = useDispatch()
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const { appointment_form, service_form } = useSelector((state: any) => state.Appointment)
  const { starttime, endtime } = service_form
  const is_homecare = appointment_form.location === 'Homecare'

  const setSelectedDate = async (val: { starttime: string, endtime: string, date: string, time: string }, is_online?, is_homecare?) => {
    const service = is_online ? 'online' : is_homecare ? 'homecare' : 'offline'
    const { mh_form_status, burnout_form_status } = appointment_form
    const resp = await onGetScheduleAvailability({ healthcare_id: selected_health_care.id, date: val.date, service, mh_form_status, burnout_form_status })
    dispatch(set_list_of_times(resp))
    dispatch(set_service_form(val))
  }
  return (
    <div className="space-y-2">
      <AppointmentSelectServiceCard setSelectedDate={setSelectedDate} />
      <AppointmentScheduleCard setSelectedDate={setSelectedDate} />
      <AppointmentConditionCard />
      <Button
        disabled={!starttime || !endtime || !appointment_form.condition || (is_homecare && !appointment_form.homecare.service)}
        className="w-full" size="sm" onClick={() => dispatch(set_form_page(2))}
      >
        Isi Data
      </Button>
    </div>
  );
}

export default AppointmentServiceForm;