import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import { getCurrentUser } from 'src/config/services/core/auth.api'

export const appointmentSlicer = createSlice({
  name: 'appointmentSlicer',
  initialState: {
    form_page: 1,
    selected_appointment: {},
    appointment_form: {
      user_id: getCurrentUser().id,
      user_child_id: null,
      health_care_id: 0,
      start_datetime: '',
      end_datetime: '',
      status: 'New Entry',
      condition: '',
      location: '',
      payment_verification: false,
      homecare: {},
      mh_form_status: 0,
    },
    list_of_times: [],
    service_form: {
      date: format(new Date(), 'yyyy-MM-dd'), time: '',
      starttime: '', endtime: ''
    },
    user_form: [],
    invoice: {
      status: '',
      invoice: {},
      invoice_id: 0,
      invoice_url: ''
    }
  },
  reducers: {
    set_form_page: (state, action) => {
      state.form_page = action.payload
    },
    set_selected_appointment: (state, action) => {
      state.selected_appointment = action.payload
      state.appointment_form.health_care_id = Number(action.payload)
    },
    set_appointment_form: (state, action) => {
      state.appointment_form = action.payload
    },
    set_list_of_times: (state, action) => {
      state.list_of_times = action.payload
    },
    set_service_form: (state, action) => {
      state.service_form = action.payload
    },
    set_user_form: (state, action) => {
      state.user_form = action.payload
    },
    set_invoice: (state, action) => {
      state.invoice = action.payload
    }
  },
})

export const { set_selected_appointment, set_appointment_form, set_service_form, set_form_page, set_invoice, set_user_form, set_list_of_times } = appointmentSlicer.actions
export default appointmentSlicer.reducer
