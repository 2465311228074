import { Tag } from "antd";
import { format } from "date-fns";
import { Copy, X } from "lucide-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Timeline from "src/components/timeline";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { useToast } from "src/components/ui/use-toast";
import { PAYOUT_TRANSACTION_TIMELINE, STATUS_PAYOUT } from "src/config/const/status";
import { set_close_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_withdraw_data } from "src/config/redux/slicer/expertise/expertiseWalletSlicer";
import { onPaymentGetPayoutLink } from "src/config/services/fellas/payment.api";

function DialogWithdraw() {
  const dispatch = useDispatch()
  const { toast } = useToast()
  const { dialog, payment_data } = useSelector((state: any) => state.PaymentDialog)
  const { withdraw_data } = useSelector((state: any) => state.ExpertiseWallet)
  const { amount, admin_fees, payout_url, external_id, payout_id, recipient_email, created, status } = payment_data

  const { color, text } = STATUS_PAYOUT[status]
  const VAT = admin_fees?.at(0).value
  const admin_fee = admin_fees?.at(1).value

  const verifyPayment = async (params = '') => {
    const verify_bill = await onPaymentGetPayoutLink({ query: `?verify=${payout_id}` + params })
    const selected_verify_data = verify_bill.results[0]
    const updated_data = withdraw_data.results.map(data => data.payout_id === payout_id ? selected_verify_data : data)
    dispatch(set_payment_data(selected_verify_data))
    dispatch(set_withdraw_data({ ...withdraw_data, results: updated_data }))
  }

  const onClose = () => {
    dispatch(set_close_payment_dialog('withdraw'))
  }

  const onVoidBillPayment = async () => {
    verifyPayment('&void=true')
    toast({ variant: 'success', title: 'Link penarikan dibatalkan' })
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        verifyPayment()
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[600px] p-6 space-y-4">
          <div className="flex justify-between items-center">
            <div />
            <p className="text-lg font-semibold">Informasi Penarikan</p>
            <X onClick={onClose} className="hover:stroke-red-500 cursor-pointer h-4 w-4" />
          </div>
          <div className="grid grid-cols-2 gap-2 gap-x-8 text-sm">
            <div>
              <label>Nominal Penarikan</label>
              <p className="text-lg text-primary">Rp {amount.toLocaleString('id')}</p>
            </div>
            <div>
              <label>Pajak</label>
              <p className="text-lg text-primary">Rp {VAT.toLocaleString('id')}</p>
            </div>
            <div>
              <label>Biaya Admin</label>
              <p className="text-lg text-primary">Rp {admin_fee.toLocaleString('id')}</p>
            </div>
            <div>
              <label>Nominal Akhir</label>
              <p className="text-lg text-primary">Rp {(amount - VAT - admin_fee).toLocaleString('id')}</p>
            </div>
            <div className="space-y-2">
              <div>
                <label>Payout URL</label>
                <div className="flex gap-1">
                  <Input className="text-gray-400" value={payout_url || '-'} disabled={!payout_url} />
                  <Button
                    disabled={!payout_url}
                    className="px-1" size="icon"
                    onClick={() => {
                      navigator.clipboard.writeText(payout_url)
                      toast({ variant: 'success', title: 'Link telah disalin' })
                    }}
                  ><Copy /></Button>
                </div>
              </div>
              <div>
                <label>Payment ID</label>
                <p className="text-gray-400">{external_id}</p>
              </div>
              <div>
                <label>Email Penerima</label>
                <p className="text-gray-400">{recipient_email}</p>
              </div>
              <div>
                <label>Tanggal Dibuat</label>
                <p className="text-gray-400">{format(new Date(created), 'PPPP')}</p>
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <label>Status</label>
                <Tag color={color}>{text}</Tag>
              </div>
              <Timeline data={PAYOUT_TRANSACTION_TIMELINE[text]} />
            </div>
          </div>
          <div className="flex justify-center gap-2">
            <Button variant="outline" onClick={onClose}>Kembali</Button>
            <Button onClick={onVoidBillPayment} disabled={status !== 'PENDING'}>Batalkan Penarikan</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default DialogWithdraw;