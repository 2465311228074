import { createSlice } from '@reduxjs/toolkit'

export const adminExpertiseRequestSlicer = createSlice({
  name: 'adminExpertiseRequestSlicer',
  initialState: {
    value: [],
  },
  reducers: {
    set_request_data: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { set_request_data } = adminExpertiseRequestSlicer.actions
export default adminExpertiseRequestSlicer.reducer
