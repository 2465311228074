import { Select, Tabs, Tooltip } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { differenceInYears, format } from "date-fns";
import { CheckCircle, CircleSlashed, MoonStar, SunDim, SunMedium, Sunrise } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { getCurrentUser, getJWT } from "src/config/services/core/auth.api";
import { onGetNutritionURT } from "src/config/services/fellas/nutrition.api";
import { cn } from "src/lib/utils";

interface CalculatorType {
  form: {
    name: string
    birth_date: string,
    weight?: number
    height?: number
    condition: string
    physical_activity: string
    physical_activity_weight: number
    mother_breastfeeding_calories: number
    method: 'Harris-Benedict' | 'Dubois'
    filter_calories?: 'breakfast' | 'lunch' | 'dinner' | 'all'
    calories_weight: number
  }
  food: 'vegetable' | 'fruit' | 'carbohydrate' | 'protein'
}

const MotherBreastfeedingCalories = () => {
  const jwt = getJWT()
  const [form, setForm] = useState<CalculatorType['form']>({
    name: '',
    birth_date: '',
    weight: undefined, height: undefined,
    physical_activity: 'little/no exercise',
    condition: 'lactation 0-6 month',
    physical_activity_weight: 0,
    mother_breastfeeding_calories: 0,
    method: 'Harris-Benedict',
    calories_weight: 1,
    filter_calories: 'all',
  })
  const food_types: CalculatorType['food'][] = ["carbohydrate", "protein", "vegetable", "fruit"]

  const [result, setResult] = useState<any>({
    total_calories: 0,
    carbohydrate: 0,
    protein: 0,
    fat: 0,
  })

  const [urt_component, setURTComponent] = useState<any[]>([])
  const [selected_urt, setSelectedURT] = useState({
    vegetable_id: 0,
    fruit_id: 0,
    carbohydrate_id: 0,
    protein_id: 0,
  })

  const vegetable_options = urt_component.filter(urt => urt?.group === 'Sayuran') || []
  const fruit_options = urt_component.filter(urt => urt?.group === 'Buah dan Gula') || []
  const carbohydrate_options = urt_component.filter(urt => urt?.group === 'Bahan Makanan Sumber Karbohidrat') || []
  const protein_options = urt_component.filter(urt => urt?.group === 'Bahan Makanan Sumber Protein Hewani') || []

  const calculateAmountConsume = (type: CalculatorType['food'] = 'vegetable', weight = 1 / 3) => {
    const options = (
      type === 'vegetable' ? vegetable_options
        : type === 'fruit' ? fruit_options
          : type === 'carbohydrate' ? carbohydrate_options
            : protein_options
    )
    const id = (
      type === 'vegetable' ? selected_urt.vegetable_id
        : type === 'fruit' ? selected_urt.fruit_id
          : type === 'carbohydrate' ? selected_urt.carbohydrate_id
            : selected_urt.protein_id
    )
    const selected_item = options.find(opt => opt.id === id)
    if (selected_item) {
      const { calories, size, converter, food_stuff, weight_in_gr } = selected_item
      const { total_calories } = result
      const factor = form.filter_calories === 'all' ? 3 : 1
      let ratio_calories = 0
      if (type === 'vegetable') ratio_calories = 100 * factor / weight_in_gr
      else if (type === 'fruit') ratio_calories = 60 * factor / weight_in_gr
      else ratio_calories = total_calories * weight / calories

      return <div className="flex gap-1 w-full items-center">
        <p>{`${Math.round(ratio_calories * size) > 1 ? Math.round(ratio_calories * size) : 1} ${converter}`}</p>
        <Popover>
          <PopoverTrigger asChild><p className="text-primary hover:text-secondary cursor-pointer">{food_stuff}</p></PopoverTrigger>
          <PopoverContent className="w-128">
            <div className="grid grid-cols-6 max-md:grid-cols-3 gap-4">
              {options.map(opt =>
                <div
                  onClick={() => {
                    const new_selected_urt = { ...selected_urt }
                    new_selected_urt[`${type}_id`] = opt.id
                    setSelectedURT(new_selected_urt)
                  }}
                  className={cn(" items-center flex border-b shadow cursor-pointer px-2 py-1 text-sm hover:bg-gray-100", id === opt.id && "bg-secondary/10")} key={opt.id}>
                  {opt.id === id && <CheckCircle className="h-3" />}{opt.food_stuff}
                </div>
              )}
            </div>
          </PopoverContent>
        </Popover>
        <p>per hari</p>
      </div>
    } else return <div />
  }
  const updateForm = (
    method: CalculatorType['form']['method'] = 'Harris-Benedict',
    calories_weight: number = form.calories_weight
  ) => {
    let total_calories = 0
    const age = differenceInYears(new Date(), new Date(form.birth_date))

    if (method === 'Harris-Benedict') {
      const calories = (655 + (9.6 * Number(form.weight)) + (1.8 * Number(form.height)) - (4.7 * age))
      total_calories = calories + form.mother_breastfeeding_calories
    }
    else if (method === 'Dubois') {
      // TEE Duboise method
      const height = Number(form.height); const weight = Number(form.weight)
      const BBI = height > 150 ? ((height - 100) - (.1 * weight)) : (height - 100)
      const BMR = .9 * BBI * 24
      const sleep_correction = .1 * 8 * BBI
      const physical_activity = form.physical_activity_weight * (BMR - sleep_correction)
      total_calories = (BMR - sleep_correction) + physical_activity + form.mother_breastfeeding_calories
    }
    total_calories = Math.round(total_calories * calories_weight)

    const protein = Math.round(.15 * total_calories / 4)
    const fat = Math.round(.25 * total_calories / 9)
    const carbohydrate = Math.round(.6 * total_calories / 4)
    setResult({ total_calories, protein, fat, carbohydrate })
    setSelectedURT({
      vegetable_id: vegetable_options[0]?.id,
      fruit_id: fruit_options[0]?.id,
      carbohydrate_id: carbohydrate_options[0]?.id,
      protein_id: protein_options[0]?.id
    })
  }
  const method_options: DefaultOptionType[] = [
    { value: 1, label: 'Harris-Benedict' },
    { value: 2, label: 'Dubois' },
  ]
  const physical_activity_options: DefaultOptionType[] = [
    { value: 1, label: 'little/no exercise', weight: .1 },
    { value: 2, label: 'exercise 1-2 times/week', weight: .2 },
    { value: 3, label: 'exercise 2-3 times/week', weight: .3 },
    { value: 4, label: 'exercise 3-5 times/week', weight: .4 },
  ]
  const condition_options: DefaultOptionType[] = [
    { value: 1, label: 'lactation 0-6 month', add_energy: 330 },
    { value: 2, label: 'lactation > 6 month', add_energy: 400 },
    { value: 3, label: 'pregnancy 1st trimester', add_energy: 180 },
    { value: 4, label: 'pregnancy 2nd trimester', add_energy: 300 },
    { value: 5, label: 'pregnancy 3st trimester', add_energy: 300 },
  ]
  useEffect(() => {
    if (jwt.key) {
      const user = getCurrentUser()
      const { first_name, last_name, birth_date, weight, height } = user
      setForm(form => ({ ...form, name: `${first_name} ${last_name}`, birth_date, weight, height }))
    }
    onGetNutritionURT({}).then(urt => !urt.error && setURTComponent(urt))
  }, []);
  return (
    <div className="space-y-6 text-sm">
      <div>
        <p className="text-lg font-medium">Kalkulator Kalori Menyusui</p>
        <p>Melalui kalkulator menyusui Anda akan dibekali dengan pengetahuan yang penting mengenai fisiologi proses menyusui dan kesehatan ibu yang sedang menyusui</p>
      </div>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-8">
        <form className="space-y-2" onSubmit={e => { e.preventDefault(); updateForm(form.method) }}>
          <div className="space-y-1">
            <div className="flex justify-between items-end">
              <label className="font-bold">Nama</label>
              <div className="flex gap-2 items-center font-semibold text-xs">Method:
                <Select
                  variant="borderless"
                  className="border-b"
                  style={{ minWidth: 150 }}
                  size="small"
                  options={method_options}
                  value={form.method}
                  onChange={(_, opt: any) => {
                    setForm({ ...form, method: opt.label })
                    updateForm(opt.label)
                  }}
                />
              </div>
            </div>
            <Input disabled={jwt.key} placeholder="Isi nama anda" value={form.name} onChange={e => { (!jwt.key && result.total_calories) && setResult([]); !jwt.key && setForm(form => ({ ...form, name: e.target.value })) }} />
          </div>
          <div className="space-y-1">
            <label className="font-bold">Tanggal Lahir</label>
            <Input disabled={jwt.key} type="date" max={format(new Date(), 'yyyy-MM-dd')} value={form.birth_date} onChange={e => setForm(form => ({ ...form, birth_date: e.target.value }))} />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="space-y-1">
              <label className="font-bold">Tinggi</label>
              <Input placeholder="cm" type="number" value={form.height} onChange={e => !jwt.key && setForm(form => ({ ...form, height: Number(e.target.value) }))} />
            </div>
            <div className="space-y-1">
              <label className="font-bold">Berat</label>
              <Input placeholder="kg" type="number" value={form.weight} onChange={e => !jwt.key && setForm(form => ({ ...form, weight: Number(e.target.value) }))} />
            </div>
          </div>
          <div className={cn("grid gap-2", form.method === 'Dubois' && 'grid-cols-2')}>
            {form.method === 'Dubois' && (
              <div className="space-y-1 grid">
                <label className="font-bold">Aktivitas Fisik</label>
                <Select
                  value={form.physical_activity}
                  options={physical_activity_options}
                  onChange={(_, opt: any) => setForm({ ...form, physical_activity: opt.label, physical_activity_weight: opt.weight })}
                  size="large"
                />
              </div>
            )}
            <div className="space-y-1 grid">
              <label className="font-bold">Kondisi</label>
              <Select
                value={form.condition}
                options={condition_options}
                onChange={(_, opt: any) => setForm({ ...form, condition: opt.label, mother_breastfeeding_calories: opt.add_energy })}
                size="large"
              />
            </div>
          </div>
          <Button className="w-full" disabled={urt_component.length === 0 || !form.name || !form.birth_date || !form.weight || !form.height} type="submit" size="sm">
            Hitung
            {urt_component.length === 0 && <CircleSlashed className="animate-spin h-4 text-white" />}
          </Button>
        </form>
        {
          result.total_calories > 0 &&
          <Card className="border-primary p-4 pb-0 text-muted-foreground">
            <div className="flex flex-col justify-between h-full">
              <div className="space-y-2">
                <p className="text-[20px] leading-6 font-bold text-black">Kebutuhan energi {form.name} <b className="text-primary">{result.total_calories} kkal</b></p>
                <p>Berdasarkan perkiraan kisaran tingkat kalori Anda, sebaiknya Anda mengonsumsi&nbsp;
                  <span className="text-primary">{result.protein} gr protein</span>,&nbsp;
                  <span className="text-primary">{result.carbohydrate} gr karbohidrat</span>, dan&nbsp;
                  <span className="text-primary">{result.fat} gr lemak</span>,&nbsp;
                  atau sama dengan sekitar:</p>
                {food_types.map((type, i) => (
                  <div key={i} className="flex gap-2 items-center">
                    <img src={require(`src/assets/icons/${type}.svg`)} className="h-4 aspect-square" />
                    {calculateAmountConsume(type, (type === 'carbohydrate' || type === 'vegetable') ? 1 / 3 : 1 / 6)}
                  </div>
                ))}
              </div>
              <div className="flex justify-end mb-[-10px]">
                <Tabs
                  hideAdd
                  activeKey={form.filter_calories}
                  items={[
                    { key: 'breakfast', label: '', icon: <Tooltip title='Sarapan'> <Sunrise /></Tooltip> },
                    { key: 'lunch', label: '', icon: <Tooltip title='Makan Siang'><SunMedium /></Tooltip> },
                    { key: 'dinner', label: '', icon: <Tooltip title='Makan Malam'><MoonStar /></Tooltip> },
                  ]}
                  onContextMenu={(e) => {
                    e.preventDefault()
                    setForm({ ...form, filter_calories: 'all', calories_weight: 1 })
                    updateForm(form.method, 1)
                  }}
                  onChange={(filter_calories: any) => {
                    const calories_weight = filter_calories === 'lunch' ? 0.4 : 0.3
                    setForm({ ...form, filter_calories, calories_weight })
                    updateForm(form.method, calories_weight)
                  }}
                />
              </div>
            </div>
          </Card>
        }
      </div >
    </div >
  )
}

export default MotherBreastfeedingCalories