import Axios, { AxiosRequestConfig } from 'axios';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';
import { store } from 'src/config/redux/store';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ''
const GEOCODE_URL = `https://maps.googleapis.com/maps/api/geocode/json`

export const getAddressFromCoords = async (data: { lat: number, lng: number }) => {
  store.dispatch(set_on_progress(true))
  const requestConfig: AxiosRequestConfig = {
    method: 'get',
    url: GEOCODE_URL,
    params: {
      latlng: `${data.lat},${data.lng}`,
      key: GOOGLE_MAP_API_KEY,
    },
  };

  try {
    const { data: response } = await Axios.request(requestConfig);
    if (response.results && response.results.length > 0) {
      return response.results[0]
    } else return response
  }
  catch (err: any) {
    const { data, status } = err.response
    return { error: data, status }
  }
  finally {
    store.dispatch(set_on_progress(false))
  }
};

export const getAddressLabel = (
  address_components: any[],
  type: string,
  name_type: 'long_name' | 'short_name' = 'long_name'
) => {
  const filtered_label = address_components.filter(address => address.types.includes(type))
  if (filtered_label.length > 0) return filtered_label[0][name_type]
  else return 'No data'
}

export const USER_LOCAL_STORAGE_LOCATION = JSON.parse(
  localStorage.getItem('user_location') ||
  JSON.stringify({ city: 'Tentukan Lokasi', province: '', coords: '' })
)