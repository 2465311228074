import { create } from "zustand"

interface State {
  user_socmed: {
    facebook: string
    instagram: string
    linkedin: string
    twitter: string
  }
}

type UserSocmedKey = keyof State['user_socmed'];
type UserSocmedValue = State['user_socmed'][UserSocmedKey];

interface Action {
  setUserSocmed: (user_socmed: State['user_socmed']) => void
  setUserSocmedbyKey: (key: UserSocmedKey, value: UserSocmedValue) => void
}

export const getUserStore = create<State & Action>(set => ({
  user_socmed: {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
  },
  setUserSocmed: (user_socmed) => set(() => ({ user_socmed })),
  setUserSocmedbyKey: (key, value) => set(({ user_socmed }) => ({ user_socmed: { ...user_socmed, [key]: value } })),
}))