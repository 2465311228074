import Axios, { AxiosRequestConfig } from 'axios';
import { set_transaction_data } from 'src/config/redux/slicer/admin/adminWalletSlicer';
import { set_bill_payment_data, set_offline_transaction_data, set_online_transaction_data, set_withdraw_data } from 'src/config/redux/slicer/expertise/expertiseWalletSlicer';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';
import { store } from 'src/config/redux/store';
import { authHeader, autoLogout } from '../core/auth.api';
import { APIRequestType } from '../type';
import { onGetAppointment } from './appointment.api';

const API_URL = "/api/v1/payment/xendit/"
const BALANCE_URL = '/api/v1/payment/balance/'

const health_care_id = localStorage.getItem('health_care_id') || 0

const initial_invoice = { status: '', invoice: {}, invoice_id: 0, invoice_url: '' }
export const getAppointmentInvoice = () => {
    return JSON.parse(
        localStorage.getItem('invoice') || JSON.stringify(initial_invoice)
    )
}

export const readTransactionOnline = async (limit, offset, params?, is_verify?, setState?: (data) => void) => {
    const query = `?limit=${limit}&offset=${offset}&location=Online&health_care_id=${health_care_id}` + (params || '')
    const appointment = await onGetAppointment({ query })
    if (is_verify) {
        await Promise.all(appointment.results.map(async (data) => {
            await onGetAppointment({ query: `${data.id}/?verify=true` })
        }))
    }
    if (!appointment.error) {
        const data = { ...appointment, limit, offset, query }
        setState ? setState(data) : store.dispatch(set_online_transaction_data(data))
    }
}

export const readTransactionOffline = async (limit, offset, params?, setState?: (data) => void) => {
    const query = `?limit=${limit}&offset=${offset}&location=Homecare,Offline&health_care_id=${health_care_id}` + (params || '')
    const appointment = await onGetAppointment({ query })
    if (!appointment.error) {
        const data = { ...appointment, limit, offset, query }
        setState ? setState(data) : store.dispatch(set_offline_transaction_data(data))
    }
}

export const readTransactionAll = async (limit, offset, params?, setState?: (data) => void) => {
    const query = `?limit=${limit}&offset=${offset}` + (params || '')
    let appointment = await onGetAppointment({ query })
    await Promise.all(appointment.results.map(async (data) => {
        await onGetAppointment({ query: `${data.id}/?verify=true` })
    }))
    if (!appointment.error) {
        const data = { ...appointment, limit, offset, query }
        setState ? setState(data) : store.dispatch(set_transaction_data(data))
    }
}

export const readPayoutLink = async (limit, offset, params?, is_verify?, setState?: (data) => void) => {
    const query = `?limit=${limit}&offset=${offset}&health_care_id=${health_care_id}` + (params || '')
    const payout = await onPaymentGetPayoutLink({ query })
    // const verif_payout: any = is_verify
    //     ? await Promise.all(payout.results.map(async (data) => {
    //         const update_payout = await onPaymentGetPayoutLink({ query: query + `&verify=${data.payout_id}` })
    //         return update_payout.results[0]
    //     }))
    //     : payout.results
    const verif_payout = payout.results
    if (!payout.error) {
        const data = { ...payout, results: verif_payout, limit, offset, query }
        setState ? setState(data) : store.dispatch(set_withdraw_data(data))
    }
}

export const readBillPayment = async (limit, offset, params?, is_verify?, setState?: (data) => void, selected_health_care?: string) => {
    const query = `?limit=${limit}&offset=${offset}&health_care_id=${selected_health_care || health_care_id}` + (params || '')
    const bill = await onPaymentGetBillPayment({ query })
    const verif_payout: any = is_verify
        ? await Promise.all(bill.results.map(async (data) => {
            const update_bill = await onPaymentGetBillPayment({ query: query + `&verify=${data.invoice_id}` })
            return update_bill.results[0]
        }))
        : bill.results
    if (!bill.error) {
        const data = { ...bill, results: verif_payout, limit, offset, query }
        setState ? setState(data) : store.dispatch(set_bill_payment_data(data))
    }
}

export const onPaymentGetInvoice = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `payment-online/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onPaymentCreateOnlineInvoice = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `payment-online/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onPaymentUpdateInvoice = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + `payment-online/${data.id}/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onQueryPayoutLink = async ({ data, params }: APIRequestType) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `payout-link`,
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onPaymentGetPayoutLink = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `payout-link/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}


export const onPaymentCreatePayoutLink = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `payout-link/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onQueryBillPayment = async ({ data, params }: APIRequestType) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `payment-offline`,
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onPaymentGetBillPayment = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `payment-offline/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onPaymentCreateBillPayment = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `payment-offline/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onGetPaymentBalanceCards = async ({ data, params }: APIRequestType) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: BALANCE_URL + `cards`,
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}
