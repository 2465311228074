import { createSlice } from '@reduxjs/toolkit'

export const searchQuerySlicer = createSlice({
  name: 'searchQuerySlicer',
  initialState: {
    query: '',
    field: '',
    profession: 'all',
    location: 'all',
    gender: 'all',
    service: 'all',
    filtered_data: [],
    selected_health_care: {},
    mh_form: null,
    burnout_form: null,
  },
  reducers: {
    set_search_query: (state, action) => {
      state.query = action.payload
    },
    set_field_query: (state, action) => {
      state.field = action.payload
    },
    set_profession_query: (state, action) => {
      state.profession = action.payload
    },
    set_location_query: (state, action) => {
      state.location = action.payload
    },
    set_gender_query: (state, action) => {
      state.gender = action.payload
    },
    set_service_query: (state, action) => {
      state.service = action.payload
    },
    set_health_care_query: (state, action) => {
      state.filtered_data = action.payload
    },
    set_selected_health_care: (state, action) => {
      state.selected_health_care = action.payload
    },
    set_mh_form: (state, action) => {
      state.mh_form = action.payload
    },
    set_burnout_form: (state, action) => {
      state.burnout_form = action.payload
    },
  },
})

export const {
  set_search_query,
  set_health_care_query,
  set_field_query,
  set_profession_query,
  set_gender_query,
  set_location_query,
  set_service_query,
  set_selected_health_care,
  set_mh_form,
  set_burnout_form,
} = searchQuerySlicer.actions
export default searchQuerySlicer.reducer
