import { differenceInDays, differenceInMonths, addDays, format } from "date-fns";
import { CalendarSearch, Check, CheckCircle, CheckSquare, ChevronLeft, ChevronRight, Circle, CircleDashed, CircleEllipsis, CircleEqual, CircleSlashed, Info, RefreshCcw, Save, SaveAll, Square, XCircle, XSquare } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { set_selected_child, set_vaccine } from "src/config/redux/slicer/lisban/lisbanAppSlicer";
import { set_child_data } from "src/config/redux/slicer/main/userChildSlicer";
import { getJWT } from "src/config/services/core/auth.api";
import { getUserChildInformation } from "src/config/services/core/user.api";
import { onCreateVaccineScheduler, onGetVaccineScheduler } from "src/config/services/fellas/vaccine.api";
import { cn, convertToMultipleArrays } from "src/lib/utils";
import { getLisbanStorage } from "..";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Calendar } from "src/components/ui/calendar";

export const findClosestNumber = (target, array) => array.reduce((a, b) => Math.abs(b - target) < Math.abs(a - target) ? b : a);

function VaccineScheduler() {
  const jwt = getJWT()
  const dispatch = useDispatch()
  const { toast } = useToast()
  const is_login = Boolean(getJWT().key)
  const [vpage, setVaccinePage] = useState(1)
  const user_child: any = useSelector((state: any) => state.UserChild.value)
  const { selected_child, vaccine, vaccine_progress } = useSelector((state: any) => state.LisbanApp)
  const [window_width, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => setWindowWidth(window.innerWidth)
  const maxLG = window_width < 1024
  const maxSM = window_width < 380

  const month_col = [0, 1, 2, 3, 4, 6, 9, 12, 15, 18, 24, 60, 108, 120, 144]
  const vaccine_month_cols = convertToMultipleArrays(month_col, maxSM ? 2 : maxLG ? 3 : 5)

  const selected_cols = vaccine_month_cols[vpage - 1]
  const seen_vaccine = new Set()
  const initial_vaccine_info = [...vaccine['mandatory'], ...vaccine['booster']].filter(item => !seen_vaccine.has(item.vaccine_name) && seen_vaccine.add(item.vaccine_name))
  const [vaccine_info, setVaccineInfo] = useState(initial_vaccine_info)
  const [input_vaccine_info, setInputVaccineInfo] = useState('')
  const updateUserChildState = async () => {
    if (jwt.key) {
      const resp = await getUserChildInformation({})
      if (!resp.error) dispatch(set_child_data(resp))
    }
  }

  const updateVaccineScheduler = async ({ user_child_id, birth_date }: { user_child_id?: number, birth_date?: string }) => {
    if (birth_date) {
      const vaccine = await onCreateVaccineScheduler({ birth_date })
      if (!vaccine.error) dispatch(set_vaccine(vaccine))
    } else {
      const vaccine_list = await onGetVaccineScheduler({ query: `?user_child_id=${user_child_id}` })
      if (vaccine_list.length === 0) {
        const vaccine = await onCreateVaccineScheduler({ user_child_id })
        if (!vaccine.error) dispatch(set_vaccine(vaccine))
      } else {
        if (!vaccine_list.error) dispatch(set_vaccine(vaccine_list[0]))
      }
    }
  }

  const fullname = selected_child.first_name && `${selected_child.first_name} ${selected_child.last_name}`
  const month_diff = differenceInMonths(new Date(), new Date(selected_child.birth_date))
  const day_diff = differenceInDays(new Date(), new Date(selected_child.birth_date))

  useEffect(() => {
    if (is_login) updateUserChildState()
    if (selected_child.id) updateVaccineScheduler({ user_child_id: selected_child.id })
    if (vaccine.id) setVaccineInfo(initial_vaccine_info)

  }, [selected_child.id, vaccine.id]);

  useEffect(() => {
    const selected_child_storage = getLisbanStorage().selected_child
    if (selected_child_storage.id) {
      dispatch(set_selected_child(selected_child_storage))
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onUpdateVacineStatus = (val, selected_vac, type) => {
    const vaccine_date = format(val, 'yyyy-MM-dd')
    const day_diff_ = day_diff - differenceInDays(new Date(), new Date(vaccine_date))
    const month_diff_ = day_diff_ / 30.44
    const status = (
      (month_diff_ <= selected_vac.start_time_month) ? 'not yet'
        : ((selected_vac.start_time_month <= month_diff_) && (month_diff_ <= selected_vac.end_time_month)) ? 'on-time'
          : ((selected_vac.end_time_month < month_diff_) && (month_diff_ <= selected_vac.tolerance_in_month)) ? 'warning'
            : 'off-time')
    const new_vaccine = { ...vaccine }
    new_vaccine[type] = vaccine[type].map(vac => vac.id === selected_vac.id ? ({ ...vac, status, vaccine_date, is_edited: true }) : vac)
    dispatch(set_vaccine(new_vaccine))
    saveVaccineScheduler(new_vaccine)
  }

  const calculateTargetDate = (month) => {
    const target_date = addDays(new Date(selected_child.birth_date), Math.round(month * 30.44))
    return format(target_date, 'PP')
  }

  const saveVaccineScheduler = async (current_vaccine = vaccine) => {
    const { mandatory, booster } = current_vaccine
    const resp = await onCreateVaccineScheduler({ user_child_id: selected_child.id, mandatory, booster })
    ToastAlert(resp, 'Jadwal vaksin berhasil disimpan', () => dispatch(set_vaccine(resp)))
  }

  const refreshVaccineScheduler = async () => {
    const resp = await onCreateVaccineScheduler({ user_child_id: selected_child.id, reset: true })
    ToastAlert(resp, 'Jadwal vaksin berhasil direset', () => dispatch(set_vaccine(resp)))
  }

  const VaccineItems = ({ type, month, ...props }) => {
    const vcolor = {
      'on-time': 'bg-green-300',
      'off-time': 'bg-red-300',
      'not yet': type === 'mandatory' ? 'bg-blue-300' : 'bg-violet-300',
      'warning': 'bg-yellow-300',
    }
    const filtered_vaccine = vaccine[type]
      .filter(vac => findClosestNumber(vac.end_time_month, vaccine_month_cols.flat(Infinity)) === month)
      .map(vac => ({ ...vac, start_date: calculateTargetDate(vac.start_time_month), end_date: calculateTargetDate(vac.end_time_month), tolerance_date: calculateTargetDate(vac.tolerance_in_month) }))
    const uniqueStatus: any = [...new Set(filtered_vaccine.map(vac => vac.status))]
    const status = uniqueStatus.length === 1 ? uniqueStatus[0] : 'not yet'
    return (
      <div {...props} className="min-h-[165px] h-full">
        {filtered_vaccine.length > 0 && (
          <div className={cn("m-2 rounded-md p-1 space-y-1", vcolor[status])}>
            {filtered_vaccine.map(vac => (
              <div key={vac.id} className={cn("flex gap-1 p-1 text-[11px] items-center h-5 rounded-md", vcolor[vac.status])}>
                <Button
                  variant="ghost" size="icon"
                  className={cn("p-0 h-3 w-3 rounded-sm")}
                >
                  <Popover>
                    <PopoverTrigger>
                      {vac.is_edited
                        ? <CheckCircle className="h-3 w-3 fill-white" />
                        : <Circle className="h-3 w-3 fill-white" />}
                    </PopoverTrigger>
                    <PopoverContent className="space-y-2 text-start">
                      <div className="grid items-center space-y-2">
                        <div className="flex justify-between">
                          <p className={type === 'mandatory' ? 'text-blue-500' : 'text-violet-500'}>{type.toUpperCase()}</p>
                          <p>{vac.vaccine_name}</p>
                        </div>
                        {/* <Input className="col-span-2" type="date" min={selected_child.birth_date} max={format(new Date(), 'yyyy-MM-dd')} defaultValue={vac.vaccine_date} onChange={e => onUpdateVacineStatus(e, vac, type)} /> */}
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button className="text-black hover:bg-transparent border active:border-primary bg-white justify-between">
                              <span className={cn(!vac.vaccine_date && "text-muted-foreground")}>{vac.vaccine_date ? vac.vaccine_date : 'Pilih Tanggal Vaksin'}</span>
                              <CalendarSearch className={cn("h-4", type === 'mandatory' ? 'text-blue-500' : 'text-violet-500')} />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="end">
                            <Calendar
                              className="bg-white"
                              mode="single"
                              selected={new Date(vac.vaccine_date)}
                              onSelect={val => onUpdateVacineStatus(val, vac, type)}
                              disabled={(date) =>
                                date > new Date() || date < new Date(selected_child.birth_date)
                              }
                              initialFocus />
                          </PopoverContent>
                        </Popover>
                      </div>
                      <div className="text-xs flex"><Square className="h-4 fill-green-300" />{vac.start_date} - {vac.end_date}</div>
                      <div className="text-xs flex"><Square className="h-4 fill-yellow-300" />{vac.end_date} - {vac.tolerance_date}</div>
                      <div className="text-xs flex"><Square className="h-4 fill-red-300" />lebih dari {vac.tolerance_date}</div>
                    </PopoverContent>
                  </Popover>
                </Button>
                <p className="hover:font-semibold cursor-pointer" onClick={() => onQueryInfo(vac.vaccine_name)}>{vac.freq !== '' && `[${vac.freq}]`} {vac.vaccine_name}</p>
              </div>
            ))}
          </div>
        )}
      </div>)
  }

  const child_month_col = findClosestNumber(day_diff / 30, vaccine_month_cols.flat(Infinity))

  const onQueryInfo = (value) => {
    setInputVaccineInfo(value)
    setVaccineInfo(initial_vaccine_info.filter(s => s.vaccine_name.toLowerCase().includes(value.toLowerCase())))
  }
  return (
    <div className="space-y-2">
      <p className="text-xl text-center text-primary font-semibold">Fellas Vaccine Calculator</p>
      <hr className="pb-4" />
      {is_login
        ? <div className="flex gap-4 items-center">
          <p>Silahkan pilih anak anda</p>
          <div className="w-64">
            <Select
              value={selected_child.id}
              onValueChange={id => {
                const selected_child = user_child.find(child => child.id === id)
                dispatch(set_selected_child(selected_child));
                localStorage.setItem('lisban', JSON.stringify({ selected_child, navbar: 'vaccine-scheduler' }))
                setInputVaccineInfo('')
              }}>
              <SelectTrigger><SelectValue>{fullname ? fullname + ` (${month_diff > 0 ? month_diff : day_diff} ${month_diff > 0 ? 'Bulan' : 'Hari'})` : 'Belum memilih'}</SelectValue></SelectTrigger>
              <SelectContent>
                {user_child.map(child => <SelectItem key={child.id} value={child.id}>{child.first_name} {child.last_name}</SelectItem>)}
              </SelectContent>
            </Select>
          </div>
          {vaccine_progress && <CircleSlashed className="animate-spin h-5 text-primary" />}
        </div>
        : <div className="flex gap-4 items-center">
          <p>Tanggal lahir anak anda</p>
          <Input
            type="date" className="w-64"
            max={format(new Date(), 'yyyy-MM-dd')} value={selected_child.birth_date}
            onChange={e => {
              dispatch(set_selected_child({ ...selected_child, birth_date: e.target.value }))
              updateVaccineScheduler({ birth_date: e.target.value })
            }}
          />
          {vaccine_progress && <CircleSlashed className="animate-spin h-5 text-primary" />}
        </div>}
      <div className="h-8 text-lg font-medium flex gap-2 justify-between items-center">Jadwal Vaksin
        {selected_child.id && <div className="flex gap-2">
          {/* <Button className="hover:text-primary" variant="ghost" size="icon"><Save onClick={saveVaccineScheduler} className="h-5" /></Button> */}
          {/* <Button className="hover:text-primary" variant="ghost" size="icon"><RefreshCcw onClick={refreshVaccineScheduler} className="h-5" /></Button> */}
        </div>}
      </div>
      <div className="border shadow-sm rounded-lg text-center text-sm">
        <div className="font-semibold p-1">{vpage !== 3 ? 'Bulan' : 'Tahun'} </div>
        <hr />
        <div className="grid grid-cols-5 max-lg:grid-cols-3 max-xs:grid-cols-2 items-center justify-center">
          {selected_cols.map(month => (
            <div key={month} className={cn((day_diff && (child_month_col === month)) && "bg-primary/20", "pt-1 font-semibold")}>
              <p>{month < 1 ? '0-14 Hari' : month < 2 ? '0-1' : month > 18 ? month / 12 : month}</p>
            </div>
          ))}
          <hr className="col-span-5" />
          {selected_cols.map(month => <VaccineItems key={month} type="mandatory" month={month} />)}
          <hr className="col-span-5" />
          {selected_cols.map(month => <VaccineItems key={month} type="booster" month={month} />)}
        </div>
      </div>
      <div className="w-full flex gap-2 text-gray-500 text-xs items-center justify-center">
        <ChevronLeft className="p-1 rounded-md hover:bg-muted cursor-pointer" onClick={() => vpage !== 1 && setVaccinePage(vpage - 1)} />
        <p>{vpage} / {vaccine_month_cols.length}</p>
        <ChevronRight className="p-1 rounded-md hover:bg-muted cursor-pointer" onClick={() => vpage !== vaccine_month_cols.length && setVaccinePage(vpage + 1)} />
      </div>
      <div className="space-y-2 min-h-[25em]">
        <div className="flex flex-wrap justify-center gap-x-8">
          <div className="flex gap-1 text-sm items-center"><Square className="h-3 w-3 fill-blue-300" /><Square className="h-3 w-3 fill-violet-300" /> Belum waktunya</div>
          <div className="flex gap-1 text-sm items-center"><Square className="h-3 w-3 fill-green-300" /> Tepat waktu</div>
          <div className="flex gap-1 text-sm items-center"><Square className="h-3 w-3 fill-yellow-300" /> Masih bisa ditoleransi</div>
          <div className="flex gap-1 text-sm items-center"><Square className="h-3 w-3 fill-red-300" /> Terlambat</div>
        </div>
        <div>Informasi Jenis Imunisasi</div>
        <Input placeholder="Cari nama imunisasi" value={input_vaccine_info} onChange={e => onQueryInfo(e.target.value)} />
        <Accordion type="single" collapsible className="w-full">
          <div className="space-y-2">
            {vaccine_info.slice(0, 5).map(item => (
              <AccordionItem key={item.id} value={item.id}>
                <AccordionTrigger className="hover:text-primary hover:no-underline border border-b-0 px-3 shadow-sm">{item.vaccine_name}</AccordionTrigger>
                <AccordionContent className="pt-2">{item.information}</AccordionContent>
              </AccordionItem>
            ))}
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default VaccineScheduler;