import { useSelector } from "react-redux";
import NoImage from "src/assets/images/NoImage.png";
import { Card } from "src/components/ui/card";
import { toAbsolutePath } from "src/lib/utils";

function AppointmentServiceReview() {
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  return (
    <Card className="rounded-b-none">
      <div className="flex gap-4 p-4 items-center">
        <img className="h-12 aspect-square rounded-full bg-gray-300" src={toAbsolutePath(selected_health_care.profile_img) || NoImage} />
        <div className="grid">
          <div className="font-semibold">{selected_health_care.detail.fullname}</div>
          <div>{selected_health_care.detail.profession}</div>
        </div>
      </div>
    </Card>
  );
}

export default AppointmentServiceReview;