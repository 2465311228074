import { cn } from "src/lib/utils"

type SkeletonType = {
    className?: string
    loading?: boolean
    children?: any
}

function Skeleton({
    className,
    loading,
    ...props
}: SkeletonType) {
    return (
        <div
            className={cn(className, loading && "animate-pulse rounded-md bg-muted text-transparent")}
            {...props}
        />
    )
}

export { Skeleton }

