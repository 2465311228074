import { createSlice } from '@reduxjs/toolkit'

export const userSlicer = createSlice({
  name: 'userSlicer',
  initialState: {
    value: {},
  },
  reducers: {
    set_user_data: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { set_user_data } = userSlicer.actions
export default userSlicer.reducer
