import type { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  token: {
    fontSize: 13,
    colorPrimary: '#FB77C6',
  },
  components: {
    Modal: {
      titleFontSize: 20,
    },
    Menu: {
      itemHeight: 32,
    },
    Carousel: {
      colorBgContainer: '#FB77C6'
    }
  }
};