import { Table, Tag } from "antd";
import { ArrowLeftCircle, BeanOff, CalendarDays, Ruler, Scale } from "lucide-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { onQueryAppointment } from "src/config/services/fellas/appointment.api";
import { columns_child, updatePatientId } from "./columns";
import { columns } from "../history/columns";

const health_care_id = localStorage.getItem('health_care_id') || 0

function PatientDetail({ patient }) {
  const [selected_table, setSelectedTable] = useState('Online')
  const { value } = useSelector((state: any) => state.Expertise)

  const { data: history, isLoading, refetch } = useQuery('get-appointment', async () => {
    const data = await onQueryAppointment({
      params: {
        health_care_id,
        appointment_ids: patient.history_id || 0,
        status: 'Completed',
        location: selected_table
      },
      hide_page_loading: true
    })
    return data
  })
  let history_data = (history?.results || []).map(item => ({ ...item, key: item.id }))

  useEffect(() => {
    refetch()
  }, [selected_table]);

  return (
    <div className="px-4 flex justify-between">
      <div className="space-y-2 w-full">
        <p className="text-lg flex gap-2 items-center">
          <ArrowLeftCircle onClick={() => updatePatientId('')} className="text-primary cursor-pointer" />
          <b>{patient.fullname}</b>
        </p>
        <div>
          <hr className="mx-[-1em] py-2" />
          <div className="text-base text-black font-medium">Informasi Pasien</div>
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 text-sm text-gray-400">
            <div>{patient.gender || '-'}</div>
            <div className="flex gap-1 items-center"><Ruler className="h-4" />{patient.height || '-'} cm</div>
            <div className="flex gap-1 items-center"><BeanOff className="h-4" />Alergi</div>
            <div className="flex gap-1 items-center"><CalendarDays className="h-4" />{patient.birth_date || '-'}</div>
            <div className="flex gap-1 items-center"><Scale className="h-4" />{patient.weight || '-'} kg</div>
            <div className="flex gap-1 flex-wrap ml-7">{!patient.allergy ? '-' : patient.allergy.split(',').map(a => <Tag key={a} color="pink">{a}</Tag>)}</div>
          </div>
        </div>
        <hr className="mx-[-1em]" />
        {patient.type === 'parent' &&
          <div className="py-2 space-y-2">
            <div className="font-medium">Anak</div>
            {patient.user_child.length
              ? <Table
                columns={columns_child}
                dataSource={patient.user_child.map(item => ({ ...item, key: item.id }))}
                pagination={{ pageSize: 4, hideOnSinglePage: true }}
                scroll={{ x: 1000 }}
              />
              : <p className="text-destructive text-xs">* Belum mengisi data anak</p>}
            <hr className="mx-[-1em]" />
          </div>}
        <div className="py-2 space-y-2">
          <div className="flex max-md:flex-col justify-between">
            <div className="font-medium">Riwayat Pertemuan</div>
            <RadioGroup value={selected_table} onValueChange={val => setSelectedTable(val)}>
              <div className="grid grid-cols-3 max-md:grid-cols-2 md:items-center gap-4 max-md:gap-1">
                <div className="flex items-center gap-2"><RadioGroupItem value="Online" />Online</div>
                <div className="flex items-center gap-2"><RadioGroupItem value="Homecare" />Homecare</div>
                <div className="flex items-center gap-2"><RadioGroupItem value="Offline" />{value.offline_location?.label || 'Offline'}</div>
              </div>
            </RadioGroup>
          </div>
          <Table
            loading={isLoading}
            columns={columns?.filter(col => col.key !== 'Action')}
            dataSource={history_data}
            pagination={{ pageSize: 6, hideOnSinglePage: true }}
            scroll={{ x: 1000 }}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientDetail;