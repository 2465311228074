import { ItemType } from "antd/es/menu/hooks/useItems"

export const menuClassName = window.innerWidth >= 1024 ? 'pt-2 text-[15px]' : 'w-full'
export const iconClassName = 'mr-2 h-4 w-4'

export const INITIAL_HEADER_MENU: ItemType[] = [
  {
    key: 'fellas',
    label: 'Beranda',
    className: menuClassName,
    onClick: () => window.location.assign('https://fellas.id')
  },
  {
    key: 'about-us',
    label: 'Tentang Kami',
    className: menuClassName,
    onClick: () => window.location.assign('https://fellas.id/about-us')
  },
  {
    key: 'service',
    label: 'Layanan Kami',
    className: menuClassName,
    children: [
      {
        key: 'health-care',
        label: 'Konsultasi',
        onClick: () => window.location.assign('/health-care')
      },
      {
        key: 'event',
        label: 'Acara',
        disabled: true,
      },
      {
        key: 'community',
        label: 'Komunitas',
        disabled: true,
      }
    ]
  },
  {
    key: 'article',
    label: 'Artikel',
    className: menuClassName,
    onClick: () => window.location.assign('https://fellas.id/blog')
  },
]

export const INITIAL_MAIN_NAVBAR: ItemType[] = [
  { key: 'main', label: 'Dashboard' },
  { key: 'expertise', label: 'Tenaga Ahli' },
  { key: 'child_profile', label: 'Profil Anak' },
  { key: 'appointment', label: 'Janji Temu' },
  { key: 'event', label: 'Acara' },
  { key: 'community', label: 'Komunitas' },
]

export const INITIAL_EXPERTISE_NAVBAR: ItemType[] = [
  { key: 'main', label: 'Dashboard' },
  { key: 'appointment', label: 'Janji Temu' },
  { key: 'patient', label: 'Pasien' },
  { key: 'history', label: 'Riwayat' },
  { key: 'wallet', label: 'Dompetku' },
]

export const INITIAL_ADMIN_NAVBAR: ItemType[] = [
  { key: 'main', label: 'Dashboard' },
  { key: 'request', label: 'Expertise Request' },
  { key: 'wallet-expertise', label: 'Appointment Balance' },
]

export const INITIAL_LISBAN_NAVBAR: ItemType[] = [
  // { key: 'main', label: 'Dashboard' },
  { key: 'vaccine-scheduler', label: 'FECAL' },
  { key: 'nutrition-calculator', label: 'CALNITION' },
]