import { BuildingIcon, CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon, CrossIcon, UserIcon } from "lucide-react";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoImage from "src/assets/images/NoImage.png";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import { set_open_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_appointment_unpaid_data } from "src/config/redux/slicer/main/myAppointmentSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { onPaginateAppointment, onPaginateInvoice } from "src/config/services/fellas/appointment.api";
import { getHealthCareDataByQuery } from "src/config/services/fellas/health-care.api";
import { onPaymentGetInvoice } from "src/config/services/fellas/payment.api";
import { cn, toAbsolutePath } from "src/lib/utils";
import DialogPayment, { createNewAppointment } from "src/pages/HealthCare/appointment/payment";

export const colors = {
  'SETTLED': 'bg-green-300',
  'PAID': 'bg-green-300',
  'PENDING': 'bg-violet-300',
  'EXPIRED': 'bg-red-500 text-white',
}

export const status = {
  'SETTLED': 'Lunas',
  'PAID': 'Lunas',
  'PENDING': 'Belum Dibayar',
  'EXPIRED': 'Kadaluarsa',
}

function UnpaidAppointment() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = getCurrentUser()
  const { unpaid_data } = useSelector((state: any) => state.MyAppointment)
  const { dialog } = useSelector((state: any) => state.PaymentDialog)
  const query = `&appointment_id=null&user_id=${user.id}`

  const { mutate: getHealthCareData, data: health_care } = useMutation(async () => {
    if ((unpaid_data.results || []).length) {
      const id = unpaid_data.results.map(item => item.health_care_id).join(',')
      const data = await getHealthCareDataByQuery({ params: { id } })
      return data?.results
    }
  }, {
    onSuccess: (data) => {
      const results = unpaid_data.results.map(item => ({ ...item, health_care: data.find(d => d.id === item.health_care_id) }))
      dispatch(set_appointment_unpaid_data({ ...unpaid_data, results }))
    }
  })

  const readAppointment = async (limit, offset, query?, is_verify?) => {
    const query_params = `?limit=${limit}&offset=${offset}` + (query || '')
    const invoice = await onPaymentGetInvoice({ query: query_params })
    const verif_invoice: any = is_verify
      ? await Promise.all(invoice.results.map(async (data) => {
        const update_invoice = await onPaymentGetInvoice({ query: query_params + `&verify=${data.invoice_id}` })
        return update_invoice.results[0]
      }))
      : invoice.results
    const unpaid = verif_invoice.map(inv => {
      const { id, status, amount, invoice_id, invoice_url, admin_fees } = inv
      return ({ ...inv.appointment_form, id, status, invoice_id, invoice_url, amount, admin_fees })
    })
    invoice.results = unpaid
    dispatch(set_appointment_unpaid_data({ ...invoice, limit, offset, query }))
  }
  useEffect(() => {
    readAppointment(3, 0, query, true).then(() => {
      getHealthCareData()
    })
  }, []);

  return unpaid_data.results.length > 0 ? (
    <div className="space-y-2 w-full grid">
      {unpaid_data.results.map((item, i) => (
        <Card key={i}>
          <div className="m-4 flex gap-2">
            <img src={toAbsolutePath(item.health_care?.profile_img) || NoImage} className="h-16 aspect-square rounded-full max-xs:hidden" />
            <div className="grid grid-cols-4 max-md:grid-cols-1 gap-2 w-full text-sm text-gray-400">
              <div className="col-span-2 text-lg text-black font-medium">{item.health_care?.detail?.fullname}</div>
              <div className="col-span-2 flex gap-2 md:justify-self-end">
                {(item.location === 'Online') && ['PENDING'].includes(item.status) &&
                  <Dialog open={dialog.online_invoice} onOpenChange={() => dispatch(set_open_payment_dialog('online_invoice'))}>
                    <DialogTrigger asChild>
                      <Badge className="hover:bg-lime-300/80 bg-lime-300 text-black animate-bounce duration-1000 h-6 font-semibold">
                        Invoice Link
                      </Badge>
                    </DialogTrigger>
                    <DialogPayment invoice={item} is_dashboard />
                  </Dialog>
                }
                {(item.location === 'Online') && ['PAID', 'SETTLED'].includes(item.status) &&
                  <Badge
                    className="hover:bg-primary/80 bg-primary text-white animate-bounce duration-1000 h-6 font-semibold"
                    onClick={() => { }}
                  >
                    Memproses Pembayaran
                  </Badge>}
                <Badge className={cn(`hover:${colors[item.status]}`, 'text-black col-span-1 h-6 w-fit', colors[item.status])}>{status[item.status]}</Badge>
              </div>
              <div className="md:col-span-4">{item.health_care?.detail?.profession}</div>
              <div className="flex gap-1"><BuildingIcon className="h-5 text-primary" />{['Online', 'Homecare'].includes(item.location) ? item.location : 'Offline'}</div>
              <div className="flex gap-1"><CalendarDaysIcon className="h-5 text-primary" />{item.date}</div>
              <div className="md:col-span-2 flex gap-1"><ClockIcon className="h-5 text-primary" />{item.time_range}</div>
              <div className="flex gap-1"><UserIcon className="h-5 text-primary" />{item.user_child ? item.user_child.first_name : item.user?.first_name} {item.user_child ? item.user_child.last_name : item.user?.last_name}</div>
              <div className="flex flex-wrap gap-1"><CrossIcon className="h-5 text-primary" />{item.condition}</div>
            </div>
          </div>
        </Card>
      ))}
      {(unpaid_data.next || unpaid_data.previous) && <div className="justify-end flex gap-2">
        <Button variant="ghost" onClick={() => onPaginateInvoice(unpaid_data, 'previous', 'unpaid')} disabled={!unpaid_data.previous}><ChevronLeftIcon /></Button>
        <Button variant="ghost" onClick={() => onPaginateInvoice(unpaid_data, 'next', 'unpaid')} disabled={!unpaid_data.next}><ChevronRightIcon /></Button>
      </div >}
    </div >
  ) : (
    <div className="h-36 space-y-2 py-8 justify-center text-center rounded-md bg-white shadow shadow-secondary/70 px-4">
      <p className="text-xl font-semibold">Ingin konsultasi dengan tenaga ahli Fellas?</p>
      <Button variant="outline" className="w-32" onClick={() => navigate('/health-care?filter=&profession=all')}>Atur Janji</Button>
    </div>
  );
}

export default UnpaidAppointment;