import { Download, Info, Repeat, Search } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Input } from "src/components/ui/input";
import { readBillPayment, readTransactionAll, readTransactionOffline } from "src/config/services/fellas/payment.api";
import { cn } from "src/lib/utils";
import DataTable from "src/pages/Expertise/tabs/wallet/data-table";
import { columns } from "./columns";
import { set_all_bill_payment_data, set_all_transaction_data } from "src/config/redux/slicer/admin/adminWalletSlicer";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { getHealthCareDataByQuery } from "src/config/services/fellas/health-care.api";
import DialogAllTransaction from "src/components/dialog/wallet/appointment/all-transaction";

function AdminWalletExpertise() {
  const dispatch = useDispatch()
  const initial_all_hc = { key: '', fullname: 'Semua' }
  const [query_filter, setQuerySearch] = useState({ start_date: '', end_date: '', filter: '', health_care_id: '', is_submit: true, })
  const [health_care_list, setHealthCareList] = useState<{ key: string, fullname: string }[]>([initial_all_hc])
  const { transaction_data, all_transaction_data, all_bill_payment_data } = useSelector((state: any) => state.AdminWallet)
  const { dialog } = useSelector((state: any) => state.PaymentDialog)
  const { limit, offset } = transaction_data

  const { filter, start_date, end_date, health_care_id, is_submit } = query_filter
  const params = `&filter=${filter}&start_date=${start_date}&end_date=${end_date}&health_care_id=${health_care_id}`
  const selected_health_care = health_care_list.find(hc => hc.key === health_care_id) || initial_all_hc

  const online_balance = all_transaction_data.results
    .filter(data => (data.status === 'Completed' && data.is_settled && data.location === 'Online'))
    .map(data => Math.round(data.amount * 0.3)).reduce((a, b) => a + b, 0)

  const offline_balance = all_transaction_data.results
    .filter(data => (data.status === 'Completed' && data.location !== 'Online'))
    .map(data => Math.round(data.amount * 0.3)).reduce((a, b) => a + b, 0)

  const complete_bill_amount = all_bill_payment_data.results.filter(data => data.status !== 'EXPIRED').map(entry => {
    const adminFeesTotal = entry.admin_fees.reduce((total, fee) => total + fee.value, 0);
    return entry.amount - adminFeesTotal;
  }).reduce((a, b) => a + b, 0);

  const handleSubmitFilter = (e: React.FormEvent) => {
    e.preventDefault()
    setQuerySearch({ ...query_filter, is_submit: true })
  }

  const setHealthCareOptions = async () => {
    const resp = await getHealthCareDataByQuery({})
    const hc_options = resp.results.map(hc => ({ key: String(hc.id), fullname: hc.detail.fullname }))
    setHealthCareList([initial_all_hc, ...hc_options])
  }

  useEffect(() => {
    readTransactionAll(limit, offset, params)
    setQuerySearch({ ...query_filter, is_submit: false })
  }, [is_submit, health_care_id]);

  useEffect(() => {
    const bill_params = ``
    readTransactionAll('', '', params, (data) => dispatch(set_all_transaction_data(data)))
    readBillPayment('', '', bill_params, true, (data) => dispatch(set_all_bill_payment_data(data)), health_care_id)
    setHealthCareOptions()
  }, [transaction_data.count, is_submit, health_care_id]);

  const balance_expertise_card = [
    {
      key: 'online_income',
      title: 'Total Pemasukan Online',
      icon: <Download className="w-5 h-5 stroke-success" />,
      amount: online_balance,
      info: 'Total pendapatan dari layanan online dari biaya aplikasi sejumlah 30%',
      textClassName: 'text-success'
    },
    {
      key: 'offline_income',
      title: 'Total Pemasukan Offline',
      icon: <Download className="w-5 h-5 stroke-blue-500" />,
      amount: offline_balance,
      info: 'Tagihan pendapatan dari layanan offline & homecare dari 30% setiap transaksi',
      textClassName: 'text-blue-500'
    },
    {
      key: 'transaction',
      title: 'Total Transaksi',
      icon: <Repeat className="w-5 h-5 stroke-primary" />,
      total: all_transaction_data.count,
      info: 'Total transaksi tenaga ahli fellas',
      textClassName: 'text-primary'
    },
  ]

  return (
    <div className="container space-y-4 mb-4">
      <div className="flex justify-between items-end">
        <div className="grid gap-1">
          <div className="text-3xl font-semibold">Appointment Balance</div>
          <div className="text-zinc-400">Berikut informasi saldo admin dari transaksi janji tenaga ahli</div>
        </div>
      </div>
      <div className="grid grid-cols-3 max-md:grid-cols-1 gap-2">
        {balance_expertise_card.map(item => (
          <Card key={item.key} className="p-4 flex justify-between">
            <div className="flex gap-4">
              {item.icon}
              <div>
                <p className="text-sm">{item.title}</p>
                <b className={cn("text-lg", item.textClassName)}>{item.hasOwnProperty('amount') ? `Rp ${(item.amount || 0).toLocaleString('id')}` : item.total}</b>
                {health_care_id && item.key === 'offline_income' &&
                  <p className="text-destructive text-xs">
                    * telah dibayarkan:
                    <b>{` Rp ${(complete_bill_amount < item.amount ? complete_bill_amount : item.amount).toLocaleString('id')}`}</b>
                  </p>}
              </div>
            </div>
            <Popover>
              <PopoverTrigger><Info className="w-4 h-4 cursor-help" /> </PopoverTrigger>
              <PopoverContent className="px-2 py-1 text-xs w-fit max-w-[240px]">{item.info}</PopoverContent>
            </Popover>
          </Card>
        ))}
      </div>
      <form onSubmit={handleSubmitFilter} className="flex max-md:flex-wrap gap-2 w-2/3 max-md:w-full">
        <div className="flex gap-2 w-full">
          <Select onValueChange={val => setQuerySearch(query => ({ ...query, health_care_id: val }))}>
            <SelectTrigger value={selected_health_care.key} className="h-8"><SelectValue>{selected_health_care?.fullname}</SelectValue></SelectTrigger>
            <SelectContent>{health_care_list.map(hc =>
              <SelectItem key={hc.key} value={hc.key}>{hc.fullname}</SelectItem>
            )}</SelectContent>
          </Select>
          <Input className="h-8" placeholder="Cari" onChange={e => setQuerySearch({ ...query_filter, filter: e.target.value })} />
        </div>
        <div className="flex gap-2 w-full">
          <Input disabled className="h-8 md:w-1/2" placeholder="Filter.." />
          <Button size="icon" type="submit" className="px-1 h-8"><Search className="h-5" /></Button>
        </div>
      </form>
      <DataTable data={transaction_data} columns={columns} selected_tab="admin_transaction" />
      {dialog.admin_transaction && <DialogAllTransaction />}
    </div>);
}

export default AdminWalletExpertise;