import Axios, { AxiosRequestConfig } from 'axios';
import { set_current_appointment, set_homecare_appointment, set_offline_appointment, set_online_appointment } from 'src/config/redux/slicer/expertise/expertiseSlicer';
import { set_bill_payment_data, set_offline_transaction_data, set_online_transaction_data, set_withdraw_data } from 'src/config/redux/slicer/expertise/expertiseWalletSlicer';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';
import { set_appointment_data, set_appointment_unpaid_data } from 'src/config/redux/slicer/main/myAppointmentSlicer';
import { store } from 'src/config/redux/store';
import { authHeader, autoLogout } from '../core/auth.api';
import { set_transaction_data } from 'src/config/redux/slicer/admin/adminWalletSlicer';
import { APIRequestType } from '../type';
import { API_FELLAS } from '../api';

const API_URL = "/api/v1/health-care/"
const API_CALENDAR_URL = "/api/v1/calendar-event/"

const health_care_id = localStorage.getItem('health_care_id') || 0

export const readAppointmentOnline = async (limit, offset, params?) => {
    const query = `?limit=${limit}&offset=${offset}&location=Online&health_care_id=${health_care_id}` + (params || '')
    const resp = await onGetAppointment({ query })
    store.dispatch(set_current_appointment({ ...resp, limit, offset, query }))
}

export const readAppointmentOffline = async (limit, offset, params?) => {
    const query = `?limit=${limit}&offset=${offset}&location=Offline&health_care_id=${health_care_id}` + (params || '')
    const resp = await onGetAppointment({ query })
    store.dispatch(set_current_appointment({ ...resp, limit, offset, query }))
}

export const readAppointmentHomecare = async (limit, offset, params?) => {
    const query = `?limit=${limit}&offset=${offset}&location=Homecare&health_care_id=${health_care_id}` + (params || '')
    const resp = await onGetAppointment({ query })
    store.dispatch(set_current_appointment({ ...resp, limit, offset, query }))
}

export const onPaginateWallet = async (data, action, selected_tab: 'withdraw' | 'bill' | 'online' | 'offline' | 'admin_transaction') => {
    const { limit, offset } = data
    const { data: resp } = await Axios.request({ method: 'get', url: data[action], headers: authHeader() })
    const query = data[action]
    if (selected_tab === 'withdraw') store.dispatch(set_withdraw_data({ ...resp, limit, offset, query }))
    else if (selected_tab === 'bill') store.dispatch(set_bill_payment_data({ ...resp, limit, offset, query }))
    else if (selected_tab === 'online') store.dispatch(set_online_transaction_data({ ...resp, limit, offset, query }))
    else if (selected_tab === 'admin_transaction') store.dispatch(set_transaction_data({ ...resp, limit, offset, query }))
    else store.dispatch(set_offline_transaction_data({ ...resp, limit, offset, query }))
}

export const onPaginateAppointment = async (data, action) => {
    const is_online = data.query.includes('Online')
    const { limit, offset } = data
    const { data: resp } = await Axios.request({ method: 'get', url: data[action], headers: authHeader() })
    const query = data[action]
    if (data.query.includes('location')) {
        if (is_online) store.dispatch(set_online_appointment({ ...resp, limit, offset, query }))
        else store.dispatch(set_offline_appointment({ ...resp, limit, offset, query }))
    } else store.dispatch(set_appointment_data({ ...resp, limit, offset, query }))
}

export const onPaginateInvoice = async (data, action, page) => {
    const { limit, offset } = data
    const { data: invoice } = await Axios.request({ method: 'get', url: data[action], headers: authHeader() })
    const { query } = data
    if (page === 'unpaid') {
        const unpaid = invoice.results.map(inv => {
            const { id, status, amount, invoice_id, invoice_url, admin_fees } = inv
            return ({ ...inv.appointment_form, id, status, invoice_id, invoice_url, amount, admin_fees })
        })
        invoice.results = unpaid
        store.dispatch(set_appointment_unpaid_data({ ...invoice, limit, offset, query }))
    }
}

export const onCreateAppointment = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `appointment/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onDeleteAppointment = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'delete',
        url: API_URL + `appointment/${data.id}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onUpdateAppointment = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + `appointment/${data.id}/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onQueryAppointment = async ({ data, params, id }: APIRequestType) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `appointment` + (id ? `/${id}` : ''),
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onGetAppointment = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `appointment/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onGetScheduleAvailability = async (params: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_FELLAS + `/api/v2/healthcare/schedule/avail/`,
        headers: authHeader(),
        params
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}


export const onCreateCalendarEvent = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_CALENDAR_URL,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}