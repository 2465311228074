import { CheckCircle, Info, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { checkUserExists, getCurrentUser, getJWT, onChangeUserPassword, onUpdateUserInformation } from "src/config/services/core/auth.api";
import { cn } from "src/lib/utils";
import { setAuthPage } from ".";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Progress, Spin } from "antd";

function SetPassword() {
  const user = getCurrentUser('user_temp')
  const { first_name, last_name, username, is_password_created } = user
  const navigate = useNavigate()
  const [form, setForm] = useState({
    first_name,
    last_name,
    username,
    password: '',
    confirm_password: ''
  })
  const [is_error, setIsError] = useState({
    username: { status: false, text: '' },
    password: { status: false, text: '' },
    confirm_password: { status: false, text: '' },
  })
  const [username_not_exists, setUsernameNotExists] = useState(true)

  const { mutate: onCheckUsername, isLoading } = useMutation(async () => {
    const { data: is_exists } = await checkUserExists({ username })
    return is_exists
  }, {
    onSuccess: (is_exists) => {
      if (!is_exists) {
        setUsernameNotExists(false)
        setIsError(error => ({ ...error, username: { status: false, text: '' } }))
      }
      else {
        setUsernameNotExists(true)
        setIsError(error => ({ ...error, username: { status: true, text: 'Account already registered, please use another username' } }))
      }
    }
  })

  const checkUsername = async () => {
    const { username } = form
    if (username.length < 6 || username.length > 12) {
      setUsernameNotExists(true)
      setIsError(error => ({ ...error, username: { status: true, text: 'Username must be at least 6 and below 12 characters' } }))
    } else {
      await onCheckUsername()
    }
  }

  const updateAccountInformation = async (e: React.FormEvent) => {
    e.preventDefault()
    const { first_name, last_name, username, password, confirm_password } = form
    const user = await onUpdateUserInformation({ first_name, last_name, username, is_password_created: true, is_email_verified: true })
    await onChangeUserPassword({ new_password1: password, new_password2: confirm_password })
    localStorage.removeItem('user_temp')
    localStorage.setItem('user', JSON.stringify(user))
    setTimeout(() => window.location.assign('/'), 1000)
  }

  useEffect(() => {
    if (!username || is_password_created) {
      navigate('/')
    }
    checkUsername()
  }, [form.username]);

  return (
    <div className="fixed top-0 left-0 z-10 h-screen w-screen bg-muted/60 ">
      <Card className="max-w-[600px] mx-auto my-[30vh] p-6 space-y-2 shadow-lg text-center">
        <div className='flex justify-between'>
          <div className='text-lg font-semibold'>Informasi Akun</div>
          <X className='stroke-destructive cursor-pointer' onClick={setAuthPage('login')} />
        </div>
        <p className='text-sm text-left'>Lengkapi data berikut sebelum masuk dashboard fellas</p>
        <div className='grid grid-cols-2 gap-4 p-2'>
          <div className="col-span-2">
            <Input
              className={cn(is_error.username.status && "border-destructive", !username_not_exists && "border-success")}
              placeholder='Username'
              value={form.username}
              onChange={e => {
                const username = e.target.value.toLowerCase()
                setForm(form => ({ ...form, username }))
              }}
            />
            {isLoading
              ? <div className="flex justify-start pt-1 px-2"><Spin size="small" /></div>
              : <div>
                {is_error.username.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.username.text}</div>}
                {!username_not_exists && <div className="flex items-center text-sm text-success"><CheckCircle className="h-3" />Username bisa didaftarkan</div>}
              </div>
            }
          </div>
          <Input value={form.first_name} placeholder="First Name" onChange={e => setForm(form => ({ ...form, first_name: e.target.value }))} />
          <Input value={form.last_name} placeholder="Last Name" onChange={e => setForm(form => ({ ...form, last_name: e.target.value }))} />
          <div>
            <Input
              type="password"
              className={cn(is_error.password.status && "border-destructive")}
              placeholder='Password'
              value={form.password}
              onChange={e => {
                setForm(form => ({ ...form, password: e.target.value }))
                if (e.target.value !== form.confirm_password) {
                  setIsError(error => ({ ...error, password: { status: true, text: '' }, confirm_password: { status: true, text: 'Password tidak sesuai' } }))
                } else {
                  setIsError(error => ({ ...error, password: { status: false, text: '' }, confirm_password: { status: false, text: '' } }))
                }
              }}
            />
            {is_error.password.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.password.text}</div>}
          </div>
          <div>
            <Input
              type="password"
              className={cn(is_error.confirm_password.status && "border-destructive")}
              placeholder='Confirm Password'
              value={form.confirm_password}
              onChange={e => {
                setForm(form => ({ ...form, confirm_password: e.target.value }))
                if (e.target.value !== form.password) {
                  setIsError(error => ({ ...error, password: { status: true, text: '' }, confirm_password: { status: true, text: 'Password tidak sesuai' } }))
                } else {
                  setIsError(error => ({ ...error, password: { status: false, text: '' }, confirm_password: { status: false, text: '' } }))
                }
              }}
            />
            {is_error.confirm_password.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.confirm_password.text}</div>}
          </div>
          <Button
            disabled={
              Object.values(form).some(is_fill => !is_fill) ||
              is_error.username.status || is_error.password.status || is_error.confirm_password.status}
            className={cn('col-span-2')}
            onClick={updateAccountInformation}
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default SetPassword;