import { ArrowRightCircle, CheckCircle2Icon, ChevronDown, ChevronUp, ChevronsRight, FileCheck2, FileIcon, FileMinus, FileMinus2, FilePlus2, Trash2Icon, XCircleIcon } from "lucide-react";
import { useDispatch } from "react-redux";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/components/ui/card";
import { set_request_data } from "src/config/redux/slicer/admin/adminExpertiseRequestSlicer";
import { set_all_expertise_propose } from "src/config/redux/slicer/expertise/expertiseProposeSlicer";
import { onDeleteHealthCarePropose, onHealthCareApproval } from "src/config/services/fellas/health-care.api";
import { ToastAlert } from "../ui/toaster";
import { useToast } from "../ui/use-toast";
import { cn, toAbsolutePath } from "src/lib/utils";
import { useNavigate } from "react-router-dom";


type ExpertiseRequestCardType = {
  data: any,
  status?: string,
  onUpdateDate?: Function,
}

export function ExpertiseRequestCard({ data, status, onUpdateDate }: ExpertiseRequestCardType) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { toast } = useToast()
  const toggleExpertiseRequest = (id) => {
    const updated_data = data.map(d => d.id === id ? { ...d, is_open_detail: !d.is_open_detail } : d)
    if (status === 'admin') dispatch(set_request_data(updated_data))
    else if (status === 'user') dispatch(set_all_expertise_propose(updated_data))
  }

  const submitApproval = (id: number, is_accepted: boolean) => async () => {
    if (window.confirm(`Anda yakin untuk ${is_accepted ? 'menyetujui' : 'menolak'} permintaan ini?`)) {
      const resp = await onHealthCareApproval({ id, is_accepted })
      if (onUpdateDate) onUpdateDate()
      ToastAlert(resp, `Permintaan tenaga ahli ${is_accepted ? 'disetujui' : 'ditolak'}`)
    }
  }

  const deletePropose = (id: number) => async () => {
    if (window.confirm(`Anda yakin untuk menghapus permintaan ini?`)) {
      const resp = await onDeleteHealthCarePropose({ id })
      ToastAlert(resp, `Permintaan berhasil dihapus`)
      if (onUpdateDate) onUpdateDate()
    }
  }

  return (
    <div className="space-y-2">
      {data.map(item => (
        <Card key={item.id}>
          <CardHeader>
            <div className="flex justify-between">
              <div className="md:items-center flex max-md:flex-col gap-x-4">
                <CardTitle className="font-medium text-xl">{item.fullname}</CardTitle>
                <div className="flex gap-x-4">
                  {item.is_accepted === -1 ? <Badge className="bg-transparent border-primary text-primary">Diajukan</Badge>
                    : item.is_accepted ? <Badge className="bg-primary text-white">Diterima</Badge>
                      : <Badge className="bg-gray-300 text-muted-foreground">Ditolak</Badge>}
                  {status === 'user' && item.is_accepted === -1 && <Trash2Icon className="text-destructive p-1 rounded-full hover:bg-destructive/10 cursor-pointer" onClick={deletePropose(item.id)} />}
                  {item.is_accepted === 1 &&
                    <ArrowRightCircle className="hover:stroke-primary cursor-pointer" onClick={() => navigate('/app/expertise')} />
                  }
                </div>
              </div>
              <div className="cursor-pointer" onClick={() => toggleExpertiseRequest(item.id)}>
                {item.is_open_detail ? <ChevronUp /> : <ChevronDown />}
              </div>
            </div>
            <CardDescription>{item.profession}</CardDescription>
          </CardHeader>
          {item.is_open_detail && (
            <CardContent>
              <div className="space-y-3">
                <div className="grid grid-cols-2">
                  <div>
                    <div>Preferensi Layanan</div>
                    <div className="flex gap-4">
                      <div className="flex gap-2 items-center">
                        {item.is_online ? <CheckCircle2Icon className="w-4 h-4 text-primary" /> : <XCircleIcon className="w-4 h-4 text-destructive" />}
                        <div className="text-sm text-muted-foreground">Online</div>
                      </div>
                      <div className="flex gap-2 items-center">
                        {item.is_offline ? <CheckCircle2Icon className="w-4 h-4 text-primary" /> : <XCircleIcon className="w-4 h-4 text-destructive" />}
                        <div className="text-sm text-muted-foreground">Offline</div>
                      </div>
                      <div className="flex gap-2 items-center">
                        {item.is_homecare ? <CheckCircle2Icon className="w-4 h-4 text-primary" /> : <XCircleIcon className="w-4 h-4 text-destructive" />}
                        <div className="text-sm text-muted-foreground">Homecare</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div>Keahlian dan Tindakan</div>
                  <div className="flex flex-wrap gap-2">{item.skills.split(',').map(s => <Badge key={s}> {s}</Badge>)}</div>
                </div>
                <div className="space-y-1">
                  <div>File Pendukung</div>
                  <div className="grid grid-cols-3 max-md:grid-cols-1 gap-4">
                    <Card className={cn("cursor-pointer", item.sip_file && "hover:text-primary hover:border-primary")}>
                      <a href={toAbsolutePath(item.sip_file || '')} target="_blank" className="p-6 flex gap-4">
                        {item.sip_file ? <FileCheck2 className={"w-6 h-6 text-primary"} /> : <FileMinus2 className={"w-6 h-6"} />}sip_file.pdf
                      </a>
                    </Card>
                    <Card className={cn("cursor-pointer", item.str_file && "hover:text-primary hover:border-primary")}>
                      <a href={toAbsolutePath(item.str_file || '')} target="_blank" className="p-6 flex gap-4">
                        {item.str_file ? <FileCheck2 className={"w-6 h-6 text-primary"} /> : <FileMinus2 className={"w-6 h-6"} />}str_file.pdf
                      </a>
                    </Card>
                    <Card className={cn("cursor-pointer", item.cv_file && "hover:text-primary hover:border-primary")}>
                      <a href={toAbsolutePath(item.cv_file || '')} target="_blank" className="p-6 flex gap-4">
                        {item.cv_file ? <FileCheck2 className={"w-6 h-6 text-primary"} /> : <FileMinus2 className={"w-6 h-6"} />}cv_file.pdf
                      </a>
                    </Card>
                  </div>
                </div>
                {status === 'admin' && item.is_accepted === -1 &&
                  <div className="flex justify-end gap-2">
                    <Button className="bg-success hover:bg-success/80 px-6 font-semibold" onClick={submitApproval(item.id, true)}>Disetujui</Button>
                    <Button className="bg-destructive hover:bg-destructive/80 px-6 font-semibold" onClick={submitApproval(item.id, false)}>Ditolak</Button>
                  </div>}
              </div>
            </CardContent>
          )}
        </Card>
      ))}
    </div>
  )
}