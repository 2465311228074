import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_EXPERTISE_NAVBAR } from 'src/config/const/menubar'
import { onGetAppointment } from 'src/config/services/fellas/appointment.api'

export const expertiseSlicer = createSlice({
  name: 'expertiseSlicer',
  initialState: {
    value: { id: localStorage.getItem('health_care_id' || 0) },
    values: [],
    user_is_expertise: false,
    history_appointment: { limit: 6, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    current_appointment: { limit: 6, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    // --will deleted
    online_appointment: { limit: 6, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    offline_appointment: { limit: 6, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    homecare_appointment: { limit: 6, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    // 
    offline_location: {},
    homecare_area: {},
    patient: [],
    navbar: 'main',
    selected_schedule: 'online'
  },
  reducers: {
    set_expertise_data: (state, action) => {
      state.value = action.payload
    },
    set_all_expertise_data: (state, action) => {
      state.values = action.payload
    },
    set_user_is_expertise: (state, action) => {
      state.user_is_expertise = action.payload
    },
    set_history_appointment: (state, action) => {
      state.history_appointment = action.payload
    },
    set_current_appointment: (state, action) => {
      state.current_appointment = action.payload
    },
    // --will deleted
    set_online_appointment: (state, action) => {
      state.online_appointment = action.payload
    },
    set_offline_appointment: (state, action) => {
      state.offline_appointment = action.payload
    },
    set_homecare_appointment: (state, action) => {
      state.homecare_appointment = action.payload
    },
    // 
    set_expertise_patient: (state, action) => {
      state.patient = action.payload
    },
    set_expertise_navbar: (state, action) => {
      state.navbar = action.payload
    },
    set_selected_schedule: (state, action) => {
      state.selected_schedule = action.payload
    },
  },
})

export const {
  set_expertise_data,
  set_all_expertise_data,
  set_user_is_expertise,
  set_current_appointment,
  set_history_appointment,
  // will deleted
  set_online_appointment,
  set_offline_appointment,
  set_homecare_appointment,
  // 
  set_expertise_patient,
  set_expertise_navbar,
  set_selected_schedule
} = expertiseSlicer.actions
export default expertiseSlicer.reducer
