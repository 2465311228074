import { createSlice } from '@reduxjs/toolkit'

export const userChildSlicer = createSlice({
  name: 'userChildSlicer',
  initialState: {
    value: [],
  },
  reducers: {
    set_child_data: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { set_child_data } = userChildSlicer.actions
export default userChildSlicer.reducer
