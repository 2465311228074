import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "../redux/store";
import routes from "./routes";
import CSBubble from "src/components/cs-bubble";
import { Toaster } from "src/components/ui/toaster";

const Router = () => {
    return (
        <Provider store={store}>
            <RouterProvider router={routes} />
            <Toaster />
            <CSBubble />
        </Provider>
    );
}

export default Router;