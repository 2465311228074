import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Button } from "src/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { set_open_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { store } from "src/config/redux/store";
import { onPaginateAppointment, onPaginateWallet } from "src/config/services/fellas/appointment.api";

export default function DataTable({ columns, data, selected_tab }) {
  const table = useReactTable({
    data: data.results,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  return (
    <div className="rounded-md border">
      <Table className="max-md:text-xs">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="text-center font-semibold text-black" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="cursor-pointer"
                onClick={() => {
                  store.dispatch(set_payment_data(data.results[row.id]))
                  store.dispatch(set_open_payment_dialog(selected_tab))
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="text-center">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {
        (data.previous || data.next) &&
        <div className="flex items-center md:justify-end space-x-2 py-2 pb-4 pr-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onPaginateWallet(data, 'previous', selected_tab)}
            disabled={!data.previous}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onPaginateWallet(data, 'next', selected_tab)}
            disabled={!data.next}
          >
            <ChevronRightIcon />
          </Button>
        </div>
      }
    </div >
  )
}