import { useSelector } from "react-redux";
import AppointmentFillDataReview from "./fill_data";
import AppointmentServiceReview from "./service";
import AppointmentPaymentReview from "./payment";

function AppointmentFormReview() {
  const { form_page } = useSelector((state: any) => state.Appointment)
  return (
    <div className="lg:col-span-2 h-fit col-span-7 rounded-md shadow">
      <AppointmentServiceReview />
      {form_page > 1 && <AppointmentFillDataReview />}
      {form_page > 2 && <AppointmentPaymentReview />}
    </div>);
}

export default AppointmentFormReview;