import { Info, X } from "lucide-react";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { emailRegex, setAuthPage } from ".";
import { useEffect, useState } from "react";
import { cn } from "src/lib/utils";
import { useLocation } from "react-router-dom";
import { onChangeUserPassword, resetPasswordSendEmail, resetPasswordVerify } from "src/config/services/core/auth.api";
import { ToastAlert } from "src/components/ui/toaster";
import { toast } from "src/components/ui/use-toast";

function ForgotPassword() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || ''
  const uid = queryParams.get('uid') || ''
  const email = queryParams.get('email') || ''

  const [form, setForm] = useState({
    email: '',
    password: '',
    confirm_password: ''
  })
  const [is_error, setIsError] = useState({
    email: { status: false, text: '' },
    password: { status: false, text: '' },
    confirm_password: { status: false, text: '' },
    submit: { status: false, text: '' },
  })
  const [token_verified, setTokenVerified] = useState(false)

  const sendEmailVerif = async (e: React.FormEvent) => {
    e.preventDefault()
    const { email } = form
    const resp = await resetPasswordSendEmail({ email })
    ToastAlert(resp, resp.detail)
  }

  const changePassword = async (e: React.FormEvent) => {
    e.preventDefault()
    const { password, confirm_password } = form
    const resp = await resetPasswordVerify({ new_password1: password, new_password2: confirm_password, uid, token })
    ToastAlert(resp, 'Password berhasil diubah')
    setTimeout(() => setAuthPage('login'), 1000)
  }

  useEffect(() => {
    if (token && uid) {
      setForm(form => ({ ...form, email }))
      setTokenVerified(true)
    }
  }, [token, uid]);

  return (
    <div className="fixed top-0 left-0 z-10 h-screen w-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[500px] p-6 space-y-2">
          <div className='flex justify-between'>
            <div className='text-lg font-semibold'>{token_verified ? 'Ubah Password' : 'Lupa Password'}</div>
            <X className='stroke-destructive cursor-pointer' onClick={setAuthPage('login')} />
          </div>
          {token_verified ? (
            <form onSubmit={changePassword} className="grid gap-2 text-start py-4">
              <div>
                <label className="text-sm">Password Baru</label>
                <Input
                  type="password"
                  className={cn(is_error.password.status && "border-destructive")}
                  placeholder="Masukkan password baru"
                  value={form.password}
                  onChange={e => {
                    setForm(form => ({ ...form, password: e.target.value }))
                    if (e.target.value !== form.confirm_password) {
                      setIsError(error => ({ ...error, password: { status: true, text: '' }, confirm_password: { status: true, text: 'Password tidak sesuai' } }))
                    } else {
                      setIsError(error => ({ ...error, password: { status: false, text: '' }, confirm_password: { status: false, text: '' } }))
                    }
                  }}
                />
                {is_error.password.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.password.text}</div>}
              </div>
              <div>
                <label className="text-sm">Alamat email</label>
                <Input
                  type="password"
                  className={cn(is_error.confirm_password.status && "border-destructive")}
                  placeholder="Masukkan ulang password baru"
                  onChange={e => {
                    setForm(form => ({ ...form, confirm_password: e.target.value }))
                    if (e.target.value !== form.password) {
                      setIsError(error => ({ ...error, password: { status: true, text: '' }, confirm_password: { status: true, text: 'Password tidak sesuai' } }))
                    } else {
                      setIsError(error => ({ ...error, password: { status: false, text: '' }, confirm_password: { status: false, text: '' } }))
                    }
                  }}
                />
                {is_error.confirm_password.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.confirm_password.text}</div>}
              </div>
              <Button
                type="submit"
                className="mt-2"
                disabled={
                  Object.values(form).some(is_fill => !is_fill) ||
                  is_error.password.status || is_error.confirm_password.status}
              >Ajukan Reset Password</Button>
            </form>
          ) : (
            <form onSubmit={sendEmailVerif} className="grid gap-2 text-start py-4">
              <div>
                <label className="text-sm">Alamat email</label>
                <Input
                  className={cn(is_error.email.status && "border-destructive ring-destructive")}
                  placeholder="Masukkan alamat email"
                  value={form.email}
                  onChange={e => {
                    const email = e.target.value
                    if (!emailRegex.test(email)) {
                      setIsError(error => ({ ...error, email: { status: true, text: 'Email tidak sesuai format' } }))
                    } else {
                      setIsError(error => ({ ...error, email: { status: false, text: '' } }))
                    }
                    setForm(form => ({ ...form, email }))
                  }}
                />
                {is_error.email.text && <div className="flex items-center text-sm text-destructive"><Info className="h-3" />{is_error.email.text}</div>}
              </div>
              <Button
                type="submit"
                className="mt-2"
                disabled={is_error.email.status}
              >Ajukan Reset Password</Button>
            </form>)}
        </Card>
      </div>
    </div>
  );
}

export default ForgotPassword;