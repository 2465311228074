function NotFound({ title }: {
  title?: string
}) {
  return (
    <div className="py-64 items-center justify-center flex">
      <p className="text-9xl text-gray-200 font-bold">404</p>
      <p className="fixed text-xl font-medium mt-10">{title || 'COMING SOON..'}</p>
    </div>
  );
}

export default NotFound;