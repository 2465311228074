import { Banknote, Coins, DollarSign, Download, Info, Send, Upload } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogBillPaymentForm from "src/components/dialog/wallet/appointment/bill-payment-form";
import DialogWithdrawForm from "src/components/dialog/wallet/appointment/withdraw-form";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { set_open_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_balance_cards, set_current_balance, set_current_bill, set_current_income, set_selected_tab } from "src/config/redux/slicer/expertise/expertiseWalletSlicer";
import { onGetPaymentBalanceCards, readBillPayment, readPayoutLink, readTransactionOffline, readTransactionOnline } from "src/config/services/fellas/payment.api";
import { cn } from "src/lib/utils";
import BillWallet from "./tabs/bill";
import OfflineTransactionWallet from "./tabs/offline";
import OnlineTransactionWallet from "./tabs/online";
import WithdrawWallet from "./tabs/withdraw";
import { useMutation, useQuery } from "react-query";
import APIRequest from "src/config/services/api";

function ExpertiseWallet() {
  const dispatch = useDispatch()
  const health_care_id = localStorage.getItem('health_care_id')
  const { dialog } = useSelector((state: any) => state.PaymentDialog)

  const { withdraw_data, bill_payment_data, online_transaction_data, offline_transaction_data, selected_tab, balance_cards } = useSelector((state: any) => state.ExpertiseWallet)

  const { mutate: onUpdateCardsData } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      path: '/payment/balance/cards/',
      params: { health_care_id }
    })
    return data
  }, {
    onSuccess: (data) => {
      dispatch(set_balance_cards(data))
    }
  })

  const balance_card = [
    {
      key: 'income',
      title: 'Total Pemasukan',
      icon: <Download className="w-5 h-5 stroke-success" />,
      amount: balance_cards['total_income'] || 0,
      info: 'Total pendapatan dari layanan online setelah dikurangi biaya aplikasi sejumlah 30%'
    },
    {
      key: 'total_bill',
      title: 'Total Tagihan',
      icon: <Banknote className="w-5 h-5 stroke-destructive" />,
      amount: balance_cards['total_bill'] || 0,
      info: 'Tagihan transaksi offline & homecare sejumlah 30% dari setiap transaksi'
    },
    {
      key: 'balance',
      title: 'Saldo saat ini',
      icon: <Coins className="w-5 h-5 stroke-primary" />,
      amount: balance_cards['total_balance'] || 0,
      info: 'Jumlah uang yang dapat ditarik, setiap penarikan dikenakan biaya Rp 5.000 + 2.73% VAT'
    },
    {
      key: 'total_withdraw',
      title: 'Total Penarikan',
      icon: <Upload className="w-5 h-5 stroke-primary" />,
      amount: balance_cards['total_withdraw'] || 0,
      info: 'Jumlah uang yang telah ditarik'
    },
  ]

  useEffect(() => {
    onUpdateCardsData()
  }, [
    withdraw_data.count,
    bill_payment_data.count,
    online_transaction_data.count,
    offline_transaction_data.count
  ]);

  return (
    <div className="container space-y-4">
      <div className="flex max-md:flex-col justify-between md:items-end gap-1">
        <div className="grid gap-1">
          <div className="text-3xl font-semibold">Dompetku</div>
          <div className="text-zinc-400">Berikut informasi saldo anda di Fellas</div>
        </div>
        <div className="flex max-md:flex-col gap-2">
          <Dialog open={dialog.withdraw_form} onOpenChange={() => dispatch(set_open_payment_dialog('withdraw_form'))}>
            <DialogTrigger asChild>
              <Button className="gap-2" size="sm">Ajukan Penarikan Saldo<DollarSign className="h-4" /></Button>
            </DialogTrigger>
            <DialogWithdrawForm />
          </Dialog>
          <Dialog open={dialog.bill_form} onOpenChange={() => dispatch(set_open_payment_dialog('bill_form'))}>
            <DialogTrigger asChild>
              <Button className="gap-2" size="sm">Ajukan Pembayaran Tagihan<Send className="h-4" /></Button>
            </DialogTrigger>
            <DialogBillPaymentForm />
          </Dialog>
        </div>
      </div>
      <div className="grid grid-cols-4 max-md:grid-cols-2 gap-1">
        {balance_card.map(item => (
          <Card key={item.key} className="p-3 max-xs:p-2 flex justify-between">
            <div className="flex gap-2">
              <div className="max-md:hidden">{item.icon}</div>
              <div>
                <p className="text-sm max-xs:text-xs">{item.title}</p>
                <p className={cn("text-lg max-xs:text-base font-bold", item.key === 'income' ? 'text-success' : item.key === 'total_bill' ? 'text-destructive' : 'text-primary')}>Rp {item.amount.toLocaleString('id')}</p>
              </div>
            </div>
            <Popover>
              <PopoverTrigger><Info className="w-4 h-4 cursor-help" /> </PopoverTrigger>
              <PopoverContent className="px-2 py-1 text-xs w-fit max-w-[240px]">{item.info}</PopoverContent>
            </Popover>
          </Card>
        ))}
      </div>
      <Tabs value={selected_tab} onValueChange={val => dispatch(set_selected_tab(val))}>
        <TabsList className="mb-4 grid w-full grid-cols-4 max-md:grid-cols-1">
          <TabsTrigger value="withdraw">Penarikan</TabsTrigger>
          <TabsTrigger value="bill">Pembayaran</TabsTrigger>
          <TabsTrigger value="online">Transaksi Online</TabsTrigger>
          <TabsTrigger value="offline">Transaksi Offline</TabsTrigger>
        </TabsList>
        <TabsContent value="withdraw"><WithdrawWallet /></TabsContent>
        <TabsContent value="bill"><BillWallet /></TabsContent>
        <TabsContent value="online"><OnlineTransactionWallet /></TabsContent>
        <TabsContent value="offline"><OfflineTransactionWallet /></TabsContent>
      </Tabs>

    </div>
  );
}

export default ExpertiseWallet;