import { cn } from "src/lib/utils";

function Timeline({ data }:
  {
    data: {
      event: string,
      date?: string,
      dotClassName?: string
    }[]
  }) {
  return (
    <div className="relative flex flex-col justify-center items-start py-4">
      <div className="absolute h-full w-[2px] bg-pink-200 left-2 transform -translate-x-1/2"></div>
      <div className="relative flex flex-col justify-center items-start">
        {data.map((item, id) => (
          <div key={id} className="relative flex items-center mb-6">
            <div className={cn("flex-shrink-0 w-4 h-4 rounded-full", item.dotClassName || 'bg-gray-300')}></div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700">{item.event}</p>
              {item.date && <p className="text-xs text-gray-500">{item.date}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>);
}

export default Timeline;