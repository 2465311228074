import { X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../ui/card";
import { set_close_appointment_dialog, set_selected_appointment } from "src/config/redux/slicer/dialog/appointmentDialogSlicer";
import { Textarea } from "../ui/textarea";
import { store } from "src/config/redux/store";
import { Button } from "../ui/button";
import { onUpdateAppointment, readAppointmentHomecare, readAppointmentOffline, readAppointmentOnline } from "src/config/services/fellas/appointment.api";
import { ToastAlert } from "../ui/toaster";
import { useToast } from "../ui/use-toast";

type DialogReportType = {
  is_online?: boolean
  is_homecare?: boolean
  is_appointment?: boolean
}
function DialogReport({ is_online, is_homecare, is_appointment }: DialogReportType) {
  const dispatch = useDispatch()
  const { toast } = useToast()
  const onClose = () => dispatch(set_close_appointment_dialog('report'))
  const { selected_appointment } = useSelector((state: any) => state.AppointmentDialog)
  const { online_appointment, offline_appointment, homecare_appointment } = useSelector((state: any) => state.Expertise)

  const onSubmit = async (e) => {
    e.preventDefault()
    const { id, report } = selected_appointment
    const resp = await onUpdateAppointment(is_appointment ? { id, report, status: 'Completed' } : { id, report })
    const params = is_appointment ? '&status=New Entry,Accepted,Cancelled,On Going' : '&status=Completed'
    ToastAlert(resp, 'Data berhasil diupdate', () => {
      if (is_online) {
        const { limit, offset } = online_appointment
        readAppointmentOnline(limit, offset, params)
      } else if (is_homecare) {
        const { limit, offset } = homecare_appointment
        readAppointmentHomecare(limit, offset, params)
      } else {
        const { limit, offset } = offline_appointment
        readAppointmentOffline(limit, offset, params)
      }
    })
    onClose()
  }
  return (
    <div className="fixed top-0 left-0 z-10 h-screen w-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[30vw] p-6 space-y-2">
          <div className="flex justify-between items-center">
            <p className="text-lg font-semibold">Isi Rekam Medis</p>
            <X onClick={onClose} className="hover:stroke-red-500 cursor-pointer" />
          </div>
          <form onSubmit={onSubmit}>
            <div className="space-y-2">
              <Textarea value={selected_appointment.report} onChange={e => dispatch(set_selected_appointment({ ...selected_appointment, report: e.target.value }))} />
              <Button className="w-full" variant="outline">Submit</Button>
            </div>
          </form>
        </Card>
      </div>
    </div>

  );
}

export default DialogReport;