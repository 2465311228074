import Axios, { AxiosRequestConfig } from 'axios';
import { onCreateEmailVerification } from './user.api';
import { store } from 'src/config/redux/store';
import { set_progress_host_email } from 'src/config/redux/slicer/admin/adminDashboardSlicer';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';

const API_URL = "/api/v1/auth/"
const GOOGLE_URL = "/api/v1/google/"

const initialUser = { pk: 0, username: "", email: "", first_name: "", last_name: "" }

export const initialCache = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    localStorage.removeItem('lisban')
    localStorage.removeItem('health_care_id')
    localStorage.removeItem('user_location')
}

export const autoLogout = (status) => {
    if (status === 401) {
        initialCache()
        window.location.reload()
    }
}

export const getCurrentUser = (key: 'user' | 'user_temp' = 'user') => {
    return JSON.parse(
        localStorage.getItem(key) || JSON.stringify(initialUser)
    )
}

export const getJWT = () => {
    return JSON.parse(
        localStorage.getItem('jwt') || JSON.stringify({ key: "" })
    )
}

export const onLogin = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: data.oauth === 'google' ? GOOGLE_URL : API_URL + 'login/',
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        const user = await getUserInformation({ jwt: response })
        localStorage.setItem('jwt', JSON.stringify(response))
        if (!user.is_password_created) {
            localStorage.setItem('user_temp', JSON.stringify(user))
            window.location.assign('/?page=set_password')
        } else {
            if (user.is_email_verified) {
                localStorage.setItem('user', JSON.stringify(user))
                setTimeout(() => window.location.reload(), 1000)
            } else return { error: { Unauthorized: 'Email belum diverifikasi' }, user_id: user.id }
        }
    }
    catch (err: any) {
        const { data, status } = err.response
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onRegister = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + 'register/',
        data
    }
    try {
        const { data: user } = await Axios.request(requestConfig)
        // const { username, password } = data
        // const response = await onLogin({ username, password })
        const { data: response } = await onCreateEmailVerification({ token: '0', user_id: user.id })
        setTimeout(() => window.location.assign('/?page=login'), 1000)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onLogout = async () => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + 'logout/',
        headers: authHeader(),
    }
    try {
        await Axios.request(requestConfig)
        initialCache()
        window.location.reload()
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onConfigureHostEmail = async (method: 'get' | 'post') => {
    store.dispatch(set_progress_host_email(true))
    const requestConfig: AxiosRequestConfig = {
        method,
        url: API_URL + 'host-email/',
        headers: authHeader(),
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_progress_host_email(false))
    }
}


export const getUserInformation = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + 'user/',
        headers: authHeader(data?.jwt),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        return { error: data, status }
    }
}

export const checkUserExists = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `user/is-exists?username=${data.username}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        return { error: data, status }
    }
}

export const resetPasswordSendEmail = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `password/reset/`,
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        return { error: data, status }
    }
}

export const resetPasswordVerify = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `password/reset/confirm/`,
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        return { error: data, status }
    }
}

export const onUpdateUserInformation = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + 'user/',
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onChangeUserPassword = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + 'password/change/',
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const authHeader = (jwt = '') => {
    const jwt_ = jwt || getJWT();
    if (jwt_ && jwt_.key) {
        return { Authorization: `Token ${jwt_.key}` };
    } else if (jwt_ && jwt_.access) {
        return { Authorization: `Token ${jwt_.access}` };
    } else {
        return { Authorization: '' };
    }
}