import { Info } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_close_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { onPaymentCreatePayoutLink, readPayoutLink } from "src/config/services/fellas/payment.api";

function DialogWithdrawForm() {
  const user = getCurrentUser()
  const { toast } = useToast()
  const dispatch = useDispatch()
  const [withdraw_form, setWithdrawForm] = useState({ email: user.email || '', amount: 0 })
  const { value: expertise } = useSelector((state: any) => state.Expertise)
  const { withdraw_data, balance_cards } = useSelector((state: any) => state.ExpertiseWallet)
  const current_balance = balance_cards['total_balance'] || 0
  const { email, amount } = withdraw_form
  const [{ admin_fee, VAT, total_amount }, setTotalAmount] = useState({ admin_fee: 0, VAT: 0, total_amount: 0 })
  const { limit, offset } = withdraw_data

  const handleAmountChange = (e) => {
    const amount = Number(e.target.value.replace(/\D/g, ''))
    if (amount > current_balance) {
      toast({
        variant: 'destructive',
        title: `[Error] Something when wrong!`,
        description: `Saldo tidak mencukupi`
      })
      setWithdrawForm(form => ({ ...form, amount: current_balance }))
    } else {
      setWithdrawForm(form => ({ ...form, amount }))
    }
  }
  const updateTotalAmount = (e) => {
    if (e.key === 'Enter') {
      const VAT = Math.round(0.0273 * amount)
      const admin_fee = 5000
      const total_amount = amount - admin_fee - VAT
      if (total_amount < 0) {
        toast({ variant: 'destructive', title: 'Minimum penarikan Rp. 10.000' })
      }
      setTotalAmount({ admin_fee, VAT, total_amount })
    }
  }

  const submitForm = async (e: React.FormEvent) => {
    const payment = await onPaymentCreatePayoutLink({ query: `?email=${email}&amount=${amount}`, health_care_id: expertise.id })
    // const payment = { payout_url: 'https://payout.xendit.co/web/bd4519c6-9c7d-493f-8038-6a61113d3133', error: '' }
    ToastAlert(payment, 'Pengajuan penarikan berhasil dibuat')
    readPayoutLink(limit, offset)
    window.open(payment.payout_url, '_blank')
    dispatch(set_close_payment_dialog('withdraw_form'))
  }

  return (
    <DialogContent onClose={() => dispatch(set_close_payment_dialog('withdraw_form'))} className="sm:max-w-[425px] lg:min-w-[500px] bg-white">
      <DialogHeader>
        <DialogTitle className="text-center">Ajukan Penarikan</DialogTitle>
      </DialogHeader>
      <div className="grid gap-4 text-sm">
        <div className="space-y-1">
          <label>Email Penerima</label>
          <Input type="email" value={email} placeholder={user.email} onChange={e => setWithdrawForm(form => ({ ...form, email: e.target.value }))} />
        </div>
        <div className="space-y-1">
          <label>Nominal Penarikan</label>
          <Input
            value={amount.toLocaleString('id')}
            onChange={handleAmountChange}
            onKeyDown={updateTotalAmount}
          />
        </div>
        {total_amount > 0 && <div className="grid grid-cols-3 gap-1">
          <div className="space-y-1">
            <div className="flex">Biaya Admin
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{admin_fee.toLocaleString('id')}</p>
          </div>
          <div className="space-y-1">
            <div className="flex">Pajak
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{VAT.toLocaleString('id')}</p>
          </div>
          <div className="space-y-1">
            <div className="flex">Nominal Akhir
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{total_amount > 0 ? total_amount.toLocaleString('id') : 0}</p>
          </div>
          {total_amount < 10000 && <p className="col-span-3 text-xs text-destructive">*minimum penarikan Rp. 10.000</p>}
        </div>}
        <Button disabled={total_amount < 10000} onClick={submitForm}>Ajukan Penarikan</Button>
      </div>
    </DialogContent>
  );
}

export default DialogWithdrawForm;