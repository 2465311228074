import { createSlice } from '@reduxjs/toolkit'

export const myAppointmentSlicer = createSlice({
  name: 'myAppointmentSlicer',
  initialState: {
    data: { limit: 3, offset: 0, count: 0, next: null, previous: null, results: [] },
    unpaid_data: { limit: 3, offset: 0, count: 0, next: null, previous: null, results: [] },
  },
  reducers: {
    set_appointment_data: (state, action) => {
      state.data = action.payload
    },
    set_appointment_unpaid_data: (state, action) => {
      state.unpaid_data = action.payload
    },
  },
})

export const { set_appointment_data, set_appointment_unpaid_data } = myAppointmentSlicer.actions
export default myAppointmentSlicer.reducer
