import { AdvancedMarker, Map, Marker, useAutocomplete } from "@vis.gl/react-google-maps";
import { LocateFixed } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_expertise_data } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { getAddressFromCoords, getAddressLabel } from "src/config/services/fellas/google.api";
import { updateHealthCare } from "src/config/services/fellas/health-care.api";
import { FellasPin } from "./user";
import { getDragLocation } from "src/lib/utils";

function DialogLocationOffline() {
  const { toast } = useToast()
  const dispatch = useDispatch()
  const { value: expertise } = useSelector((state: any) => state.Expertise)
  const current_address = expertise.offline_location?.address || []
  const [autoCompleteValue, setAutoCompleteValue] = useState('')
  const autoCompleteRef = useRef<any>(null);
  const { REACT_APP_GOOGLE_MAP_ID } = process.env

  useAutocomplete({
    inputField: autoCompleteRef && autoCompleteRef.current,
    onPlaceChanged: (place) => {
      const { address_components: address, formatted_address: full_address, geometry, name: label } = place
      const coords = { lat: geometry.location.lat(), lng: geometry.location.lng() }
      dispatch(set_expertise_data({
        ...expertise,
        offline_location: { coords, address, full_address, label: label || '' }
      }))
      setAutoCompleteValue(full_address)
      autoCompleteRef.current && autoCompleteRef.current.focus();
    }
  })

  const onDragOfflineLocation = async (location) => {
    const { address, full_address, coords, label } = await getDragLocation(location)
    dispatch(set_expertise_data({
      ...expertise,
      offline_location: { coords, address, full_address, label }
    }))
  }

  const updateOfflineLocationForm = async () => {
    const { id, offline_location } = expertise
    const resp = await updateHealthCare({ id, offline_location: JSON.stringify(offline_location) })
    ToastAlert(resp, 'Lokasi Berhasil di update')
  }

  const getCurrentUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const coords = { lat: latitude, lng: longitude }

          const place = await getAddressFromCoords(coords)
          const { address_components: address, formatted_address: full_address, geometry, name: label } = place
          dispatch(set_expertise_data({
            ...expertise,
            offline_location: { coords, address, full_address, label: label || '' }
          }))
          setAutoCompleteValue(full_address)
        },
        (error) => {
          ToastAlert({ error }, `Error getting user location: ${error}`)
        }
      );
    } else {
      ToastAlert({ error: 'Error' }, 'Geolocation is not supported by your browser')
    }
  }

  useEffect(() => {
    !expertise.offline_location.label && getCurrentUserLocation()
  }, []);

  return (
    <DialogContent className="sm:max-w-[425px] lg:min-w-[500px] bg-white">
      <DialogHeader>
        <DialogTitle className="text-center">Tentukan Lokasi Layanan Offline</DialogTitle>
      </DialogHeader>
      <div className="space-y-2 text-sm">
        <Input
          placeholder="Nama Lokasi"
          value={expertise.offline_location.label}
          onChange={(e) => {
            dispatch(set_expertise_data({ ...expertise, offline_location: { ...expertise.offline_location, label: e.target.value } }))
          }} />
        <div className="h-48 rounded-sm">
          <Map
            center={expertise.offline_location.coords}
            zoom={16}
            mapId={REACT_APP_GOOGLE_MAP_ID}
          >
            {expertise.offline_location.coords &&
              <AdvancedMarker position={expertise.offline_location.coords} draggable onDragEnd={onDragOfflineLocation} >
                <FellasPin />
              </AdvancedMarker>}
          </Map>
        </div>
        <div className="flex gap-2 items-end">
          <div className="w-full">
            <p>Cari alamat lokasi layanan</p>
            <Input ref={autoCompleteRef} placeholder={expertise.offline_location.full_address} value={autoCompleteValue} onChange={e => setAutoCompleteValue(e.target.value)} />
          </div>
          {/* <Button className="aspect-square" size="icon"><Search /></Button> */}
        </div>
        <div className="flex justify-between gap-2 items-center">
          <LocateFixed
            className="text-primary cursor-pointer hover:animate-pulse"
            onClick={getCurrentUserLocation}
          />
          <div className="w-3/4">
            <p>{getAddressLabel(current_address, 'administrative_area_level_2')}, {getAddressLabel(current_address, 'administrative_area_level_1')}</p>
            <p className="text-xs text-gray-400">{getAddressLabel(current_address, 'route')}</p>
          </div>
          <Button disabled={!expertise.offline_location.label} onClick={updateOfflineLocationForm}>Terapkan</Button>
        </div>
      </div>
    </DialogContent >
  );
}

export default DialogLocationOffline;