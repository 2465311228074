import { differenceInMonths, differenceInYears, format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { set_child_data } from "src/config/redux/slicer/main/userChildSlicer";
import { getJWT } from "src/config/services/core/auth.api";
import { getUserChildInformation } from "src/config/services/core/user.api";
import { getLisbanStorage } from "..";
import { Info } from "lucide-react";


const BabyFormula = () => {
  const dispatch = useDispatch()
  const jwt = getJWT()
  const selected_child_storage = getLisbanStorage().selected_child
  const user_child: any = useSelector((state: any) => state.UserChild.value)

  const [form, setForm] = useState({ id: 0, name: '', birth_date: '', weight: '' })
  const [result, setResult] = useState<number[][]>([])
  const updateUserChildState = async () => {
    if (jwt.key) {
      const resp = await getUserChildInformation({})
      if (!resp.error) dispatch(set_child_data(resp))
    }
  }
  const updateForm = () => {
    const calories = Number(form.weight) * 150
    const range_consume = [6, 8]
    const range_times = range_consume.map(consume => Math.round(24 / consume)).reverse()
    const range_cal_consume = range_consume.map(consume => Math.round(calories / consume)).reverse()
    setResult([[calories], range_consume, range_times, range_cal_consume])
  }

  useEffect(() => {
    updateUserChildState()
    if (selected_child_storage.id) {
      const { id, first_name, last_name, birth_date, weight } = selected_child_storage
      setForm({ id, name: first_name + ' ' + last_name, birth_date, weight })
    }
  }, []);

  const diff_month = differenceInMonths(new Date(), new Date(form.birth_date))

  useEffect(() => {
    if (selected_child_storage.id) updateForm()
  }, [form]);

  return (
    <div className="space-y-6 text-sm">
      <div>
        <p className="text-lg font-medium">Kalkulator Formula Bayi</p>
        <p>Saat anda memasuki peran sebagai orangtua baru atau dalam perjalanan menuju itu, Anda mungkin ingin tahu berapa jumlah formula yang tepat untuk bayi Anda. Di sinilah aplikasi kalkulator formula bayi hadir untuk memberikan panduan yang diperlukan</p>
      </div>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-8">
        <form className="space-y-2" onSubmit={e => { e.preventDefault(); updateForm() }}>
          <div className="space-y-1">
            <label className="font-bold">Nama</label>
            {jwt.key
              ? <Select
                value={String(form.id)}
                onValueChange={val => {
                  const selected_child = user_child.find(child => child.id === Number(val))
                  if (selected_child) {
                    const { id, first_name, last_name, birth_date, weight } = selected_child
                    setForm({ id, name: first_name + ' ' + last_name, birth_date, weight })
                    localStorage.setItem('lisban', JSON.stringify({ selected_child, navbar: 'nutrition-calculator' }))
                  }
                }}>
                <SelectTrigger><SelectValue>{form.name || '...'}</SelectValue></SelectTrigger>
                <SelectContent>{user_child.map(child => (
                  <SelectItem value={String(child.id)} key={child.id}>{`${child.first_name} ${child.last_name}`}</SelectItem>
                ))}</SelectContent>
              </Select>
              : <Input placeholder="Isi nama anak anda" value={form.name} onChange={e => { result[0] && setResult([]); setForm(form => ({ ...form, name: e.target.value })) }} />
            }
          </div>
          <div className="space-y-1">
            <label className="font-bold">Tanggal Lahir</label>
            <Input disabled={jwt.key} type="date" max={format(new Date(), 'yyyy-MM-dd')} value={form.birth_date} onChange={e => setForm(form => ({ ...form, birth_date: e.target.value }))} />
          </div>
          <div className="space-y-1">
            <label className="font-bold">Berat</label>
            <Input disabled={jwt.key} placeholder="kg" type="number" value={form.weight} onChange={e => !jwt.key && setForm(form => ({ ...form, weight: e.target.value }))} />
          </div>
          {!jwt.key && <Button className="w-full" disabled={!form.name || !form.birth_date || !form.weight || diff_month > 12} type="submit" size="sm">Hitung</Button>}
          {diff_month > 12 && <p className="text-destructive text-sm flex items-center"><Info className="h-4" />Perhitungan untuk anak berumur kurang dari 1 tahun</p>}
        </form>
        {result.length > 0 && diff_month <= 12 &&
          <Card className="border-primary space-y-2 p-4">
            <p className="text-[20px] leading-6 font-bold">{form.name} harus minum sekitar <b className="text-primary">{result[0]} ml</b> susu formula per hari</p>
            <p className="text-muted-foreground">
              Anda harus memberi makan sekitar {(result[1] || []).join('-')} kali sehari (kira-kira setiap {(result[2] || []).join('-')} jam).
              Sekali menyusui, bayi harus minum sekitar {(result[3] || []).join('-')} ml.
              Ingat, jumlah susu formula yang dihitung disini hanyalah perkiraan - setiap anak adalah unik.
              Jika Anda mempunyai kekhawatiran mengenai pola makan bayi Anda, bicarakan dengan dokter anak Anda.
            </p>
          </Card>
        }
      </div>
    </div>
  )
}

export default BabyFormula