import { TagProps } from "antd"

interface STATUS_TYPE {
  text: string
  className: string
  color: TagProps['color']
}

export const STATUS_PAYOUT: Record<string, STATUS_TYPE> = {
  'COMPLETED': { text: 'Sukses', className: 'bg-green-300', color: 'green' },
  'CLAIMED': { text: 'Proses Pencairan', className: 'bg-violet-300', color: 'purple' },
  'PENDING': { text: 'Dalam Proses', className: 'bg-yellow-300', color: 'gold' },
  'VOIDED': { text: 'Dibatalkan', className: 'bg-red-400', color: 'red' },
  'EXPIRED': { text: 'Kadaluarsa', className: 'bg-gray-300', color: 'default' },
  'FAILED': { text: 'Gagal', className: 'bg-red-400', color: 'red' },
}

export const STATUS_BILL: Record<string, STATUS_TYPE> = {
  'SETTLED': { text: 'Sukses', className: 'bg-green-300', color: 'green' },
  'PAID': { text: 'Sukses', className: 'bg-green-300', color: 'green' },
  'PENDING': { text: 'Dalam Proses', className: 'bg-yellow-300', color: 'gold' },
  'VOIDED': { text: 'Dibatalkan', className: 'bg-gray-300', color: 'default' },
  'EXPIRED': { text: 'Kadaluarsa', className: 'bg-gray-300', color: 'default' },
}

export const STATUS_PAYMENT_TRANSACTION: Record<string, STATUS_TYPE> = {
  'Completed': { text: 'Sukses', className: 'bg-green-300', color: 'green' },
  'Accepted': { text: 'Dalam Proses', className: 'bg-yellow-300', color: 'gold' },
  'On Going': { text: 'Dalam Proses', className: 'bg-yellow-300', color: 'gold' },
  'New Entry': { text: 'Dalam Proses', className: 'bg-yellow-300', color: 'gold' },
  'Cancelled': { text: 'Dibatalkan', className: 'bg-gray-300', color: 'default' },
}

export const PAYOUT_TRANSACTION_TIMELINE = {
  'Sukses': [
    { event: 'Sukses', dotClassName: 'bg-green-300' },
    { event: 'Proses Pencairan', dotClassName: 'bg-pink-200' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Proses Pencairan': [
    { event: 'Sukses', dotClassName: 'bg-gray-300' },
    { event: 'Proses Pencairan', dotClassName: 'bg-violet-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Dalam Proses': [
    { event: 'Sukses', dotClassName: 'bg-gray-300' },
    { event: 'Proses Pencairan', dotClassName: 'bg-gray-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-yellow-300' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Dibatalkan': [
    { event: 'Dibatalkan', dotClassName: 'bg-gray-500' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Kadaluarsa': [
    { event: 'Kadaluarsa', dotClassName: 'bg-gray-500' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Gagal': [
    { event: 'Gagal', dotClassName: 'bg-red-400' },
    { event: 'Proses Pencairan', dotClassName: 'bg-pink-200' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
}

export const PAYMENT_BILL_TIMELINE = {
  'Sukses': [
    { event: 'Sukses', dotClassName: 'bg-green-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Dalam Proses': [
    { event: 'Sukses', dotClassName: 'bg-gray-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-yellow-300' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Dibatalkan': [
    { event: 'Dibatalkan', dotClassName: 'bg-gray-500' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
  'Kadaluarsa': [
    { event: 'Kadaluarsa', dotClassName: 'bg-gray-500' },
    { event: 'Mengajukan', dotClassName: 'bg-pink-200' },
  ],
}

export const PAYMENT_TRANSACTION_TIMELINE = {
  'Sukses': [
    { event: 'Sukses', dotClassName: 'bg-green-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
  ],
  'Dalam Proses': [
    { event: 'Sukses', dotClassName: 'bg-gray-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-yellow-300' },
  ],
  'Dibatalkan': [
    { event: 'Dibatalkan', dotClassName: 'bg-gray-300' },
    { event: 'Dalam Proses', dotClassName: 'bg-pink-200' },
  ],
}
