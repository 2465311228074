import { addDays, format } from "date-fns";
import { CalendarClock, CalendarDays, Moon, Sun, SunDim } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "src/components/ui/badge";
import { Calendar } from "src/components/ui/calendar";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "src/components/ui/carousel";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { onGetScheduleAvailability } from "src/config/services/fellas/appointment.api";
import { cn } from "src/lib/utils";

function AppointmentScheduleCard({ setSelectedDate }) {
  const dispatch = useDispatch()
  const { selected_health_care, mh_form } = useSelector((state: any) => state.SearchQuery)
  const { appointment_form, service_form, list_of_times } = useSelector((state: any) => state.Appointment)
  const { date, time, starttime, endtime } = service_form
  const is_online = appointment_form.location === 'Online'
  const is_homecare = appointment_form.location === 'Homecare'

  const TimeBadges = ({ item, disabled }) => {
    const is_time_valid = item.starttime === starttime && item.endtime === endtime
    return (
      <Badge
        key={item}
        className={cn(is_time_valid && "bg-primary text-white", disabled ? "bg-gray-200 cursor-default" : "hover:shadow")}
        onClick={() => !disabled && (is_time_valid ? setSelectedDate({ starttime: '', endtime: '', date, time }, is_online, is_homecare) : setSelectedDate({ ...item, date, time }, is_online, is_homecare))}
      >
        {item.starttime} - {item.endtime}
      </Badge>
    )
  }

  const [week_avail, setWeekAvail] = useState([])
  const times_group = [
    { name: 'Pagi', starthour: 2, endhour: 10, icon: <SunDim className="h-4 stroke-primary" /> },
    { name: 'Siang', starthour: 10, endhour: 18, icon: <Sun className="h-4 stroke-primary" /> },
    { name: 'Malam', starthour: 18, endhour: 2, icon: <Moon className="h-4 stroke-primary" /> },
  ]

  const getWeekAvailableDate = async (selected_date, is_online?, is_homecare?) => {
    setWeekAvail([])
    const service = is_online ? 'online' : is_homecare ? 'homecare' : 'offline'
    const { mh_form_status, burnout_form_status } = appointment_form
    const weekAvailDates: any = await Promise.all(
      Array.from({ length: 7 }, async (_, i) => {
        const date = addDays(selected_date, i)
        const schedule = await onGetScheduleAvailability({ healthcare_id: selected_health_care.id, date: format(date, 'yyyy-MM-dd'), service, mh_form_status, burnout_form_status })
        if (!schedule.error) {
          const availability_length = schedule.filter(avail => !avail.is_booked).length
          return { date, schedule, availability_length }
        } else return { date, schedule, availability_length: 0 }
      })
    );
    setWeekAvail(weekAvailDates)
  }

  useEffect(() => {
    getWeekAvailableDate(new Date(), is_online, is_homecare)
    setSelectedDate({ date: format(new Date(), 'yyyy-MM-dd'), starttime, endtime, time }, is_online, is_homecare)
  }, [appointment_form.location, appointment_form.mh_form_status, appointment_form.burnout_form_status]);

  return appointment_form.location && (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-3">
          <CalendarClock className="w-5 h-5 text-primary" />
          <CardTitle> Pilih Jadwal</CardTitle>
        </div>
      </CardHeader>
      <div className="mx-14 pb-6">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="flex gap-4 max-md:flex-col">
              <div>
                <div>Pilih Tanggal</div>
                <Popover>
                  <PopoverTrigger asChild>
                    <div className="flex gap-2 cursor-pointer p-1 rounded-md hover:shadow active:shadow-inner">
                      <CalendarDays className="stroke-primary cursor-pointer" />
                      <div>
                        <p>{new Date(date).toLocaleDateString('id-ID', { weekday: 'long' })}</p>
                        <p className="text-sm text-zinc-400">{new Date(date).toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
                      </div>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="end">
                    <Calendar
                      className="bg-white"
                      mode="single"
                      selected={new Date(date)}
                      onSelect={(date) => {
                        getWeekAvailableDate(date, is_online, is_homecare)
                        setSelectedDate({ date: date ? format(date, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'), endtime: '', starttime: '', time }, is_online, is_homecare)
                      }}
                      disabled={(date) => date < addDays(new Date(), -1)}
                      initialFocus />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="w-full md:pl-12">
                <div>Ketersediaan Jadwal</div>
                <Carousel className="border-x">
                  <CarouselContent className="gap-2">
                    {week_avail.map((item: any, i: number) => (
                      <CarouselItem
                        key={i}
                        className={cn(
                          "basis-1/6 max-md:basis-1/3 p-1 w-14 cursor-pointer bg-white rounded-lg text-center text-xs border aspect-square flex flex-col justify-center items-center",
                          format(item.date, 'yyyy-MM-dd') === date
                            ? "bg-primary text-white"
                            : item.availability_length === 0
                              ? "bg-zinc-100 text-zinc-500 cursor-default"
                              : "border-primary text-primary"
                        )}
                        onClick={() => item.availability_length > 0 && setSelectedDate({ date: format(item.date, 'yyyy-MM-dd'), endtime: '', starttime: '', time }, is_online, is_homecare)}
                      >
                        <p>{format(item.date, 'dd/MM')}</p>
                        <p className="text-xl">{item.availability_length}</p>
                        <p>Jadwal</p>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious />
                  <CarouselNext />
                </Carousel>
              </div>

            </div>
          </div>
          {date &&
            <div className="space-y-2">
              <div>Pilih Waktu</div>
              <div className="space-y-4">
                {times_group.map(item => (
                  <div key={item.name} className="space-y-1">
                    <div className="flex gap-1 items-center">{item.icon}<p>{item.name}</p></div>
                    <div className="flex gap-2 flex-wrap">
                      {list_of_times
                        .filter(time => {
                          const hour = Number(time.starttime.split(':')[0])
                          return (hour >= item.starthour && hour < (item.name === 'Malam' ? item.endhour + 24 : item.endhour))
                        }).length > 0
                        ? list_of_times
                          .filter(time => {
                            const hour = Number(time.starttime.split(':')[0])
                            return (hour >= item.starthour && hour < (item.name === 'Malam' ? item.endhour + 24 : item.endhour))
                          })
                          .map((time, i) => <TimeBadges key={i} item={time} disabled={time.is_booked} />)
                        : <div className="text-destructive text-sm">Jadwal tidak tersedia</div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    </Card >
  );
}

export default AppointmentScheduleCard;