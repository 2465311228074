import axios, { AxiosRequestConfig } from "axios"
import { authHeader } from "./core/auth.api";

interface APIRequestType {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  path: string
  url?: string
  data?: any,
  params?: any
}

export const API_FELLAS = 'https://api.fellas.id'
// export const API_FELLAS = 'http://localhost:8080'

const APIRequest = async ({
  method,
  path,
  url = '/api/v1',
  data = {},
  params = {},
}: APIRequestType) => {
  const config: AxiosRequestConfig = {
    method,
    url: url + path,
    headers: authHeader(),
    data,
    params
  }
  return axios(config)
}

export default APIRequest