import { DatePicker, Input, Table } from "antd";
import dayjs from "dayjs";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import DialogOfflineTransaction from "src/components/dialog/wallet/appointment/offline-transaction";
import { Button } from "src/components/ui/button";
import { set_open_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_offline_transaction_data } from "src/config/redux/slicer/expertise/expertiseWalletSlicer";
import { onQueryAppointment } from "src/config/services/fellas/appointment.api";
import { columns } from "./columns";

function OfflineTransactionWallet() {
  const dispatch = useDispatch()
  const health_care_id = localStorage.getItem('health_care_id') || 0

  const [query_filter, setQuerySearch] = useState({ start_date: '', end_date: '', filter: '' })
  const { offline_transaction_data } = useSelector((state: any) => state.ExpertiseWallet)
  const { dialog } = useSelector((state: any) => state.PaymentDialog)
  const { limit, offset, count } = offline_transaction_data
  const { filter, start_date, end_date } = query_filter
  const current = Math.round(offset / limit) + 1
  const params = { filter, start_date, end_date, limit, offset, health_care_id, location: '~Online' }

  const { mutate: updateOfflineTransaction, isLoading } = useMutation(async () => {
    const appointment = await onQueryAppointment({ params })
    return { appointment }
  }, {
    onSuccess: async ({ appointment }) => {
      const data = { ...appointment, limit, offset }
      dispatch(set_offline_transaction_data(data))
    }
  })

  const handleDatePicker = (date) => {
    if (date) {
      setQuerySearch({
        ...query_filter,
        start_date: date[0].format('YYYY-MM-DD'),
        end_date: date[1].format('YYYY-MM-DD'),
      })
    } else {
      setQuerySearch({ ...query_filter, start_date: '', end_date: '' })
    }
  }

  const handleSubmitFilter = (e: React.FormEvent) => {
    updateOfflineTransaction()
    e.preventDefault()
  }

  const handleTableParams = (pagination) => {
    if (pagination) {
      const { pageSize: limit, current } = pagination
      const offset = (current - 1) * limit
      dispatch(set_offline_transaction_data({ ...offline_transaction_data, offset, limit }))
    }
  }

  const rowHandle = (data) => ({
    onClick: () => {
      dispatch(set_payment_data(data))
      dispatch(set_open_payment_dialog('offline'))
    }
  })

  useEffect(() => {
    updateOfflineTransaction()
  }, [offset]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-end">
        <form onSubmit={handleSubmitFilter} className="flex flex-wrap gap-2 items-end text-sm">
          <DatePicker.RangePicker className="max-sm:w-full" maxDate={dayjs()} onChange={handleDatePicker} />
          <div className="flex gap-2 max-sm:w-full">
            <Input className="h-8" placeholder="Cari" onChange={e => setQuerySearch({ ...query_filter, filter: e.target.value })} />
            <Button className="h-8 px-1" size="icon" type="submit"><Search className="h-5" /></Button>
          </div>
        </form>
        {/* <Button className="h-8 gap-1" variant="outline">Download CSV <FileDown className="h-4" /></Button> */}
      </div>
      <Table
        loading={isLoading}
        dataSource={offline_transaction_data.results}
        columns={columns}
        scroll={{ x: 1100 }}
        pagination={{
          current,
          total: count,
          pageSize: limit,
          hideOnSinglePage: true,
        }}
        onChange={handleTableParams}
        onRow={rowHandle}
      />
      {dialog.offline && <DialogOfflineTransaction />}
    </div>
  );
}

export default OfflineTransactionWallet;