import { MedicalReportForm, MedicalReportPrescriptionType } from "src/lib/types";
import { create } from "zustand";

interface State {
  form: MedicalReportForm
  prescription: MedicalReportPrescriptionType
}

type FormKey = keyof State['form'];
type FormValue = State['form'][FormKey];

type PrescriptionKey = keyof State['prescription'];
type PrescriptionValue = State['prescription'][PrescriptionKey];

interface Action {
  setForm: (key: FormKey, value: FormValue) => void
  setPrescription: (key: PrescriptionKey, value: PrescriptionValue) => void
  resetPrescription: () => void
}

const initial_prescription: State['prescription'] = {
  medicine_name: '',
  dose: { morning: 0, noon: 0, night: 0, description: 'Tidak ada' },
  duration_in_day: 0
}

export const getMedicalReportStore = create<State & Action>(set => ({
  form: {
    appointment_id: 0,
    symptoms: '',
    diagnosis: '',
    prescription: [],
    is_breastfeeding: false,
    is_pregnancy: false,
    is_taking_medication: false,
    have_allergies: false,
  },
  prescription: initial_prescription,
  setForm: (key, value) => set(({ form }) => ({ form: { ...form, [key]: value } })),
  setPrescription: (key, value) => set(({ prescription }) => ({ prescription: { ...prescription, [key]: value } })),
  resetPrescription: () => set(() => ({ prescription: initial_prescription }))
}))