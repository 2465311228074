import { DatePicker, Input, Select } from "antd";
import { format } from "date-fns";
import dayjs from "dayjs";
import { ExternalLink } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import APIRequest, { API_FELLAS } from "src/config/services/api";
import { getUserStore } from "src/config/store/global/user";
import { cn, isSocialMediaUrl } from "src/lib/utils";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { MARKETING_CHANNEL } from "src/config/const/user";

interface ModalProfileProps {
  action: any
  buttonText: string,
  formData: any,
  is_user_form?: boolean
}
const Default = () => <span className="text-destructive">*</span>

export function ModalProfile({ action, buttonText, formData, is_user_form }: ModalProfileProps) {
  // const { form }: any = useSelector((state: any) => state.User)
  const dispatch = useDispatch()
  const now = format(new Date(), 'yyyy-MM-dd')
  const [form, setForm] = useState(formData || {
    first_name: "",
    last_name: "",
    birth_date: "",
    gender: "Perempuan",
    weight: "",
    height: "",
    allergy: "",
    marketing_channel: ""
  })
  const incomplete_profile = !(form.birth_date && form.first_name && form.last_name && form.gender && form.weight && form.height && form.marketing_channel)

  const [socmed_menu, onSocmedMenu] = useState(false)
  const { user_socmed, setUserSocmed, setUserSocmedbyKey } = getUserStore()

  const { facebook, linkedin, instagram, twitter } = user_socmed

  const onClose = (e) => {
    e.preventDefault()
    action(form)
    onSocmedMenu(false)
    if (is_user_form) {
      onAddUserSocialMedia()
    }
  }
  const toggleSocialMenu = (e) => {
    e.preventDefault()
    onSocmedMenu(!socmed_menu)
  }

  const { mutate: onAddUserSocialMedia } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'POST',
      url: API_FELLAS + '/api/v2',
      path: '/user/social-media/',
      data: { facebook, linkedin, instagram, twitter }
    })
    return data
  })

  useEffect(() => {
    formData && setForm(formData)
  }, [formData]);

  const isSocmedValid = () => {
    return Object.entries({ facebook, linkedin, instagram, twitter }).every(([key, value]: any) => (!value || isSocialMediaUrl(value, key)))
  }


  return !socmed_menu ? (
    <form className="space-y-2 text-xs">
      <p className="text-xl font-bold text-center">{is_user_form ? 'Lengkapi Profil Anda' : 'Lengkapi Data Anak Anda'}</p>
      {is_user_form && <p>Silahkan lengkapi profil anda untuk dapat memanfaatkan sepenuhnya semua fitur yang kami sediakan</p>}
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4">
        <div className="space-y-1">
          <label className="font-semibold">Nama Depan<Default /></label>
          <Input size="large" className="w-full" placeholder="Input" value={form.first_name} onChange={e => setForm({ ...form, first_name: e.target.value })} />
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Nama Belakang<Default /></label>
          <Input size="large" className="w-full" placeholder="Input" value={form.last_name} onChange={e => setForm({ ...form, last_name: e.target.value })} />
        </div>
      </div>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-2">
        <div className="space-y-1">
          <label className="font-semibold">Tanggal Lahir<Default /></label>
          <DatePicker
            size="large"
            className="w-full"
            value={form.birth_date ? dayjs(form.birth_date, 'YYYY-MM-DD') : dayjs()}
            maxDate={dayjs(now, 'YYYY-MM-DD')}
            onChange={val => setForm({ ...form, birth_date: val.format('YYYY-MM-DD') })}
          />
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Jenis Kelamin<Default /></label>
          <Select
            size="large"
            className="w-full"
            options={[
              { value: 'Laki-Laki', label: 'Laki-Laki' },
              { value: 'Perempuan', label: 'Perempuan' },
            ]}
            value={form.gender}
            onChange={val => setForm({ ...form, gender: val })}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className="space-y-1">
          <label className="font-semibold">Berat<Default /></label>
          <Input size="large" className="w-full" placeholder="kg" type="number" value={form.weight} onChange={e => setForm({ ...form, weight: e.target.value })} />
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Tinggi<Default /></label>
          <Input size="large" className="w-full" placeholder="cm" type="number" value={form.height} onChange={e => setForm({ ...form, height: e.target.value })} />
        </div>
      </div>
      <div className="space-y-1">
        <label className="font-semibold">Allergy</label>
        <Input size="large" className="w-full" placeholder="ex: Buah, Susu, ..." value={form.allergy}
          onChange={(e) => {
            setForm({ ...form, allergy: e.target.value })
          }} />
      </div>
      <div className="flex flex-wrap gap-1">
        {form.allergy && form.allergy.split(',').map((a, i) => (<Badge key={i} variant="secondary">{a}</Badge>))}
      </div>
      {is_user_form
        ? <Button onClick={toggleSocialMenu} variant={"outline"} className="w-full">Selanjutnya</Button>
        : <Button onClick={onClose} className="w-full">{buttonText}</Button>
      }
    </form>
  ) : (
    <div className="space-y-2">
      <p className="text-xl font-bold text-center">Social Media</p>
      <p>Silahkan isi salah satu informasi akun social media anda*</p>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-4 gap-y-2">
        <div className="space-y-1">
          <label className="font-semibold">Facebook</label>
          <Input
            addonAfter={<ExternalLink className={cn("w-4", isSocialMediaUrl(facebook, 'facebook') && 'text-primary cursor-pointer')} onClick={() => isSocialMediaUrl(facebook, 'facebook') && window.open(facebook, '_blank')} />}
            size="large" className="w-full" placeholder="https://facebook.com/.."
            value={facebook}
            onChange={e => setUserSocmedbyKey('facebook', e.target.value)}
          />
          {(!isSocialMediaUrl(facebook, 'facebook') && facebook) && <p className="text-destructive text-xs">*link tidak sesuai</p>}
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Instagram</label>
          <Input
            addonAfter={<ExternalLink className={cn("w-4", isSocialMediaUrl(instagram, 'instagram') && 'text-primary cursor-pointer')} onClick={() => isSocialMediaUrl(instagram, 'instagram') && window.open(instagram, '_blank')} />}
            size="large" className="w-full" placeholder="https://instagram.com/.."
            value={instagram}
            onChange={e => setUserSocmedbyKey('instagram', e.target.value)}
          />
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Linkedin</label>
          <Input
            addonAfter={<ExternalLink className={cn("w-4", isSocialMediaUrl(linkedin, 'linkedin') && 'text-primary cursor-pointer')} onClick={() => isSocialMediaUrl(linkedin, 'linkedin') && window.open(linkedin, '_blank')} />}
            size="large" className="w-full" placeholder="https://linkedin.com/.."
            value={linkedin}
            onChange={e => setUserSocmedbyKey('linkedin', e.target.value)}
          />
        </div>
        <div className="space-y-1">
          <label className="font-semibold">Twitter / X</label>
          <Input
            addonAfter={<ExternalLink className={cn("w-4", isSocialMediaUrl(twitter, 'twitter') && 'text-primary cursor-pointer')} onClick={() => isSocialMediaUrl(twitter, 'twitter') && window.open(twitter, '_blank')} />}
            size="large" className="w-full" placeholder="https://x.com/.."
            value={twitter}
            onChange={e => setUserSocmedbyKey('twitter', e.target.value)}
          />
        </div>
      </div>
      <div className="pt-2 flex flex-col gap-1">
        <label className="font-semibold">Bagaimana anda dapat mengenal Fellas.ID?<Default /></label>
        <Select
          size="large"
          options={MARKETING_CHANNEL}
          placeholder="Belum memilih"
          value={form.marketing_channel}
          onChange={val => setForm({ ...form, marketing_channel: val })}
        />
      </div>
      <div className="space-y-2 pt-4">
        <Button onClick={toggleSocialMenu} variant={"outline"} className="w-full">Kembali</Button>
        <Button onClick={onClose} className="w-full" disabled={!isSocmedValid() || incomplete_profile}>Perbarui Data</Button>
      </div>
    </div>
  );
}
