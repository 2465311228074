import { createSlice } from '@reduxjs/toolkit'

export const lisbanNavbarSlicer = createSlice({
  name: 'lisbanNavbarSlicer',
  initialState: {
    value: 'vaccine-scheduler',
  },
  reducers: {
    set_lisban_menu: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { set_lisban_menu } = lisbanNavbarSlicer.actions
export default lisbanNavbarSlicer.reducer
