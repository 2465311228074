import { Drawer, Modal } from "antd";
import { Apple, ArrowRight, ArrowRightCircle, Calendar, CalendarPlus, Facebook, Info, Instagram, Linkedin, Mail, MinusCircle, Phone, Stethoscope, Subtitles, Twitter } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExpertiseRequestCard } from "src/components/cards/expertise_request";
import { ModalProfile } from "src/components/modal/profile";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_close_user_dialog, set_open_user_dialog } from "src/config/redux/slicer/dialog/userDialogSlicer";
import { set_all_expertise_propose } from "src/config/redux/slicer/expertise/expertiseProposeSlicer";
import { set_expertise_data } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { set_child_data } from "src/config/redux/slicer/main/userChildSlicer";
import { set_user_data } from "src/config/redux/slicer/main/userSlicer";
import APIRequest, { API_FELLAS } from "src/config/services/api";
import { getCurrentUser, getUserInformation, onUpdateUserInformation } from "src/config/services/core/auth.api";
import { getUserChildInformation, onQueryUserCount, onUpdateUserChildInformation } from "src/config/services/core/user.api";
import { onGetAppointment, onQueryAppointment } from "src/config/services/fellas/appointment.api";
import { getHealthCareDataByQuery, onGetHealthCarePrices, onGetHealthCarePropose, updateHealthCare } from "src/config/services/fellas/health-care.api";
import { getUserStore } from "src/config/store/global/user";
import { cn, formatPhoneNumber } from "src/lib/utils";
import { onRedirectLisban } from "../child_profile";
import NotRateAppointment from "./not-rate";
import { useGlobalStore } from "src/config/store/global";

function Dashboard() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { toast } = useToast()
  const { value: user } = useSelector((state: any) => state.User)
  const { value: user_child } = useSelector((state: any) => state.UserChild)
  const { values: expertise_request } = useSelector((state: any) => state.ExpertisePropose)
  const { dialog } = useSelector((state: any) => state.UserDialog)
  const { rate_appointment, set_rate_appointment } = useGlobalStore()

  const [counts, setCounts] = useState({ appointment: 0, event: 0, vaccine: 0, community: 0 })
  const [is_edit_phone, setIsEditPhone] = useState(false)
  const [selected_child_id, setSelectedChildID] = useState(0)
  const incomplete_profile = !(user.birth_date && user.first_name && user.last_name && user.gender && user.weight && user.height && user.marketing_channel)

  const { user_socmed, setUserSocmed } = getUserStore()

  const { mutate: updateCounts } = useMutation(async () => {
    return await onQueryUserCount({})
  }, {
    onSuccess: (count) => {
      setCounts({ ...counts, ...count })
    }
  })

  const updateUser = async () => {
    const resp_user = await getUserInformation({})
    let user = getCurrentUser()
    if (!resp_user.error) {
      localStorage.setItem('user', JSON.stringify(resp_user))
      localStorage.setItem('health_care_id', user.health_care_id)
      user = resp_user
    }
    const [resp_user_child, resp_exp_req, resp_appointment] = await Promise.all([
      getUserChildInformation({}),
      onGetHealthCarePropose({ query: `?user_id=${user.id}` }),
      onGetAppointment({ query: `?user_id=${user.id}` })
    ]);
    dispatch(set_user_data(user))
    if (!user.number) setIsEditPhone(true)
    if (!resp_exp_req.error) dispatch(set_all_expertise_propose(resp_exp_req.map(r => ({ ...r, is_open_detail: false }))))
    if (!resp_user_child.error) dispatch(set_child_data(resp_user_child))
  }

  const editUser = async (value: any) => {
    const resp = await onUpdateUserInformation(value)
    ToastAlert(resp, `Profile berhasil diupdate`, () => {
      dispatch(set_user_data(resp))
      localStorage.setItem('user', JSON.stringify(resp))
      dispatch(set_close_user_dialog('edit_profile'))
    })
  }

  const getExpertisePrice = async () => {
    const expertise = await getHealthCareDataByQuery({ query: user.health_care_id })
    dispatch(set_expertise_data(expertise))
    const prices = await onGetHealthCarePrices({})
    const { profession } = expertise.detail
    const { online: current_price, online_duration } = expertise.prices
    if (prices && !prices.error) {
      const selected_profession = prices.find(pr => pr.profession === profession)
      const price = selected_profession?.price || 20000
      if (current_price !== price || !online_duration) {
        toast({ variant: 'default', title: `Harga layanan online menjadi Rp. ${price.toLocaleString()}` })
        const { offline, homecare } = expertise.prices
        const { id } = expertise
        const prices = { offline, online: price, homecare, online_duration: selected_profession?.duration_in_min || 15 }
        await updateHealthCare({ id, prices: JSON.stringify(prices) })
      }
    }
  }

  const { mutate: onGetUserSocialMedia } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      url: API_FELLAS + '/api/v2',
      path: '/user/social-media/'
    })
    return data
  }, {
    onSuccess: (data) => {
      const { facebook, linkedin, twitter, instagram } = data
      setUserSocmed({ facebook, linkedin, twitter, instagram })
    }
  })

  useEffect(() => {
    updateUser()
    updateCounts()
    if (user.health_care_id) {
      getExpertisePrice()
    }
  }, [user.health_care_id])

  const { mutate: onGetNotRateAppointment } = useMutation(async () => {
    const data = await onQueryAppointment({
      params: {
        user_id: user.id,
        status: 'Completed',
        is_rate: false,
      },
      hide_page_loading: true
    })
    return data
  }, {
    onSuccess: (data) => {
      const appointment = data.results.map(item => ({ ...item, review: { rating: 0, description: '' } }))
      set_rate_appointment(appointment)
    }
  })

  useEffect(() => {
    if (user.id) {
      onGetNotRateAppointment()
      if (incomplete_profile) {
        toast({ variant: 'default', title: 'Data profil belum lengkap' })
        dispatch(set_open_user_dialog('edit_profile'))
      }
    }
    onGetUserSocialMedia()
  }, [user]);

  const updateUserChild = async (value) => {
    const resp = await onUpdateUserChildInformation(value)
    ToastAlert(resp, 'Data anak telah diupdate', updateUser)
    const resp_user_child = await getUserChildInformation({})
    if (!resp_user_child.error) {
      dispatch(set_child_data(resp_user_child))
    }
    dispatch(set_close_user_dialog('child_profile'))
  }

  const CountCard = ({ title, count, route, icon = <CalendarPlus className="text-primary w-4" /> }: any) => (
    <Card
      className="cursor-pointer p-4 max-md:p-2 h-28 shadow hover:shadow-inner hover:shadow-primary hover:border-primary"
      onClick={() => navigate(route)}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          {icon}
          <p className="text-sm max-md:text-xs">{title}</p>
        </div>
        <ArrowRight className="cursor-pointer w-4 max-md:hidden" />
      </div>
      <div className="text-4xl flex justify-center h-16 items-center">
        {count}
      </div>
    </Card>
  )

  const extractSocmedLink = (type: 'facebook' | 'instagram' | 'linkedin' | 'twitter') => {
    return user_socmed[type] ? (user_socmed[type].split('/').at(-1) || user_socmed[type].split('/').at(-2)) : '-'
  }
  const ProfileCard = {
    user:
      <Card className="p-4 text-sm space-y-2 h-[280px]">
        <div className="flex gap-2  justify-between items-center">
          <div className="h-28 max-md:hidden aspect-square rounded-full bg-primary/30 justify-center items-center flex text-white text-5xl font-semibold cursor-pointer">{user.first_name ? user.first_name[0] : 'N'}{user.last_name ? user.last_name[0] : 'N'}</div>
          <div className="gap-2 grid text-zinc-400 w-full pl-4">
            <div className="text-lg font-medium text-black">{user.first_name} {user.last_name}</div>
            <div className="cursor-pointer flex items-center gap-2">
              <Phone className="w-5" />
              {is_edit_phone ?
                <div className="flex gap-2 items-center w-full justify-between">
                  <Input type="tel" onChange={e => dispatch(set_user_data(({ ...user, number: e.target.value })))} placeholder="081xxxxxxxxx" value={user.number} className="h-6" />
                  <Button
                    className="h-6"
                    type="submit"
                    onClick={() => { setIsEditPhone(false); editUser(user) }}
                  >Ubah</Button>
                </div> :
                <div className="flex gap-2 items-center w-full justify-between">
                  {user.number ? formatPhoneNumber(user.number) : '081x-xxxx-xxxx'}
                  <Button
                    className="h-6"
                    variant="outline"
                    onClick={() => setIsEditPhone(true)}
                  >Ubah</Button>
                </div>}
            </div>
            <div className="cursor-pointer flex items-center gap-2"><Mail className="w-5" />{user.email}</div>
            <div className="cursor-pointer flex items-center gap-2"><Calendar className="w-5" />{user.birth_date}</div>
          </div>
        </div>
        <p className="text-lg font-medium">Social Media</p>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex gap-2 items-center text-zinc-400"> <Facebook className="text-primary" /> {extractSocmedLink('facebook')}</div>
          <div className="flex gap-2 items-center text-zinc-400"> <Linkedin className="text-primary" /> {extractSocmedLink('linkedin')}</div>
          <div className="flex gap-2 items-center text-zinc-400"> <Twitter className="text-primary" /> {extractSocmedLink('twitter')}</div>
          <div className="flex gap-2 items-center text-zinc-400"> <Instagram className="text-primary" /> {extractSocmedLink('instagram')}</div>
        </div>
      </Card>,
    child:
      <Card className="space-y-2 p-6 text-sm items-center h-[280px] overflow-y-auto">
        {user_child.length > 0 ? user_child.map(child => (
          <div key={child.id} className="flex justify-between">
            <p className="flex gap-2 items-center">{child.first_name || ''} {child.last_name || ''}
              {child.vaccine && child.vaccine.current.length > 0 && <Popover>
                <PopoverTrigger asChild><Info className="text-primary w-5 cursor-pointer" /></PopoverTrigger>
                <PopoverContent className="space-y-2 w-96">
                  <p className="font-semibold">Pengingat Vaksin</p>
                  <div className="grid grid-cols-3 gap-1 text-xs">
                    {child.vaccine.current.map((item, i) =>
                      <p key={i} className={cn("flex gap-1 font-semibold items-center", item.status === 'off-time' ? 'text-red-500' : 'text-yellow-500')}>
                        <MinusCircle className="h-4 w-4" />{item.vaccine_name}
                      </p>)}
                  </div>
                  <Button size="sm" variant="destructive" className="w-full" onClick={onRedirectLisban(child, 'vaccine-scheduler')}>Lengkapi Data Vaksin</Button>
                </PopoverContent>
              </Popover>}
            </p>
            <ArrowRightCircle
              onClick={() => {
                setSelectedChildID(child.id)
                dispatch(set_open_user_dialog('child_profile'))
              }}
              className="w-5 cursor-pointer hover:text-primary"
            />
            {selected_child_id === child.id &&
              <Modal
                open={dialog.child_profile}
                onCancel={() => dispatch(set_close_user_dialog('child_profile'))}
                footer={[]}
              >
                <ModalProfile action={e => updateUserChild({ ...e, id: child.id })} buttonText="Edit Data" formData={child} />
              </Modal>
            }
          </div>
        )) : (
          <div className="text-zinc-400">Anda belum memasukkan profil anak anda, <br />silahkan isi
            <span onClick={() => navigate('/app?page=child_profile')} className="text-primary cursor-pointer"> di sini!</span>
          </div>
        )}
      </Card>
  }

  return (
    <div className="container space-y-4">
      <Card className="p-5 space-y-2 bg-gradient-to-r from-primary via-primary to-primary/50 flex flex-col justify-start text-white">
        <p className="text-3xl max-md:text-xl font-semibold">Selamat Datang di Fellas!</p>
        {/* <p className="font-semibold mt-3 flex gap-2">Anda memiliki _ notifikasi, lihat semua notifikasi <ArrowRightCircle className="cursor-pointer" /></p> */}
        <div className="grid grid-cols-4 max-md:grid-cols-2 gap-2">
          <CountCard title='Janjian' count={counts.appointment} route='/app?page=appointment' icon={<Stethoscope className="text-primary w-4" />} />
          <CountCard title='Acara' count={counts.event} route='/app?page=event' />
          <CountCard title='Vaksin' count={counts.vaccine} route='/lisban?page=vaccine-scheduler' icon={<Apple className="text-primary w-4" />} />
          <CountCard title='HelloFellas' count={counts.community} route='/app?page=community' icon={<Subtitles className="text-primary w-4" />} />
        </div>
      </Card>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4">
        <div className="space-y-2">
          <p className="flex gap-4 items-center">Profil Anda
            <Button variant="outline" className="text-xs h-6 px-2" onClick={() => dispatch(set_open_user_dialog('edit_profile'))}>Edit</Button>
            <Modal
              open={dialog.edit_profile}
              onCancel={() => !incomplete_profile && dispatch(set_close_user_dialog('edit_profile'))}
              footer={[]}
            >
              <ModalProfile action={e => editUser(e)} buttonText="Update Profile" formData={user} is_user_form />
            </Modal>
          </p>

          {ProfileCard.user}
        </div>
        <div className="space-y-2">
          <p className="flex gap-4 items-center">Profil Anak
            <div className="space-x-2">
              <Button variant="outline" onClick={() => navigate('/app?page=child_profile')} className="text-xs h-6 px-2">Edit</Button>
              <Button variant="default" onClick={() => navigate('/lisban?page=nutrition-calculator')} className="text-xs h-6 px-2">Hitung Nutrisi</Button>
            </div>
          </p>
          {ProfileCard.child}
        </div>
      </div>
      <p>Tenaga Ahli</p>
      {expertise_request.length > 0
        ? (
          <ExpertiseRequestCard data={expertise_request} status="user" onUpdateDate={updateUser} />
        ) : (
          <div className="mt-4 text-zinc-400 space-y-1">
            <p>Apakah anda tenaga ahli? Daftarkan diri anda sebagai tenaga ahli</p>
            <Button onClick={() => navigate('/app?page=appointment')} className="gap-1">Daftar Tenaga Ahli <ArrowRightCircle className="h-5" /></Button>
          </div>
        )}
      {/* Not yet rate appointment */}
      <Drawer title="Rating pengalaman konsultasi, yuk!" open={rate_appointment.length > 0} placement="bottom" onClose={() => set_rate_appointment([])}>
        <NotRateAppointment />
      </Drawer>
    </div>
  )
}

export default Dashboard;