import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { ToastAlert } from "src/components/ui/toaster";
import { toast } from "src/components/ui/use-toast";
import { set_open_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_form_page, set_invoice } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { getUserChildInformation } from "src/config/services/core/user.api";
import { onPaymentCreateOnlineInvoice } from "src/config/services/fellas/payment.api";
import { formatTime } from "src/lib/utils";
import AppointmentPatientCard from "./cards/patient";
import AppointmentUserCard from "./cards/user";

function AppointmentFillDataForm({ handlePayment }) {
  const dispatch = useDispatch()
  const { appointment_form } = useSelector((state: any) => state.Appointment)
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const is_homecare_range = appointment_form.homecare?.user_location?.distance <= selected_health_care.homecare_area.radius


  return (
    <div className="space-y-2">
      <AppointmentUserCard />
      <AppointmentPatientCard />
      <Button
        disabled={(appointment_form.location === 'Homecare' && !is_homecare_range)}
        className="w-full"
        size="sm"
        onClick={() => {
          dispatch(set_form_page(3))
          appointment_form.location === 'Online' && handlePayment()
        }}
      >Pembayaran</Button>

    </div>
  );
}

export default AppointmentFillDataForm;