import { AdvancedMarker, Map, Marker } from "@vis.gl/react-google-maps";
import { Circle, LocateFixed, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "src/components/ui/card";
import { set_close_appointment_dialog } from "src/config/redux/slicer/dialog/appointmentDialogSlicer";

import DeckGL from '@deck.gl/react';
import { ScatterplotLayer } from '@deck.gl/layers';
import { Button } from "src/components/ui/button";
import { getCurrentUserLocation } from "src/lib/utils";
import { useEffect, useState } from "react";
import { FellasPin } from "./user";

interface DialogLocationRoute {
  is_offline_patient?: boolean
}

function DialogLocationRoute({ is_offline_patient }: DialogLocationRoute) {
  const dispatch = useDispatch()
  const { selected_appointment } = useSelector((state: any) => state.AppointmentDialog)
  const [current_coords, setCurrentCoords] = useState({ lat: 0, lng: 0 })
  const { REACT_APP_GOOGLE_MAP_ID } = process.env

  const target_coords = is_offline_patient ? selected_appointment.health_care.offline_location.coords : selected_appointment.homecare.user_location.coords
  const { radius, coords } = selected_appointment.health_care.homecare_area
  const { lng, lat } = coords
  const { lat: user_lat, lng: user_lng } = target_coords
  const { lat: current_lat, lng: current_lng } = current_coords

  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${current_lat},${current_lng}&destination=${user_lat},${user_lng}`;
  const zoom = Math.log2(40075.16 / (radius * 2 * Math.PI))

  const INITIAL_VIEW_STATE = {
    longitude: lng,
    latitude: lat,
    zoom,
  };

  const circleLayers = new ScatterplotLayer({
    id: 'center-circle-layer',
    data: [
      { name: 'Center', position: [lng, lat], radius: radius * 1000 }
    ],
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    getPosition: d => d.position,
    getRadius: d => d.radius,
    getFillColor: [255, 182, 193, 32],
    getLineColor: [255, 0, 127],
  })
  const onClose = () => {
    dispatch(set_close_appointment_dialog('homecare_location'))
    dispatch(set_close_appointment_dialog('appointment_location'))
  }

  useEffect(() => {
    getCurrentUserLocation()
      .then((location: any) => setCurrentCoords(location.coords))
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 h-screen w-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[600px] p-6 space-y-4">
          <div className="flex justify-between items-center">
            <p className="text-lg font-semibold">Lokasi Pasien</p>
            <X onClick={onClose} className="hover:stroke-red-500 cursor-pointer" />
          </div>
          <div className="h-64 rounded-sm">
            <Map
              center={target_coords}
              zoom={zoom}
              mapId={REACT_APP_GOOGLE_MAP_ID}
            >
              {coords && !is_offline_patient &&
                <DeckGL
                  initialViewState={INITIAL_VIEW_STATE}
                  controller={false}
                  layers={[circleLayers]}
                />}
              {current_coords && <AdvancedMarker position={current_coords} ><LocateFixed className="stroke-primary" /></AdvancedMarker>}
              {target_coords && <AdvancedMarker position={target_coords} ><FellasPin /></AdvancedMarker>}
            </Map>
          </div>
          <Button variant="ghost" className="w-full" onClick={() => window.open(googleMapsUrl, '_blank')}>Akses Rute Google Map ke {is_offline_patient ? selected_appointment.location || 'lokasi offline' : 'lokasi pasien'}</Button>
        </Card>
      </div>
    </div>
  );
}

export default DialogLocationRoute;