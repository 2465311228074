import { Popover, Tag } from "antd";
import { Briefcase } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { set_appointment_form } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { USER_LOCAL_STORAGE_LOCATION } from "src/config/services/fellas/google.api";
import { calculateDistance, cn } from "src/lib/utils";

function AppointmentSelectServiceCard({ setSelectedDate }) {
  const dispatch = useDispatch()
  const { selected_health_care, mh_form } = useSelector((state: any) => state.SearchQuery)
  const { appointment_form, service_form } = useSelector((state: any) => state.Appointment)
  const { address, coords, full_address } = USER_LOCAL_STORAGE_LOCATION
  const { date, time } = service_form

  const onSelectService = (location) => {
    const homecare_coords = selected_health_care.homecare_area.coords
    const distance = calculateDistance(homecare_coords || { lat: 0, lng: 0 }, coords)
    const new_appointment_form = {
      ...appointment_form, location, homecare: location === 'Homecare'
        ? { service: '', user_location: { address, full_address, coords, distance } }
        : {}
    }
    dispatch(set_appointment_form(new_appointment_form))
    setSelectedDate({ date, starttime: '', endtime: '', time }, location === 'Online', location === 'Homecare')
  }
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-3">
          <Briefcase className="w-5 h-5 text-primary" />
          <CardTitle> Pilih Layanan</CardTitle>
        </div>
      </CardHeader>
      <div className="mx-14 pb-6">
        <RadioGroup className="grid grid-cols-2 gap-2" onValueChange={val => onSelectService(val)} value={appointment_form.location}>
          {/* SERVICE: OFFLINE */}
          {(selected_health_care.detail.is_offline && typeof selected_health_care.distance?.offline === 'number') &&
            <div className="flex items-center space-x-2">
              <RadioGroupItem value={selected_health_care.offline_location?.label} id="offline" />
              <div>{selected_health_care.offline_location?.label}</div>
            </div>}
          {/* SERVICE: ONLINE */}
          {selected_health_care.detail.is_online &&
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Online" id="online" />
                <div>Online Meet</div>
              </div>
              {appointment_form.location === 'Online' && (selected_health_care.is_mh_checkup || selected_health_care.is_burnout_checkup) && (
                <div className="flex flex-wrap">
                  <Popover
                    title={<div>Biaya Layanan: <a className="text-primary">Rp. {selected_health_care.prices?.online.toLocaleString('id')}</a></div>}
                    trigger={['click', 'hover']}
                  >
                    <Tag
                      color={(appointment_form.mh_form_status || appointment_form.burnout_form_status) ? "pink" : "pink-inverse"}
                      className="cursor-pointer"
                      onClick={() => dispatch(set_appointment_form({ ...appointment_form, mh_form_status: 0, burnout_form_status: 0 }))}
                    >
                      Konsultasi Umum
                    </Tag>
                  </Popover>
                  <Popover
                    title={<div>Biaya Layanan: <a className="text-primary">Rp. {mh_form.price.toLocaleString('id')}</a></div>}
                    trigger={['click', 'hover']}
                  >
                    <Tag
                      color={!appointment_form.burnout_form_status ? "pink" : "pink-inverse"}
                      className="cursor-pointer"
                      onClick={() => dispatch(set_appointment_form({ ...appointment_form, mh_form_status: 0, burnout_form_status: 1 }))}
                    >
                      Burnout Checkup
                    </Tag>
                  </Popover>
                  <Popover
                    title={<div>Biaya Layanan: <a className="text-primary">Rp. {mh_form.price.toLocaleString('id')}</a></div>}
                    trigger={['click', 'hover']}
                  >
                    <Tag
                      color={!appointment_form.mh_form_status ? "pink" : "pink-inverse"}
                      className="cursor-pointer"
                      onClick={() => dispatch(set_appointment_form({ ...appointment_form, mh_form_status: 1, burnout_form_status: 0 }))}
                    >
                      Mental Health Checkup
                    </Tag>
                  </Popover>
                </div>
              )}
            </div>}
          {/* SERVICE: HOMECARE */}
          {(selected_health_care.detail.is_homecare && selected_health_care.is_homecare_range) &&
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Homecare" id="homecare" />
                <div>Homecare</div>
              </div>
              <div className="flex flex-wrap">
                {appointment_form.location === 'Homecare' && selected_health_care.prices.homecare.map(item => (
                  <Popover
                    title={<div>Biaya Layanan: <a className="text-primary">Rp. {item.value.toLocaleString('id')}</a></div>}
                    trigger={['click', 'hover']}
                  >
                    <Tag
                      color={item.service === appointment_form.homecare.service ? "pink-inverse" : "pink"}
                      className="cursor-pointer"
                      onClick={() => {
                        const { homecare } = appointment_form
                        const { service } = item
                        dispatch(set_appointment_form({ ...appointment_form, homecare: { ...homecare, service } }))
                      }}
                    >{item.service}</Tag>

                  </Popover>
                ))}
              </div>
            </div>}
        </RadioGroup>
      </div>
    </Card>
  );
}

export default AppointmentSelectServiceCard;