import { Skeleton, Tag, message } from "antd";
import { CalendarClock, CheckSquare, CircleSlashed, Edit, Save, Square, Timer, TimerOff, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { Card, CardContent, CardHeader } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { set_selected_schedule } from "src/config/redux/slicer/expertise/expertiseSlicer";
import APIRequest, { API_FELLAS } from "src/config/services/api";
import { useExpertisePortfolio } from "src/config/store/expertise/portfolio";
import { cn } from "src/lib/utils";
import { editValue } from "..";

type ScheduleType = {
  id: number,
  day: string,
  times_offline: { starttime: string, endtime: string }[],
  times_online: { starttime: string, endtime: string }[],
  times_homecare: { starttime: string, endtime: string }[],
  is_active_online: boolean
  is_active_offline: boolean
  is_active_homecare: boolean
}[]

type ScheduleTypeOptions = {
  day: string,
  starttime: string,
  endtime: string
}[]

function ExpertiseScheduleCard({ expertise, is_dashboard }) {
  const healthcare_id = expertise.id
  const dispatch = useDispatch()
  const { on_edit, toggleEdit } = useExpertisePortfolio()
  const [messageApi, contextHolder] = message.useMessage();
  const [query_schedule, setQuerySchedule] = useState<any>([])
  const [schedule, setSchedule] = useState<any>([])

  const { mutate: onQuerySchedule, isLoading } = useMutation(async () => {
    setSchedule([])
    const { data } = await APIRequest({
      method: 'GET',
      url: API_FELLAS + '/api/v2',
      path: '/healthcare/schedule',
      params: { healthcare_id }
    })
    return data
  }, {
    onSuccess: (data) => {
      setQuerySchedule(data)
      setSchedule(data)
    }
  })

  const { mutate: onUpdateSchedule, isLoading: isLoadingUpdate } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'POST',
      url: API_FELLAS + '/api/v2',
      path: '/healthcare/schedule',
      data: { healthcare_id, schedule },
    })
    return data
  }, {
    onSuccess: (data) => {
      messageApi.success('Berhasil mengubah jadwal')
      toggleEdit('schedule')
    },
    onError: (error) => {
      messageApi.success('Gagal mengubah jadwal, silahkan hubungi admin fellas')
      setSchedule(query_schedule)
      toggleEdit('schedule')
    }
  })

  const { selected_schedule } = useSelector((state: any) => state.Expertise)
  const initial_schedule_times = schedule.map(s => ({ day: s.day, starttime: '', endtime: '' }))
  const [schedule_times_off, setScheduleTimesOff] = useState<ScheduleTypeOptions>(initial_schedule_times)
  const [schedule_times_on, setScheduleTimesOn] = useState<ScheduleTypeOptions>(initial_schedule_times)
  const [schedule_times_hc, setScheduleTimesHC] = useState<ScheduleTypeOptions>(initial_schedule_times)

  const days_of_week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const initial_schedule: ScheduleType = days_of_week.map((day, i) => (
    {
      id: i + 1,
      day,
      times_offline: [],
      times_online: [],
      times_homecare: [],
      is_active_online: false,
      is_active_offline: false,
      is_active_homecare: false
    }
  ))

  const updateScheduleForm = async () => {
    onUpdateSchedule()
  }

  const updateExpertiseState = (field, obj, new_value = {}, mode = "edit", depth = 0) => () => {
    let updated_value = []
    if (mode === "edit") {
      updated_value = schedule.map(d => d.id === obj.id ? ({ ...d, ...new_value }) : d)
    } else if (mode === "delete") {
      updated_value = schedule.filter(d => d.id !== obj.id)
    }
    setSchedule(updated_value)
  }

  const schedule_options = [
    { key: 'online', label: 'Online' },
    { key: 'offline', label: 'Offline' },
    { key: 'home_care', label: 'Home Care' },
  ]
  const selected_schedule_item = schedule_options.find(opt => opt.key === selected_schedule)

  useEffect(() => {
    if (schedule.length !== schedule_times_off.length) {
      setScheduleTimesOff(initial_schedule_times)
      setScheduleTimesOn(initial_schedule_times)
      setScheduleTimesHC(initial_schedule_times)
    }
  }, [schedule.length]);

  useEffect(() => {
    onQuerySchedule()
  }, [healthcare_id]);

  return ((
    <Card className="shadow">
      {contextHolder}
      <CardHeader>
        <div className="flex justify-between text-lg">
          <div className="flex gap-3 font-medium items-center ">
            <CalendarClock className="text-primary w-5" />Jadwal
          </div>
          {is_dashboard && <div>
            {on_edit.schedule
              ? <Button size="sm" onClick={updateScheduleForm}>{isLoadingUpdate ? <CircleSlashed className="animate-spin h-4" /> : <Save className="h-4" />} SIMPAN</Button>
              : <Edit className="self-start text-primary w-4 h-4 cursor-pointer"
                onClick={() => {
                  toggleEdit('schedule')
                  const has_homecare = schedule.filter(item => item.times_homecare)
                  if (schedule.length === 0 || has_homecare.length === 0) {
                    editValue(expertise, "schedule", initial_schedule, 0)
                  }
                }}
              />}
          </div>}
        </div>
      </CardHeader>
      <CardContent className="md:ml-8 md:mr-6 space-y-4">
        <Select onValueChange={key => dispatch(set_selected_schedule(key))}>
          <SelectTrigger className="w-1/2"><SelectValue>{selected_schedule_item?.label}</SelectValue></SelectTrigger>
          <SelectContent>
            {schedule_options.map(option => (
              <SelectItem key={option.key} value={option.key}>{option.label}</SelectItem>
            ))}
          </SelectContent>
        </Select>
        {isLoading && <Skeleton active />}
        {on_edit.schedule
          ? (
            <div className="space-y-4">
              {schedule_times_off.length > 0 && selected_schedule_item?.key === 'offline' && schedule.map((s, i) => (<div key={s.day} className="grid gap-2">
                <div className="flex max-md:flex-col md:justify-between md:items-center space-y-1">
                  <div>
                    <div className={cn("flex gap-2 items-center text-sm", !s.is_active_offline && "text-gray-400")}>
                      {s.is_active_offline
                        ? <CheckSquare className="w-3 h-3 text-primary cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_offline: false })} />
                        : <Square className="w-3 h-3 cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_offline: true })} />}
                      {s.day}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_offline.length > 0 && s.times_offline.map((off, i) =>
                        <Tag key={i} color={s.is_active_offline && 'pink'} className="flex items-center">
                          {off.starttime} - {off.endtime}
                          <XCircle className="ml-2 hover:text-destructive w-3 h-3 cursor-pointer"
                            onClick={(updateExpertiseState("schedule", s, { times_offline: s.times_offline.filter(t => (t.starttime !== off.starttime) || (t.endtime !== off.endtime)) }))}
                          />
                        </Tag>)}
                    </div>
                  </div>
                  <div className="flex gap-1 cursor-pointer">
                    <Input placeholder="starttime" type="time" className="h-8" value={schedule_times_off[i].starttime} onChange={e => setScheduleTimesOff(times => times.map(t => t.day === s.day ? ({ ...t, starttime: e.target.value }) : t))} />
                    <Input placeholder="endtime" type="time" className="h-8" value={schedule_times_off[i].endtime} onChange={e => setScheduleTimesOff(times => times.map(t => t.day === s.day ? ({ ...t, endtime: e.target.value }) : t))} />
                    <Button className="h-8 w-8 text-2xl font-extralight"
                      disabled={!schedule_times_off[i].starttime || !schedule_times_off[i].endtime || schedule_times_off[i].endtime < schedule_times_off[i].starttime}
                      onClick={updateExpertiseState("schedule", s, { times_offline: [...s.times_offline, { starttime: schedule_times_off[i].starttime, endtime: schedule_times_off[i].endtime }] })}>+</Button>
                  </div>
                </div>
              </div>))}
              {schedule_times_on.length > 0 && selected_schedule_item?.key === 'online' && schedule.map((s, i) => (<div key={s.day} className="grid gap-2">
                <div className="flex max-md:flex-col md:justify-between md:items-center space-y-1">
                  <div>
                    <div className={cn("flex gap-2 items-center text-sm", !s.is_active_online && "text-gray-400")}>
                      {s.is_active_online
                        ? <CheckSquare className="w-3 h-3 text-primary cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_online: false })} />
                        : <Square className="w-3 h-3 cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_online: true })} />}
                      {s.day}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_online.length > 0 && s.times_online.map((off, i) =>
                        <Tag key={i} color={s.is_active_online && 'pink'} className="flex items-center">
                          {off.starttime} - {off.endtime}
                          <XCircle className="ml-2 hover:text-destructive w-3 h-3 cursor-pointer"
                            onClick={(updateExpertiseState("schedule", s, { times_online: s.times_online.filter(t => (t.starttime !== off.starttime) || (t.endtime !== off.endtime)) }))}
                          />
                        </Tag>)}
                    </div>
                  </div>
                  <div className="flex gap-1 cursor-pointer">
                    <Input placeholder="starttime" type="time" className="h-8" value={schedule_times_on[i].starttime} onChange={e => setScheduleTimesOn(times => times.map(t => t.day === s.day ? ({ ...t, starttime: e.target.value }) : t))} />
                    <Input placeholder="endtime" type="time" className="h-8" value={schedule_times_on[i].endtime} onChange={e => setScheduleTimesOn(times => times.map(t => t.day === s.day ? ({ ...t, endtime: e.target.value }) : t))} />
                    <Button className="h-8 w-8 text-2xl font-extralight"
                      disabled={!schedule_times_on[i].starttime || !schedule_times_on[i].endtime || schedule_times_on[i].endtime < schedule_times_on[i].starttime}
                      onClick={updateExpertiseState("schedule", s, { times_online: [...s.times_online, { starttime: schedule_times_on[i].starttime, endtime: schedule_times_on[i].endtime }] })}>+</Button>
                  </div>
                </div>
              </div>))}
              {schedule_times_hc.length > 0 && selected_schedule_item?.key === 'home_care' && schedule.map((s, i) => (<div key={s.day} className="grid gap-2">
                <div className="flex max-md:flex-col md:justify-between md:items-center space-y-1">
                  <div>
                    <div className={cn("flex gap-2 items-center text-sm", !s.is_active_homecare && "text-gray-400")}>
                      {s.is_active_homecare
                        ? <CheckSquare className="w-3 h-3 text-primary cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_homecare: false })} />
                        : <Square className="w-3 h-3 cursor-pointer" onClick={updateExpertiseState("schedule", s, { is_active_homecare: true })} />}
                      {s.day}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_homecare.length > 0 && s.times_homecare.map((hc, i) =>
                        <Tag key={i} color={s.is_active_homecare && 'pink'} className="flex items-center">
                          {hc.starttime} - {hc.endtime}
                          <XCircle className="ml-2 hover:text-destructive w-3 h-3 cursor-pointer"
                            onClick={(updateExpertiseState("schedule", s, { times_homecare: s.times_homecare.filter(t => (t.starttime !== hc.starttime) || (t.endtime !== hc.endtime)) }))}
                          />
                        </Tag>)}
                    </div>
                  </div>
                  <div className="flex gap-1 cursor-pointer">
                    <Input placeholder="starttime" type="time" className="h-8" value={schedule_times_hc[i].starttime} onChange={e => setScheduleTimesHC(times => times.map(t => t.day === s.day ? ({ ...t, starttime: e.target.value }) : t))} />
                    <Input placeholder="endtime" type="time" className="h-8" value={schedule_times_hc[i].endtime} onChange={e => setScheduleTimesHC(times => times.map(t => t.day === s.day ? ({ ...t, endtime: e.target.value }) : t))} />
                    <Button className="h-8 w-8 text-2xl font-extralight"
                      disabled={!schedule_times_hc[i].starttime || !schedule_times_hc[i].endtime || schedule_times_hc[i].endtime < schedule_times_hc[i].starttime}
                      onClick={updateExpertiseState("schedule", s, { times_homecare: [...s.times_homecare, { starttime: schedule_times_hc[i].starttime, endtime: schedule_times_hc[i].endtime }] })}>+</Button>
                  </div>
                </div>
              </div>))}
            </div>
          )
          : (
            <div className="grid grid-cols-7 max-md:grid-cols-1 gap-2">
              {schedule.map(s => (
                <div key={s.day} className="flex gap-2">
                  {selected_schedule_item?.key === 'offline' && <div className="text-sm">
                    <div className={cn("flex gap-2 items-center", !s.is_active_offline && "text-gray-400")}>{s.is_active_offline ? <Timer className="w-3 h-3" /> : <TimerOff className="w-3 h-3" />}{s.day}</div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_offline.length > 0 && s.times_offline.map((off, i) =>
                        <Tag key={i} color={s.is_active_offline && 'pink'} className="flex items-center">
                          {off.starttime} - {off.endtime}
                        </Tag>)}
                    </div>
                  </div>}
                  {selected_schedule_item?.key === 'online' && <div className="text-sm">
                    <div className={cn("flex gap-2 items-center", !s.is_active_online && "text-gray-400")}>{s.is_active_online ? <Timer className="w-3 h-3" /> : <TimerOff className="w-3 h-3" />}{s.day}</div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_online.length > 0 && s.times_online.map((on, i) =>
                        <Tag key={i} color={s.is_active_online && 'pink'} className="flex items-center">
                          {on.starttime} - {on.endtime}
                        </Tag>)}
                    </div>
                  </div>}
                  {selected_schedule_item?.key === 'home_care' && <div className="text-sm">
                    <div className={cn("flex gap-2 items-center", !s.is_active_homecare && "text-gray-400")}>{s.is_active_homecare ? <Timer className="w-3 h-3" /> : <TimerOff className="w-3 h-3" />}{s.day}</div>
                    <div className="flex flex-wrap gap-1">
                      {s.times_homecare.length > 0 && s.times_homecare.map((on, i) =>
                        <Tag key={i} color={s.is_active_online && 'pink'} className="flex items-center">
                          {on.starttime} - {on.endtime}
                        </Tag>)}
                    </div>
                  </div>}
                </div>
              ))}
            </div>
          )}
      </CardContent>
    </Card >
  ));
}

export default ExpertiseScheduleCard;