import { Cross } from "lucide-react";
import { useSelector } from "react-redux";
import { Card } from "src/components/ui/card";

function AppointmentPaymentReview() {
  const { appointment_form, user_form } = useSelector((state: any) => state.Appointment)
  const selected_user = user_form.find(item => (!item.username && appointment_form.user_child_id === item.id) || (item.username && !appointment_form.user_child_id))
  return (
    <Card className="rounded-t-none">
      <div className="p-4 grid gap-2">
        <div className="space-y-1">
          <div className="flex gap-2 items-center"><Cross className="w-4 h-4 text-primary" />Pasien</div>
          <div className="ml-6">
            {selected_user && selected_user.first_name} {selected_user && selected_user.last_name} ({selected_user && selected_user.username ? "Saya" : "Anak"})
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AppointmentPaymentReview;