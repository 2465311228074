import { ArrowLeft, Phone, Upload, X } from "lucide-react";
import { useState } from "react";
import { Card } from "../ui/card";
import { ReactComponent as GmailIcon } from "src/assets/icons/Gmail.svg";
import { ReactComponent as WhatsappIcon } from "src/assets/icons/Whatsapp.svg";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";

function CSBubble() {
  const [is_open, setIsOpen] = useState(false)
  const [is_use_fellas, setIsUseFellas] = useState(false)
  const onClose = () => {
    setIsOpen(false)
    setIsUseFellas(false)
  }
  function redirectToWhatsApp() {
    const phoneNumber = '+6282223752472'
    const message = ``
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  }
  function redirectToEmail() {
    const emailAddress = 'cs@fellas.id'
    const emailLink = `mailto:${emailAddress}`;
    window.open(emailLink, '_blank');
  }
  return is_open ? (
    <div className="bg-white fixed bottom-5 right-5 h-1/2 w-80 rounded-md shadow text-sm">
      <div className="w-80 fixed p-4 bg-primary rounded-t-md text-left text-white flex justify-between">
        <div className="leading-4">
          <p>Selamat datang di </p>
          <b>Layanan Pengaduan FELLAS</b>
        </div>
        <X className="cursor-pointer h-5 m-[-6px]" onClick={onClose} />
      </div>
      {!is_use_fellas ? (
        <div className="mx-4 pt-16 flex flex-col items-center justify-center h-full space-y-1">
          <p>anda dapat menghubungi kami melalui</p>
          <div className="flex gap-4">
            <Card onClick={redirectToWhatsApp} className="flex gap-2 p-2 items-center hover:shadow-secondary/80 active:shadow-inner cursor-pointer">
              <WhatsappIcon className="w-5 h-5" /> Whatsapp
            </Card>
            <Card onClick={redirectToEmail} className="flex gap-2 p-2 items-center hover:shadow-secondary/80 active:shadow-inner cursor-pointer">
              <GmailIcon className="w-5 h-5" /> Email
            </Card>
          </div>
          <p>atau</p>
          <Button onClick={() => setIsUseFellas(true)}>Ajukan ke FELLAS</Button>
        </div>
      ) : (
        <div className="mx-4 pt-16 mt-2 pb-4 text-left h-full space-y-3">
          <div>
            <p className="text-gray-400">Topik permasalahan</p>
            <Input />
          </div>
          <div>
            <p className="text-gray-400">Deskripsi Permasalahan</p>
            <Textarea rows={6} />
          </div>
          <div className="flex justify-between gap-2">
            <Button variant="outline" size="icon" onClick={() => setIsUseFellas(false)}><ArrowLeft className="h-5" /></Button>
            <Button variant="outline" className="gap-2"><Upload className="h-5" /> Upload Bukti</Button>
            <Button disabled>Ajukan</Button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div onClick={() => setIsOpen(true)} className="bg-white fixed bottom-5 right-5 p-3 rounded-full cursor-pointer shadow shadow-secondary/80 active:shadow-inner">
      <Phone className="stroke-secondary" />
    </div>
  )
}

export default CSBubble;