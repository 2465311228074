import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_MAIN_NAVBAR } from 'src/config/const/menubar'

export const menuBarSlicer = createSlice({
  name: 'menuBarSlicer',
  initialState: {
    value: 'main',
    header_menu: [
      { key: 'main', text: 'Beranda', href: 'https://fellas.id/' },
      { key: 'about-us', text: 'Tentang Kami', href: 'https://fellas.id/about-us/' },
      {
        key: 'service', text: 'Layanan Kami', on_hover: false, subMenu: [
          { key: 'article', text: 'Artikel', href: 'https://fellas.id/blog' },
          { key: 'events', text: 'Events', href: 'https://fellas.id/events' },
          { key: 'health-care', text: 'Health Care', href: '/health-care' }
        ]
      },
      { key: 'comunity', text: 'Komunitas', href: '' }
    ],
    show_md_sidebar: false,
    md_sidebar_menu: { state: [], setState: () => undefined },
    on_progress: false,
  },
  reducers: {
    set_menu: (state, action) => {
      state.value = action.payload
    },
    set_header_menu: (state, action) => {
      state.header_menu = action.payload
    },
    set_show_md_sidebar: (state, action) => {
      state.show_md_sidebar = action.payload
    },
    set_md_sidebar_menu: (state, action) => {
      state.md_sidebar_menu = action.payload
    },
    set_on_progress: (state, action) => {
      state.on_progress = action.payload
    },
  },
})

export const { set_menu, set_header_menu, set_show_md_sidebar, set_md_sidebar_menu, set_on_progress } = menuBarSlicer.actions
export default menuBarSlicer.reducer
