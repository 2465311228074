import { ColumnDef } from "@tanstack/table-core"
import { TableProps, Tag } from "antd"
import { Badge } from "src/components/ui/badge"
import { STATUS_PAYMENT_TRANSACTION } from "src/config/const/status"
import { AppointmentType } from "src/lib/types"
import { cn } from "src/lib/utils"

export const columns: TableProps<AppointmentType>['columns'] = [
  {
    key: 'status',
    title: "Status",
    align: 'center',
    fixed: 'left',
    width: 140,
    render: (data) => {
      const { className, text, color } = STATUS_PAYMENT_TRANSACTION[data.status]

      return (
        <Tag color={color}>{text}</Tag>
      )
    }
  },
  {
    key: 'datetime',
    title: "Date (GMT+7)",
    align: 'center',
    render: (data) => {
      return (
        <p>{data.date}, {data.time_range}</p>
      )
    }
  },
  {
    key: 'location',
    dataIndex: 'location',
    title: "Layanan",
    align: 'center',
  },
  {
    key: 'registration_num',
    dataIndex: 'registration_num',
    title: "Appointment ID",
    align: 'center',
  },
  {
    key: 'amount',
    title: "Nominal",
    align: 'center',
    width: 140,
    render: (data) => {
      const { amount } = data
      return (
        <b>Rp {amount.toLocaleString('id')}</b>
      )
    }
  },
  {
    key: 'fees',
    title: "Tagihan",
    align: 'center',
    fixed: 'right',
    width: 140,
    render: (data) => {
      const { amount } = data
      const fees = Math.round(amount * 0.3)
      return ['Completed'].includes(data.status) && (
        <b className="text-destructive">Rp {fees.toLocaleString('id')}</b>
      )
    }
  },
]