import { Tag } from "antd";
import { X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Timeline from "src/components/timeline";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { PAYMENT_TRANSACTION_TIMELINE, STATUS_PAYMENT_TRANSACTION } from "src/config/const/status";
import { set_close_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";

function DialogOnlineTransaction() {
  const dispatch = useDispatch()
  const { dialog, payment_data } = useSelector((state: any) => state.PaymentDialog)
  let { amount, location, health_care, registration_num, user, user_child, date, time_range, status, is_settled } = payment_data

  const first_name = user_child ? user_child.first_name : user.first_name
  const last_name = user_child ? user_child.last_name : user.last_name
  if (status === 'Completed') {
    status = is_settled ? status : 'Accepted'
  }
  const { color, text } = STATUS_PAYMENT_TRANSACTION[status]

  const admin_cost = Math.round(amount * 0.3)
  const income = amount - admin_cost
  const onClose = () => {
    dispatch(set_close_payment_dialog('online'))
  }

  return (
    <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-white/80">
      <div className="grid h-full items-center justify-center">
        <Card className="shadow-md w-[800px] p-6 space-y-4">
          <div className="flex justify-between items-center">
            <div />
            <p className="text-lg font-semibold">Informasi Transaksi</p>
            <X onClick={onClose} className="hover:stroke-red-500 cursor-pointer h-4 w-4" />
          </div>
          <div className="grid grid-cols-3 gap-2 gap-x-8 text-sm">
            <div className="space-y-2">
              <label className="text-lg">Layanan</label>
              <div>
                <label>No. Registrasi / Appointment ID</label>
                <p className="text-gray-400">{registration_num}</p>
              </div>
              <div>
                <label>Tenaga Ahli</label>
                <p className="text-gray-400">{health_care?.detail?.fullname}</p>
              </div>
              <div>
                <label>Pasien</label>
                <p className="text-gray-400">{first_name} {last_name}</p>
              </div>
              <div>
                <label>Email</label>
                <p className="text-gray-400">{user?.email}</p>
              </div>
              <div>
                <label>Waktu Layanan</label>
                <p className="text-gray-400">{date}, {time_range}</p>
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-lg">Informasi</label>
              <div>
                <label>Biaya Layanan</label>
                <p className="text-gray-400">Rp {amount.toLocaleString('id')}</p>
              </div>
              <div>
                <label>Tagihan Layanan</label>
                <p className="text-gray-400">{status === 'Completed' ? `Rp ${admin_cost.toLocaleString('id')}` : '-'}</p>
              </div>
              <div>
                <label>Pemasukan</label>
                <p className="text-gray-400">{status === 'Completed' ? `Rp ${income.toLocaleString('id')}` : '-'}</p>
              </div>
              <div>
                <label>Jenis Layanan</label>
                <p className="text-gray-400">{location}</p>
              </div>

            </div>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <label className="text-lg">Status</label>
                <Tag color={color}>{text}</Tag>
              </div>
              <Timeline data={PAYMENT_TRANSACTION_TIMELINE[text]} />
              {(status === 'Completed' && !is_settled) && <p className="text-xs text-destructive">*masih dalam proses pencairan dana</p>}
            </div>
          </div>
          <div className="flex justify-center gap-2">
            <Button variant="outline" onClick={onClose}>Kembali</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default DialogOnlineTransaction;