import Axios, { AxiosRequestConfig } from 'axios';
import { authHeader, autoLogout } from './auth.api';
import { store } from 'src/config/redux/store';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';
import { APIRequestType } from '../type';

const API_URL = "/api/v1/user/"

export const getUserChildInformation = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `child/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onAddUserChildInformation = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + 'child/',
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onUpdateUserChildInformation = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + `child/${data.id}/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onDeleteUserChildInformation = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'delete',
        url: API_URL + `child/${data.id}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onCreateEmailVerification = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + 'verification/',
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onCheckEmailVerification = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `verification/1/?token=${data.token}`,
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onQueryUserCount = async ({ data, params }: APIRequestType) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `count`,
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}