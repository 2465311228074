import HostEmail from "./host-email";

function AdminDashboard() {

  return (
    <div className="container space-y-2">
      {/* <HostEmail /> */}
    </div>
  );
}

export default AdminDashboard;