import { Input as InputANTD, Popover as PopoverANTD, Tag } from "antd";
import { CheckCircle, Edit, ExternalLink, FileText, Info, Plus, Save, Stethoscope, Trash2, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import DialogLocationHomecare from "src/components/dialog/location/homecare";
import DialogLocationOffline from "src/components/dialog/location/offline";
import { Button } from "src/components/ui/button";
import { Card, CardContent, CardHeader } from "src/components/ui/card";
import { Checkbox } from "src/components/ui/checkbox";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Textarea } from "src/components/ui/textarea";
import APIRequest from "src/config/services/api";
import { updateHealthCare, updateHealthCareDetail } from "src/config/services/fellas/health-care.api";
import { PsychologyForm, useExpertisePortfolio } from "src/config/store/expertise/portfolio";
import { cn, isUrl } from "src/lib/utils";
import { editValue } from "..";

function ExpertiseProfileCard({ expertise, is_dashboard }) {
  const { on_edit, mh_form, burnout_form, toggleEdit, setMHForm, setBurnoutForm } = useExpertisePortfolio()
  const homecare_price = Array.isArray(expertise.prices?.homecare) && expertise.prices?.homecare.length > 0 ? expertise.prices?.homecare : [{ id: 1, service: '', value: 0 }]
  const [selected_service_id, setSelectedServiceId] = useState(1)

  const updateProfileForm = async () => {
    let { id, str_number, meet_link, profile, prices } = expertise
    if (prices.homecare) {
      const homecare = prices.homecare.filter(item => item.service && item.value !== 0)
      if (homecare.length !== prices.homecare.length) {
        editValue(expertise, 'prices', { ...prices, homecare })
      }
      await updateHealthCare({ id, prices: JSON.stringify({ ...prices, homecare }) })
    }
    let { str_id, skills, is_online, is_offline, is_homecare } = expertise.detail
    skills = skills.replace(', ', ',')
    await updateHealthCare({ id, str_number, meet_link, profile })
    await updateHealthCareDetail({ id: expertise.detail.id, str_id, skills, is_online, is_offline, is_homecare })
    await updatePsychologyForm(mh_form)
    await updatePsychologyForm(burnout_form)
    toggleEdit('profile')
  }

  const { mutate: getPsychologyForm } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      path: '/health-care/mh-check/',
      params: { healthcare: mh_form.healthcare }
    })
    return data
  }, {
    onSuccess: data => {
      if (data.length) {
        const mh_form = data.find(item => item.form_type === 'Mental Health')
        const burnout_form = data.find(item => item.form_type === 'Burnout')
        if (mh_form) {
          Object.entries(mh_form).map(([key, value]: any) => setMHForm(key, value))
        }
        if (burnout_form) {
          Object.entries(burnout_form).map(([key, value]: any) => setBurnoutForm(key, value))
        }
      }
    }
  })


  const { mutate: updatePsychologyForm } = useMutation(async (form: PsychologyForm) => {
    const { healthcare, form_link, is_active, form_type } = form
    const { data } = await APIRequest({
      method: 'POST',
      path: '/health-care/mh-check/',
      data: { healthcare, form_link, is_active, form_type }
    })
    return data
  })
  const getPsychologyFormTitle = (form_type: PsychologyForm['form_type']) => {
    if (form_type === 'Mental Health') return 'Mental Health Checkup'
    else return 'Burnout Checkup'
  }

  useEffect(() => {
    getPsychologyForm()
  }, []);

  return (
    <Card className="shadow">
      <CardHeader>
        {/* MAIN PROFILE */}
        <div className="flex justify-between text-lg">
          <div className="flex gap-3 font-medium items-center ">
            <Stethoscope className="text-primary w-5" />Profil Tenaga Ahli
          </div>
          {is_dashboard && <div>
            {on_edit.profile
              ? <Button size="sm" onClick={updateProfileForm}><Save className="h-4" /> SIMPAN</Button>
              : <Edit className="self-start text-primary w-4 h-4 cursor-pointer" onClick={() => toggleEdit('profile')} />}
          </div>}
        </div>
      </CardHeader>
      <CardContent className="md:ml-8 md:mr-6 space-y-4">
        <div className="grid md:grid-cols-2 gap-1 w-full">
          {/* EMAIL */}
          {is_dashboard && <div>
            <div>Email</div>
            <div className="text-sm text-muted-foreground">{expertise.email}</div>
          </div>}
          {/* STR NUMBER */}
          <div>
            <div>Nomor STR</div>
            {on_edit.profile
              ? <Input className="bg-transparent h-5 text-muted-foreground w-5/6" placeholder="Isi data berikut" value={expertise.str_number} onChange={e => editValue(expertise, 'str_number', e.target.value, 0)} />
              : <div className="text-sm text-muted-foreground">{expertise.str_number || '-'}</div>}
          </div>
        </div>
        {is_dashboard &&
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4 leading-3">
            <div className="grid gap-4">
              {/* OFFLINE PRICE */}
              <div className="space-y-2 w-full">
                <div className="flex gap-2 items-start">
                  {on_edit.profile ?
                    <Checkbox className="mt-1" checked={expertise.detail?.is_offline} onCheckedChange={checked => editValue(expertise, 'is_offline', checked, 1)} />
                    : expertise.detail?.is_offline ? <CheckCircle className="text-success" /> : <XCircle className="text-destructive" />
                  }
                  <div className="space-y-1 w-full">
                    <div>{'Layanan Offline, '}
                      <Popover>
                        <PopoverTrigger className={cn("text-sm text-gray-400 cursor-pointer", on_edit.profile && "hover:text-primary")}>
                          {on_edit.profile
                            ? <Dialog>
                              <DialogTrigger asChild>
                                <span>
                                  {expertise.offline_location?.label || 'Tentukan Lokasi'}
                                </span>
                              </DialogTrigger>
                              <DialogLocationOffline />
                            </Dialog>
                            : expertise.offline_location?.label
                          }
                        </PopoverTrigger>
                        <PopoverContent className="text-xs text-gray-400 p-2 w-fit max-w-[16rem] text-center">
                          {on_edit.profile ? "Klik untuk ubah alamat"
                            : expertise.offline_location?.full_address || 'belum menentukan alamat'}
                        </PopoverContent>
                      </Popover>
                    </div>
                    <div className="flex">
                      <p className="text-xs">Harga Layanan</p>
                      <NetPricePopover price={expertise.prices?.offline || 0} duration={60} />
                    </div>
                    {on_edit.profile
                      ? <Input className="bg-transparent h-5 text-muted-foreground w-5/6" placeholder="Isi data berikut" value={`Rp. ${(expertise.prices?.offline || 0).toLocaleString('id') || ''}`}
                        onChange={e => {
                          const offline: string = e.target.value.split('. ').at(-1) || ''
                          editValue(expertise, 'prices', { offline: Number(offline.replace(/\D/g, '')), online: expertise.prices?.online || 0, homecare: expertise.prices?.homecare }, 0)
                        }
                        } />
                      : <div className="text-sm text-muted-foreground">Rp. {(expertise.prices?.offline || 0).toLocaleString('id')}</div>}
                  </div>
                </div>
              </div>
              {/* ONLINE PRICE */}
              <div className="space-y-2 w-full">
                <div className="flex gap-2 items-start">
                  {on_edit.profile ?
                    <Checkbox className="mt-1" checked={expertise.detail?.is_online} onCheckedChange={checked => { editValue(expertise, 'is_online', checked, 1); if (!checked) setMHForm('is_active', checked) }} />
                    : expertise.detail?.is_online ? <CheckCircle className="text-success" /> : <XCircle className="text-destructive" />
                  }
                  <div className="space-y-1 w-full">
                    <p>Layanan Online</p>
                    {expertise.detail?.profession === 'Psikologi' ? (
                      <div className="space-y-1 pt-1 w-full">
                        <p className="text-xs">Harga Layanan</p>
                        {!on_edit.profile ? (
                          <div>
                            <div className="w-5/6 grid grid-cols-3 text-sm items-center h-7">
                              <div className="flex col-span-2">
                                Konsultasi
                                <NetPricePopover price={expertise.prices?.online || 0} duration={expertise.prices?.online_duration || 15} is_active={expertise.detail?.is_online} />
                              </div>
                              <a className="text-gray-500">Rp. {(expertise.prices?.online || 0).toLocaleString('id')}</a>
                              <hr className="col-span-3" />
                            </div>
                            {[burnout_form, mh_form].map(form => (
                              <div key={form.form_type} className="w-5/6 grid grid-cols-3 text-sm items-center mb-1">
                                <div className="flex col-span-2">
                                  {getPsychologyFormTitle(form.form_type)}
                                  <NetPricePopover price={form.price || 0} duration={form.duration_in_min || 15} is_active={expertise.detail?.is_online && form.is_active} />
                                  <PopoverANTD
                                    content={<p className="text-gray-400 text-xs">{form.form_link || 'Lampirkan link dari Assessment yang akan diisi pasien'}</p>}
                                    trigger={['click', 'hover']}
                                  >
                                    <FileText
                                      className={cn("stroke-gray-400 h-3 w-3", isUrl(form.form_link || '') && 'hover:stroke-primary cursor-pointer')}
                                      onClick={() => isUrl(form.form_link || "") && window.open(form.form_link, '_blank')} />
                                  </PopoverANTD>
                                </div>
                                <a className="text-gray-500">Rp. {(form.price || 0).toLocaleString('id')}</a>
                                <hr className="col-span-3" />
                              </div>

                            ))}
                          </div>
                        ) : (
                          <div className="space-y-1 text-sm text-muted-foreground">
                            <div className="flex gap-2">
                              <div className="w-1/2">Konsultasi Umum</div>
                              <div className="w-1/2">Rp. {(expertise.prices?.online || 0).toLocaleString('id')}</div>
                            </div>
                            {[burnout_form, mh_form].map(form => (
                              <div key={form.form_type} className="space-y-1">
                                <div className="flex gap-2">
                                  <div className="w-1/2">{getPsychologyFormTitle(form.form_type)}</div>
                                  <div className="w-1/2">Rp. {(form.price || 0).toLocaleString('id')}</div>
                                </div>
                                <InputANTD
                                  addonBefore={(form.is_active && expertise.detail?.is_online)
                                    ? <CheckCircle
                                      className="text-success cursor-pointer w-3 h-3"
                                      onClick={() => {
                                        if (form.form_type === 'Mental Health') setMHForm('is_active', false)
                                        else setBurnoutForm('is_active', false)
                                      }}
                                    />
                                    : <XCircle
                                      className="text-destructive cursor-pointer w-3 h-3"
                                      onClick={() => {
                                        if (expertise.detail?.is_online && isUrl(form.form_link || '')) {
                                          if (form.form_type === 'Mental Health') setMHForm('is_active', true)
                                          else setBurnoutForm('is_active', true)
                                        }
                                      }}
                                    />
                                  }
                                  addonAfter={<ExternalLink className={cn("w-3 h-3", isUrl(form.form_link || '') && 'text-primary cursor-pointer')} onClick={() => isUrl(form.form_link || '') && window.open(form.form_link, '_blank')} />}
                                  size="small" className="w-full" placeholder="https://.."
                                  value={form.form_link}
                                  onChange={e => {
                                    const { value } = e.target
                                    if (form.form_type === 'Mental Health') {
                                      setMHForm('form_link', value)
                                      setMHForm('is_active', isUrl(value || ''))
                                    } else {
                                      setBurnoutForm('form_link', value)
                                      setBurnoutForm('is_active', isUrl(value || ''))
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="space-y-1 pt-1 w-full">
                        <div className="flex">
                          <p className="text-xs">Harga Layanan / {expertise.prices?.online_duration || 15} menit</p>
                          <NetPricePopover price={expertise.prices?.online || 0} duration={expertise.prices?.online_duration || 15} />
                        </div>
                        <div className="text-sm text-muted-foreground">Rp. {(expertise.prices?.online || 0).toLocaleString('id')}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-2 w-full">
              {/* HOMECARE PRICE */}
              <div className="flex gap-2 items-start">
                {on_edit.profile ?
                  <Checkbox className="mt-1" checked={expertise.detail?.is_homecare} onCheckedChange={checked => editValue(expertise, 'is_homecare', checked, 1)} />
                  : expertise.detail?.is_homecare ? <CheckCircle className="text-success" /> : <XCircle className="text-destructive" />
                }
                <div className="space-y-1 w-full">
                  <div>{'Layanan Homecare, '}
                    <Popover>
                      <PopoverTrigger className={cn("text-sm text-gray-400 cursor-pointer", on_edit.profile && "hover:text-primary")}>
                        {on_edit.profile
                          ? <Dialog>
                            <DialogTrigger asChild>
                              <span>
                                {expertise.homecare_area?.radius ? `${expertise.homecare_area?.label}, radius: ${expertise.homecare_area?.radius} km` : 'Tentukan Lokasi'}
                              </span>
                            </DialogTrigger>
                            <DialogLocationHomecare />
                          </Dialog>
                          : expertise.homecare_area?.radius && `${expertise.homecare_area?.label}, radius: ${expertise.homecare_area?.radius} km`}
                      </PopoverTrigger>
                      <PopoverContent className="text-xs text-gray-400 p-2 w-fit max-w-[16rem] text-center">
                        {on_edit.profile
                          ? "Klik untuk ubah alamat"
                          : expertise.homecare_area?.full_address || 'belum menentukan alamat'}
                      </PopoverContent>
                    </Popover>
                  </div>
                  <p className="text-xs">Harga Layanan</p>
                  <div className="space-y-1 text-sm">
                    {homecare_price.map((price, i) => on_edit.profile ? (
                      <div key={i} className="flex gap-2" onMouseEnter={() => setSelectedServiceId(price.id)} onTouchStart={() => setSelectedServiceId(price.id)}>
                        <Input
                          className="bg-transparent h-5 text-muted-foreground"
                          placeholder="Layanan"
                          value={price.service}
                          onChange={e => {
                            const homecare = homecare_price.map(item => item.id === i + 1 ? ({ ...item, service: e.target.value }) : item)
                            editValue(expertise, 'prices', { homecare, online: expertise.prices?.online || 0, offline: expertise.prices?.offline || 0 })
                          }}
                        />
                        <Input
                          className="bg-transparent h-5 text-muted-foreground w-2/3"
                          value={`Rp. ${(price.value || 0).toLocaleString('id') || ''}`}
                          onChange={e => {
                            const value: string = e.target.value.split('. ').at(-1) || ''
                            const homecare = homecare_price.map(item => item.id === i + 1 ? ({ ...item, value: Number(value.replace(/\D/g, '')) }) : item)
                            editValue(expertise, 'prices', { homecare, online: expertise.prices?.online || 0, offline: expertise.prices?.offline || 0 })
                          }}
                        />
                        <div className="flex justify-end w-20">
                          {i + 1 === homecare_price.length && <Button variant="ghost" className="h-5 w-5 p-1" onClick={() => editValue(expertise, 'prices', { homecare: [...homecare_price, { id: i + 2, service: '', value: 0 }], online: expertise.prices?.online || 0, offline: expertise.prices?.offline || 0 })}><Plus /></Button>}
                          {i !== 0 && (
                            <Button
                              variant="ghost"
                              className="h-5 w-5 p-1 text-red-500 hover:text-red-600"
                              onClick={() => {
                                const homecare = homecare_price.filter(item => item.id !== selected_service_id)
                                editValue(expertise, 'prices', { homecare, online: expertise.prices?.online || 0, offline: expertise.prices?.offline || 0 })
                              }}
                            ><Trash2 /></Button>)}
                        </div>
                      </div>
                    ) : (
                      <div key={i} className="w-5/6 grid grid-cols-2">
                        <div className="flex">
                          {price.service}
                          <NetPricePopover price={price.value || 0} duration={60} />
                        </div>
                        <a className="text-gray-500">Rp. {(price.value).toLocaleString('id')}</a>
                        <hr className="col-span-2" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>}
        {/* SKILLS */}
        <div className="space-y-1">
          <div>Keahlian dan Tindakan</div>

          {on_edit.profile ?
            <div className="space-y-1">
              <Input className="bg-transparent text-muted-foreground" value={expertise.detail?.skills} onChange={e => editValue(expertise, 'skills', e.target.value, 1)} />
              <div className="flex flex-wrap gap-1">{expertise.detail?.skills ? expertise.detail?.skills.split(',').map((s, i) => <Tag color="pink" key={i} >{s}</Tag>) : '-'}</div>
            </div> :
            <div className="flex flex-wrap gap-1">
              {expertise.detail?.skills ? expertise.detail?.skills.split(",").map((v, i) => <Tag color="pink" key={i}>{v}</Tag>) : '-'}
            </div>}
        </div>
        {/* DESCRIPTION */}
        <div className="space-y-1">
          <div>Deskripsi</div>
          {on_edit.profile
            ? <Textarea className="bg-transparent text-muted-foreground w-full" placeholder="Isi data berikut" value={expertise.profile} onChange={e => editValue(expertise, 'profile', e.target.value, 0)} />
            : <div className="text-sm text-muted-foreground">{expertise.profile || '-'}</div>}
        </div>
      </CardContent>
    </Card >);
}

function NetPricePopover({ trigger, price, duration, is_active }: {
  trigger?:
  any, price: number
  duration: number
  is_active?: boolean
}) {
  const admin_fee = Math.round(price * 0.3)
  const net = price - admin_fee
  return (
    <PopoverANTD
      content={
        <div className="w-fit space-y-1 text-gray-400 text-xs">
          {is_active !== undefined && (
            <div className="justify-between flex gap-2 items-end">
              <p>Status Layanan</p>
              <p className={cn(is_active ? "text-success" : "text-destructive", "flex font-semibold")}>
                {is_active ? <CheckCircle className="h-4" /> : <XCircle className="h-4" />}
                {is_active ? 'Buka' : 'Tutup'}
              </p>
            </div>
          )}
          <div className="justify-between flex gap-2">
            <p>Durasi</p>
            <b className="text-black">{duration} menit</b>
          </div>
          <div className="justify-between flex gap-2">
            <p>Potongan Fellas</p>
            <b className="text-red-400">- Rp {admin_fee.toLocaleString()}</b>
          </div>
          <div className="justify-between flex gap-2">
            <p>Penghasilan bersih</p>
            <b className="text-green-400">Rp {net > 0 ? net.toLocaleString() : 0}</b>
          </div>
        </div>
      }
    >
      {trigger || <Info className="h-3 w-5 stroke-gray-400 cursor-help" />}
    </PopoverANTD>
  )
}

export default ExpertiseProfileCard;