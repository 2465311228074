import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import BabyImage from "src/assets/images/BabyImage.png";
import Header from "src/components/header";
import Navbar from "src/components/navbar";
import { INITIAL_LISBAN_NAVBAR } from "src/config/const/menubar";
import { set_lisban_menu } from "src/config/redux/slicer/lisban/lisbanNavbarSlicer";
import { getJWT } from "src/config/services/core/auth.api";
import { useMenuBarStore } from "src/config/store/global/menubar";
import NutritionCalculator from "./nutrition-calculator";
import VaccineScheduler from "./vaccine-scheduler";

export const getLisbanStorage = () => {
  const initial_user_child = { first_name: '', last_name: '', weight: 0, height: 0, birth_date: '' }
  return JSON.parse(localStorage.getItem('lisban') || JSON.stringify({ navbar: 'vaccine-scheduler', selected_child: initial_user_child }))
}

function LisbanPage() {
  const dispatch = useDispatch()
  const is_login = Boolean(getJWT().key)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'
  const { set_navbar_menu } = useMenuBarStore()

  const navbar = useSelector((state: any) => state.LisbanMenubar.value)
  const main_menu = (
    navbar === 'vaccine-scheduler' ? <VaccineScheduler /> :
      navbar === 'nutrition-calculator' ? <NutritionCalculator /> :
        <div />
  )
  useEffect(() => {
    const navbar = getLisbanStorage().navbar
    dispatch(set_lisban_menu(navbar))
    set_navbar_menu(INITIAL_LISBAN_NAVBAR)
    ReactGA.send({ hitType: "pageview", page: "/lisban", title: "LISBAN Page" });
  }, []);

  useEffect(() => {
    dispatch(set_lisban_menu(page))
  }, [page]);

  return (
    <div className="min-h-screen bg-white">
      <Header is_login={is_login} />
      <div className="h-64 pt-16 bg-primary text-3xl text-start text-white font-semibold">
        <div className="flex h-full justify-between">
          <div className="w-3/4 max-sm:w-full px-16 self-center">
            <p>LISBAN</p>
            <p className="text-2xl font-medium">Layanan Informasi Kesehatan Ibu dan Anak</p>
          </div>
          <div className="self-end h-full pt-8 max-sm:hidden">
            <img src={BabyImage} className="h-full aspect-auto" />
          </div>
        </div>
      </div>
      <div className="lg:container max-lg:px-4 flex pt-8 gap-4">
        <div className="w-1/5 max-lg:hidden">
          <Navbar className="relative w-full top-0 left-0" navbar_menu={INITIAL_LISBAN_NAVBAR} setNavbar={set_lisban_menu} selected_navbar={navbar} />
        </div>
        <div className="w-4/5 max-lg:w-screen text-start px-2 pb-4">
          {main_menu}
        </div>
      </div>
    </div>
  );
}

export default LisbanPage;