import Axios, { AxiosRequestConfig } from 'axios';
import { authHeader, autoLogout } from '../core/auth.api';
import { store } from 'src/config/redux/store';
import { set_on_progress } from 'src/config/redux/slicer/main/menuBarSlicer';
import { APIRequestType } from '../type';

const API_URL = "/api/v1/health-care/"

export const getHealthCareDataByQuery = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `data/${data.query || ''}`,
        headers: authHeader(),
        params: data.params,
        data,
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const updateHealthCare = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const formData = new FormData();
    Object.keys(data).map((item) => formData.append(item, data[item]))

    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + `data/${data.id}/`,
        headers: authHeader(),
        data: formData
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onGetHealthCarePropose = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `propose/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onDeleteHealthCarePropose = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'delete',
        url: API_URL + `propose/${data.id}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}

export const onGetHealthCareApproval = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `approve/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}


export const onQueryHealthCarePatient = async ({ data, params, hide_page_loading }: APIRequestType) => {
    !hide_page_loading && store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `patient`,
        headers: authHeader(),
        params: params || {},
        data: data || {}
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onHealthCareApproval = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'put',
        url: API_URL + `approve/${data.id}/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const updateHealthCareDetail = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const requestConfig: AxiosRequestConfig = {
        method: 'patch',
        url: API_URL + `propose/${data.id}/`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onProposeHealthCare = async (data: any) => {
    store.dispatch(set_on_progress(true))
    const formData = new FormData();
    Object.keys(data).map((item) => formData.append(item, data[item]))

    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: API_URL + `propose/`,
        headers: authHeader(),
        data: formData
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
    finally {
        store.dispatch(set_on_progress(false))
    }
}

export const onGetHealthCarePrices = async (data: any) => {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: API_URL + `prices/${data.query || ''}`,
        headers: authHeader(),
        data
    }
    try {
        const { data: response } = await Axios.request(requestConfig)
        return response
    }
    catch (err: any) {
        const { data, status } = err.response
        autoLogout(status)
        return { error: data, status }
    }
}