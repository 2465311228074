
export const PROFESSION_OPTIONS = [
  { key: "Dokter", text: "Dokter" },
  { key: "Dokter Spesialis", text: "Dokter Spesialis" },
  { key: "Psikologi", text: "Psikolog" },
  { key: "Bidan", text: "Bidan" },
  { key: "Doula", text: "Doula" },
  { key: "Konselor Laktasi", text: "Konselor Laktasi" },
  { key: "Konselor Gizi", text: "Konselor Gizi" },
  { key: "Terapis", text: "Terapis" },
]
