import DashboardAppointment from "./tabs/dashboard";
import HistoryAppointment from "./tabs/history";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import UnpaidAppointment from "./tabs/unpaid";

function Appointment() {
    return (
        <div className="container gap-1 grid">
            <div className="text-3xl font-semibold">Janji Saya</div>
            <div className="text-zinc-400">Berikut informasi permintaan janji ketemu tenaga kesehatan</div>
            <Tabs defaultValue="main">
                <TabsList className="mb-4 grid w-full grid-cols-3 max-md:grid-cols-1" >
                    <TabsTrigger value="main">Utama</TabsTrigger>
                    <TabsTrigger value="unpaid">Menunggu Pembayaran</TabsTrigger>
                    <TabsTrigger value="report">Riwayat</TabsTrigger>
                </TabsList>
                <TabsContent value="main">
                    <DashboardAppointment />
                </TabsContent>
                <TabsContent value="unpaid">
                    <UnpaidAppointment />
                </TabsContent>
                <TabsContent value="report">
                    <HistoryAppointment />
                </TabsContent>
            </Tabs>
        </div>
    );
}

export default Appointment;