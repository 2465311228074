import { Building2, CalendarClock, ClipboardList } from "lucide-react";
import { useSelector } from "react-redux";
import { Badge } from "src/components/ui/badge";
import { Card } from "src/components/ui/card";
import { cn } from "src/lib/utils";

function AppointmentFillDataReview() {
  const { appointment_form, form_page, service_form, user_form, invoice } = useSelector((state: any) => state.Appointment)
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const { date, time, starttime, endtime } = service_form
  const homecare_address = appointment_form.homecare?.user_location?.full_address
  const offline_address = selected_health_care.offline_location?.full_address

  return (
    <div>
      <Card className="rounded-none">
        <div className="p-4 grid gap-2">
          <div className="space-y-1">
            <div className="flex gap-2 items-center"><CalendarClock className="w-4 h-4 text-primary" />{date && new Date(date).toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
            <div className="ml-6"><Badge>{starttime} - {endtime}</Badge></div>
          </div>
          <div className="space-y-1">
            <div className="flex gap-2 items-center"><Building2 className="w-4 h-4 text-primary" />{appointment_form.location}</div>
            <div className={cn(appointment_form.location === "Online" && "blur-sm", "ml-6 text-muted-foreground text-sm")}>
              {appointment_form.location === "Online" ? 'inibukanmerupakanlinkmeeting' :
                appointment_form.location === 'Homecare' ? homecare_address
                  : offline_address}
            </div>
          </div>
        </div>
      </Card>
      <Card className={cn(form_page === 2 ? "rounded-t-none" : "rounded-none")}>
        <div className="p-4 grid gap-2">
          <div className="space-y-1">
            <div className="flex gap-2 items-center"><ClipboardList className="w-4 h-4 text-primary" />Keluhan</div>
            <div className="ml-6 flex gap-2">
              {appointment_form.condition ? appointment_form.condition.split(',').map((item, i) => <Badge key={i}>{item}</Badge>) : <Badge>None</Badge>}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AppointmentFillDataReview;