import { type ClassValue, clsx } from "clsx"
import { format } from "date-fns"
import { getAddressFromCoords, getAddressLabel } from "src/config/services/fellas/google.api"
import { twMerge } from "tailwind-merge"
import { MedicalReportPrescriptionType, MedicalReportType } from "./types"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toAbsolutePath(path = '') {
  const LOCAL_ENDPOINT = ['http://localhost:8000', 'http://127.0.0.1:8000']
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000'

  if (path && !path.includes('storage.googleapis.com')) {
    if (LOCAL_ENDPOINT.some(endpoint => path.includes(endpoint))) {
      const matchedEndpoint = LOCAL_ENDPOINT.find(endpoint => path.includes(endpoint));

      if (matchedEndpoint) {
        return REACT_APP_API_ENDPOINT + '/media' + path.replace(matchedEndpoint, '');
      }
    } else {
      return REACT_APP_API_ENDPOINT + '/media' + path;
    }
  }
  else {
    return path;
  }
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getCurrentUserLocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const place = await getAddressFromCoords({ lat: latitude, lng: longitude })
        const { address_components: address, formatted_address: full_address, geometry, name: label } = place

        const coords = { lat: geometry.location.lat, lng: geometry.location.lng }
        const province = getAddressLabel(address, 'administrative_area_level_1')
        const city = getAddressLabel(address, 'administrative_area_level_2')
        const user_location = { city, province, address, full_address, coords, label: label || '' }
        localStorage.setItem('user_location', JSON.stringify(user_location))
        resolve(user_location)
      }
    );
  }
})

export const getDragLocation = async (location) => {
  const lat = location.latLng.lat()
  const lng = location.latLng.lng()

  const place = await getAddressFromCoords({ lat, lng })
  const { address_components: address, formatted_address: full_address, geometry, name: label } = place

  const coords = { lat: geometry.location.lat, lng: geometry.location.lng }
  const province = getAddressLabel(address, 'administrative_area_level_1')
  const city = getAddressLabel(address, 'administrative_area_level_2')
  const user_location = { city, province, address, full_address, coords, label: label || '' }
  return user_location
}

export const formatDistanceUnit = (value) => {
  if (value < 1) {
    // If the value is less than 1, format in meters
    return `${Math.round(value * 1000)} m`;
  } else {
    // If the value is 1 or greater, format in kilometers
    return `${Math.round(value * 10) / 10} km`;
  }
};

export const formatHomecarePrice = (prices) => {
  const numbers = prices.map(price => price.value)
  const sortedNumber = numbers.sort((a, b) => a - b);
  const minNumber = sortedNumber.at(0)
  const maxNumber = sortedNumber.at(-1)
  const formatNumber = (num) => (num >= 10000 ? `${num / 1000}rb` : num.toString());
  return `${formatNumber(minNumber)} - ${formatNumber(maxNumber)}`;
};


export function formatPhoneNumber(phoneNumber) {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check the length of the cleaned number to determine the format
  if (cleaned.startsWith('62')) {
    return cleaned.replace(/(62)(\d{3})(\d{4})(\d{4})/, '($1) $2-$3-$4');
  } else if (cleaned.length === 12) {
    return cleaned.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (cleaned.length === 13) {
    return cleaned.replace(/(\d{4})(\d{4})(\d{4})(\d{1})/, '$1-$2-$3-$4');
  }
}

const degreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

type Coordinates = {
  lat: number;
  lng: number;
};

export const calculateDistance = (coord1: Coordinates, coord2: Coordinates): number => {
  const earthRadius = 6371000; // Earth's radius in meters

  const lat1Rad = degreesToRadians(coord1.lat);
  const lng1Rad = degreesToRadians(coord1.lng);
  const lat2Rad = degreesToRadians(coord2.lat);
  const lng2Rad = degreesToRadians(coord2.lng);

  const dLat = lat2Rad - lat1Rad;
  const dLng = lng2Rad - lng1Rad;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLng / 2) * Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c / 1000;

  return distance;
};

export const convertToMultipleArrays = (arr, n) =>
  Array.from({ length: Math.ceil(arr.length / n) }, (_, i) =>
    arr.slice(i * n, i * n + n)
  );


export function formatTime(date) {
  const date_jakarta = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
  return format(new Date(date_jakarta), 'hh:mm')
}

export function isSocialMediaUrl(text: string, type: 'facebook' | 'twitter' | 'instagram' | 'linkedin') {
  return (text || '').includes(type + '.com/') ? isUrl(text) : false
}

export function isUrl(text: string) {
  const regex = /(?:https|http?):\/\/(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  return regex.test(text)
}

export function renderPrescription(data: MedicalReportPrescriptionType, type: 'dose' | 'duration') {
  if (type === 'dose') {
    const { dose } = data
    const morning = dose.morning ? `${dose.morning} Pagi` : ''
    const noon = dose.noon ? `${dose.noon} Siang` : ''
    const night = dose.night ? `${dose.night} Malam` : ''
    const description = dose.description !== 'Tidak ada' ? `(${dose.description})` : ''
    if (morning || noon || night) {
      return `${morning} ${noon} ${night} ${description}`
    }
  }
  else if (type === 'duration') {
    const { dose, duration_in_day } = data
    const total_dose = (dose.morning + dose.noon + dose.night)
    if (duration_in_day && total_dose > 0) {
      return `${duration_in_day} Hari (${duration_in_day * total_dose})`
    }
  }
}