import { Tag, type TableProps } from 'antd';
import { ArrowRight } from 'lucide-react';
import { Badge } from 'src/components/ui/badge';
import { Button } from 'src/components/ui/button';

export interface DataType {
  first_name: string
  last_name: string
  birth_date: string
  last_appointment_datetime: string
  last_appointment_condition: string[]
}

export const updatePatientId = (patientId) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('patient_id', patientId);
  window.location.search = searchParams.toString();
}

export const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Name Pasien',
    key: 'fullname',
    align: 'right',
    fixed: window.innerWidth > 600 && 'left',
    render: (data) => `${data.first_name} ${data.last_name}`
  },
  {
    title: 'Status',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 80,
    render: (data) => data === 'child' ? 'Anak' : 'Ibu'
  },
  {
    title: 'Tanggal Lahir',
    dataIndex: 'birth_date',
    key: 'birth_date',
    align: 'center',
  },
  {
    title: 'Pertemuan Terakhir',
    dataIndex: 'last_appointment_datetime',
    key: 'last_appointment_datetime',
    align: 'center',
  },
  {
    title: 'Kondisi Terakhir',
    key: 'last_appointment_condition',
    align: 'center',
    render: (data) => {
      const { last_appointment_condition } = data
      return (
        <div className='flex justify-center flex-wrap gap-1'>
          {last_appointment_condition ? last_appointment_condition.split(',').map(item => <Tag key={item} color='pink'>{item}</Tag>) : '-'}
        </div>
      )
    }
  },
  {
    title: 'Action',
    key: 'action',
    width: 80,
    align: 'center',
    fixed: 'right',
    render: (data) => {
      return (
        <div className='flex justify-center'>
          <Button onClick={() => updatePatientId(data.id)} className='p-1 h-6'><ArrowRight className='h-4 w-4 stroke-2' /></Button>
        </div>
      )
    }
  },
]

export const columns_child: TableProps<any>['columns'] = [
  {
    title: 'Name',
    align: 'right',
    fixed: 'left',
    width: 200,
    render: (data) => `${data.first_name} ${data.last_name}`
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'gender',
    key: 'gender',
    align: 'center',
  },
  {
    title: 'Tanggal Lahir',
    dataIndex: 'birth_date',
    key: 'birth_date',
    align: 'center',
  },
  {
    title: 'Berat Badan',
    dataIndex: 'weight',
    key: 'weight',
    align: 'center',
  },
  {
    title: 'Tinggi Badan',
    dataIndex: 'height',
    key: 'height',
    align: 'center',
  },
  {
    title: 'Alergi',
    dataIndex: 'allergy',
    key: 'allergy',
    align: 'center',
    width: 200,
    render: (data) => data
      ? <div className='flex flex-wrap justify-center gap-1'>
        {data.split(',').map(item => <Tag key={item} color='pink'>{item}</Tag>)}
      </div>
      : '-'
  },
]