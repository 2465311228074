import { BeanOff, CalendarDays, Cross, Ruler, Scale } from "lucide-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BabyFemale from "src/assets/images/BabyFemale.png";
import BabyMale from "src/assets/images/BabyMale.png";
import { Badge } from "src/components/ui/badge";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { set_appointment_form, set_user_form } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { getUserChildInformation } from "src/config/services/core/user.api";
import { cn } from "src/lib/utils";

function AppointmentPatientCard() {
  const user = getCurrentUser()
  const dispatch = useDispatch()
  const { appointment_form, user_form } = useSelector((state: any) => state.Appointment)

  const selectPatient = (item) => () => {
    if (!item.username) {
      dispatch(set_appointment_form({ ...appointment_form, user_child_id: item.id }))
    } else {
      dispatch(set_appointment_form({ ...appointment_form, user_child_id: null }))
    }
  }

  const updateUserChildState = () => {
    getUserChildInformation({}).then(user_child => {
      dispatch(set_user_form([user, ...user_child]))
    })
  }

  useEffect(() => {
    updateUserChildState()
  }, [appointment_form.location]);

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-3">
          <Cross className="w-5 h-5 text-primary" />
          <CardTitle> Data Pasien</CardTitle>
        </div>
      </ CardHeader>
      <div className="mx-14 pb-6 grid gap-2">
        {user_form.map((item, i) => (
          <Card key={i} className={cn(!item.username && item.id === appointment_form.user_child_id ? "border-primary" : item.username && !appointment_form.user_child_id && "border-primary", "cursor-pointer border-2")}>
            <div onClick={selectPatient(item)} className="flex gap-4 items-center p-4">
              <div className="max-md:hidden">
                {item.username
                  ? <div className="h-20 aspect-square rounded-full bg-primary/30 justify-center items-center flex text-3xl font-semibold text-white cursor-pointer">{item.first_name ? item.first_name[0] : 'N'}{item.last_name ? item.last_name[0] : 'N'}</div>
                  : <img src={item.gender === 'Laki-Laki' ? BabyMale : BabyFemale} className="h-20 aspect-square rounded-full bg-zinc-200 cursor-pointer" />
                }
              </div>
              <div className="space-y-1 w-full">
                <div className="text-semibold">{item.first_name} {item.last_name} ({item.username ? "Saya" : `Anak ${i}`})</div>
                <div className="grid grid-cols-3 max-md:grid-cols-2 gap-6 max-md:gap-2 text-gray-400 text-sm max-md:text-xs">
                  <div className="space-y-2">
                    <p>{item.gender || '-'}</p>
                    <p className="flex gap-1"><CalendarDays className="h-4 w-4" />{item.birth_date || '-'}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="flex gap-1"><Ruler className="w-4 h-4" />{item.height || '-'} cm</p>
                    <p className="flex gap-1"><Scale className="w-4 h-4" />{item.weight || '-'} kg</p>
                  </div>
                  <div className="space-y-1 max-md:col-span-2">
                    <p className="flex gap-1"><BeanOff className="h-4 w-4" />Allergy</p>
                    <div className="flex gap-1 ml-5 flex-wrap">
                      {!item.allergy ? '-' : item.allergy.split(',').map(a => <Badge key={a} className="bg-primary/90 text-white">{a}</Badge>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Card>
  );
}

export default AppointmentPatientCard;