import { Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';
import { createTw } from "react-pdf-tailwind";
import fonts from 'src/assets/fonts';
import icons from 'src/assets/icons';
import { MedicalReportType } from 'src/lib/types';
import { renderPrescription } from 'src/lib/utils';

Font.register({ family: 'Inter', src: fonts.Inter.default });
Font.register({ family: 'Inter-Bold', src: fonts.Inter.semibold });
Font.register({ family: 'Inter-Medium', src: fonts.Inter.medium });

const tw = createTw({
  theme: {
    fontFamily: {
      sans: ['Inter'],
      bold: ['Inter-Bold'],
      medium: ['Inter-Medium']
    },
    extend: {
      colors: {
        primary: "#FF42B3",
      },
    },
  }
})

export default function MedicalReportPDF({ form }:
  { form: MedicalReportType }
) {
  const admin_fees = form.appointment.admin_fees || []

  const data = {
    recipe: form.prescription.map((prescription) => ({
      medicine_name: prescription.medicine_name,
      dose: renderPrescription(prescription, 'dose'),
      duration: renderPrescription(prescription, 'duration')
    })),
    invoice: [
      { description: `Layanan ${form.appointment.service} - ${form.appointment.healthcare} - ${form.appointment.profession}`, price: form.appointment.amount },
      ...admin_fees.map(({ type, value }) => ({
        description: type, price: -value
      }))
    ],
    total_price: form.appointment.amount - (admin_fees.reduce((total, fee) => total + fee.value, 0))
  }
  const columns_recipe = [
    { key: 'no', title: 'No', width: 100, align: 'center' },
    { key: 'medicine_name', title: 'Nama Obat' },
    { key: 'dose', width: 800, title: 'Dosis' },
    { key: 'duration', title: 'Durasi' },
  ]

  const columns_invoice = [
    { key: 'no', title: 'No', width: 40, align: 'center' },
    { key: 'description', title: 'Keterangan' },
    {
      key: 'price', title: 'Harga', align: 'right', width: 100,
      render: (item) => item > 0
        ? <Text style={tw("text-green-600")}>{'Rp. ' + item.toLocaleString('id')}</Text>
        : item === 0
          ? 'Rp. 0'
          : <Text style={tw("text-red-600")}>{'- Rp. ' + (item * -1).toLocaleString('id')}</Text>
    },
  ]


  const TableView = ({ data, columns }) => (
    <View style={tw("mb-2")}>
      <View style={tw("flex flex-row border-[.5px] p-1 pr-4 font-bold gap-2")}>
        {columns.map((c) =>
          <Text key={c.key} style={{ width: c.width || `100%`, textAlign: c.align }}>
            {c.title}
          </Text>)}
      </View>
      {data.map((rowData, i) =>
        <View key={i} style={tw("flex flex-row border-r-[.5px] border-l-[.5px] border-b-[.5px] p-1 pr-4 gap-2")}>
          {columns.map((c, j) =>
            <Text key={c.key} style={{ width: c.width || `100%`, textAlign: c.align }}>
              {j === 0 ? i + 1
                : c.render
                  ? c.render(rowData[c.key])
                  : rowData[c.key]}
            </Text>
          )}
        </View>
      )}
    </View>
  )

  return (
    <Document>
      <Page size="A4" style={tw("text-sm font-sans")}>
        {/* Header */}
        <View style={tw("bg-primary h-4 w-screen absolute top-0")} />
        <View style={tw("mx-6 my-8")}>
          <View style={tw("flex flex-row gap-4 border-b-[0.5px] pb-4 mb-4 items-end")}>
            <Image src={icons.FELLAS_ICON} style={tw("w-20 h-20")} />
            <View>
              <Text style={tw("font-bold text-lg")}>Fellas.ID</Text>
              <Text>Platform Edukasi Ibu dan Anak Indonesia</Text>
              <Text>NPWP: 62.193.699.6-061.000</Text>
            </View>
          </View>

          {/* Informasi Pasien */}
          <View style={tw("flex flex-row border-b-[0.5px] pb-4 mb-4")}>
            <View style={tw("w-1/2")}>
              <Text style={tw("font-bold text-lg")}>Info Pasien</Text>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Nama Pasien</Text>
                <Text>: {form.patient.fullname}</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Umur Pasien</Text>
                <Text>: {form.patient.age}</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Berat Badan</Text>
                <Text>: {form.patient.weight} kg</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Tinggi Badan</Text>
                <Text>: {form.patient.height} cm</Text>
              </View>
            </View>
            <View style={tw("w-1/2")}>
              <Text style={tw("font-bold text-lg")}>Info Layanan</Text>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Nama</Text>
                <Text>: {form.appointment.healthcare}</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Profesi</Text>
                <Text>: {form.appointment.profession}</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>ID Layanan</Text>
                <Text>: {form.appointment.id}</Text>
              </View>
              <View style={tw("flex flex-row")}>
                <Text style={tw("w-1/3")}>Waktu</Text>
                <Text>: {form.appointment.datetime}</Text>
              </View>
            </View>
          </View>


          {/* Hasil Pemeriksaan */}
          <Text style={tw("font-bold text-lg")}>Hasil Pemeriksaan</Text>
          <View style={tw("border-[0.5px] mb-2")}>
            <View style={tw("flex flex-row border-b-[0.5px] text-center py-1")}>
              <Text style={tw("font-bold w-full")}>Diagnosa</Text>
            </View>
            <View style={tw("w-full px-4 py-1")} wrap>
              <Text>{form.diagnosis}</Text>
            </View>
          </View>

          {/* Resep */}
          <Text style={tw("font-bold text-lg mt-2")}>Resep</Text>
          {data.recipe.length
            ? <TableView data={data.recipe} columns={columns_recipe} />
            : <Text style={tw("mb-2 text-red-500")}>* Tidak ada resep obat</Text>
          }

          {/* Invoice Layanan */}
          <Text style={tw("font-bold text-lg mt-2")}>Invoice Layanan</Text>
          <TableView data={data.invoice} columns={columns_invoice} />
          <View style={tw("flex flex-row justify-end mr-4 font-bold")}>
            <Text style={tw("w-[100px]")}>Total</Text>
            <Text style={tw("text-right")}>{'Rp ' + (data.total_price).toLocaleString('id')}</Text>
          </View>
        </View>

        {/* Footer */}
        <View style={tw("absolute bottom-10 right-0 px-6 items-center")}>
          <Text>{form.date_created}</Text>
          <Image src={form.qr_code} style={tw("w-16 h-16 my-1")} />
          <Text>{form.appointment.healthcare}</Text>
          {form.appointment.healthcare_str_number && <Text>No STR-{form.appointment.healthcare_str_number}</Text>}
        </View>
        <View style={tw("bg-primary h-4 w-screen absolute bottom-0")} />
      </Page>
    </Document>
  )
}