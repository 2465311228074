import { createSlice } from '@reduxjs/toolkit'

export const paymentDialogSlicer = createSlice({
  name: 'paymentDialogSlicer',
  initialState: {
    dialog: {
      withdraw: false,
      bill: false,
      online: false,
      offline: false,
      withdraw_form: false,
      bill_form: false,
      online_invoice: false,
      admin_transaction: false,
    },
    payment_data: {},
  },
  reducers: {
    set_open_payment_dialog: (state, action) => {
      state.dialog[action.payload] = true
    },
    set_close_payment_dialog: (state, action) => {
      state.dialog[action.payload] = false
    },
    set_payment_data: (state, action) => {
      state.payment_data = action.payload
    },
  },
})

export const { set_open_payment_dialog, set_close_payment_dialog, set_payment_data } = paymentDialogSlicer.actions
export default paymentDialogSlicer.reducer
