import { configureStore } from '@reduxjs/toolkit'
import adminDashboardSlicer from './slicer/admin/adminDashboardSlicer'
import adminExpertiseRequestSlicer from './slicer/admin/adminExpertiseRequestSlicer'
import adminNavbarSlicer from './slicer/admin/adminNavbarSlicer'
import appointmentDialogSlicer from './slicer/dialog/appointmentDialogSlicer'
import expertiseProposeSlicer from './slicer/expertise/expertiseProposeSlicer'
import expertiseSlicer from './slicer/expertise/expertiseSlicer'
import appointmentSlicer from './slicer/health-care/appointmentSlicer'
import searchQuerySlicer from './slicer/health-care/searchQuerySlicer'
import lisbanAppSlicer from './slicer/lisban/lisbanAppSlicer'
import lisbanNavbarSlicer from './slicer/lisban/lisbanNavbarSlicer'
import menuBarSlicer from './slicer/main/menuBarSlicer'
import myAppointmentSlicer from './slicer/main/myAppointmentSlicer'
import userChildSlicer from './slicer/main/userChildSlicer'
import userSlicer from './slicer/main/userSlicer'
import expertiseWalletSlicer from './slicer/expertise/expertiseWalletSlicer'
import paymentDialogSlicer from './slicer/dialog/paymentDialogSlicer'
import userDialogSlicer from './slicer/dialog/userDialogSlicer'
import adminWalletSlicer from './slicer/admin/adminWalletSlicer'

export const store = configureStore({
  reducer: {
    MenuBar: menuBarSlicer,
    User: userSlicer,
    UserChild: userChildSlicer,
    Expertise: expertiseSlicer,
    ExpertisePropose: expertiseProposeSlicer,
    ExpertiseWallet: expertiseWalletSlicer,
    AdminDashboard: adminDashboardSlicer,
    AdminMenuBar: adminNavbarSlicer,
    AdminRequest: adminExpertiseRequestSlicer,
    AdminWallet: adminWalletSlicer,
    SearchQuery: searchQuerySlicer,
    Appointment: appointmentSlicer,
    MyAppointment: myAppointmentSlicer,
    UserDialog: userDialogSlicer,
    AppointmentDialog: appointmentDialogSlicer,
    PaymentDialog: paymentDialogSlicer,
    LisbanMenubar: lisbanNavbarSlicer,
    LisbanApp: lisbanAppSlicer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})