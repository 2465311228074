import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpertiseRequestCard } from "src/components/cards/expertise_request";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { set_request_data } from "src/config/redux/slicer/admin/adminExpertiseRequestSlicer";
import { onGetHealthCarePropose } from "src/config/services/fellas/health-care.api";


function AdminExpertiseRequest() {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.AdminRequest.value)
  const [selected_tab, setSelectedTab] = useState('-1')
  const [review, reject, accept] = [
    data.filter(item => item.is_accepted === -1),
    data.filter(item => item.is_accepted === 0),
    data.filter(item => item.is_accepted === 1),
  ]

  const updateRequest = async () => {
    const resp_exp_req = await onGetHealthCarePropose({})
    dispatch(set_request_data(resp_exp_req))
  }

  useEffect(() => {
    updateRequest()
  }, [])

  return (
    <div className="container gap-2 grid pb-4">
      <div className="text-3xl font-semibold">Permintaan sebagai Tenaga Ahli</div>
      <Tabs value={selected_tab} onValueChange={val => setSelectedTab(val)}>
        <TabsList className="mb-4 grid w-full grid-cols-3 max-md:grid-cols-1">
          <TabsTrigger value="-1">Diajukan</TabsTrigger>
          <TabsTrigger value="0">Ditolak</TabsTrigger>
          <TabsTrigger value="1">Diterima</TabsTrigger>
        </TabsList>
        <TabsContent value="-1">
          <ExpertiseRequestCard data={review} status="admin" onUpdateDate={updateRequest} />
        </TabsContent>
        <TabsContent value="0">
          <ExpertiseRequestCard data={reject} status="admin" onUpdateDate={updateRequest} />
        </TabsContent>
        <TabsContent value="1">
          <ExpertiseRequestCard data={accept} status="admin" onUpdateDate={updateRequest} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default AdminExpertiseRequest;