import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/ui/button';
import { Form, FormField, FormItem, FormMessage } from "src/components/ui/form";
import { Input } from 'src/components/ui/input';
import { ToastAlert } from "src/components/ui/toaster";
import { toast } from "src/components/ui/use-toast";
import { onRegister } from "src/config/services/core/auth.api";
import { z } from 'zod';
import { setAuthPage } from ".";

function RegisterForm() {
  const FormSchema = z.object({
    username: z.string()
      .min(6, { message: "Username must be at least 6 characters." })
      .max(12, { message: "Username must below 12 characters " }),
    email: z.string()
      .email({ message: 'Please enter valid email' }),
    password: z.string()
      .min(6, { message: "Password must be at least 6 characters." }),
    confirmPassword: z.string()
      .min(6, { message: "Password must be at least 6 characters." }),
  }).refine(({ confirmPassword, password }) => confirmPassword === password, {
    message: "The passsword didn't match",
    path: ['confirmPassword'],
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    }
  })

  const onSubmitRegister = async (data: z.infer<typeof FormSchema>) => {
    const { username, email, password } = data
    const resp = await onRegister({ username, email, password })
    ToastAlert(resp, `${username} berhasil dibuat, silahkan verifikasi akun via email`)
  }

  return (
    <div className='container flex flex-col gap-4 justify-center h-full text-center'>
      <div className='text-md space-y-1'>
        <div className='text-3xl font-semibold text-center'>Register your new account</div>
        <p>
          Already have an account?
          <a className='text-primary cursor-pointer' onClick={setAuthPage('login')}> Login here!</a>
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitRegister)} className="space-y-3">
          <FormField control={form.control} name="username"
            render={({ field }) => (
              <FormItem className="text-center">
                <Input className="bg-white" placeholder="Username" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField control={form.control} name="email"
            render={({ field }) => (
              <FormItem className="text-center">
                <Input className="bg-white" placeholder="Email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <FormField control={form.control} name="password"
              render={({ field }) => (
                <FormItem className="text-center">
                  <Input className="bg-white" placeholder="Password" type="password" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField control={form.control} name="confirmPassword"
              render={({ field }) => (
                <FormItem className="text-center">
                  <Input className="bg-white" placeholder="Confirm Password" type="password" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <hr />
          <Button type="submit" className="w-full">Register</Button>
        </form>

      </Form>
    </div>
  );
}

export default RegisterForm;
