import { Button, Skeleton, message } from "antd";
import { ArrowLeftCircle, Download, DownloadCloud, HardDriveDownload, Send } from "lucide-react";

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import MedicalReportPDF from "./document";
import Header from "src/components/header";
import { getCurrentUser, getJWT } from "src/config/services/core/auth.api";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import APIRequest, { API_FELLAS } from "src/config/services/api";
import { useEffect, useState } from "react";
import { MedicalReportType } from "src/lib/types";
import NotFound from "src/components/not-found/NotFound";

const is_login = Boolean(getJWT().key)
export default function MedicalReportPreview() {
  const { reportId } = useParams()
  const user = getCurrentUser()
  const navigate = useNavigate()
  const [medical_report, setMedicalReport] = useState<MedicalReportType | undefined>(undefined)
  const [messageApi, contextHolder] = message.useMessage()
  const { mutate: onGetMedicalReport, isLoading } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      url: API_FELLAS + '/api/v2',
      path: '/healthcare/medical-report',
      params: { id: reportId }
    })
    return data
  }, {
    onSuccess: data => {
      const is_healthcare = (user.health_care_id === data?.healthcare_id)
      const is_user = (user.id === data?.user_id)
      if (is_healthcare || is_user) {
        setMedicalReport(data)
      } else {
        messageApi.error('Akun ini tidak dapat mengakses rekam medis')
      }
    },
    onError: error => {
      messageApi.error('Rekam medis tidak tersedia')
    }
  })

  const is_healthcare = (user.health_care_id && user.health_care_id === medical_report?.healthcare_id)

  useEffect(() => {
    onGetMedicalReport()
  }, [reportId]);
  return (
    <div className="h-screen">
      <Header is_login={is_login} />
      {contextHolder}
      <div className="px-8 pt-24 h-[calc(100vh-96px)]">
        <div className="h-14 flex justify-between">
          <Button type="text" onClick={() => navigate(is_healthcare ? '/app/expertise?page=history' : '/app')} size="large" icon={<ArrowLeftCircle className="text-primary" />} />
          {medical_report &&
            <PDFDownloadLink document={<MedicalReportPDF form={medical_report} />} fileName={`Rekam Medis - ${medical_report.appointment.id}.pdf`}>
              <Button type="primary" className="bg-primary" size="large" icon={<DownloadCloud className="h-5 mb-[-4px]" />}>
                Download PDF
              </Button>
            </PDFDownloadLink>
          }
        </div>

        {!isLoading ?
          medical_report ?
            <PDFViewer className="h-full w-full shadow-xl rounded">
              <MedicalReportPDF form={medical_report} />
            </PDFViewer>
            : <NotFound title="" />
          : <Skeleton active />}
      </div>
    </div>
  );
}

