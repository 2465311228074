import { Tag } from "antd";
import { Info } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_close_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { onPaymentCreateBillPayment, readBillPayment } from "src/config/services/fellas/payment.api";

function DialogBillPaymentForm() {
  const user = getCurrentUser()
  const { toast } = useToast()
  const dispatch = useDispatch()
  const [bill_form, setBillForm] = useState({ email: user.email || '', amount: 0 })
  const { value: expertise } = useSelector((state: any) => state.Expertise)
  const { bill_payment_data, balance_cards } = useSelector((state: any) => state.ExpertiseWallet)
  const current_balance = balance_cards['total_balance']
  const current_bill = balance_cards['total_bill']
  const { email, amount } = bill_form
  const [{ admin_fee, VAT, total_amount }, setTotalAmount] = useState({ admin_fee: 0, VAT: 0, total_amount: 0 })
  const [selected_method, setSelectedMethod] = useState('virtual')
  const { limit, offset } = bill_payment_data

  const method_options = [
    { key: 'virtual', label: 'Bayar secara virtual' },
    { key: 'balance', label: 'Potong dari saldo' },
  ]

  const handleAmountChange = (e) => {
    const amount = Number(e.target.value.replace(/\D/g, ''))
    const is_bill_check = (selected_method === 'virtual' || current_bill < current_balance)
    const check_amount = is_bill_check ? current_bill : current_balance
    if (amount > check_amount) {
      toast({
        variant: 'destructive',
        title: `[Error] Something when wrong!`,
        description: is_bill_check ? `Angka melebihi tagihan` : `Saldo tidak mencukupi`
      })
      setBillForm(form => ({ ...form, amount: check_amount }))
    } else {
      setBillForm(form => ({ ...form, amount }))
    }
  }
  const updateTotalAmount = (e) => {
    if (e.key === 'Enter') {
      const VAT = selected_method === 'virtual' ? Math.round(0.0273 * amount) : 0
      const admin_fee = selected_method === 'virtual' ? 0 : 0
      const total_amount = amount + admin_fee + VAT
      setTotalAmount({ admin_fee, VAT, total_amount })
    }
  }

  const submitForm = async (e: React.FormEvent) => {
    let query = `?email=${email}&amount=${amount}&admin_fee=${admin_fee}`
    if (selected_method === 'balance') {
      query += '&from_balance=true'
    }
    const payment = await onPaymentCreateBillPayment({ query, health_care_id: expertise.id })
    ToastAlert(payment, 'Pengajuan pembayaran tagihan berhasil dibuat')
    readBillPayment(limit, offset)
    window.open(payment.invoice_url, '_blank')
    dispatch(set_close_payment_dialog('bill_form'))
  }

  return (
    <DialogContent onClose={() => dispatch(set_close_payment_dialog('bill_form'))} className="sm:max-w-[425px] lg:min-w-[500px] bg-white">
      <DialogHeader>
        <DialogTitle className="text-center">Ajukan Pembayaran Tagihan</DialogTitle>
      </DialogHeader>
      <div className="grid gap-4 text-sm">
        <div className="space-y-1">
          <label>Email Penerima</label>
          <Input type="email" value={email} placeholder={user.email} onChange={e => setBillForm(form => ({ ...form, email: e.target.value }))} />
        </div>
        <div className="space-y-1">
          <label>Nominal Penarikan</label>
          <Input
            value={amount.toLocaleString('id')}
            onChange={handleAmountChange}
            onKeyDown={updateTotalAmount}
          />
        </div>
        <div className="flex justify-evenly">
          {method_options.map(method => (
            <Tag
              key={'method'}
              color={selected_method === method.key ? "pink-inverse" : 'pink'}
              className="cursor-pointer"
              onClick={() => {
                if (selected_method !== method.key) {
                  setSelectedMethod(method.key);
                  setTotalAmount({ admin_fee: 0, VAT: 0, total_amount: 0 })
                  setBillForm({ amount: 0, email })
                }
              }}
            >{method.label}</Tag>
          ))}
        </div>
        {total_amount > 0 && <div className="grid grid-cols-3 gap-1">
          <div className="space-y-1">
            <div className="flex">Biaya Admin
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{admin_fee.toLocaleString('id')}</p>
          </div>
          <div className="space-y-1">
            <div className="flex">Pajak
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{VAT.toLocaleString('id')}</p>
          </div>
          <div className="space-y-1">
            <div className="flex">Nominal Akhir
              <Info className="cursor-help h-4" />
            </div>
            <p className="text-gray-500">{total_amount > 0 ? total_amount.toLocaleString('id') : 0}</p>
          </div>
        </div>}
        <Button disabled={total_amount === 0} onClick={submitForm}>Ajukan Pembayaran Tagihan</Button>
      </div>
    </DialogContent >
  );
}

export default DialogBillPaymentForm;