import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_ADMIN_NAVBAR } from 'src/config/const/menubar'

export const adminNavbarSlicer = createSlice({
  name: 'adminNavbarSlicer',
  initialState: {
    value: 'main',
  },
  reducers: {
    set_admin_menu: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { set_admin_menu } = adminNavbarSlicer.actions
export default adminNavbarSlicer.reducer
