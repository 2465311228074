import { Table } from "antd";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { set_expertise_patient } from "src/config/redux/slicer/expertise/expertiseSlicer";
import APIRequest, { API_FELLAS } from "src/config/services/api";
import { columns } from "./columns";
import PatientDetail from "./detail";

function AppointmentPatient() {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const { patient } = useSelector((state: any) => state.Expertise)
  const [search_field, setSearchField] = useState<string | undefined>(undefined)

  const patient_id = queryParams.get("patient_id")
  const selected_patient = patient.find(item => item.id === patient_id)
  const { mutate: getPatient, isLoading } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      url: API_FELLAS + '/api/v2',
      path: '/healthcare/patient',
      params: {
        patient_name: search_field
      }
    })
    return data
  }, {
    onSuccess: data => {
      const patient = data.map((item, key) => ({ ...item, key }))
      dispatch(set_expertise_patient(patient))
    }
  })

  useEffect(() => {
    getPatient()
  }, []);

  return (
    <div className="container space-y-4">
      <div className="flex max-md:flex-col justify-between md:items-end gap-1">
        <div className="grid gap-1">
          <div className="text-3xl font-semibold">Pasien</div>
          <div className="text-zinc-400">Berikut informasi pasien anda</div>
        </div>
        {!selected_patient && <form onSubmit={(e) => { e.preventDefault(); getPatient() }} className="flex gap-2 md:w-1/3">
          <Input value={search_field || ''} onChange={e => setSearchField(e.target.value)} placeholder="Cari" />
          <Button size="icon" className="px-2" type="submit"><Search /></Button>
        </form>}
      </div>
      {!selected_patient
        ? <Table
          columns={columns}
          loading={isLoading}
          dataSource={patient}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: 6,
            hideOnSinglePage: true,
          }}
        />
        : <PatientDetail patient={selected_patient} />
      }
    </div>
  );
}

export default AppointmentPatient;