import { createSlice } from '@reduxjs/toolkit'
import { getCurrentUser } from 'src/config/services/core/auth.api'

export const expertiseProposeSlicer = createSlice({
  name: 'expertiseProposeSlicer',
  initialState: {
    value: {
      fullname: '',
      str_file: new File([], ''),
      sip_file: new File([], ''),
      cv_file: new File([], ''),
      is_online: false,
      is_offline: false,
      profession: '',
      skills: '',
      user_id: getCurrentUser().id,
    },
    values: [],
  },
  reducers: {
    set_expertise_propose: (state, action) => {
      state.value = action.payload
    },
    set_all_expertise_propose: (state, action) => {
      state.values = action.payload
    },
  },
})

export const { set_expertise_propose, set_all_expertise_propose } = expertiseProposeSlicer.actions
export default expertiseProposeSlicer.reducer
