import { Rate } from "antd";
import { Cross, Edit, Save, Star, ThumbsUp } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useLocation } from "react-router";
import AddImage from "src/assets/images/AddImage.png";
import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { onQueryUserCount } from "src/config/services/core/user.api";
import { updateHealthCare, updateHealthCareDetail } from "src/config/services/fellas/health-care.api";
import { useExpertisePortfolio } from "src/config/store/expertise/portfolio";
import { cn, toAbsolutePath } from "src/lib/utils";
import { editValue } from "..";

function ExpertiseMainCard({ expertise, is_dashboard }) {
  const imageRef = useRef<HTMLInputElement>(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'
  const health_care_id = is_dashboard ? localStorage.getItem('health_care_id') : expertise.id
  const [view_profile, setViewProfile] = useState(false)

  const { on_edit, toggleEdit } = useExpertisePortfolio()
  const [counts, setCounts] = useState({ appointment: 0, patient: 0, history: 0 })
  const { mutate: updateCounts } = useMutation(async () => {
    return await onQueryUserCount({ params: { health_care_id } })
  }, {
    onSuccess: (count) => {
      setCounts(count)
    }
  })

  const updateProfileImg = async (e) => {
    if (e.target.files![0]) {
      const profile_img = e.target.files![0]
      const resp = await updateHealthCare({ id: expertise.id, profile_img })
      if (!resp.error) editValue(expertise, 'profile_img', resp.profile_img, 0)
    }
  }

  const updateMainForm = async () => {
    const { id, fullname, profession } = expertise.detail
    await updateHealthCareDetail({ id, fullname, profession })
    toggleEdit('main')
  }

  useEffect(() => {
    if (health_care_id !== 0 && page === 'main') {
      updateCounts()
    }
  }, [health_care_id]);

  return (
    <Card className="shadow">
      {view_profile && <div className="bg-black/50 h-screen w-screen fixed z-50 left-0 top-0 flex items-center justify-center" onClick={() => setViewProfile(false)}>
        <img src={toAbsolutePath(expertise.profile_img) || AddImage} className="w-60 aspect-square rounded-full" />
      </div>}
      <CardHeader>
        <div className="flex justify-between">
          <div className="grid grid-cols-3 max-md:grid-cols-1 gap-2 items-center w-full">
            <div className="flex lg:col-span-2 sm:col-span-3 items-center">
              <img className="w-20 aspect-square rounded-full bg-zinc-300 cursor-pointer hover:shadow-md active:shadow-inner" onClick={() => is_dashboard ? imageRef?.current?.click() : setViewProfile(true)} src={toAbsolutePath(expertise.profile_img) || AddImage} />
              <input className="hidden" ref={imageRef} type="file" accept="image/*" onChange={updateProfileImg} />
              <div className="px-3 space-y-1">
                <div className="flex gap-4">
                  {on_edit.main
                    ? <Input className="bg-transparent h-7 text-lg" value={expertise.detail?.fullname} onChange={e => editValue(expertise, 'fullname', e.target.value, 1)} />
                    : <div className="text-lg">{expertise.detail?.fullname}</div>}
                </div>
                <div className="text-md text-muted-foreground">{expertise.detail?.profession}</div>
              </div>
            </div>
            {is_dashboard
              ? <div className="lg:col-span-1 sm:col-span-3 grid grid-cols-3">
                <div className="grid gap-1 text-center">
                  <div className="text-primary text-3xl">{counts.appointment}</div>
                  <div className="text-md">Janji</div>
                </div>
                <div className="grid gap-1 text-center">
                  <div className="text-primary text-3xl">{counts.patient}</div>
                  <div className="text-md">Pasien</div>
                </div>
                <div className="grid gap-1 text-center">
                  <div className="text-primary text-3xl">{counts.history}</div>
                  <div className="text-md">Riwayat</div>
                </div>
              </div>
              : <div className="lg:col-span-1 sm:col-span-3 grid grid-cols-2 text-center">
                <div className="text-muted-foreground text-3xl flex flex-col items-center justify-end">
                  <div className="text-primary font-medium flex gap-1 items-center">{Math.round(expertise.rating*10)/10}<Star className={cn(expertise.rating > 0 && "fill-primary")} /></div>
                  <div className="flex items-center justify-center gap-1 text-sm"><ThumbsUp className="w-4" /> Rating</div>
                </div>
                <div className="text-muted-foreground text-3xl flex flex-col items-center justify-end">
                  <span><a className="text-primary font-medium">{expertise.total_rating}</a></span>
                  <div className="flex items-center justify-center gap-1 text-sm"><Cross className="w-4" /> Pasien</div>
                </div>
              </div>
            }
          </div>
          {is_dashboard && <div>
            {on_edit.main
              ? <Button size="sm" onClick={updateMainForm}><Save className="h-4" /> SIMPAN</Button>
              : <Edit className="self-start text-primary w-4 h-4 cursor-pointer" onClick={() => toggleEdit('main')} />}
          </div>}
        </div>
      </CardHeader>
    </Card >
  );
}

export default ExpertiseMainCard;