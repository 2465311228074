import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import AdminPage from "src/pages/Admin";
import Auth from "src/pages/Auth";
import HealthCare from "src/pages/HealthCare";
import HealthCareAppointment from "src/pages/HealthCare/appointment";
import MainPage from "src/pages/Main";
import { getCurrentUser, getJWT } from "../services/core/auth.api";
import ExpertisePage from "src/pages/Expertise";
import LisbanPage from "src/pages/Lisban";
import MedicalReportPreview from "src/pages/Expertise/tabs/history/medical-report/preview";

const autoRedirect = (component, target_path, state) => (
  state ? component : <Navigate replace to={target_path} />
)

const isLogged = Boolean(getJWT().key) && Boolean(getCurrentUser().username)
const user = getCurrentUser()

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: autoRedirect(<Auth />, '/app', !isLogged),
      },
      {
        path: 'app',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: autoRedirect(<MainPage />, '/', isLogged)
          },
          {
            path: 'admin',
            element: autoRedirect(<AdminPage />, '/', isLogged && user.is_staff),
          },
          {
            path: 'expertise',
            element: autoRedirect(<ExpertisePage />, '/', isLogged)
          },
          {
            path: 'medical-report/:reportId',
            element: autoRedirect(<MedicalReportPreview />, '/', isLogged),
          }
        ]
      },
      {
        path: 'health-care',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <HealthCare />,
          },
          {
            path: ':healthCareId',
            element: <HealthCareAppointment />,
          },
        ]
      },
      {
        path: 'lisban',
        element: <LisbanPage />
      },
    ]
  },
])

export default routes;