import Axios, { AxiosRequestConfig } from 'axios';
import { authHeader } from '../core/auth.api';

const API_URL = "/api/v1/nutrition/"

export const onGetNutritionURT = async (data: any) => {
  const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: API_URL + `urt-component/`,
      headers: authHeader(),
      data
  }
  try {
      const { data: response } = await Axios.request(requestConfig)
      return response
  }
  catch (err: any) {
      const errMessage = err.response.data
      return { error: errMessage }
  }
}