import { DatePicker, Input, Table } from "antd";
import dayjs from "dayjs";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import DialogWithdraw from "src/components/dialog/wallet/appointment/withdraw";
import { Button } from "src/components/ui/button";
import { set_open_payment_dialog, set_payment_data } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_withdraw_data } from "src/config/redux/slicer/expertise/expertiseWalletSlicer";
import { onQueryPayoutLink } from "src/config/services/fellas/payment.api";
import { columns } from "./columns";

function WithdrawWallet() {
  const dispatch = useDispatch()
  const health_care_id = localStorage.getItem('health_care_id') || 0
  const [query_filter, setQuerySearch] = useState({ start_date: '', end_date: '', filter: '' })
  const { withdraw_data } = useSelector((state: any) => state.ExpertiseWallet)
  const { dialog } = useSelector((state: any) => state.PaymentDialog)
  const { limit, offset, count } = withdraw_data
  const { filter, start_date, end_date } = query_filter
  const current = Math.round(offset / limit) + 1
  const params = { filter, start_date, end_date, limit, offset, health_care_id }

  const { mutate: updatePayoutLink, isLoading } = useMutation(async (is_verify: boolean) => {
    const payout = await onQueryPayoutLink({ params })
    return { payout, is_verify }
  }, {
    onSuccess: async ({ payout, is_verify }) => {
      const results = payout.results.map((item, i) => ({ ...item, key: i }))
      const verif_payout = is_verify
        ? await Promise.all(results.map(async (data, i) => {
          const update_payout = await onQueryPayoutLink({ params: { ...params, verify: data.payout_id } })
          return update_payout.results[0] || data
        }))
        : results

      const data = { ...payout, results: verif_payout, limit, offset }
      dispatch(set_withdraw_data(data))
    }
  })

  const handleDatePicker = (date) => {
    if (date) {
      setQuerySearch({
        ...query_filter,
        start_date: date[0].format('YYYY-MM-DD'),
        end_date: date[1].format('YYYY-MM-DD'),
      })
    } else {
      setQuerySearch({ ...query_filter, start_date: '', end_date: '' })
    }
  }

  const handleSubmitFilter = (e: React.FormEvent) => {
    e.preventDefault()
    updatePayoutLink(false)
  }

  const handleTableParams = (pagination) => {
    if (pagination) {
      const { pageSize: limit, current } = pagination
      const offset = (current - 1) * limit
      dispatch(set_withdraw_data({ ...withdraw_data, offset, limit }))
    }
  }

  const rowHandle = (data) => ({
    onClick: () => {
      dispatch(set_payment_data(data))
      dispatch(set_open_payment_dialog('withdraw'))
    }
  })

  useEffect(() => {
    updatePayoutLink(true)
  }, [offset]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-end">
        <form onSubmit={handleSubmitFilter} className="flex flex-wrap gap-2 items-end text-sm w-full">
          <DatePicker.RangePicker className="max-sm:w-full" maxDate={dayjs()} onChange={handleDatePicker} />
          <div className="flex gap-2 max-sm:w-full">
            <Input className="h-8" placeholder="Cari" onChange={e => setQuerySearch({ ...query_filter, filter: e.target.value })} />
            <Button className="h-8 px-1" size="icon" type="submit"><Search className="h-5" /></Button>
          </div>
        </form>
      </div>
      <Table
        loading={isLoading}
        dataSource={withdraw_data.results}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          current,
          total: count,
          pageSize: limit,
          hideOnSinglePage: true,
        }}
        onChange={handleTableParams}
        onRow={rowHandle}
      />
      {dialog.withdraw && <DialogWithdraw />}
    </div>
  );
}

export default WithdrawWallet;