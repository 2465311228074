import { Building2Icon, CalendarClockIcon, ClipboardListIcon, CrossIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoImage from "src/assets/images/NoImage.png";
import { Badge } from "src/components/ui/badge";
import { Card } from "src/components/ui/card";
import { Progress } from "src/components/ui/progress";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_open_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";
import { set_appointment_form, set_form_page, set_invoice } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { getUserChildInformation } from "src/config/services/core/user.api";
import { onCreateAppointment, onGetAppointment, onCreateCalendarEvent, onDeleteAppointment, onUpdateAppointment } from "src/config/services/fellas/appointment.api";
import { onPaymentCreateOnlineInvoice, onPaymentUpdateInvoice } from "src/config/services/fellas/payment.api";
import { cn, formatTime, toAbsolutePath } from "src/lib/utils";
import AppointmentFillDataForm from "./fill_data";
import AppointmentOrderReviewForm from "./order_review";
import AppointmentPaymentForm from "./payment";
import AppointmentServiceForm from "./service";
import AppointmentFormReview from "./review";

function AppointmentForm() {
  const dispatch = useDispatch()
  const user = getCurrentUser()
  const { toast } = useToast()
  const { appointment_form, form_page, service_form, user_form, invoice } = useSelector((state: any) => state.Appointment)
  const { selected_health_care, mh_form } = useSelector((state: any) => state.SearchQuery)
  const { show_md_sidebar } = useSelector((state: any) => state.MenuBar)
  const [loading, setLoading] = useState(true)
  const is_online = appointment_form.location === 'Online'
  const is_homecare = appointment_form.location === 'Homecare'
  const { date, time, starttime, endtime } = service_form

  const getPrice = () => {
    if (is_online) {
      if (appointment_form.mh_form_status) {
        return mh_form.price
      } else {
        return selected_health_care.prices?.online || 25000
      }
    } else if (is_homecare) {
      const selected_service = selected_health_care.prices?.homecare.find(homecare => homecare.service === appointment_form.homecare.service)
      return selected_service?.value || 100000
    } else {
      return selected_health_care.prices?.offline
    }
  }

  const updateFormState = () => {
    if (is_online) {
      dispatch(set_appointment_form({ ...appointment_form, payment_verification: false }))
    } else {
      dispatch(set_appointment_form({ ...appointment_form, payment_verification: true }))
    }
    if (starttime && endtime) {
      const start_datetime = new Date(`${date}T${starttime}:00.000`).toISOString()
      const end_datetime = new Date(`${date}T${endtime}:00.000`).toISOString()
      dispatch(set_appointment_form({ ...appointment_form, start_datetime, end_datetime }))
    }
  }


  const handlePayment = async () => {
    dispatch(set_open_payment_dialog('online_invoice'))
    if (!invoice.status) {
      const user_child = appointment_form.user_child_id && await getUserChildInformation({ query: appointment_form.user_child_id })
      const date = appointment_form.start_datetime.split('T')[0];
      const time_range = `${formatTime(appointment_form.start_datetime)} - ${formatTime(appointment_form.end_datetime)}`;
      const form = { ...appointment_form, status: 'PENDING', user, user_child, date, time_range }
      const resp_inv = await onPaymentCreateOnlineInvoice({ query: `?amount=${getPrice()}`, appointment_form: form })
      ToastAlert(resp_inv, 'Invoice berhasil dibuat', () => {
        dispatch(set_invoice(resp_inv))
      })
    } else {
      toast({
        variant: 'success',
        title: 'Invoice telah dibuat sebelumnya'
      })
    }
  }

  const submitForm = async () => {
    // check if online:
    if (is_online) {
      setLoading(true)
      const today_appointments = await onGetAppointment({
        query: `?user_id=${user.id}`
      })
      const is_exist = today_appointments.results.length > 0
      if (!is_exist) {
        console.log('No appointment today')
      } else {
        console.log('=Appointment today=')
      }
      let correct_appointment = null
      for (let appo of today_appointments.results) {
        let appoStarDate = new Date(appo.start_datetime)
        let appoEndDate = new Date(appo.end_datetime)
        let formStartDate = new Date(appointment_form.start_datetime)
        let formEndDate = new Date(appointment_form.end_datetime)

        if (+formStartDate === +appoStarDate && +formEndDate === +appoEndDate) {
          correct_appointment = appo
          break
        }
      }
      if (is_exist && correct_appointment) {
        setLoading(false)
        dispatch(set_appointment_form(correct_appointment))
        dispatch(set_form_page(4));
      }
      return
    }
    // This below code is not reachable for online because the recent update is handled in frontend
    dispatch(set_form_page(4));
    if (window.confirm('Apakah data pemesanan sudah sesuai?')) {
      const appointment = await onCreateAppointment({ ...appointment_form, amount: getPrice() })
      dispatch(set_appointment_form(appointment))
      await onPaymentUpdateInvoice({ id: invoice.id, appointment_id: appointment.id })
      if (selected_health_care.auto_accept) {
        await onUpdateAppointment({ id: appointment.id, status: 'Accepted' })
        if (is_online) {
          // generate meet link
          const meet = await onCreateCalendarEvent({
            attendees: selected_health_care.email,
            start: appointment_form.start_datetime.split('+')[0],
            end: appointment_form.end_datetime.split('+')[0],
            summary: `[Fellas] Janji Temu ${selected_health_care.detail.profession}`,
            description: `Pertemuan dengan ${selected_health_care.detail.fullname}`
          })
          ToastAlert(meet, 'Link Meeting berhasil dijadwalkan', async () => {
            const { hangoutLink } = meet.resp
            const update_resp = await onUpdateAppointment({ id: appointment.id, meet_link: hangoutLink })
            dispatch(set_appointment_form(update_resp))
          })
          if (meet.error) {
            dispatch(set_form_page(3));
            setLoading(true)
            await onDeleteAppointment({ id: appointment.id })
          }
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    updateFormState()
  }, [appointment_form.location, starttime, endtime]);

  return (
    <div>
      <div className={cn("container fixed w-full left-0 right-0 bg-white top-16 pt-8", !show_md_sidebar && "z-10")}>
        <Progress className="h-2" value={(form_page * 25) - 12.5} />
        <div className="grid grid-cols-4 sticky mt-[-15px]">
          <div className="self-center grid">
            <div
              className={cn("bg-primary text-white cursor-pointer hover:scale-125 hover:shadow-lg", "font-medium justify-self-center text-xs h-6 aspect-square rounded-full py-1")}
              onClick={() => form_page !== 4 && dispatch(set_form_page(1))}
            >1</div>
            <div className="font-medium max-md:hidden">Layanan</div>
          </div>
          <div className="self-center grid">
            <div
              className={cn(form_page < 2 ? "bg-gray-200 text-black" : "bg-primary text-white hover:scale-125 hover:shadow-lg cursor-pointer", "font-medium text-xs h-6 justify-self-center aspect-square rounded-full py-1")}
              onClick={() => form_page !== 4 && form_page > 2 && dispatch(set_form_page(2))}
            >2</div>
            <div className="font-medium max-md:hidden">Isi Data</div>
          </div>
          <div className="self-center grid">
            <div
              className={cn(form_page < 3 ? "bg-gray-200 text-black" : "bg-primary text-white hover:scale-125 hover:shadow-lg cursor-pointer", "font-medium text-xs h-6 justify-self-center aspect-square rounded-full py-1")}
              onClick={() => form_page !== 4 && form_page > 3 && dispatch(set_form_page(3))}
            >3</div>
            <div className="font-medium max-md:hidden">Pembayaran</div>
          </div>
          <div className="self-center grid">
            <div className={cn(form_page < 4 ? "bg-gray-200 text-black" : "bg-primary text-white hover:scale-125 hover:shadow-lg cursor-pointer", "font-medium text-xs h-6 justify-self-center aspect-square rounded-full py-1")}>4</div>
            <div className="font-medium max-md:hidden">Informasi Pemesanan</div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-8 pt-16 max-md:pt-8 pb-8 text-start">
        <div className={cn(form_page === 4 ? "lg:col-span-7 mx-[20vw] max-lg:mx-[5vw]" : "lg:col-span-5", "col-span-7")}>
          <div className="space-y-2">
            {form_page === 1 && <AppointmentServiceForm />}
            {form_page === 2 && <AppointmentFillDataForm handlePayment={handlePayment} />}
            {form_page === 3 && <AppointmentPaymentForm handlePayment={handlePayment} submitForm={submitForm} />}
            {form_page === 4 && <AppointmentOrderReviewForm loading={loading} />}
          </div>
        </div>
        {form_page !== 4 && <AppointmentFormReview />}
      </div>
      {/* {on_payment && <PaymentModal />} */}
    </div>
  );
}

export default AppointmentForm;