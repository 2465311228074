import { createSlice } from '@reduxjs/toolkit'

export const lisbanAppSlicer = createSlice({
  name: 'lisbanAppSlicer',
  initialState: {
    selected_child: {
      first_name: '',
      last_name: '',
      weight: 0,
      height: 0,
      birth_date: '',
    },
    vaccine: {
      age_in_month: 0,
      mandatory: [],
      booster: []
    },
    vaccine_progress: false

  },
  reducers: {
    set_selected_child: (state, action) => {
      state.selected_child = action.payload
    },
    set_vaccine: (state, action) => {
      state.vaccine = action.payload
    },
    set_vaccine_progress: (state, action) => {
      state.vaccine_progress = action.payload
    },
  },
})

export const { set_selected_child, set_vaccine, set_vaccine_progress } = lisbanAppSlicer.actions
export default lisbanAppSlicer.reducer
