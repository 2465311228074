import { Menu } from "antd";
import { MenuIcon } from "lucide-react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { set_show_md_sidebar } from "src/config/redux/slicer/main/menuBarSlicer";
import { useMenuBarStore } from "src/config/store/global/menubar";

function MDSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'

  const handlePageChange = (key) => {
    queryParams.set('page', key);
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const { header_menu, show_sidebar, navbar_menu, toggle_show_sidebar } = useMenuBarStore()

  return (
    <div className="lg:hidden fixed top-4 left-4">
      <MenuIcon
        className="text-zinc-400 hover:bg-zinc-100 rounded-lg cursor-pointer p-1 w-8 h-8"
        onClick={toggle_show_sidebar}
      />
      {show_sidebar && (
        <div>
          <div className="fixed w-screen h-screen bg-white/20" onClick={toggle_show_sidebar} />
          <div className="fixed left-0 h-screen w-56 bg-white mt-4 shadow-lg">
            <Menu mode="inline" items={header_menu} />
            <hr className="mx-1 rounded-full border-accent" />
            <Menu mode="inline" items={navbar_menu} selectedKeys={[page]} onClick={({ key }) => handlePageChange(key)} />
          </div>
        </div>
      )}
    </div>
  );
}

export default MDSidebar;