import { useDispatch } from "react-redux";
import { Card, CardHeader } from "../ui/card";
import { cn } from "src/lib/utils";
import { useLocation, useNavigate } from "react-router";

type NavbarType = {
  navbar_menu: any
  setNavbar: any
  selected_navbar: string
  className?: string
}
function Navbar({ navbar_menu, setNavbar, selected_navbar, className }: NavbarType) {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Card className={cn("text-start shadow-md fixed w-1/5 top-20 left-10", className)}>
      <CardHeader className="gap-1">
        {navbar_menu.map(m => (
          <div
            key={m.key}
            onClick={() => { navigate(location.pathname + `?page=${m.key}`) }}
            className={`cursor-pointer font-medium
                        ${m.key !== selected_navbar ? 'hover:text-zinc-500' : ''}
                        ${m.key === selected_navbar ? 'text-primary' : 'text-zinc-900'}`}>
            {m.text || m.label}
          </div>
        ))}
      </CardHeader>
    </Card>
  );
}

export default Navbar;