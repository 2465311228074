import { InfoIcon } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "src/components/ui/card";
import { Skeleton } from "src/components/ui/skeleton";
import { cn } from "src/lib/utils";

function AppointmentOrderReviewForm({ loading }) {
  const navigate = useNavigate()
  const { appointment_form, service_form, user_form } = useSelector((state: any) => state.Appointment)
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const is_online = appointment_form.location === 'Online'
  const homecare_address = appointment_form.homecare?.user_location?.full_address
  const offline_address = selected_health_care.offline_location?.full_address
  const { date, time, starttime, endtime } = service_form
  const selected_user = user_form.find(item => (!item.username && appointment_form.user_child_id === item.id) || (item.username && !appointment_form.user_child_id))

  return (
    <div className="space-y-2">
      <div className="space-y-1 mt-4">
        <Skeleton loading={loading} className="font-semibold text-2xl">Terima kasih telah memesan layanan FELLAS</Skeleton>
        <Skeleton loading={loading} className="font-medium">berikut ringkasan pesanan anda</Skeleton>
        <Card>
          <CardHeader>
            <div className="flex items-center gap-3">
              <Skeleton loading={loading}><InfoIcon className="w-5 h-5" /></Skeleton>
              <Skeleton loading={loading}> Informasi</Skeleton>
            </div>
          </CardHeader>
          <div className="mx-14 pb-6 grid gap-2">
            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-8 gap-y-4">
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>No. Registrasi</p><p>{appointment_form.registration_num}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Status Pembayaran</p><p>{appointment_form.location === "Online" ? "Lunas" : "Bayar Ditempat"}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>{selected_health_care.detail.profession}</p><p>{selected_health_care.detail.fullname}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Pasien</p><p>{selected_user && selected_user.first_name} {selected_user && selected_user.last_name}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Tanggal</p><p>{appointment_form.date}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Waktu</p><p>{starttime} - {endtime}</p></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Jenis Pertemuan</p><div><p>{appointment_form.location === `Online` ? `Online` : `${appointment_form.location}`}</p><p className="text-xs text-gray-400">{
                is_online ? appointment_form.meet_link
                  : appointment_form.location === 'Homecare' ? homecare_address
                    : offline_address}
              </p></div></Skeleton>
              <Skeleton loading={loading}><p className={cn("text-sm", !loading && "text-gray-400")}>Keluhan</p><div className="flex gap-4">{!loading && appointment_form.condition.split(',').map((item, i) => <Badge key={i}>{item}</Badge>)}</div></Skeleton>
            </div>
          </div>
        </Card>
      </div>

      {!loading
        ? <Button className="w-full" size="sm" onClick={() => { navigate('/app?page=appointment') }}>Dashboard</Button>
        : <Skeleton loading={loading} className="h-10" />
      }
    </div>
  );
}

export default AppointmentOrderReviewForm;