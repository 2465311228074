import { Pagination, Rate, Tag } from "antd";
import { Building2, CheckCircle, Filter, Home, MapPin, Navigation, ThumbsUpIcon, Video, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NoImage from "src/assets/images/NoImage.png";
import DialogLocationUser from "src/components/dialog/location/user";
import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "src/components/ui/card";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { ScrollArea } from "src/components/ui/scroll-area";
import { PROFESSION_OPTIONS } from "src/config/const/profession";
import { set_gender_query, set_health_care_query, set_location_query, set_profession_query, set_search_query, set_service_query } from "src/config/redux/slicer/health-care/searchQuerySlicer";
import { USER_LOCAL_STORAGE_LOCATION } from "src/config/services/fellas/google.api";
import { getHealthCareDataByQuery } from "src/config/services/fellas/health-care.api";
import { encode_ } from "src/lib/hash";
import { cn, formatDistanceUnit, formatHomecarePrice, toAbsolutePath } from "src/lib/utils";

function HealthCareQuery() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const query_filter = queryParams.get('filter') || ''
  const query_profession = queryParams.get('profession') || 'all'
  const query_location = queryParams.get('location') || 'all'
  const query_gender = queryParams.get('gender') || 'all'
  const query_service = queryParams.get('service') || 'all'
  const { city, province, coords } = USER_LOCAL_STORAGE_LOCATION
  const [is_open_filter, setIsOpenFilter] = useState(false)
  const [page, setPage] = useState(1)


  const { query, filtered_data, field, profession, location, gender, service } = useSelector((state: any) => state.SearchQuery)
  // console.log((filtered_data?.results || []).map(({ detail, is_homecare_range, id }) => ({ id, fullname: detail.fullname })))
  const profession_options = [
    { key: 'all', text: 'Semua' },
    ...PROFESSION_OPTIONS
  ]
  const location_options = [
    { key: 'all', text: 'Semua' },
    { key: 'closer', text: 'Sekitar Anda' },
  ]
  const gender_options = [
    { key: 'all', text: 'Semua' },
    { key: 'laki-laki', text: 'Laki-Laki' },
    { key: 'perempuan', text: 'Perempuan' },
  ]
  const service_options = [
    { key: 'all', text: 'Semua' },
    { key: 'online', text: 'Online' },
    { key: 'offline', text: 'Offline' },
    { key: 'homecare', text: 'Homecare' },
  ]
  const coords_ = coords ? `${coords.lat},${coords.lng}` : undefined
  const limit = 5
  const { mutate: filterHealthCareQuery } = useMutation(async () => {
    const data = await getHealthCareDataByQuery({
      params: {
        coords: coords_,
        filter: query_filter,
        is_open_service: true,
        profession,
        gender,
        service,
        limit,
        offset: (page - 1) * limit
      }
    })
    return data
  }, {
    onSuccess: (data: any) => {
      dispatch(set_health_care_query(data))
    }
  })

  useEffect(() => {
    setPage(1)
    if (query_profession !== profession) dispatch(set_profession_query(query_profession))
    if (query_location !== location) dispatch(set_location_query(query_location))
    if (query_gender !== gender) dispatch(set_gender_query(query_gender))
    if (query_service !== service) dispatch(set_service_query(query_service))
    dispatch(set_search_query(`?filter=${query_filter}&profession=${query_profession}&location=${query_location}&gender=${query_gender}&service=${query_service}`))
  }, [query_profession, query_location, query_gender, query_service]);

  useEffect(() => {
    filterHealthCareQuery()
  }, [profession, location, gender, service, coords, page]);

  const FilterCard = ({ className = '' }) => (
    <Card className={cn("grid grid-cols-2 gap-y-4 max-lg:grid-cols-1 text-sm p-2", className)}>
      {/* filter by profession */}
      <div className="space-y-1">
        <b className="p-1">Pilih Keahlian</b>
        <hr />
        <div className="text-start mt-3 pl-2">
          <RadioGroup value={profession} onValueChange={val => { navigate(`?filter=${query_filter}&profession=${val}&location=${query_location}&gender=${query_gender}&service=${query_service}`) }}>
            {profession_options.map(p => (
              <div key={p.text} className="flex items-center space-x-2">
                <RadioGroupItem value={p.key} id={p.key} />
                <label>{p.text}</label>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
      {/* filter by service */}
      <div className="space-y-1">
        <b className="p-1">Tipe Layanan</b>
        <hr />
        <div className="text-start mt-3 pl-2">
          <RadioGroup value={service} onValueChange={val => { navigate(`?filter=${query_filter}&profession=${query_profession}&location=${query_location}&gender=${query_gender}&service=${val}`) }}>
            {service_options.map(p => (
              <div key={p.text} className="flex items-center space-x-2">
                <RadioGroupItem value={p.key} id={p.key} />
                <label>{p.text}</label>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
      {/* filter by location */}
      <div className="space-y-1">
        <b className="p-1">Lokasi Layanan</b>
        <hr />
        <div className="text-start mt-3 pl-2">
          <RadioGroup value={location} onValueChange={val => { navigate(`?filter=${query_filter}&profession=${query_profession}&location=${val}&gender=${query_gender}&service=${query_service}`) }}>
            {location_options.map(p => (
              <div key={p.text} className="flex items-center space-x-2">
                <RadioGroupItem value={p.key} id={p.key} />
                <label>{p.text}</label>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
      {/* filter by gender */}
      <div className="space-y-1">
        <b className="p-1">Jenis Kelamin</b>
        <hr />
        <div className="text-start mt-3 pl-2">
          <RadioGroup value={gender} onValueChange={val => { navigate(`?filter=${query_filter}&profession=${query_profession}&location=${query_location}&gender=${val}&service=${query_service}`) }}>
            {gender_options.map(p => (
              <div key={p.text} className="flex items-center space-x-2">
                <RadioGroupItem value={p.key} id={p.key} />
                <label>{p.text}</label>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
    </Card>
  )

  return (
    <div className="md:ml-6 max-md:p-4 md:py-6 grid grid-cols-4 gap-4 pr-4 bg-white">
      {/* MD Filter Bubble */}
      {is_open_filter ? (
        <div className="bg-white fixed bottom-5 right-5 h-96 w-80 max-md:w-9/10 rounded-md shadow text-sm z-40 md:hidden">
          <div className="w-80 fixed p-4 bg-primary rounded-t-md text-left text-white flex justify-between">
            <div className="leading-4">
              <b>Filter Tenaga Ahli</b>
            </div>
            <X className="cursor-pointer h-5 m-[-6px]" onClick={() => setIsOpenFilter(false)} />
          </div>
          <ScrollArea className="top-10 h-[344px] rounded-b-md">
            <FilterCard className="rounded-t-none border-none" />
          </ScrollArea>
        </div>
      ) : (
        <div onClick={() => setIsOpenFilter(true)} className="bg-white fixed bottom-5 right-20 p-3 rounded-full cursor-pointer shadow shadow-secondary/80 active:shadow-inner md:hidden">
          <Filter className="stroke-secondary" />
        </div>)
      }
      {/* Filter Bubble */}
      <div className="max-md:col-span-4 space-y-2">
        <Dialog>
          <DialogTrigger className="w-full">
            <div className="flex gap-2 items-center cursor-pointer hover:text-primary">
              <MapPin className="text-primary" />
              <p>{city}</p>
            </div>
          </DialogTrigger>
          <DialogLocationUser />
        </Dialog>
        <FilterCard className="max-md:hidden" />
      </div>
      {/* Filtered Data */}
      <div className="col-span-3 max-md:col-span-4 max-md:w-full text-start">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-semibold mb-2">Cari Jadwal Layanan</div>
          <Pagination total={filtered_data.count} pageSize={limit} current={page} hideOnSinglePage onChange={(page) => setPage(page)} />
        </div>
        <div className="max-h-[57vh] space-y-2 overflow-auto border-b rounded-b-md">
          {(filtered_data.results || []).length > 0 ? filtered_data.results.map(data => (
            <Card key={data.id} className="shadow">
              <CardHeader className="max-md:p-4">
                <div className="flex justify-between">
                  <div className="flex gap-4 items-start w-full cursor-pointer" onClick={() => navigate(`/health-care/${encode_(data.id.toString())}`)}>
                    <img className="bg-gray-200 w-28 max-md:w-14 rounded-full aspect-square" src={toAbsolutePath(data.profile_img) || NoImage} />
                    <div className="space-y-2 text-sm w-full">
                      <div>
                        <div className="text-lg font-medium">{data.detail.fullname}</div>
                        <div className="text-md text-muted-foreground flex gap-2 items-center">
                          {data.detail.profession}
                          {data.is_mh_checkup && <Tag className="flex gap-1 items-center"><CheckCircle className="text-success h-3 w-3" />Mental Health Checkup</Tag>}
                          {data.is_burnout_checkup && <Tag className="flex gap-1 items-center"><CheckCircle className="text-success h-3 w-3" />Burnout Checkup</Tag>}
                        </div>
                      </div>
                      <div className="grid grid-cols-3 text-[12px] gap-1 max-lg:grid-cols-1">
                        {(data.detail.is_offline && data.prices?.offline && typeof data.distance?.offline === 'number') && (
                          <div className="space-y-1">
                            <div className="flex gap-2">
                              <Building2 className="mt-1 h-4 w-4 text-primary" />
                              <div className="text-muted-foreground">
                                <div>{data.offline_location.label}</div>
                                <span><a className="text-primary">Rp {data.prices?.offline.toLocaleString('id')}</a> (bayar langsung)</span>
                              </div>
                            </div>
                            {data.distance && data.distance.offline && <div className="flex gap-2 items-center text-secondary">
                              <Navigation className="h-4 w-4" />
                              {formatDistanceUnit(data.distance.offline)}
                            </div>}
                          </div>
                        )}
                        {(data.detail.is_offline && data.prices?.offline) && <hr className="lg:hidden mr-[2vw]" />}
                        {(data.detail.is_homecare && data.prices?.homecare && typeof data.distance?.homecare === 'number') && (
                          <div className="space-y-1">
                            <div className="flex gap-2">
                              <Home className="mt-1 h-4 w-4 text-primary" />
                              <div className="text-muted-foreground">
                                <div>Homecare</div>
                                {data.prices?.homecare && <span><a className="text-primary">Rp {formatHomecarePrice(data.prices?.homecare)}</a> (bayar langsung)</span>}
                              </div>
                            </div>
                            {(typeof data.distance?.homecare == 'number') &&
                              <div className={cn("flex gap-2 items-center", !data.is_homecare_range ? "text-destructive line-through" : "text-secondary")}>
                                <Navigation className="h-4 w-4" />
                                {formatDistanceUnit(data.distance?.homecare || 0)}
                              </div>}
                            {!data.is_homecare_range && <p className="text-destructive text-xs ml-6">{data.distance ? `* diluar jangkauan homecare` : `* tentukan lokasi terlebih dahulu`}</p>}
                          </div>
                        )}
                        {(data.detail.is_homecare && data.prices?.homecare) && <hr className="lg:hidden mr-[2vw]" />}
                        {(data.detail.is_online && data.prices?.online) && (
                          <div className="flex gap-2">
                            <Video className="mt-1 h-4 w-4 text-primary" />
                            <div className="text-muted-foreground">
                              <div>Tersedia Online</div>
                              <span><a className="text-primary">Rp {data.prices?.online.toLocaleString('id')}</a> / {data.prices?.online_duration || 15} Menit</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between items-center w-60">
                    <div className="text-muted-foreground text-2xl flex flex-col items-center">
                      <div className="text-primary font-semibold flex gap-2 items-center">
                        <p className="flex gap-2 items-center"><Rate value={data.rating} className="text-primary text-sm" disabled /> {Math.round(data.rating * 10) / 10}</p>
                        <p className="text-muted-foreground font-medium">({data.total_rating})</p>
                      </div>
                      <div className="flex items-center justify-center gap-1 text-sm"><ThumbsUpIcon className="w-4" /> Rating</div>
                    </div>
                    <Link to={`/health-care/${encode_(data.id.toString())}`} >
                      <Button className="mt-4 w-24">Buat Janji</Button>
                    </Link>
                  </div>
                </div>
              </CardHeader>
            </Card>
          )) : <div className="flex justify-center p-8 text-4xl font-bold text-gray-200">Not Found</div>
          }

        </div>
      </div>
    </div >
  );
}

export default HealthCareQuery;