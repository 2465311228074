import { createSlice } from '@reduxjs/toolkit'

export const adminWalletSlicer = createSlice({
  name: 'adminWalletSlicer',
  initialState: {
    transaction_data: { limit: 5, offset: 0, query: '', count: 0, next: null, previous: null, results: [] },
    all_transaction_data: { query: '', count: 0, next: null, previous: null, results: [] },
    all_bill_payment_data: { query: '', count: 0, next: null, previous: null, results: [] },
  },
  reducers: {
    set_transaction_data: (state, action) => {
      state.transaction_data = action.payload
    },
    set_all_transaction_data: (state, action) => {
      state.all_transaction_data = action.payload
    },
    set_all_bill_payment_data: (state, action) => {
      state.all_bill_payment_data = action.payload
    },
  },
})

export const {
  set_transaction_data,
  set_all_transaction_data,
  set_all_bill_payment_data
} = adminWalletSlicer.actions
export default adminWalletSlicer.reducer
