import { useState } from "react";
import { Card } from "src/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import BabyFormula from "./baby_formula";
import MotherBreastfeedingCalories from "./motherbreast_feeding_calories";

function NutritionCalculator() {
  const [selected_calculator, setSelectedCalculator] = useState('baby_formula')
  const calculator_options = [
    { key: 'baby_formula', name: 'Kalkulator Formula Bayi' },
    { key: 'mother_breastfeeding_calories', name: 'Kalkulator Kalori Menyusui' },
  ]
  const selected_calculator_name = (calculator_options.find(item => item.key === selected_calculator) || { name: 'wrong key' }).name
  return (
    <div className="space-y-2">
      <p className="text-xl text-center text-primary font-semibold">Calculator Nutrition</p>
      <hr className="pb-4" />
      <div className="flex max-md:flex-col gap-2 md:items-center">
        <p>Silahkan pilih kalkulator anda</p>
        <div className="w-1/3 max-md:w-full">
          <Select value={selected_calculator} onValueChange={val => setSelectedCalculator(val)}>
            <SelectTrigger><SelectValue>{selected_calculator_name}</SelectValue></SelectTrigger>
            <SelectContent>
              {calculator_options.map(option => (
                <SelectItem key={option.key} value={option.key}>{option.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <Card className="p-6 shadow">
        {selected_calculator === 'baby_formula'
          ? <BabyFormula />
          : <MotherBreastfeedingCalories />}
      </Card>

    </div>
  );
}

export default NutritionCalculator;