import { AdvancedMarker, Map, Marker, Pin, useAutocomplete } from "@vis.gl/react-google-maps";
import { LocateFixed } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_appointment_form } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { USER_LOCAL_STORAGE_LOCATION, getAddressLabel } from "src/config/services/fellas/google.api";
import { calculateDistance, getCurrentUserLocation, getDragLocation } from "src/lib/utils";

interface DialogLocationUserType {
  is_appointment_form?: boolean
}
export const FellasPin = () => <Pin background={'#FF42B3'} glyphColor={'#FFFFFF'} borderColor={'#FF42B3'} />

function DialogLocationUser({ is_appointment_form }: DialogLocationUserType) {
  const { toast } = useToast()
  const dispatch = useDispatch()
  const [{ city, province, address, coords, full_address }, setUserLocation] = useState(USER_LOCAL_STORAGE_LOCATION)
  const { appointment_form } = useSelector((state: any) => state.Appointment)
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const [autoCompleteValue, setAutoCompleteValue] = useState('')
  const autoCompleteRef = useRef<any>(null);
  const { REACT_APP_GOOGLE_MAP_ID } = process.env
  // console.log(coords)
  // const [lat, lng] = coords ? coords.split(',').map(Number) : [0, 0]
  // const center = { lat, lng }

  useAutocomplete({
    inputField: autoCompleteRef && autoCompleteRef.current,
    onPlaceChanged: (place) => {
      const { address_components: address, formatted_address: full_address, geometry } = place
      const coords = { lat: geometry.location.lat(), lng: geometry.location.lng() }
      const province = getAddressLabel(address, 'administrative_area_level_1')
      const city = getAddressLabel(address, 'administrative_area_level_2')
      setUserLocation({ city, province, address, full_address, coords })
    }
  })

  const updateUserLocationForm = async () => {
    localStorage.setItem('user_location', JSON.stringify({ city, province, address, full_address, coords }))
    ToastAlert({}, 'Lokasi Berhasil di update')
    !is_appointment_form && window.location.reload()
  }

  const onDragUserLocation = async (location) => {
    const { city, province, address, full_address, coords } = await getDragLocation(location)
    setUserLocation({ city, province, address, coords, full_address, })
  }

  useEffect(() => {
    if (!address) {
      getCurrentUserLocation().then(res => window.location.reload())
    }
    if (is_appointment_form) {
      const homecare_coords = selected_health_care.homecare_area.coords
      const homecare = appointment_form.homecare
      const distance = calculateDistance(homecare_coords, coords)
      const new_appointment_form = { ...appointment_form, homecare: { ...homecare, user_location: { address, coords, full_address, distance } } }
      dispatch(set_appointment_form(new_appointment_form))
      const is_homecare_range = appointment_form.homecare?.user_location?.distance <= selected_health_care.homecare_area.radius
      if (!is_homecare_range) {
        toast({
          variant: 'destructive',
          title: `[Out of Range] Something when wrong!`,
          description: `Titik diluar jangkauan homecare`
        })
      }
    }
  }, [address]);

  return (
    <DialogContent className="sm:max-w-[425px] lg:min-w-[500px] bg-white">
      <DialogHeader>
        <DialogTitle className="text-center">Tentukan Lokasi Anda</DialogTitle>
      </DialogHeader>
      <div className="space-y-2 text-sm">
        <div className="h-48 rounded-sm">
          <Map
            center={coords}
            zoom={16}
            mapId={REACT_APP_GOOGLE_MAP_ID}
          >
            {coords &&
              <AdvancedMarker draggable position={coords} onDragEnd={onDragUserLocation}>
                <FellasPin />
              </AdvancedMarker>}
          </Map>
        </div>
        <div className="flex gap-2 items-end">
          <div className="w-full">
            <p>Cari alamat lokasi anda</p>
            <Input ref={autoCompleteRef} placeholder={full_address} value={autoCompleteValue} onChange={e => setAutoCompleteValue(e.target.value)} />
          </div>
          {/* <Button className="aspect-square" size="icon"><Search /></Button> */}
        </div>
        <div className="flex justify-between gap-2 items-center">
          <div className="w-3/4 flex gap-2 items-center">
            <LocateFixed
              className="text-primary cursor-pointer hover:animate-pulse"
              onClick={() => getCurrentUserLocation().then(user_location => setUserLocation(user_location))}
            />
            <div>
              <p>{city}, {province}</p>
              <p className="text-xs text-gray-400">{getAddressLabel(address || [], 'route')}</p>
            </div>
          </div>
          {!is_appointment_form && <Button onClick={updateUserLocationForm}>Terapkan</Button>}
        </div>
      </div>
    </DialogContent >
  );
}

export default DialogLocationUser;