import { set_expertise_data } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { store } from "src/config/redux/store";
import { cn } from "src/lib/utils";
import ExpertiseExperienceCard from "./cards/experience";
import ExpertiseMainCard from "./cards/main";
import ExpertiseProfileCard from "./cards/profile";
import ExpertiseScheduleCard from "./cards/schedule";
import { useExpertisePortfolio } from "src/config/store/expertise/portfolio";
import { useBlocker } from "react-router-dom";
import { Alert } from "antd";


type DashboardExpertiseType = {
  expertise: any,
  is_dashboard?: boolean,
  className?: string,
}

function DashboadExpertise({ expertise, is_dashboard, className = '' }: DashboardExpertiseType) {
  const { on_edit } = useExpertisePortfolio()
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      Object.values(on_edit).some(value => value) &&
      (
        currentLocation.search !== nextLocation.search ||
        currentLocation.pathname !== nextLocation.pathname
      )
  );

  

  return (
    <div className={cn("container space-y-2 text-start", className)}>
      {blocker.state === 'blocked' && (
        <Alert
          type="error"
          banner closable
          onClose={() => blocker.reset?.()}
          message="Simpan data terlebih dahulu"
          className="fixed left-0 top-16 z-50 w-screen"
        />
      )}
      <ExpertiseMainCard expertise={expertise} is_dashboard={is_dashboard} />
      <ExpertiseProfileCard expertise={expertise} is_dashboard={is_dashboard} />
      <ExpertiseExperienceCard expertise={expertise} is_dashboard={is_dashboard} />
      <ExpertiseScheduleCard expertise={expertise} is_dashboard={is_dashboard} />
    </div>
  );
}

export const editValue = (expertise, field, value, depth = 0) => {
  let new_expertise = { ...expertise }
  if (depth) {
    const detail = { ...expertise.detail }
    detail[field] = value
    new_expertise.detail = detail
  } else new_expertise[field] = value
  store.dispatch(set_expertise_data(new_expertise))
}

export default DashboadExpertise