import { createSlice } from '@reduxjs/toolkit'

export const appointmentDialogSlicer = createSlice({
  name: 'appointmentDialogSlicer',
  initialState: {
    dialog: {
      report: false,
      homecare_location: false,
      appointment_location: false,
    },
    selected_appointment: {
      id: 0,
      user: {},
      user_child: {},
      health_care: {},
      meet_link: '',
      start_datetime: '',
      end_datetime: '',
      date: '',
      time_range: '',
      status: '',
      location: '',
      condition: '',
      report: '',
      review: {},
    }
  },
  reducers: {
    set_open_appointment_dialog: (state, action) => {
      state.dialog[action.payload] = true
    },
    set_close_appointment_dialog: (state, action) => {
      state.dialog[action.payload] = false
    },
    set_selected_appointment: (state, action) => {
      state.selected_appointment = action.payload
    },
  },
})

export const { set_open_appointment_dialog, set_close_appointment_dialog, set_selected_appointment } = appointmentDialogSlicer.actions
export default appointmentDialogSlicer.reducer
