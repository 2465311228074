import { Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogLocationRoute from "src/components/dialog/location/route";
import DialogReport from "src/components/dialog/report";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import { set_current_appointment, set_expertise_data } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { onQueryAppointment, readAppointmentHomecare, readAppointmentOffline, readAppointmentOnline } from "src/config/services/fellas/appointment.api";
import { updateHealthCare } from "src/config/services/fellas/health-care.api";
import { AppointmentType } from "src/lib/types";
import { columns, homecare_columns, psychology_columns } from "./columns";
import { useMutation } from "react-query";

export type PaginationAppointmentType = {
  count: number,
  next?: string
  previous?: string,
  results: AppointmentType[]
}

export default function AppointmentExpertise() {
  const dispatch = useDispatch()
  const [selected_table, setSelectedTable] = useState('Online')
  const health_care_id = localStorage.getItem('health_care_id') || 0
  const { current_appointment } = useSelector((state: any) => state.Expertise)
  const expertise = useSelector((state: any) => state.Expertise.value)
  const { dialog } = useSelector((state: any) => state.AppointmentDialog)
  const column = (
    selected_table === 'Homecare' ? homecare_columns
      : expertise.detail?.profession === 'Psikologi' ? psychology_columns
        : columns
  )
  const { limit, offset, count } = current_appointment
  const current = Math.round(offset / limit) + 1

  const { mutate: readAppointment, isLoading } = useMutation(async () => {
    const data = await onQueryAppointment({
      params: {
        limit: limit || 6,
        offset: offset || 0,
        health_care_id,
        status: 'New Entry,Accepted,Cancelled,On Going',
        location: ['Homecare', 'Online'].includes(selected_table) ? selected_table : 'Offline'
      },
      hide_page_loading: true
    })
    return data
  }, {
    onSuccess: data => {
      const results = data.results.map((item, i) => ({ ...item, key: i }))
      const updated_data = ({ ...data, results, limit, offset })
      dispatch(set_current_appointment(updated_data))
    }
  })

  const handleTableParams = (pagination) => {
    if (pagination) {
      const { pageSize: limit, current } = pagination
      const offset = (current - 1) * limit
      dispatch(set_current_appointment({ ...current_appointment, offset, limit }))
    }
  }

  useEffect(() => {
    readAppointment()
  }, [selected_table, limit, offset, health_care_id]);

  const onChangeAutoAccept = async (val: Boolean) => {
    const resp = await updateHealthCare({ id: expertise.id, auto_accept: val })
    dispatch(set_expertise_data(resp))
  }

  return (
    <div className="container space-y-4">
      {dialog.report && <DialogReport is_online={selected_table === 'Online'} is_appointment />}
      {dialog.homecare_location && <DialogLocationRoute />}
      <div className="flex max-md:flex-col gap-2 justify-between">
        <div className="w-1/3 max-md:w-full">
          <Select onValueChange={val => setSelectedTable(val)}>
            <SelectTrigger><SelectValue>{selected_table}</SelectValue></SelectTrigger>
            <SelectContent>
              {['Online', expertise.offline_location?.label || 'Offline', 'Homecare'].map(item => (
                <SelectItem key={item} value={item}>{item}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex max-md:flex-row-reverse items-center gap-2">
          <label className="max-md:text-md">Terima pertemuan secara otomatis</label>
          <Switch checked={expertise.auto_accept} onCheckedChange={onChangeAutoAccept} />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={current_appointment.results}
        columns={column}
        scroll={{ x: 900 }}
        pagination={{
          current,
          total: count,
          pageSize: limit,
          hideOnSinglePage: true,
        }}
        onChange={handleTableParams}
      />
    </div>
  );
}
