import { X } from 'lucide-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import login_img from "src/assets/images/LoginPage.png";
import Header from 'src/components/header';
import { Button } from 'src/components/ui/button';
import { Card } from 'src/components/ui/card';
import { ToastAlert } from 'src/components/ui/toaster';
import { useToast } from 'src/components/ui/use-toast';
import { onCheckEmailVerification } from 'src/config/services/core/user.api';
import ForgotPassword from './ForgotPassword';
import LoginForm from "./Login";
import RegisterForm from "./Register";
import SetPassword from './SetPassword';
import ReactGA from 'react-ga4';


export const setAuthPage = (state) => () => window.location.assign(`/?page=${state}`)
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function Auth() {
  const location = useLocation()
  const { toast } = useToast()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'login'
  const token = queryParams.get("token")

  const submitVerification = async () => {
    const resp = await onCheckEmailVerification({ token })
    ToastAlert(resp, 'Email berhasil diverifikasi silahkan login')
    setTimeout(setAuthPage('login'), 1000)
  }
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Authentication" });
  }, []);
  return (
    <div className='h-screen'>
      <Header is_login={false} />
      <div className='flex flex-row max-md:flex-col'>
        <div className='w-2/3 max-md:w-full'>
          <div className='flex justify-between gap-20 px-10'>
            <div className='pt-[40vh] max-md:pt-[20vh] text-start'>
              <div className='text-4xl mb-10 font-bold'>Welcome to <p className='text-primary'>FELLAS</p></div>
            </div>
            <img src={login_img} className='pt-28 h-screen max-md:hidden' />
          </div>
        </div>
        <div className='w-1/3 max-md:w-full max-md:py-[10vh]'>
          {page === 'register' ? <RegisterForm /> : <LoginForm />}
        </div>
      </div>
      {(token && page === 'login') && (
        <div className="fixed top-0 left-0 z-10 h-screen w-screen bg-muted/60 ">
          <Card className="w-[40vw] mx-auto my-[20vh] p-6 space-y-2 shadow-lg text-center">
            <div className='flex justify-between'>
              <div className='text-xl font-semibold'></div>
              <X className='stroke-destructive cursor-pointer' onClick={setAuthPage('login')} />
            </div>
            <p className='text-sm text-muted-foreground'>Klik tombol dibawah untuk verifikasi akun mu</p>
            <Button variant="outline" className='w-1/4' onClick={submitVerification}>Verifikasi</Button>
          </Card>
        </div>
      )}
      {page === 'set_password' && <SetPassword />}
      {page === 'forgot_password' && <ForgotPassword />}
    </div>
  );
}

export default Auth;