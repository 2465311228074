import { Button, Checkbox, Input, InputNumber, Popover, Radio, Table, TableProps, message } from "antd"
import { BadgePlus, BeanOff, CalendarClock, CalendarDays, ChevronDown, Dumbbell, FileText, Info, Monitor, Pill, Plus, Ruler, Trash, User, UserCheck } from "lucide-react"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import APIRequest, { API_FELLAS } from "src/config/services/api"
import { onQueryAppointment, onUpdateAppointment } from "src/config/services/fellas/appointment.api"
import { getMedicalReportStore } from "src/config/store/expertise/medical-report"
import { decode_ } from "src/lib/hash"
import { AppointmentType, MedicalReportPrescriptionType } from "src/lib/types"
import { cn, renderPrescription } from "src/lib/utils"

export default function MedicalReportPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const mr = queryParams.get("mr") || ''
  const appointment_id = decode_(mr)
  const is_page_verify = /^-?\d+$/.test(appointment_id)
  const [appointment_data, setAppointmentData] = useState<AppointmentType | undefined>(undefined)
  const [patient_data, setPatientData] = useState<any>(undefined)
  const [messageApi, contextHolder] = message.useMessage();

  const { form, prescription, setForm, setPrescription, resetPrescription } = getMedicalReportStore()
  const { medicine_name, dose, duration_in_day } = prescription
  const dose_ = renderPrescription(prescription, 'dose')
  const duration_ = renderPrescription(prescription, 'duration')
  const [page, setPage] = useState(1)

  const { mutate: onQueryForm, isError } = useMutation(async () => {
    const data = await onQueryAppointment({ id: Number(appointment_id) })
    return data
  }, {
    onSuccess: async (data: AppointmentType) => {
      setAppointmentData(data)
      setForm('appointment_id', data.id)
      setForm('symptoms', data.condition)
      const patient_id = btoa(`${data.user_id},${data.user_child_id || ''}`)
      const { data: patient_data } = await APIRequest({
        method: 'GET',
        url: API_FELLAS + '/api/v2',
        path: '/healthcare/patient',
        params: { patient_id }
      })
      if (patient_data) {
        setPatientData(patient_data)
      }
    }
  })

  const { mutate: onCreateMedicalReport } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'POST',
      url: API_FELLAS + '/api/v2',
      path: '/healthcare/medical-report',
      data: form
    })
    return data
  }, {
    onSuccess: async (data: any) => {
      const url = '/app/medical-report/' + data.id
      const resp = await onUpdateAppointment({ id: appointment_id, report: url })
      if (resp) {
        messageApi.success(data.message)
        navigate(url)
      }
    }
  })

  const question_value = Object.entries(form).filter(([key, value]) => value === true).map(([key]) => key)
  const question_opt_child = [
    { value: 'have_allergies', label: 'Tenaga Ahli telah mengetahui apakah pasien memiliki alergi atau tidak' },
    { value: 'is_taking_medication', label: 'Tenaga Ahli telah mengetahui apakah pasien sedang mengkonsumsi obat rutin atau tidak' },
  ]

  const question_opt = appointment_data?.user_child_id
    ? question_opt_child
    : [
      { value: 'is_pregnancy', label: 'Tenaga Ahli telah mengetahui apakah pasien dalam kondisi masa kehamilan / program hamil atau tidak' },
      { value: 'is_breastfeeding', label: 'Tenaga Ahli telah mengetahui apakah pasien dalam kondisi menyusui atau tidak' },
      ...question_opt_child
    ]

  const onSubmitForm = () => {
    onCreateMedicalReport()
  }

  const onHistoryPage = () => navigate('/app/expertise/?page=history')

  useEffect(() => {
    if (!is_page_verify || isError) {
      messageApi.error('Kesalahan dalam membuat rekam medis, silahkan hubungi admin')
      onHistoryPage()
    } else {
      onQueryForm()
    }
  }, []);

  const PopoverDose = () => {
    const dose_opt = [
      {
        value: 'morning',
        label: <div className="flex gap-2 justify-between items-center w-40">
          Pagi <InputNumber min={0} size="small" value={dose.morning} onChange={val => setPrescription('dose', { ...dose, morning: val || 0 })} />
        </div>
      },
      {
        value: 'noon',
        label: <div className="flex gap-2 justify-between items-center w-40">
          Siang <InputNumber min={0} size="small" value={dose.noon} onChange={val => setPrescription('dose', { ...dose, noon: val || 0 })} />
        </div>
      },
      {
        value: 'night',
        label: <div className="flex gap-2 justify-between items-center w-40">
          Malam <InputNumber min={0} size="small" value={dose.night} onChange={val => setPrescription('dose', { ...dose, night: val || 0 })} />
        </div>
      },
    ]
    const description_opt = [
      { value: 'Tidak ada', label: 'Tidak ada' },
      { value: 'Sebelum makan', label: 'Sebelum makan' },
      { value: 'Sesudah makan', label: 'Sesudah makan' },
    ]

    const selected_dose = Object.entries(dose).filter(([key, values]) => Number(values) > 0).map(([key, values]) => key)
    return (
      <div className="flex gap-2">
        <div>
          <p className="font-medium mb-1">Atur Dosis</p>
          <Checkbox.Group
            options={dose_opt}
            rootClassName="grid gap-1"
            value={selected_dose}
          />
        </div>
        <div>
          <p className="font-medium mb-1">Ketentuan</p>
          <Radio.Group
            options={description_opt}
            value={dose.description}
            rootClassName="grid gap-1"
            onChange={(e: any) => setPrescription('dose', { ...dose, description: e.target.value })}
          />
        </div>
      </div>
    )
  }

  const PopoverDuration = () => {
    const total_dose = (dose.morning + dose.noon + dose.night)
    return (
      <div className="space-y-2 w-40">
        <p className="font-medium mb-1">Atur Dosis</p>
        <div className="flex gap-2 justify-between items-center">
          Hari <InputNumber min={0} size="small" value={duration_in_day} onChange={val => setPrescription('duration_in_day', val || 0)} />
        </div>
        <div className="flex gap-2 justify-between items-center">
          Jumlah <p>{duration_in_day * total_dose}</p>
        </div>
      </div>
    )
  }

  const columns_medical_record: TableProps<MedicalReportPrescriptionType>['columns'] = [
    {
      title: 'Nama Obat',
      dataIndex: 'medicine_name',
      key: 'medicine_name',
      align: 'center',
    },
    {
      title: 'Dosis',
      key: 'dose',
      align: 'center',
      render: (data) => renderPrescription(data, 'dose')
    },
    {
      title: 'Durasi',
      key: 'duration',
      align: 'center',
      render: (data) => renderPrescription(data, 'duration')
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (data) => {
        const filtered_prescription = form.prescription.filter(item => item.medicine_name !== data.medicine_name)
        return (
          <div className="flex justify-center items-center">
            <Button type="primary" danger icon={<Trash className="h-5 w-5" />} onClick={() => setForm('prescription', filtered_prescription)} />
          </div>
        )
      }
    }
  ]

  return (
    <div className="container space-y-6">
      {contextHolder}
      <p className="text-3xl font-medium">Isi Rekam Medis</p>
      <div className="min-h-[calc(100vh-190px)] w-full flex flex-col justify-between space-y-4">
        {page === 1 ? (
          <MedicalReportForm title="Pemeriksaan Kondisi Pasien" icon={<Info className="text-primary" />}>
            <p>Sebelum melakukan pengisian, tenaga ahli diharapkan memastikan kondisi pasien dengan menandai pemeriksaan dibawah ini</p>
            <Checkbox.Group
              options={question_opt}
              value={question_value}
              onChange={values => question_opt.map((item: any) => setForm(item.value, values.includes(item.value)))}
              className="space-y-2"
            />
          </MedicalReportForm>
        ) : page === 2 && (
          <div className="space-y-4 md:max-h-[calc(100vh-240px)] overflow-auto">
            <MedicalReportForm title="Informasi Pasien" icon={<Info className="text-primary" />}>
              <div className="grid grid-cols-3 gap-2 text-sm items-start text-[#8A8585]">
                <div className="flex gap-2 items-center">
                  <User className="h-4" /> {patient_data?.fullname}
                </div>
                <div className="flex gap-2 items-center">
                  <Ruler className="h-4" /> {patient_data?.height || 0} cm
                </div>
                <div className="flex gap-2 items-start">
                  <BeanOff className="h-4" /> Alergi: {patient_data?.allergy || '-'}
                </div>
                <div className="flex gap-2 items-center">
                  <CalendarDays className="h-4" /> {patient_data?.birth_date}
                </div>
                <div className="flex gap-2 items-center">
                  <Dumbbell className="h-4" /> {patient_data?.weight || 0} kg
                </div>
              </div>
            </MedicalReportForm>
            <MedicalReportForm title="Informasi Layanan" icon={<BadgePlus className="text-primary" />}>
              <div className="grid grid-cols-2 gap-2 text-sm text-[#8A8585]">
                <div className="flex gap-2 items-center">
                  <UserCheck className="h-4" /> {appointment_data?.health_care?.detail?.fullname}
                </div>
                <div className="flex gap-2 items-center">
                  <Info className="h-4" /> ID: {appointment_data?.registration_num}
                </div>
                <div className="flex gap-2 items-center">
                  <CalendarClock className="h-4" /> {appointment_data?.date + ', ' + appointment_data?.time_range}
                </div>
                <div className="flex gap-2 items-center">
                  <Monitor className="h-4" /> {['Online', 'Homecare'].includes(appointment_data?.location || '') ? appointment_data?.location : 'Offline'} Service
                </div>
              </div>
            </MedicalReportForm>
            <MedicalReportForm title="Hasil Pemeriksaan" icon={<FileText className="text-primary" />}>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <p>Keluhan Pasien</p>
                  <Input.TextArea style={{ height: 80 }} value={form.symptoms} onChange={e => setForm('symptoms', e.target.value)} />
                </div>
                <div className="space-y-2">
                  <p>Diagnosa</p>
                  <Input.TextArea style={{ height: 80 }} value={form.diagnosis} onChange={e => setForm('diagnosis', e.target.value)} />
                </div>
              </div>
            </MedicalReportForm>
            <MedicalReportForm title="Resep Obat" icon={<Pill className="text-primary" />}>
              <div className="flex gap-2 mb-2">
                <Input placeholder="Masukkan nama obat" value={medicine_name} onChange={e => setPrescription('medicine_name', e.target.value)} />
                <Popover content={<PopoverDose />} trigger={['click']}>
                  <Input placeholder="Pilih Dosis" value={dose_} suffix={<ChevronDown className="h-5 text-gray-300" />} />
                </Popover>
                <Popover content={<PopoverDuration />} trigger={['click']}>
                  <Input placeholder="Durasi" value={duration_} suffix={<ChevronDown className="h-5 text-gray-300" />} />
                </Popover>
                <Button
                  type="primary"
                  disabled={!(medicine_name && dose_ && duration_)}
                  className="bg-primary aspect-square"
                  icon={<Plus className="w-5 h-5" />}
                  onClick={() => {
                    setForm('prescription', [...form.prescription, prescription])
                    resetPrescription()
                  }}
                />
              </div>
              <Table dataSource={form.prescription.map((item, i) => ({ ...item, key: i + 1 }))} columns={columns_medical_record} pagination={false} size="small" />
            </MedicalReportForm>

          </div>
        )}
        <div className="flex gap-2">
          <Button danger className={cn(page === 1 ? "w-1/2" : "w-1/3")} onClick={onHistoryPage}>Batalkan</Button>
          {page === 2 && <Button className="w-1/3" onClick={() => setPage(1)} >Kembali</Button>}
          {page === 1 ? <Button type="primary" className={cn(page === 1 ? "w-1/2" : "w-1/3", "bg-primary")} onClick={() => setPage(2)}>Selanjutnya</Button>
            : page === 2 && <Button type="primary" className="w-1/3 bg-primary" onClick={onSubmitForm} disabled={!form.diagnosis}>Kirim</Button>
          }
        </div>
      </div>
    </div>
  )
}

function MedicalReportForm({ icon, title, children }: { icon: any, title: string } & React.PropsWithChildren) {
  return (
    <div className="space-y-2">
      <div className="flex gap-4 items-center">
        {icon}
        <p className="text-xl text-medium">{title}</p>
      </div>
      <div className="pl-10 space-y-2">
        {children}
      </div>
    </div>
  )
}