import { getCurrentUser } from "src/config/services/core/auth.api";
import { create } from "zustand"

type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

interface EditState {
  main: boolean
  profile: boolean
  education: boolean
  experience: boolean
  schedule: boolean
}

export interface PsychologyForm {
  id?: number,
  is_active?: boolean,
  form_link?: string,
  price?: number,
  duration_in_min?: number,
  healthcare: number
  form_type?: 'Mental Health' | 'Burnout'
}
interface State {
  on_edit: EditState
  mh_form: PsychologyForm
  burnout_form: PsychologyForm
}

type FormKey = keyof PsychologyForm;
type FormValue = PsychologyForm[FormKey];

interface Action {
  toggleEdit: (key: KeysOfType<EditState, boolean>) => void
  setMHForm: (key: FormKey, value: FormValue) => void
  setBurnoutForm: (key: FormKey, value: FormValue) => void
}

export const useExpertisePortfolio = create<State & Action>(set => ({
  on_edit: {
    main: false,
    profile: false,
    education: false,
    experience: false,
    schedule: false,
  },
  mh_form: {
    healthcare: Number(localStorage.getItem('health_care_id')),
    price: 200000,
    duration_in_min: 50,
  },
  burnout_form: {
    healthcare: Number(localStorage.getItem('health_care_id')),
    price: 200000,
    duration_in_min: 50
  },
  toggleEdit: (key) => set(({ on_edit }) => ({ on_edit: { ...on_edit, [key]: !on_edit[key] } })),
  setMHForm: (key, value) => set(({ mh_form }) => ({ mh_form: { ...mh_form, [key]: value } })),
  setBurnoutForm: (key, value) => set(({ burnout_form }) => ({ burnout_form: { ...burnout_form, [key]: value } })),
}))