import { AppointmentType } from "src/lib/types"
import { create } from "zustand"

interface State {
  rate_appointment: AppointmentType[]

}

interface Action {
  set_rate_appointment: (rate_appointment: State['rate_appointment']) => void
}

export const useGlobalStore = create<State & Action>(set => ({
  rate_appointment: [],
  set_rate_appointment: (rate_appointment) => set(() => ({ rate_appointment })),
}))