import FellasIcon from "src/assets/icons/Fellas.svg";
function Loading() {
  return (
    <div className="fixed h-screen bg-white/40 z-40 w-screen top-0 left-0 justify-center items-center flex">
      <img src={FellasIcon} className="w-24 cursor-pointer fixed z-50" />
      <div className="flex item-center justify-center w-32 bg-white animate-spin aspect-square rounded-full shadow shadow-primary" />
    </div>
  );
}

export default Loading;