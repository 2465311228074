import { Briefcase, Edit, GraduationCap, PenLine, Save, XCircle } from "lucide-react";
import { useState } from "react";
import { Button } from "src/components/ui/button";
import { Card, CardContent, CardHeader } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { updateHealthCare } from "src/config/services/fellas/health-care.api";
import { useExpertisePortfolio } from "src/config/store/expertise/portfolio";
import { editValue } from "..";

function ExpertiseExperienceCard({ expertise, is_dashboard }) {
  const { on_edit, toggleEdit } = useExpertisePortfolio()
  const [experience, setExperience] = useState({ name: '', institute: '', year: '' })
  const [education, setEducation] = useState({ name: '', institute: '', year: '' })

  const updateExperienceForm = async () => {
    const { id, experience } = expertise
    await updateHealthCare({ id, experience: JSON.stringify(experience) })
    toggleEdit('experience')
  }

  const updateEducationForm = async () => {
    const { id, education } = expertise
    await updateHealthCare({ id, education: JSON.stringify(education) })
    toggleEdit('education')
  }

  return (
    <Card className="shadow">
      <CardHeader>
        <div className="flex justify-between text-lg">
          <div className="flex gap-3 font-medium items-center ">
            <Briefcase className="text-primary w-5" />Pengalaman
          </div>
          {is_dashboard && <div>
            {on_edit.experience
              ? <Button size="sm" onClick={updateExperienceForm}><Save className="h-4" /> SIMPAN</Button>
              : <Edit className="self-start text-primary w-4 h-4 cursor-pointer" onClick={() => toggleEdit('experience')} />}
          </div>}
        </div>
      </CardHeader>
      <CardContent className="md:ml-8 space-y-4">
        {on_edit.experience
          ? <div className="grid grid-cols-5 max-md:grid-cols-2 gap-2">
            <div className="col-span-5 max-md:col-span-2">
              {(expertise.experience || []).map((x, i) => !x.on_edit ? (
                <div key={i} className="flex justify-between items-center">
                  <div className="grid">
                    {x.name} - {x.institute}
                    <div className="text-sm text-muted-foreground">{x.year}</div>
                  </div>
                  <div className="flex gap-4">
                    <PenLine className="w-5 h-5 text-primary cursor-pointer"
                      onClick={() => {
                        const data = expertise.experience
                        const on_edited_value = data.map(d => d.id === x.id ? ({ ...d, on_edit: true }) : d)
                        editValue(expertise, "experience", on_edited_value, 0)
                      }}
                    />
                    <XCircle className="w-5 h-5 text-primary cursor-pointer"
                      onClick={() => {
                        const data = expertise.experience
                        const deleted_data = data.filter(d => d.id !== x.id)
                        editValue(expertise, "experience", deleted_data, 0)
                      }}
                    />
                  </div>
                </div>
              ) : <div className="grid grid-cols-5 max-md:grid-cols-2 gap-2">
                <Input className="col-span-2 bg-white" placeholder="Profesi" value={x.name}
                  onChange={e => {
                    const data = expertise.experience
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, name: e.target.value }) : d)
                    editValue(expertise, "experience", edited_value, 0)
                  }} />
                <Input className="col-span-1 bg-white" placeholder="Institusi" value={x.institute}
                  onChange={e => {
                    const data = expertise.experience
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, institute: e.target.value }) : d)
                    editValue(expertise, "experience", edited_value, 0)
                  }} />
                <Input className="col-span-1 bg-white" placeholder="Year" value={x.year}
                  onChange={e => {
                    const data = expertise.experience
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, year: Number(e.target.value) }) : d)
                    editValue(expertise, "experience", edited_value, 0)
                  }} />
                <Button className="col-span-1 max-md:col-span-2 text-primary hover:text-secondary border border-primary" variant="ghost"
                  onClick={() => {
                    const data = expertise.experience
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, on_edit: false }) : d)
                    editValue(expertise, "experience", edited_value, 0)
                  }}>
                  Perbarui
                </Button>
              </div>)}
            </div>
            <Input className="col-span-2 bg-white" placeholder="Profesi" value={experience.name} onChange={e => setExperience(s => ({ ...s, name: e.target.value }))} />
            <Input className="col-span-1 bg-white" placeholder="Institusi" value={experience.institute} onChange={e => setExperience(s => ({ ...s, institute: e.target.value }))} />
            <Input className="col-span-1 bg-white" type="number" placeholder="Tahun" value={experience.year} onChange={e => setExperience(s => ({ ...s, year: e.target.value }))} />
            <Button className="col-span-1 max-md:col-span-2" disabled={!experience.name || !experience.institute || !experience.year} onClick={() => {
              const data = expertise.experience
              const new_data = { ...experience, id: data.length + 1, year: Number(experience.year), on_edit: false }
              editValue(expertise, "experience", [...data, new_data], 0)
            }
            }>Tambah</Button>
          </div>
          : (expertise.experience || []).map((x, i) => (
            <div key={i} className="flex justify-between items-center">
              <div className="grid">
                {x.name} - {x.institute}
                <div className="text-sm text-muted-foreground">{x.year}</div>
              </div>
            </div>
          ))}
      </CardContent>
      <CardHeader>
        <div className="flex justify-between text-lg">
          <div className="flex gap-3 font-medium items-center ">
            <GraduationCap className="text-primary w-5" />Riwayat Pendidikan
          </div>
          {is_dashboard && <div>
            {on_edit.education
              ? <Button size="sm" onClick={updateEducationForm}><Save className="h-4" /> SIMPAN</Button>
              : <Edit className="self-start text-primary w-4 h-4 cursor-pointer" onClick={() => toggleEdit('education')} />}
          </div>}
        </div>
      </CardHeader><CardContent className="md:ml-8 space-y-4">
        {on_edit.education
          ? <div className="grid grid-cols-5 max-md:grid-cols-2 gap-2">
            <div className="col-span-5 max-md:col-span-2">
              {(expertise.education || []).map((x, i) => !x.on_edit ? (
                <div key={i} className="flex justify-between items-center">
                  <div className="grid">
                    {x.name} - {x.institute}
                    <div className="text-sm text-muted-foreground">{x.year}</div>
                  </div>
                  <div className="flex gap-4">
                    <PenLine className="w-5 h-5 text-primary cursor-pointer"
                      onClick={() => {
                        const data = expertise.education
                        const on_edited_value = data.map(d => d.id === x.id ? ({ ...d, on_edit: true }) : d)
                        editValue(expertise, "education", on_edited_value, 0)
                      }}
                    />
                    <XCircle className="w-5 h-5 text-primary cursor-pointer"
                      onClick={() => {
                        const data = expertise.education
                        const deleted_data = data.filter(d => d.id !== x.id)
                        editValue(expertise, "education", deleted_data, 0)
                      }}
                    />
                  </div>
                </div>
              ) : <div className="grid grid-cols-5 max-md:grid-cols-2 gap-2">
                <Input className="col-span-2 bg-white" placeholder="Bidang/Jurusan" value={x.name}
                  onChange={e => {
                    const data = expertise.education
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, name: e.target.value }) : d)
                    editValue(expertise, "education", edited_value, 0)
                  }} />
                <Input className="col-span-1 bg-white" placeholder="Institusi" value={x.institute}
                  onChange={e => {
                    const data = expertise.education
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, institute: e.target.value }) : d)
                    editValue(expertise, "education", edited_value, 0)
                  }} />
                <Input className="col-span-1 bg-white" placeholder="Year" value={x.year}
                  onChange={e => {
                    const data = expertise.education
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, year: Number(e.target.value) }) : d)
                    editValue(expertise, "education", edited_value, 0)
                  }} />
                <Button className="col-span-1 max-md:col-span-2 text-primary hover:text-secondary border border-primary" variant="ghost"
                  onClick={() => {
                    const data = expertise.education
                    const edited_value = data.map(d => d.id === x.id ? ({ ...d, on_edit: false }) : d)
                    editValue(expertise, "education", edited_value, 0)
                  }}>
                  Perbarui
                </Button>
              </div>)}
            </div>
            <Input className="col-span-2 bg-white" placeholder="Bidang/Jurusan" value={education.name} onChange={e => setEducation(s => ({ ...s, name: e.target.value }))} />
            <Input className="col-span-1 bg-white" placeholder="Institusi" value={education.institute} onChange={e => setEducation(s => ({ ...s, institute: e.target.value }))} />
            <Input className="col-span-1 bg-white" type="number" placeholder="Tahun" value={education.year} onChange={e => setEducation(s => ({ ...s, year: e.target.value }))} />
            <Button className="col-span-1 max-md:col-span-2" disabled={!education.name || !education.institute || !education.year} onClick={() => {
              const data = expertise.education
              const new_data = { ...education, id: data.length + 1, year: Number(education.year), on_edit: false }
              editValue(expertise, "education", [...data, new_data], 0)
            }}>Tambah</Button>
          </div>
          : (expertise.education || []).map((x, i) => (
            <div key={i} className="flex justify-between items-center">
              <div className="grid">
                {x.name} - {x.institute}
                <div className="text-sm text-muted-foreground">{x.year}</div>
              </div>
            </div>
          ))}
      </CardContent>
    </Card >
  );
}

export default ExpertiseExperienceCard;