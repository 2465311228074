import { useDispatch, useSelector } from "react-redux";
import Header from "src/components/header";
import Navbar from "src/components/navbar";
import AdminExpertiseRequest from "./request";
import { set_admin_menu } from "src/config/redux/slicer/admin/adminNavbarSlicer";
import AdminDashboard from "./dashboard";
import NotFound from "src/components/not-found/NotFound";
import { INITIAL_ADMIN_NAVBAR } from "src/config/const/menubar";
import AdminWalletExpertise from "./wallet/expertise";
import { useEffect } from "react";
import { set_md_sidebar_menu } from "src/config/redux/slicer/main/menuBarSlicer";
import { useLocation } from "react-router";
import { useMenuBarStore } from "src/config/store/global/menubar";

function AdminPage() {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page") || 'main'
  const { set_navbar_menu } = useMenuBarStore()
  const navbar = useSelector((state: any) => state.AdminMenuBar.value)

  const main_menu = (
    navbar === 'main' ? <AdminDashboard /> :
      navbar === 'request' ? <AdminExpertiseRequest /> :
        navbar === 'wallet-expertise' ? <AdminWalletExpertise /> :
          <NotFound />
  )

  useEffect(() => {
    set_navbar_menu(INITIAL_ADMIN_NAVBAR)
  }, []);

  useEffect(() => {
    dispatch(set_admin_menu(page))
  }, [page]);
  
  return (
    <div className="bg-white min-h-screen overflow-hidden">
      <Header is_login />
      <div className="lg:container flex pt-[5vh] gap-2">
        <div className="w-1/5 max-lg:hidden">
          <Navbar navbar_menu={INITIAL_ADMIN_NAVBAR} setNavbar={set_admin_menu} selected_navbar={navbar} />
        </div>
        <div className="w-4/5 max-lg:w-screen text-start pt-12">
          {main_menu}
        </div>
      </div>
    </div>)
    ;
}

export default AdminPage;