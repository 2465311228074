import { ColumnDef } from "@tanstack/table-core"
import { TableProps, Tag } from "antd"
import { format } from "date-fns"
import { Badge } from "src/components/ui/badge"
import { STATUS_PAYOUT } from "src/config/const/status"
import { PayoutType } from "src/lib/types"
import { cn } from "src/lib/utils"

export const columns: TableProps<PayoutType>['columns'] = [
  {
    key: 'status',
    title: "Status",
    align: 'center',
    width: 140,
    fixed: window.innerWidth > 600 && 'left',
    render: (data) => {
      const { className, text, color } = STATUS_PAYOUT[data.status]
      return (
        <Tag color={color}>{text}</Tag>
      )
    }
  },
  {
    key: 'datetime',
    title: "Date (GMT+7)",
    align: 'center',
    render: (data) => {
      const date = new Date(data.created)
      return (
        <p>{format(date, 'yyyy-MM-dd, HH:mm')}</p>
      )
    }
  },
  {
    key: 'external_id',
    dataIndex: 'external_id',
    title: "Payment ID",
    align: 'center',
  },
  {
    key: 'recipient_email',
    dataIndex: 'recipient_email',
    title: "Recipient Email",
    align: 'center',
  },
  {
    key: 'amount',
    title: "Penarikan",
    align: 'center',
    fixed: 'right',
    width: 140,
    render: (data) => {
      return (
        <b className={cn(data.status === 'COMPLETED' && "text-primary")}>Rp {(data.amount || 0).toLocaleString('id')}</b>
      )
    }
  },
]