import { useEffect } from 'react';
import '../assets/styles/App.css';
import Router from '../config/routes/Router';
import ReactGA from 'react-ga4';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
  }, [])
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
