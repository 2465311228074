import { Building2Icon, Home, VideoIcon } from "lucide-react";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "src/components/ui/card";
import { ToastAction } from "src/components/ui/toast";
import { useToast } from "src/components/ui/use-toast";
import { set_selected_appointment } from "src/config/redux/slicer/health-care/appointmentSlicer";
import { set_burnout_form, set_mh_form } from "src/config/redux/slicer/health-care/searchQuerySlicer";
import APIRequest from "src/config/services/api";
import { getJWT } from "src/config/services/core/auth.api";
import { formatHomecarePrice } from "src/lib/utils";
import DashboadExpertise from "src/pages/Expertise/tabs/dashboard";

function AppointmentPortfolio({ id }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { toast } = useToast()
  const is_login = Boolean(getJWT().key)

  const { selected_health_care, mh_form, burnout_form } = useSelector((state: any) => state.SearchQuery)

  const makeAppointment = () => {
    if (is_login) {
      dispatch(set_selected_appointment(id))
    } else {
      toast({
        title: 'Something went wrong!',
        description: 'Silahkan login terlebih dahulu',
        variant: 'destructive',
        action: <ToastAction altText="login" onClick={() => navigate('/')}>Login</ToastAction>
      })
    }
  }

  const { mutate: onGetMentalHealthForm } = useMutation(async () => {
    const { data } = await APIRequest({
      method: 'GET',
      path: '/health-care/mh-check/',
      params: { healthcare: id }
    })
    return data
  }, {
    onSuccess: (data) => {
      if (data.length) {
        const mh_form = data.find(item => item.form_type === 'Mental Health')
        const burnout_form = data.find(item => item.form_type === 'Burnout')
        if (mh_form) dispatch(set_mh_form(mh_form))
        if (burnout_form) dispatch(set_burnout_form(burnout_form))
      }
    }
  })


  useEffect(() => {
    onGetMentalHealthForm()
  }, []);

  return (
    <div className="flex max-md:flex-col-reverse max-md:grid-cols-1 gap-8 mb-4">
      <div className="max-h-[88vh] max-md:max-h-[50vh] md:max-w-[70vw] overflow-auto border-b rounded-b-md">
        {selected_health_care.id && <DashboadExpertise expertise={selected_health_care} className="px-0" />}
      </div>
      {selected_health_care.id &&
        <Card className="h-fit md:w-[30vw]">
          <CardHeader className="text-start">
            <b>Tersedia:</b>
            <div className="grid gap-3">
              {(selected_health_care.detail.is_offline && selected_health_care.prices?.offline) && (
                <div className="flex gap-4">
                  <Building2Icon className="mt-1 w-4 h-4 text-primary" />
                  <div className="text-muted-foreground">
                    <div>{selected_health_care.offline_location.label}</div>
                    <span><a className="text-primary">Rp {selected_health_care.prices?.offline.toLocaleString('id')}</a> (bayar langsung)</span>
                  </div>
                </div>
              )}
              {(selected_health_care.detail.is_homecare && selected_health_care.prices?.homecare && selected_health_care.is_homecare_range) && (
                <div className="flex gap-4">
                  <Home className="mt-1 w-4 h-4  text-primary" />
                  <div className="text-muted-foreground">
                    <div>Tersedia Homecare</div>
                    {selected_health_care.prices?.homecare && <span><a className="text-primary">Rp {formatHomecarePrice(selected_health_care.prices?.homecare)}</a> (bayar langsung)</span>}
                  </div>
                </div>
              )}
              {(selected_health_care.detail.is_online && selected_health_care.prices?.online) && (
                <div className="flex gap-4">
                  <VideoIcon className="mt-1 w-4 h-4  text-primary" />
                  <div className="text-muted-foreground">
                    <div>Tersedia Online</div>
                    <div><a className="text-primary">Rp {selected_health_care.prices?.online.toLocaleString('id')}</a> / {selected_health_care.prices?.online_duration || 15} Menit {(mh_form || burnout_form) && '(Konsultasi Umum)'}</div>
                    {mh_form &&
                      <div><a className="text-primary">Rp {mh_form.price.toLocaleString('id')}</a> / {mh_form.duration_in_min} Menit (Mental Health Checkup)</div>
                    }
                    {burnout_form &&
                      <div><a className="text-primary">Rp {burnout_form.price.toLocaleString('id')}</a> / {burnout_form.duration_in_min} Menit (Burnout Checkup)</div>
                    }
                  </div>
                </div>
              )}
              <Button onClick={makeAppointment}>Buat Janji</Button>
            </div>
          </CardHeader>
        </Card>
      }
    </div>);
}

export default AppointmentPortfolio;