import { User } from "lucide-react";
import { useSelector } from "react-redux";
import DialogLocationUser from "src/components/dialog/location/user";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { cn, formatPhoneNumber } from "src/lib/utils";

function AppointmentUserCard() {
  const user = getCurrentUser()
  const { appointment_form } = useSelector((state: any) => state.Appointment)
  const { selected_health_care } = useSelector((state: any) => state.SearchQuery)
  const is_online = appointment_form.location === 'Online'
  const is_homecare = appointment_form.location === 'Homecare'
  const is_homecare_range = appointment_form.homecare?.user_location?.distance <= selected_health_care.homecare_area.radius
  const homecare_address = appointment_form.homecare?.user_location?.full_address
  const offline_address = selected_health_care.offline_location?.full_address
  
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-3">
          <User className="w-5 h-5 text-primary" />
          <CardTitle> Data Pemesan</CardTitle>
        </div>
      </CardHeader>
      <div className="mx-14 pb-6">
        <div className={cn(is_online ? "grid-cols-3" : "grid-cols-2", "grid max-sm:grid-cols-1 gap-4")}>
          <div>
            <div className="text-muted-foreground text-xs">Nama</div>
            <div>{user.first_name} {user.last_name}</div>
          </div>
          {!is_online && (
            <div>
              <div className="text-muted-foreground text-xs flex gap-1">Lokasi
                {is_homecare &&
                  <Dialog>
                    <DialogTrigger asChild>
                      <div className="bg-primary text-[10px] text-white px-1 rounded-md cursor-pointer hover:shadow">Edit</div>
                    </DialogTrigger>
                    <DialogLocationUser is_appointment_form />
                  </Dialog>
                }
              </div>
              {is_homecare && !is_homecare_range && <p className="text-destructive text-xs">*lokasi diluar radius homecare</p>}
              <div>{is_homecare ? homecare_address : offline_address}</div>
            </div>
          )}
          <div>
            <div className="text-muted-foreground text-xs">Email</div>
            <div>{user.email}</div>
          </div>
          <div>
            <div className="text-muted-foreground text-xs">Nomor Telepon</div>
            <div>{user.number ? formatPhoneNumber(user.number) : '-'}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default AppointmentUserCard;