import { ItemType } from "antd/es/menu/hooks/useItems";
import { LogOut, Monitor } from "lucide-react";
import { INITIAL_HEADER_MENU } from "src/config/const/menubar";
import { onLogout } from "src/config/services/core/auth.api";
import { create } from "zustand";

interface State {
  selected_menu: string
  header_menu: ItemType[]
  user_menu: ItemType[]
  navbar_menu: ItemType[]
  show_sidebar: boolean
}

interface Action {
  set_selected_menu: (selected_menu) => void
  set_header_menu: (header_menu) => void
  set_user_menu: (user_menu) => void
  set_navbar_menu: (navbar_menu) => void
  toggle_show_sidebar: () => void
}

export const menuClassName = window.innerWidth >= 1024 ? 'pt-2 text-[15px]' : 'w-full'
export const iconClassName = 'mr-2 h-4 w-4'

export const useMenuBarStore = create<State & Action>(set => ({
  selected_menu: 'main',
  show_sidebar: false,
  header_menu: INITIAL_HEADER_MENU,
  user_menu: [
    {
      key: 'dashboard',
      label: 'Dashboard',
      icon: <Monitor className={iconClassName} />,
      onClick: () => window.location.assign('/app')
    },
    {
      key: 'sign-out',
      label: 'Keluar',
      icon: <LogOut className={iconClassName} />,
      onClick: onLogout
    },
  ],
  navbar_menu: [],
  set_selected_menu: (selected_menu) => set(() => ({ selected_menu })),
  set_header_menu: (header_menu) => set(() => ({ header_menu })),
  set_user_menu: (user_menu) => set(() => ({ user_menu })),
  set_navbar_menu: (navbar_menu) => set(() => ({ navbar_menu })),
  toggle_show_sidebar: () => set((state: State) => ({ show_sidebar: !state.show_sidebar })),
}))