import { AdvancedMarker, Map, Marker, useAutocomplete } from "@vis.gl/react-google-maps";
import { LocateFixed } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { set_expertise_data } from "src/config/redux/slicer/expertise/expertiseSlicer";
import { getAddressFromCoords, getAddressLabel } from "src/config/services/fellas/google.api";
import { updateHealthCare } from "src/config/services/fellas/health-care.api";

import DeckGL from '@deck.gl/react';
import { ScatterplotLayer } from '@deck.gl/layers';
import { FellasPin } from "./user";
import { getDragLocation } from "src/lib/utils";

function DialogLocationHomecare() {
  const { toast } = useToast()
  const dispatch = useDispatch()
  const autoCompleteRef = useRef<any>(null);
  const { value: expertise } = useSelector((state: any) => state.Expertise)
  const current_address = expertise.homecare_area?.address || []
  const radius = expertise.homecare_area.radius || 0.1
  const [autoCompleteValue, setAutoCompleteValue] = useState('')
  const { lat, lng } = expertise.homecare_area.coords || { lat: 0, lng: 0 }
  const { REACT_APP_GOOGLE_MAP_ID } = process.env

  const zoom = Math.log2(40075.16 / (radius * 2 * Math.PI))
  const INITIAL_VIEW_STATE = {
    longitude: lng,
    latitude: lat,
    zoom,
  };

  const circleLayers = new ScatterplotLayer({
    id: 'center-circle-layer',
    data: [
      { name: 'Center', position: [lng, lat], radius: radius * 1000 }
    ],
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    getPosition: d => d.position,
    getRadius: d => d.radius,
    getFillColor: [255, 182, 193, 32],
    getLineColor: [255, 0, 127],
  })

  useAutocomplete({
    inputField: autoCompleteRef && autoCompleteRef.current,
    onPlaceChanged: (place) => {
      const { address_components: address, formatted_address: full_address, geometry, name: label } = place
      const coords = { lat: geometry.location.lat(), lng: geometry.location.lng() }
      dispatch(set_expertise_data({
        ...expertise,
        homecare_area: { coords, address, full_address, label: label || '', radius }
      }))
      setAutoCompleteValue(full_address)
      autoCompleteRef.current && autoCompleteRef.current.focus();
    }
  })

  const updateHomecareAreaForm = async () => {
    const { id, homecare_area } = expertise
    const resp = await updateHealthCare({ id, homecare_area: JSON.stringify(homecare_area) })
    ToastAlert(resp, 'Lokasi Berhasil di update')
  }

  const getCurrentUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const coords = { lat: latitude, lng: longitude }

          const place = await getAddressFromCoords(coords)
          const { address_components: address, formatted_address: full_address, geometry, name: label } = place
          dispatch(set_expertise_data({
            ...expertise,
            homecare_area: { coords, address, full_address, label: label || '', radius }
          }))
          setAutoCompleteValue(full_address)
        },
        (error) => {
          ToastAlert({ error }, `Error getting user location: ${error}`)
        }
      );
    } else {
      ToastAlert({ error: 'Error' }, 'Geolocation is not supported by your browser')
    }
  }

  useEffect(() => {
    !expertise.homecare_area.label && getCurrentUserLocation()
  }, []);

  return (
    <DialogContent className="sm:max-w-[425px] lg:min-w-[500px] bg-white">
      <DialogHeader>
        <DialogTitle className="text-center">Tentukan Pusat Lokasi Layanan HomeCare</DialogTitle>
      </DialogHeader>
      <div className="space-y-2 text-sm">
        {/* <p>Nama Lokasi Layanan (rumah sakit, tempat praktek, dll)</p> */}
        <Input
          placeholder="Nama Lokasi"
          value={expertise.homecare_area.label}
          onChange={(e) => {
            dispatch(set_expertise_data({ ...expertise, homecare_area: { ...expertise.homecare_area, label: e.target.value } }))
          }} />
        <div className="h-48 rounded-sm">
          <Map
            center={expertise.homecare_area.coords}
            zoom={zoom}
            mapId={REACT_APP_GOOGLE_MAP_ID}
          >
            {expertise.homecare_area.coords &&
              <AdvancedMarker position={expertise.homecare_area.coords}>
                <FellasPin />
              </AdvancedMarker>}
            <DeckGL
              initialViewState={INITIAL_VIEW_STATE}
              controller={{ scrollZoom: false, dragMode: 'pan' }}
              layers={[circleLayers]}
              isZooming={false}
              onViewStateChange={({ viewState }) => {
                const { longitude: lng, latitude: lat } = viewState
                setAutoCompleteValue('')
                const coords = { lat, lng }
                getAddressFromCoords(coords).then(place => {
                  const { address_components: address, formatted_address: full_address, geometry, name: label } = place

                  dispatch(set_expertise_data({
                    ...expertise,
                    homecare_area: { coords, address, full_address, label: label || '', radius }
                  }))
                })
              }}
            />
          </Map>

        </div>
        <div className="flex gap-2 items-end">
          <div className="w-1/3">
            <p>Jangkauan</p>
            <Input
              placeholder="in km"
              type="number"
              defaultValue={radius}
              onChange={e =>
                dispatch(set_expertise_data({ ...expertise, homecare_area: { ...expertise.homecare_area, radius: Number(e.target.value) } }))
              } />
          </div>
          <div className="w-full">
            <p>Cari alamat lokasi HomeCare</p>
            <Input ref={autoCompleteRef} placeholder={expertise.homecare_area.full_address} value={autoCompleteValue} onChange={e => setAutoCompleteValue(e.target.value)} />
          </div>
        </div>
        <div className="flex justify-between gap-2 items-center">
          <LocateFixed
            className="text-primary cursor-pointer hover:animate-pulse"
            onClick={getCurrentUserLocation}
          />
          <div className="w-3/4">
            <p>{getAddressLabel(current_address, 'administrative_area_level_2')}, {getAddressLabel(current_address, 'administrative_area_level_1')}</p>
            <p className="text-xs text-gray-400">{getAddressLabel(current_address, 'route')}</p>
          </div>
          <Button disabled={!expertise.homecare_area.label} onClick={updateHomecareAreaForm}>Terapkan</Button>
        </div>
      </div>
    </DialogContent>
  );
}

export default DialogLocationHomecare;