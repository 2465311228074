import { Button, TableProps, Tag, Tooltip } from "antd";
import { Link, MoreHorizontal, Phone, Trash } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { encode_ } from "src/lib/hash";
import { AppointmentType, MedicalReportPrescriptionType } from "src/lib/types";
import { redirectToWhatsApp } from "../appointment/columns";
import { renderPrescription } from "src/lib/utils";
import { getMedicalReportStore } from "src/config/store/expertise/medical-report";

export const columns: TableProps<AppointmentType>['columns'] = [
    {
        title: 'Nama Pasien',
        key: 'patient_name',
        align: 'right',
        fixed: window.innerWidth > 600 && 'left',
        render: (data) => {
            const first_name = data.user_child ? data.user_child.first_name : data.user.first_name
            const last_name = data.user_child ? data.user_child.last_name : data.user.last_name
            return (<div>{first_name} {last_name}</div>)
        }
    },
    {
        title: 'ID Konsultasi',
        dataIndex: 'registration_num',
        key: 'registration_num',
        align: 'center',
    },
    {
        title: 'Waktu Janji',
        key: 'time_range',
        align: 'center',
        width: 140,
        render: (data) => {
            return <Tag color="pink">{data.time_range}</Tag>
        }
    },
    {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'date',
        width: 140,
        align: 'center',
    },
    {
        title: 'Status',
        key: 'status',
        align: 'center',
        width: 120,
        render: (data) => {
            const text = {
                'Completed': 'Selesai',
            }
            return (
                <Tag color='orange'>{text[data.status] || data.status}</Tag>
            )
        }
    },
    {
        title: 'Kondisi',
        dataIndex: 'condition',
        key: 'condition',
        align: 'center',
    },
    {
        title: "Rekam Medis",
        key: 'report',
        align: 'center',
        width: 120,
        render: (data) => {
            const isValidUrl = (url: string) => url.includes('/app/medical-report')

            return (
                <Tooltip title={!isValidUrl(data.report) ? 'Buat rekam medis' : 'Akses rekam medis'}>
                    <Button
                        icon={<Link className="h-5 w-5" />}
                        type={isValidUrl(data.report) ? "primary" : "default"}
                        className={isValidUrl(data.report) ? "bg-primary" : ''}
                        onClick={() => {
                            isValidUrl(data.report)
                                ? window.location.assign(data.report)
                                : window.location.assign('/app/expertise?page=history&mr=' + encode_(data.id))
                        }}
                    />
                </Tooltip>
            )
        }

    },
    {
        key: 'Action',
        fixed: 'right',
        align: 'center',
        width: 60,
        render: (data) => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <MoreHorizontal className="cursor-pointer hover:bg-muted p-1 rounded-md" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {/* <DropdownMenuItem
                            onClick={() => {
                                store.dispatch(set_open_appointment_dialog('report'))
                                store.dispatch(set_selected_appointment(data))
                            }}
                            className="cursor-pointer">
                            <ScrollText className="h-4 mr-1 text-black/20" /> Ubah Rekam Medis
                        </DropdownMenuItem> */}
                        <DropdownMenuItem onClick={() => redirectToWhatsApp(data)} key="contact-patient" className="cursor-pointer">
                            <Phone className="h-4 mr-1 text-black/20" />Hubungi Pasien
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            )
        }
    }
]

export const homecare_columns: TableProps<AppointmentType>['columns'] = [
    ...columns.slice(0, 1),
    {
        title: 'Layanan',
        key: 'service',
        render: (data) => <div>{data.homecare.service}</div>
    },
    ...columns.slice(1)
]