import { CircleDollarSign, Coins } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import { Card, CardHeader, CardTitle } from "src/components/ui/card";
import { Dialog, DialogTrigger } from "src/components/ui/dialog";
import DialogPayment from "../../payment";
import { set_open_payment_dialog } from "src/config/redux/slicer/dialog/paymentDialogSlicer";

function AppointmentPaymentForm({ handlePayment, submitForm }) {
  const dispatch = useDispatch()
  const { appointment_form, invoice } = useSelector((state: any) => state.Appointment)
  const { selected_health_care, mh_form } = useSelector((state: any) => state.SearchQuery)
  const is_online = appointment_form.location === 'Online'
  const is_homecare = appointment_form.location === 'Homecare'
  const { dialog } = useSelector((state: any) => state.PaymentDialog)

  const getPrice = () => {
    if (is_online) {
      if (appointment_form.mh_form_status) {
        return mh_form.price
      } else {
        return selected_health_care.prices?.online || 25000
      }
    } else if (is_homecare) {
      const selected_service = selected_health_care.prices?.homecare.find(homecare => homecare.service === appointment_form.homecare.service)
      return selected_service?.value || 100000
    } else {
      return selected_health_care.prices?.offline
    }
  }

  return (
    <div className="space-y-2">
      <Card>
        <CardHeader>
          <div className="flex items-center gap-3">
            <Coins className="w-5 h-5 text-primary" />
            <CardTitle> Informasi Pembayaran</CardTitle>
          </div>
        </ CardHeader>
        <div className="mx-14 pb-6 grid gap-2">
          <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm text-gray-400">
            <div><p>Total Biaya</p><p className="text-base text-primary">Rp. {getPrice().toLocaleString('id')}
            </p></div>
            <div><p>Status Pembayaran</p>
              <p className="text-base text-black">{is_online
                ? <div className="flex gap-4">{invoice.status === 'PAID' ? 'Lunas' : 'Belum Dibayar'}
                  {invoice.status !== 'PAID' &&
                    <Dialog open={dialog.online_invoice} onOpenChange={() => dispatch(set_open_payment_dialog('online_invoice'))}>
                      <DialogTrigger asChild><CircleDollarSign onClick={handlePayment} className="h-4 w-4 stroke-primary cursor-pointer hover:stroke-yellow-400" /></DialogTrigger>
                      <DialogPayment invoice={invoice} />
                    </Dialog>}
                </div>
                : 'Bayar Ditempat'}
              </p>
            </div>
          </div>
        </div>
      </Card>

      <Button disabled={!appointment_form.payment_verification} className="w-full" size="sm" onClick={submitForm}>Cek Status Pembayaran</Button>
    </div>
  );
}

export default AppointmentPaymentForm;