import { createSlice } from '@reduxjs/toolkit'

export const adminDashboardSlicer = createSlice({
  name: 'adminDashboardSlicer',
  initialState: {
    progress_host_email: false,
    is_host_email_expired: true,
  },
  reducers: {
    set_progress_host_email: (state, action) => {
      state.progress_host_email = action.payload
    },
    set_is_host_email_expired: (state, action) => {
      state.is_host_email_expired = action.payload
    },
  },
})

export const { set_progress_host_email, set_is_host_email_expired } = adminDashboardSlicer.actions
export default adminDashboardSlicer.reducer
