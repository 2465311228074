import { createSlice } from '@reduxjs/toolkit'

export const userDialogSlicer = createSlice({
  name: 'userDialogSlicer',
  initialState: {
    dialog: {
      edit_profile: false,
      add_profile: false,
    },
    user_data: {},
  },
  reducers: {
    set_open_user_dialog: (state, action) => {
      state.dialog[action.payload] = true
    },
    set_close_user_dialog: (state, action) => {
      state.dialog[action.payload] = false
    },
    set_user_data: (state, action) => {
      state.user_data = action.payload
    },
  },
})

export const { set_open_user_dialog, set_close_user_dialog, set_user_data } = userDialogSlicer.actions
export default userDialogSlicer.reducer
