import { Popconfirm } from "antd";
import { BuildingIcon, CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon, CrossIcon, UserIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoImage from "src/assets/images/NoImage.png";
import DialogLocationRoute from "src/components/dialog/location/route";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { set_open_appointment_dialog, set_selected_appointment } from "src/config/redux/slicer/dialog/appointmentDialogSlicer";
import { set_appointment_data } from "src/config/redux/slicer/main/myAppointmentSlicer";
import APIRequest from "src/config/services/api";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { onGetAppointment, onPaginateAppointment, onUpdateAppointment } from "src/config/services/fellas/appointment.api";
import { cn, toAbsolutePath } from "src/lib/utils";

export const colors = {
  'Accepted': 'bg-green-300',
  'Cancelled': 'bg-red-300',
  'On Going': 'bg-yellow-300',
  'New Entry': 'bg-blue-300',
  'Completed': 'bg-orange-300',
}

export const status = {
  'Accepted': 'Diterima',
  'Cancelled': 'Ditolak',
  'On Going': 'Hari-H',
  'New Entry': 'Diajukan',
  'Completed': 'Selesai',
}

function DashboardAppointment() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data } = useSelector((state: any) => state.MyAppointment)
  const { dialog, selected_appointment } = useSelector((state: any) => state.AppointmentDialog)
  const query = `&user_id=${getCurrentUser().id}&status=Accepted,Cancelled,On Going,New Entry`

  const readAppointment = async (limit, offset, query?) => {
    let appointment = await onGetAppointment({ query: `?limit=${limit}&offset=${offset}` + (query || '') })
    if (!appointment.error) {
      appointment = { ...appointment, results: appointment.results.map(item => ({ ...item, confirm_form: false })) }
    }
    dispatch(set_appointment_data({ ...appointment, limit, offset, query }))
  }
  const seeLocationAppointment = async (data) => {
    dispatch(set_open_appointment_dialog('appointment_location'))
    dispatch(set_selected_appointment(data))
  }

  const { mutate: confirmFillForm, isLoading: isLoadConfirmFillForm } = useMutation(async (appointment: any) => {
    if (appointment.mh_form_status > 0) {
      await onUpdateAppointment({ id: appointment.id, mh_form_status: 2 })
    }
    else if (appointment.burnout_form_status > 0) {
      await onUpdateAppointment({ id: appointment.id, burnout_form_status: 2 })
    }
    readAppointment(3, 0, query)
  })

  const { mutate: accessFormLink, isLoading: isLoadAccessFormLink } = useMutation(async (appointment: any) => {
    const form_type = appointment.burnout_form_status > 0 ? 'Burnout' : 'Mental Health'
    const { data } = await APIRequest({
      method: 'GET',
      path: '/health-care/mh-check/',
      params: { healthcare: appointment.health_care_id, form_type }
    })
    return data
  }, {
    onSuccess: (data) => {
      if (data.length) {
        window.open(data.at(0).form_link, '_blank')
      }
    },
  })

  const onToggleConfirm = (appointment) => {
    const new_appointment = data.results.map(item => item.id === appointment.id ? ({ ...item, confirm_form: !item.confirm_form }) : item)
    dispatch(set_appointment_data({ ...data, results: new_appointment }))
  }

  useEffect(() => {
    readAppointment(3, 0, query)
  }, []);

  return data.results.length > 0 ? (
    <div className="space-y-2 w-full grid">
      {dialog.appointment_location && <DialogLocationRoute is_offline_patient />}
      {data.results.map(item => (
        <Card key={item.id} >
          <div className="m-4 flex gap-2">
            <img src={toAbsolutePath(item.health_care?.profile_img) || NoImage} className="h-16 aspect-square rounded-full max-xs:hidden" />
            <div className="grid grid-cols-4 max-md:grid-cols-1 gap-2 w-full text-sm text-gray-400">
              <div className="col-span-2 text-lg text-black font-medium">{item.health_care?.detail?.fullname}</div>
              <div className="col-span-2 flex gap-2 md:justify-self-end">
                {(item.location === 'Online') && ['Accepted', 'On Going'].includes(item.status) &&
                  <Badge
                    onClick={() => window.open(item.meet_link || '/', '_blank')}
                    className="hover:bg-primary/80 bg-primary text-white animate-bounce duration-1000 h-6 font-semibold"
                  >
                    Meeting Link
                  </Badge>}
                {(item.location === 'Online' && ['Accepted', 'On Going'].includes(item.status) && [item.burnout_form_status, item.mh_form_status].includes(1)) &&
                  <Popconfirm
                    title={<div className="w-60">Apakah anda sudah mengisi assesment mental health dari tenaga ahli berikut?</div>}
                    onCancel={() => { accessFormLink(item); onToggleConfirm(item) }}
                    onConfirm={() => { confirmFillForm(item); onToggleConfirm(item) }}
                    okText="Sudah"
                    cancelText="Belum"
                    okButtonProps={{ className: 'bg-primary', loading: isLoadConfirmFillForm }}
                    cancelButtonProps={{ danger: true, loading: isLoadAccessFormLink }}
                    open={item.confirm_form}
                  >
                    <Badge
                      className="hover:bg-violet-300 bg-violet-500 text-white animate-bounce duration-1000 h-6 font-semibold"
                      onClick={() => onToggleConfirm(item)}
                    >
                      Isi Form
                    </Badge>
                  </Popconfirm>}
                {(item.location === 'Online' && ['Accepted', 'On Going'].includes(item.status) && [item.burnout_form_status, item.mh_form_status].includes(2)) &&
                  <Badge
                    className="hover:bg-violet-300 bg-violet-500 text-white animate-bounce duration-1000 h-6 font-semibold"
                    onClick={() => accessFormLink(item)}
                  >
                    Isi Ulang Form
                  </Badge>}
                {(item.location !== 'Homecare' && item.location !== 'Online') && ['Accepted', 'On Going'].includes(item.status) &&
                  <Badge
                    onClick={() => seeLocationAppointment(item)}
                    className="hover:bg-primary/80 bg-primary text-white animate-bounce duration-1000 h-6 font-semibold"
                  >
                    Liat Lokasi
                  </Badge>}
                <Badge className={cn(`hover:${colors[item.status]}`, 'text-black col-span-1 h-6 w-fit', colors[item.status])}>{status[item.status]}</Badge>
              </div>
              <div className="md:col-span-4">{item.health_care?.detail?.profession}</div>
              <div className="flex gap-1"><BuildingIcon className="h-5 text-primary" />{['Online', 'Homecare'].includes(item.location) ? item.location : 'Offline'}</div>
              <div className="flex gap-1"><CalendarDaysIcon className="h-5 text-primary" />{item.date}</div>
              <div className="md:col-span-2 flex gap-1"><ClockIcon className="h-5 text-primary" />{item.time_range}</div>
              <div className="flex gap-1"><UserIcon className="h-5 text-primary" />{item.user_child ? item.user_child.first_name : item.user?.first_name} {item.user_child ? item.user_child.last_name : item.user?.last_name}</div>
              <div className="flex flex-wrap gap-1"><CrossIcon className="h-5 text-primary" />{item.condition}</div>
            </div>
          </div>
        </Card>
      ))
      }
      {
        (data.next || data.previous) && <div className="justify-end flex gap-2">
          <Button variant="ghost" onClick={() => onPaginateAppointment(data, 'previous')} disabled={!data.previous}><ChevronLeftIcon /></Button>
          <Button variant="ghost" onClick={() => onPaginateAppointment(data, 'next')} disabled={!data.next}><ChevronRightIcon /></Button>
        </div >
      }
    </div >
  ) : (
    <div className="h-36 space-y-2 py-8 justify-center text-center rounded-md bg-white shadow shadow-secondary/70 px-4">
      <p className="text-xl font-semibold">Ingin konsultasi dengan tenaga ahli Fellas?</p>
      <Button variant="outline" className="w-32" onClick={() => navigate('/health-care?filter=&profession=all')}>Atur Janji</Button>
    </div>
  );
}

export default DashboardAppointment;