import { zodResolver } from "@hookform/resolvers/zod";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from 'react-hook-form';
import { ReactComponent as GoogleIcon } from "src/assets/icons/Google.svg";
import { Button } from 'src/components/ui/button';
import { Form, FormField, FormItem, FormMessage } from "src/components/ui/form";
import { Input } from 'src/components/ui/input';
import { ToastAction } from "src/components/ui/toast";
import { ToastAlert } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { onLogin } from "src/config/services/core/auth.api";
import { onCreateEmailVerification } from "src/config/services/core/user.api";
import { z } from 'zod';
import { setAuthPage } from ".";

function LoginForm() {
  const { toast } = useToast()
  const googleLogin = useGoogleLogin({
    flow: 'implicit',
    onSuccess(tokenResponse) {
      onLogin({ access_token: tokenResponse.access_token, oauth: 'google' })
        .then(resp => resp?.status === 500 && (
          toast({
            variant: 'destructive',
            title: `[error] Something when wrong!`,
            description: `Account already registered in fellas, login with your username and password`
          })
        ))
    },
    onError(errorResponse) {
      toast({
        variant: 'destructive',
        title: `[errro] Something when wrong!`,
        description: `Account already registered in fellas, login with your username and password`
      })
    },
  })

  const FormSchema = z.object({
    username: z.string()
      .min(6, { message: "Username must be at least 6 characters." }),
    password: z.string()
      .min(6, { message: "Password must be at least 6 characters." }),
  })



  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      username: "",
      password: "",
    }
  })

  const resendVerificationEmail = async (user_id) => {
    const { data: response } = await onCreateEmailVerification({ token: '0', user_id })
    ToastAlert(response, 'Email verifikasi terkirim, silahkan cek email')
  }

  const onSubmitLogin = async (data: z.infer<typeof FormSchema>) => {
    const { username, password } = data
    const resp = await onLogin({ username, password })
    if (resp?.error?.Unauthorized) {
      toast({
        variant: 'destructive',
        title: `[Unauthorized]`,
        description: resp?.error?.Unauthorized,
        action: (
          <ToastAction altText="Resend verification email" onClick={() => resendVerificationEmail(resp.user_id)}>Verifikasi Ulang</ToastAction>
        )
      })
    } else {
      ToastAlert(resp, `Berhasil masuk ke akun ${username}`)

    }
  }

  return (
    <div className='container flex flex-col gap-4 justify-center h-full text-center'>
      <div className='text-md space-y-1'>
        <div className='text-3xl font-semibold text-center'>Login to your account</div>
        <p>
          You don't have an account?
          <a className='text-primary cursor-pointer' onClick={setAuthPage('register')}> Register here!</a>
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitLogin)} className="space-y-3">
          <FormField control={form.control} name="username"
            render={({ field }) => (
              <FormItem className="text-center">
                <Input className="bg-white" placeholder="Username" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField control={form.control} name="password"
            render={({ field }) => (
              <FormItem className="text-center">
                <Input className="bg-white" type="password" placeholder="Password" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
          <hr />
          <Button type="submit" className="w-full">Login</Button>
        </form>
      </Form>
      <div className="grid grid-cols-3 gap-4 align-middle">
        <hr className="my-auto" />
        <p className="text-muted-foreground/50 text-xs">Or continue with</p>
        <hr className="my-auto" />
      </div>
      <div className="flex justify-center gap-4">
        <Button onClick={() => googleLogin()} className="bg-white hover:bg-gray-50 w-full shadow active:bg-gray-100 cursor-pointer">
          <GoogleIcon className="w-5 h-5" />
        </Button>
      </div>
      <p>
        Forgot your password?
        <a className='text-primary cursor-pointer' onClick={setAuthPage('forgot_password')}> Click here!</a>
      </p>
    </div>
  );
}

export default LoginForm;