import * as DialogPrimitive from "@radix-ui/react-dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { BuildingIcon, CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon, CrossIcon, Star, StarHalf, UserIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoImage from "src/assets/images/NoImage.png";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Textarea } from "src/components/ui/textarea";
import { ToastAlert } from "src/components/ui/toaster";
import { set_selected_appointment } from "src/config/redux/slicer/dialog/appointmentDialogSlicer";
import { set_appointment_data } from "src/config/redux/slicer/main/myAppointmentSlicer";
import { getCurrentUser } from "src/config/services/core/auth.api";
import { onGetAppointment, onPaginateAppointment, onUpdateAppointment } from "src/config/services/fellas/appointment.api";
import { cn, toAbsolutePath } from "src/lib/utils";
import { colors, status } from "../dashboard";
import { Rate } from "antd";

function HistoryAppointment() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data } = useSelector((state: any) => state.MyAppointment)
  const { selected_appointment } = useSelector((state: any) => state.AppointmentDialog)
  const [current_page, setCurrentPage] = useState(0)
  const query = `&user_id=${getCurrentUser().id}&status=Completed`
  const readAppointment = async (limit, offset, query?) => {
    const resp = await onGetAppointment({ query: `?limit=${limit}&offset=${offset}` + (query || '') })
    dispatch(set_appointment_data({ ...resp, limit, offset, query }))
  }
  useEffect(() => {
    readAppointment(3, 0, query)
  }, []);

  // const [current_rating, setCurrentRating] = useState(0)
  const updateReviewField = (value: any, field: 'description' | 'rating') => {
    const review = { ...selected_appointment.review }
    review[field] = value
    dispatch(set_selected_appointment({ ...selected_appointment, review }))
  }

  const submitReview = async (e: React.FormEvent) => {
    e.preventDefault()
    const { id, review } = selected_appointment
    const resp = await onUpdateAppointment({ id, review })
    ToastAlert(resp, 'Terimakasih atas penilaiannya')
    await readAppointment(3, current_page, query)
  }
  return data.results.length > 0 ? (
    <div className="space-y-2 w-full grid">
      {data.results.map(item => (
        <Card key={item.id}>
          <div className="m-4 flex gap-2">
            <img src={toAbsolutePath(item.health_care?.profile_img) || NoImage} className="h-16 aspect-square rounded-full max-xs:hidden" />
            <div className="grid grid-cols-4 max-md:grid-cols-1 gap-2 w-full text-sm text-gray-400">
              <div className="col-span-2 text-lg text-black font-medium">{item.health_care?.detail?.fullname}</div>
              <div className="col-span-2 flex gap-2 md:justify-self-end items-center">
                {(item.location === 'Online') && (item.status !== 'Completed') &&
                  <Badge
                    onClick={() => item.status === 'Accepted' && window.open(item.meet_link || '/', '_blank')}
                    className="hover:bg-primary/80 bg-primary text-white animate-bounce duration-1000 h-6 font-semibold"
                  >
                    Meeting Link
                  </Badge>}
                <div className="flex">

                  {item.review?.rating && <Rate value={item.review?.rating} className="text-sm" disabled />}
                </div>
                {!item.review?.rating && item.status === 'Completed' && (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Badge onClick={() => !item.review?.rating && dispatch(set_selected_appointment({ ...item, review: { rating: 0, description: '' } }))} className="text-black h-6 bg-yellow-200 animate-bounce duration-1000"><StarHalf className="h-3 w-3" />Rating</Badge>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader className="font-semibold">Beri Penilaian</DialogHeader>
                      <form onSubmit={submitReview} className="space-y-2">
                        <div className="flex gap-2 justify-center">
                          <Rate
                            allowHalf allowClear autoFocus
                            className="text-3xl"
                            value={selected_appointment.review?.rating || 0}
                            onChange={val => updateReviewField(val, 'rating')}
                          />
                        </div>
                        <Textarea
                          rows={5}
                          placeholder={`Bagaimana pengalaman konsultasi dengan ${item.health_care?.detail?.fullname}?`}
                          defaultValue={item.review?.description}
                          onChange={e => updateReviewField(e.target.value, 'description')}
                        />
                        <DialogPrimitive.Close className="w-full">
                          <Button disabled={!(selected_appointment.review?.rating > 0)} className="w-full" type="submit">Submit</Button>
                        </DialogPrimitive.Close>
                      </form>
                    </DialogContent>
                  </Dialog>
                )}
                {item.report.includes('/app/medical-report')
                  ? <Badge className="bg-violet-300 hover:bg-violet-300 text-black h-6 w-fit" onClick={() => navigate(item.report)}>Rekam Medis</Badge>
                  : <Badge className={cn(colors[item.status], `hover:${colors[item.status]}`, 'text-black h-6 w-fit')}>{status[item.status]}</Badge>
                }
              </div>
              <div className="md:col-span-4">{item.health_care?.detail?.profession}</div>
              <div className="flex gap-1"><BuildingIcon className="h-5 text-primary" />{['Online', 'Homecare'].includes(item.location) ? item.location : 'Offline'}</div>
              <div className="flex gap-1"><CalendarDaysIcon className="h-5 text-primary" />{item.date}</div>
              <div className="md:col-span-2 flex gap-1"><ClockIcon className="h-5 text-primary" />{item.time_range}</div>
              <div className="flex gap-1"><UserIcon className="h-5 text-primary" />{item.user_child ? item.user_child.first_name : item.user?.first_name} {item.user_child ? item.user_child.last_name : item.user?.last_name}</div>
              <div className="flex flex-wrap gap-1"><CrossIcon className="h-5 text-primary" />{item.condition}</div>
            </div>
          </div>
        </Card>
      ))}
      {(data.next || data.previous) && <div className="md:justify-end flex gap-2">
        {/* <Button variant="ghost" onClick={() => { onPaginateAppointment(data, 'previous'); setCurrentPage(page => page - 1) }} disabled={!data.previous}><ChevronLeftIcon /></Button>
        <Button variant="ghost" onClick={() => { onPaginateAppointment(data, 'next'); setCurrentPage(page => page - 1) }} disabled={!data.next}><ChevronRightIcon /></Button> */}
      </div >}
    </div >)
    : (
      <div className="h-36 space-y-2 py-8 justify-center text-center rounded-md bg-white shadow shadow-secondary/70 px-4">
        <p className="text-xl font-semibold">Ingin konsultasi dengan tenaga ahli Fellas?</p>
        <Button variant="outline" className="w-32" onClick={() => navigate('/health-care?filter=&profession=all')}>Atur Janji</Button>
      </div>
    );
}

export default HistoryAppointment;